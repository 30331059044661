import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ModalWrapper } from './modal_wrapper'

import { Item, Listing, ListingFee, ListingWarning, Unit } from '../types'
import { useForm, useWatch } from 'react-hook-form'
import { conditions } from '../lib/conditions'
import { listingsRepository } from '../repositories/listings_repository'
import { AxiosError } from 'axios'
import { AuthContext } from '../contexts/auth_context'

type Props = Readonly<{
  items: Item[]
  onClose: (listing?: Listing) => void
  platform: string
  unit: Unit
  workingListing?: Listing
}>

const ListingModal = ({ onClose, items, platform, unit, workingListing }: Props): JSX.Element => {
  const { t } = useTranslation()

  const { currentUser } = useContext(AuthContext)

  const [useDefaultItemTitle, setUseDefaultItemTitle] = useState<boolean>(true)
  const [inheritPrice, setInheritPrice] = useState<boolean>(true)
  const [showInheritPriceHelp, setShowInheritPriceHelp] = useState<boolean>(false)
  const [completeListing, setCompleteListing] = useState<Listing|undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [fees, setFees] = useState<ListingFee[]>()
  const [warnings, setWarnings] = useState<ListingWarning[]>()

  const holicListing = useMemo<Listing|undefined>(() => {
    return unit.listings?.find(l => l.platform === 'holic')
  }, [unit.listings])

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
  } = useForm()

  const condition = useMemo(() => {
    const c = conditions.find(c => c.id === unit.condition)

    return c?.nameListing
  }, [unit])

  const watchPriceSetting = useWatch({
    control,
    name: 'priceInherit'
  })

  const watchTitleFormat = useWatch({
    control,
    name: 'titleFormat'
  })

  useEffect(() => {
    setInheritPrice(watchPriceSetting === 'true')
  }, [watchPriceSetting])

  useEffect(() => {
    if (workingListing) return

    // defaults for new listing

    if (!watchPriceSetting && holicListing) {
      setValue('priceInherit', 'true')
    }
    if (!watchTitleFormat) {
      setValue('titleFormat', 'auto')
    }
  })

  useMemo(() => {
    reset({
      ...workingListing,
      titleFormat: 'custom',
      priceInherit: workingListing?.parentId ? 'true' : 'false',
    })

    // FIXME
    // setValue('titleFormat', 'custom')
    // setValue('priceInherit', workingListing?.parentId ? 'true' : 'false')
  }, [reset, workingListing])

  useEffect(() => {
    setUseDefaultItemTitle(watchTitleFormat === 'auto')
  }, [watchTitleFormat])

  const category: string = useMemo(() => {
    const item = items[0]

    if (!item.tags) return ''

    const c = item.tags.find(c => c.type === 'Category')

    return c?.nameEn ?? ''
  }, [items])

  const titleMaxLength = useMemo(() => platform === 'ebay' ? 80 : 200, [platform])
  const platformTitle = useMemo(() => platform === 'ebay' ? t('listings.platform_title_ebay') : '', [platform, t])

  const defaultItemTitle = useMemo(() => {
    // structure:
    // [card name] [card set] - [condition] - [card game]

    const conditionText: string = condition ? ` - ${condition}` : ''
    const game = ' - Pokemon'

    let tentativeLength = (conditionText + game).length
    let remainingCharacters = titleMaxLength - tentativeLength

    const item = items[0]

    const title = String(item.nameEn ?? item.nameJa).substring(0, remainingCharacters)

    tentativeLength = tentativeLength + title.length
    remainingCharacters = titleMaxLength - tentativeLength

    const categoryText: string = category ? (` - ${category}`).substring(0, remainingCharacters) : ''

    return `${title}${categoryText}${conditionText}${game}`
  }, [category, condition, items, titleMaxLength])

  useEffect(() => {
    if (!useDefaultItemTitle || workingListing) return

    setValue('title', defaultItemTitle)
  }, [defaultItemTitle, setValue, useDefaultItemTitle, workingListing])

  const defaultItemDescription = useMemo(() => {
    const conditionText: string = !condition ? '' : `${condition} condition`

    const item = items[0]

    return `${item.nameEn ?? item.nameJa ?? ''}
${category ?? ''}
${conditionText}`
  }, [category, condition, items])

  useEffect(() => {
    if (workingListing) return

    setValue('description', defaultItemDescription)
  }, [defaultItemDescription, setValue, workingListing])

  const onSubmit = useCallback((params) => {
    if (loading) return

    setLoading(true)

    // TODO: Validation
    const newListing: Listing = {
      ...params,
      platform,
      unitId: unit.id,
      parentId: inheritPrice ? holicListing?.id : null,
    }

    if (workingListing) {
      // updating an existing listing
      listingsRepository
        .update({ params: newListing, id: workingListing.id ?? 0 })
        .then(({ listing, fees, warnings }) => {
          setCompleteListing(listing)
          setWarnings(warnings)
          setFees(fees)
        })
        .catch((err: AxiosError) => {
          // eslint-disable-next-line no-console
          console.error(err)
          // TODO: make error notify common function.
          alert(err)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      // creating a new listing
      listingsRepository
        .create(newListing)
        .then(({ listing, fees, warnings }) => {
          setCompleteListing(listing)
          setWarnings(warnings)
          setFees(fees)
        })
        .catch((err: AxiosError) => {
          // eslint-disable-next-line no-console
          console.error(err)
          // TODO: make error notify common function.
          alert(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [holicListing?.id, inheritPrice, loading, platform, unit.id, workingListing])

  const ebayLink = useMemo<string|undefined>(() => {
    if (!completeListing?.platformId) return

    return 'https://ebay.com/itm/' + completeListing.platformId
  }, [completeListing])

  const listingFee = useMemo<number>(() => {
    const f = fees?.map(f => Number(f.fee)).reduce((prev, next) => (prev + next))

    return f ?? 0
  }, [fees])

  const onclickShowInheritPriceHelp = useCallback((e) => {
    e.preventDefault()
    setShowInheritPriceHelp(a => !a)
  }, [])

  const deleteListingOnClick = useCallback(() => {
    if (confirm(t('listings.delete_confirm'))) {
      setLoading(true)

      if (!workingListing?.id) return alert('error')

      listingsRepository
        .destroy(workingListing?.id)
        .then(() => {
          onClose(undefined)
        })
        .catch((err: AxiosError) => {
          // eslint-disable-next-line no-console
          console.error(err)
          // TODO: make error notify common function.
          alert(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [onClose, t, workingListing?.id])

  if (
    (!unit.images || unit.images.length === 0) &&
    platform === 'ebay'
  ) {
    return (
      <ModalWrapper>
        <h1>{ t('listings.photos_none.title') }</h1>
        <p>
          { t('listings.photos_none.body1') }
        </p>
        <p>
          { t('listings.photos_none.body2') }
        </p>
        <p>
          <button className="full" onClick={ () => onClose(workingListing) }>
            { t('listings.photos_none.button_close') }
          </button>
        </p>
      </ModalWrapper>
    )
  }

  if (completeListing) {
    if (!currentUser) return <></>

    return (
      <ModalWrapper>
        {
          workingListing
            ? <h1>{ t('listings.complete.title_updated') }</h1>
            : <h1>{ t('listings.complete.title_new') }</h1>
        }
        <p>
          {
            workingListing
              ? t('listings.complete.body_updated')
              : t('listings.complete.body_new')
          }
        </p>
        <p>
          { t('listings.complete.fee') }<strong>${ listingFee.toFixed(2) }</strong> { listingFee > 0 ? null : '🎉' }
        </p>
        {
          listingFee > 0
            ? <p>{ t('listings.complete.fee_explaination') }</p>
            : null
        }
        {
          warnings && warnings.length > 0
            ? (
              <>
                <h3>{ t('listings.complete.messages') }</h3>
                <ul className="editable">{ warnings.map((w, i) => <li key={ i }>{ w.longMessage }</li>) }</ul>
              </>
              )
            : null
        }
        <p>
          <a href={ ebayLink } target="_blank" rel="noreferrer" className="button full white">{ t('listings.complete.button_view') }</a>
        </p>
        <p>
          <Link to={ `/u/${currentUser.id}` } className="button full white">{ t('listings.complete.button_collection') }</Link>
        </p>
        <p>
          <button className="full" onClick={ () => onClose(completeListing) }>{ t('listings.complete.button_close') }</button>
        </p>
      </ModalWrapper>
    )
  }

  return (
    <ModalWrapper>
      {
        workingListing
          ? <h1>{ t('listings.edit.title_update', { title: platformTitle }) }</h1>
          : <h1>{ t('listings.edit.title_new', { title: platformTitle }) }</h1>
      }
      <form onSubmit={ handleSubmit(onSubmit) } className="single-line">
        { !useDefaultItemTitle
          ? (
            <p>
              <label>{ t('listings.edit.title_custom') }</label>
              <input type="text" className='full' { ...register('title') } maxLength={ titleMaxLength } required />
            </p>
            )
          : <h3>{ defaultItemTitle }<input type="hidden" { ...register('title') } value={ defaultItemTitle } /></h3>
        }
        <div>
          <label>
            { t('listings.edit.title.label') }
          </label>
          <div>
            <label>
              <input type="radio" value='auto' { ...register('titleFormat') } /> { t('listings.edit.title.automatic') }
            </label>
            <label>
              <input type="radio" value='custom' { ...register('titleFormat') } /> { t('listings.edit.title.custom') }
            </label>
          </div>
        </div>
        <label>{ t('listings.edit.description') }</label>
        <p>
          <textarea { ...register('description') } rows={ 4 } autoFocus></textarea>
        </p>
        {
          holicListing
            ? (
              <div>
                <label>
                  { t('listings.edit.price.label') }
                </label>
                <div>
                  <label>
                    <input type="radio" { ...register('priceInherit') } value='true' /> { t('listings.edit.price.dynamic') }
                  </label>
                  <label>
                    <input type="radio" { ...register('priceInherit') } value='false' /> { t('listings.edit.price.fixed') }
                  </label>
                </div>
              </div>
              )
            : null
        }
        {
          inheritPrice && holicListing
            ? (
              <p>
                <label>{ t('listings.edit.price_dynamic') }</label>
                <span><strong>{ t('listings.price.price_with_currency_symbol', { price: Math.round(holicListing.priceJpy) }) }</strong> { t('listings.edit.price_dynamic_fee') }</span>
              </p>
              )
            : (
              <p>
                <label>{ t('listings.edit.price_fixed') }</label>
                <label>
                  <span>$</span>
                  <input className="usd" type="number" step="1" min="0" { ...register('priceJpy') } required autoFocus />
                </label>
              </p>
              )
        }
        {
          holicListing
            ? <p><button className="tool" onClick={ (e) => onclickShowInheritPriceHelp(e) }>{ showInheritPriceHelp ? t('listings.edit.price.help_close') : t('listings.edit.price.help_label') }</button></p>
            : null
        }
        {
          showInheritPriceHelp
            ? (
              <p>{ t('listings.edit.price.help') }</p>
              )
            : null
        }
        <button className="full" disabled={ loading }>{ workingListing ? t('listings.button_confirm_update') : t('listings.button_confirm_new', { title: platformTitle }) }</button>
      </form>
      {
        workingListing
          ? (
            <p>
              <button className="full white" onClick={ deleteListingOnClick } disabled={ loading }>
                { t('listings.delete') }
              </button>
            </p>
            )
          : null
      }
      <p>
        <button className="full white" onClick={ () => onClose(workingListing) } disabled={ loading }>
          { t('listings.cancel') }
        </button>
      </p>
    </ModalWrapper>
  )
}

export { ListingModal }
