import { axiosInstance } from '../lib/api_client'
import { Follow, StripeAccount, User, UserSelf } from '../types'

const PATH = '/users'

type CreateParams = Readonly<{
  email: string
  password: string
  password_confirmation: string
  collection_id?: number
  itemId?: number
}>

type UpdateBasicParams = Readonly<{
  id: number|string
  params: Readonly<{
    displayName: string
  }>
}>

type UpdateEmailParams = Readonly<{
  id: number|string
  params: Readonly<{
    email: string
    password: string
  }>
}>

type UpdatePasswordParams = Readonly<{
  id: number|string
  params: Readonly<{
    password: string
    password_new: string
    password_confirmation: string
  }>
}>

type CreateReponse = Readonly<{
  authToken: string
}>

type GetUserReponse = Readonly<{
  user: User
}>

type GetUserFollowersReponse = Readonly<{
  follows: Follow[]
}>

type GetSelfReponse = Readonly<{
  user: UserSelf
  stripeAccount?: StripeAccount
}>

type UsersRepository = Readonly<{
  create: (params: CreateParams) => Promise<CreateReponse>
  getUser: (id: number) => Promise<GetUserReponse>
  getSelf: () => Promise<GetSelfReponse>
  getFollowing: (id: number) => Promise<GetUserFollowersReponse>
  follow: (id: number) => Promise<void>
  unfollow: (id: number) => Promise<void>
  updateBasic: (params: UpdateBasicParams) => Promise<void>
  updateEmail: (params: UpdateEmailParams) => Promise<void>
  updatePassword: (params: UpdatePasswordParams) => Promise<void>
}>

const usersRepository: UsersRepository = {
  async create (params) {
    return await axiosInstance.post(PATH, { user: params }).then(({ data }) => data)
  },
  async getUser (id) {
    return await axiosInstance.get(`${PATH}/${id}`).then(({ data }) => data)
  },
  async getSelf () {
    return await axiosInstance.get(`${PATH}/me`).then(({ data }) => data)
  },
  async getFollowing (id) {
    return await axiosInstance.get(`${PATH}/${id}/following`).then(({ data }) => data)
  },
  async follow (id) {
    return await axiosInstance.post(`${PATH}/${id}/follow`, { user_id: id })
  },
  async unfollow (id) {
    return await axiosInstance.delete(`${PATH}/${id}/follow`, { data: { user_id: id } })
  },
  async updateBasic ({ params, id }) {
    return await axiosInstance.put(`${PATH}/${id}`, { user: params }).then()
  },
  async updateEmail ({ params, id }) {
    return await axiosInstance.put(`${PATH}/${id}`, { user: params }).then()
  },
  async updatePassword ({ params, id }) {
    return await axiosInstance.put(`${PATH}/${id}`, { user: params }).then()
  },
}

export { usersRepository }
