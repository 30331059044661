const queryStringFromSelection = (q: string, selection): string => {
  return selection.find(s => {
    if (typeof s === 'object') {
      if ('id' in s) return String(s.id) === q
      return false
    }
    return String(s) === q
  }) ?? selection[0]
}

export { queryStringFromSelection }
