import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { ratings } from '../lib/ratings'
import { Feedback } from '../types'

type Props = Readonly<{
  feedback: Feedback
}>

const FeedbackView = ({ feedback }: Props) => {
  const { t } = useTranslation()

  const rating = ratings.find(r => r.value === feedback.rating)

  if (!rating) return null

  return <table key={ feedback.id } className={ clsx('review', rating.value) }>
    <tbody>
      <tr>
        <td>{ t('order.feedback.left_feedback') }</td>
        <td>{ rating.label } { t(`order.feedback.${rating.value}`) }</td>
      </tr>
      {
        feedback.description
          ? <tr>
            <td>{ t('order.feedback.left_review') }</td>
            <td>{ feedback.description }</td>
          </tr>
          : null
      }
      <tr>
        <td></td>
        <td><Moment format="YYYY-MM-DD">{ feedback.createdAt }</Moment></td>
      </tr>
    </tbody>
  </table>
}

export { FeedbackView }
