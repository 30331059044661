import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from './modal_wrapper'

const UnitBuyLoadingModal = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <ModalWrapper>
      <div>
        <h1>{ t('checkout.buy_loading') }</h1>
        <p>{ t('checkout.buy_loading_wait') }</p>
      </div>
    </ModalWrapper>
  )
}

export { UnitBuyLoadingModal }
