import React, { useMemo, useState } from 'react'
import { Image, Unit } from '../types'
import { useForm } from 'react-hook-form'
import { uploadToS3 } from '../lib/s3'
import { imagesRepository } from '../repositories/images_repository'
import { unitImagesRepository } from '../repositories/unit_images_repository'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  unit?: Unit
  onClose: () => void
  callback: (image: Image) => void
  showCancelButton: boolean
}>

type UploadForm = Readonly<{
  image: FileList
}>

const validMimeTypes = new Set(['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'])

const ImageUploadModal = ({ unit, onClose, callback, showCancelButton }: Props) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
  } = useForm<UploadForm>()

  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (data: UploadForm) => {
    if (loading) return

    let imagesUploaded = 0

    for (let i = 0; i < data.image.length; ++i) {
      const im = data.image[i]

      if (!im) {
        alert(t('image_upload.err_choose_image'))
        return
      }

      if (!validMimeTypes.has(im.type)) {
        alert(t('image_upload.err_not_image'))
        return
      }

      try {
        setLoading(true)

        const uploadResult = await uploadToS3(im)

        if (unit) {
          const unitImage = await unitImagesRepository.create(Number(unit.id), { ...uploadResult, detect: false })
          callback(unitImage)
        } else {
          const image = await imagesRepository.create({ ...uploadResult, detect: true })
          callback(image)
        }

        onClose()
      } catch (error) {
        alert(t('image_upload.err_upload_failed'))
      } finally {
        imagesUploaded = imagesUploaded + 1

        if (imagesUploaded === data.image.length) {
          setLoading(false)
        }
      }
    }
  }

  const uploadButton = useMemo(() => {
    if (loading) {
      return (
        <p>
          <button className='small full' disabled>{ t('image_upload.upload_progress') }</button>
        </p>
      )
    }

    const uploadText: string = t('image_upload.upload')

    return (
      <div>
        <p>
          <input type='submit' value={ uploadText } className='small full' />
        </p>
        {
          showCancelButton
            ? <p>
              <button className='tool full' onClick={ onClose }>{ t('image_upload.cancel') }</button>
            </p>
            : null
        }
      </div>
    )
  }, [loading, onClose, showCancelButton, t])

  return (
    <form onSubmit={ handleSubmit(onSubmit) }>
      <p>
        { t('image_upload.help') }
      </p>
      <p>
        <input
          { ...register('image') }
          type='file'
          accept='image/*'
          multiple
        />
      </p>
      {
        uploadButton
      }
    </form>

  )
}

export { ImageUploadModal }
