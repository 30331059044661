import { axiosInstance } from '../lib/api_client'

const PATH = '/stripe_checkout_sessions'

type ListingWithQuantity = Readonly<{
  id: number
  quantity: number
}>

type CreateParams = Readonly<{
  addressId?: number
  regionShippingServiceId?: number
  listingIds: ListingWithQuantity[]
  returnPath: string
  referral: string
  points: number
}>

type CreateReponse = Readonly<{
  sessionId: string
}>

type StripeCheckoutSessionsRepository = Readonly<{
  create: (params: CreateParams) => Promise<CreateReponse>
}>

const stripeCheckoutSessionsRepository: StripeCheckoutSessionsRepository = {
  async create (params) {
    return await axiosInstance.post(PATH, { stripeCheckoutSession: params }).then(({ data }) => data)
  },
}

export { stripeCheckoutSessionsRepository }
