import React, { Suspense, useContext, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactGA from 'react-ga'
import './i18n'

import { Footer } from './footer'
import { Header } from './header'
import { Modal } from './modal'
import { Router } from './router'

import '../stylesheets/Modal.scss'
import { AuthProvider, AuthContext } from '../contexts/auth_context'
import { BasketProvider } from '../contexts/basket_context'
import { SettingsProvider } from '../contexts/settings_context'
import { ErrorsProvider } from '../contexts/errors_context'
import { SearchProvider } from '../contexts/search_context'

// FIXME: This should probably be set in config
ReactGA.initialize('UA-42912969-1')

const App = () => {
  const gitSha = process.env.REACT_APP_GIT_SHA ?? ''
  const { reloadCurrentUser } = useContext(AuthContext)

  useEffect(() => {
    if (reloadCurrentUser) {
      reloadCurrentUser().then(() => {})
    }
  }, [reloadCurrentUser])

  return (
    <AuthProvider>
      <SettingsProvider>
        <BasketProvider>
          <SearchProvider>
            <ErrorsProvider>
              <div className="App">
                <Suspense fallback="">
                  <BrowserRouter>
                    <Header />
                    <Router gitSha={ gitSha } />
                    <Footer />
                    <Modal />
                  </BrowserRouter>
                </Suspense>
              </div>
            </ErrorsProvider>
          </SearchProvider>
        </BasketProvider>
      </SettingsProvider>
    </AuthProvider>
  )
}

export default App
