const ratings = [
  {
    label: '💫',
    value: 'positive',
  },
  {
    label: '😕',
    value: 'neutral',
  },
  {
    label: '🌧',
    value: 'negative',
  },
]

export { ratings }
