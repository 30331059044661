import { AxiosError } from 'axios'
import clsx from 'clsx'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { AuthContext } from '../contexts/auth_context'
import { ErrorsContext } from '../contexts/errors_context'
import { copyToClipboard } from '../lib/copy_to_clipboard'
import { balancesRepository } from '../repositories/balances_repository'
import { referralsRepository } from '../repositories/referrals_repository'
import { usersRepository } from '../repositories/users_repository'
import { Balance, BalanceData, OrderReferral, Pagination, Referral, StripeAccount } from '../types'
import { ModalWrapper } from './modal_wrapper'

type WithdrawModalProps = Readonly<{
  onClose: (didWithdraw: boolean) => void
  balance: number
  withdrawalsThisMonth: number
  withdrawalFee: number
}>

type WithdrawForm = Readonly<{
  amount: number
  withdrawalFee: number
}>

const WithdrawModal = ({ onClose, balance, withdrawalsThisMonth, withdrawalFee }: WithdrawModalProps) => {
  const { t } = useTranslation()

  const { currentUser } = useContext(AuthContext)
  const { addError } = useContext(ErrorsContext)

  const [loading, setLoading] = useState(false)

  const [complete, setComplete] = useState(false)

  const {
    control,
    register,
    handleSubmit,
    setValue,
  } = useForm<WithdrawForm>({
    defaultValues: {
      amount: balance - withdrawalFee,
      withdrawalFee,
    }
  })

  const amountValue = useWatch({
    control,
    name: 'amount',
  })

  const onSubmit = useCallback((data: WithdrawForm) => {
    if (!currentUser) return

    setLoading(true)

    balancesRepository
      .withdraw({
        userId: currentUser.id,
        amount: data.amount,
        withdrawalFee: data.withdrawalFee,
      })
      .then(() => {
        setComplete(true)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError, currentUser])

  const balanceRemaining = useMemo(() => {
    return balance - amountValue - withdrawalFee
  }, [amountValue, balance, withdrawalFee])

  const balanceTooLowWarning = useMemo(() => {
    if (balanceRemaining >= 0) return

    return <p>
      { t('account.points.withdraw_modal.balance_too_low_warning') }
    </p>
  }, [balanceRemaining, t])

  const setBalanceToMax = useMemo(() => {
    const max = balance - withdrawalFee

    if (amountValue === max) return

    return <p>
      <label></label>
      <button
        className="tool"
        onClick={ (e) => {
          e.preventDefault()
          setValue('amount', max)
        } }
      >
        { t('account.points.withdraw_modal.set_balance_to_max') }
      </button>
    </p>
  }, [amountValue, balance, setValue, t, withdrawalFee])

  const isValidAmount = useMemo(() => {
    return (
      !loading &&
      balanceRemaining >= 0 &&
      !Number.isNaN(amountValue) &&
      amountValue > 0
    )
  }, [amountValue, balanceRemaining, loading])

  if (complete) {
    return <ModalWrapper>
      <div className="center">
        <h1>{ t('account.points.withdraw_complete_modal.title') }</h1>
      </div>
      <p>
        { t('account.points.withdraw_complete_modal.body') }
      </p>
      <p>
        <button onClick={ _ => onClose(true) } className='full'>{ t('account.points.withdraw_complete_modal.button') }</button>
      </p>
    </ModalWrapper>
  }

  return (
    <ModalWrapper>
      <div>
        <h1>{ t('account.points.withdraw_modal.title') }</h1>
        <form key={ 'basic' } onSubmit={ handleSubmit(onSubmit) } className='single-line'>
          <p>
            <label>{ t('account.points.withdraw_modal.original_balance') }</label>
            <label>{ t('account.points.withdraw_modal.points', { value: balance.toLocaleString() }) }</label>
          </p>
          <p>
            <label>{ t('account.points.withdraw_modal.amount') }</label>
            <input type="text" required { ...register('amount') } disabled={ loading } max={ balance - withdrawalFee } />
          </p>
          { setBalanceToMax }
          <p>
            <label>{ t('account.points.withdraw_modal.amount_to_receive') }</label>
            <label>{ amountValue.toLocaleString() }円</label>
          </p>
          <p>
            <label>{ t('account.points.withdraw_modal.withdrawal_fee') }</label>
            <label>{ withdrawalFee.toLocaleString() }円</label>
          </p>
          {
            withdrawalsThisMonth > 0 && (
              <p className='small'>
                { t('account.points.withdraw_modal.withdrawal_fee_discount') }
              </p>
            )
          }
          <p>
            <label>{ t('account.points.withdraw_modal.remaining_balance') }</label>
            <label>{ t('account.points.withdraw_modal.points', { value: balanceRemaining.toLocaleString() }) }</label>
          </p>
          { balanceTooLowWarning }
          <p>
            <button type="submit" className='full' disabled={ !isValidAmount }>{ t('account.points.withdraw_modal.confirm') }</button>
          </p>
        </form>
        <p>
          <button onClick={ _ => onClose(false) } className='full white'>{ t('account.points.withdraw_modal.cancel') }</button>
        </p>
      </div>
    </ModalWrapper>
  )
}

type Props = Readonly<{
  currentPage: number
}>

const AccountPoints = ({ currentPage }: Props) => {
  const { t } = useTranslation()

  const { currentUser } = useContext(AuthContext)
  const { addError } = useContext(ErrorsContext)

  const [balances, setBalances] = useState<Balance[]>([])
  const [balanceData, setBalanceData] = useState<BalanceData>()

  const [loading, setLoading] = useState(true)

  const [showWithdrawModal, setShowWithdrawModal] = useState(false)

  // pagination for referrals
  const [referralsPagination, setReferralsPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
  })
  // pagination
  const [pagination, setPagination] = useState<Pagination>({
    currentPage,
    totalPages: 1,
  })

  const getBalance = useCallback(() => {
    if (!currentUser) return

    setLoading(true)

    balancesRepository
      .index({ userId: currentUser.id, params: { page: currentPage, limit: 10 } })
      .then(({ balances, balanceData, pagination }) => {
        setBalances(balances)
        setBalanceData(balanceData)
        setPagination(pagination)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError, currentPage, currentUser])

  useEffect(() => {
    getBalance()
  }, [getBalance])

  const inEligibleNotice = useMemo(() => {
    if (!balanceData || !currentUser || balanceData.eligibleForWithdrawal) return

    if (!currentUser.canPayout) {
      return <p>
        { t('account.points.balance.no_bank_account_notice') }
      </p>
    }

    return <p>
      { t('account.points.balance.new_seller_withdraw_notice') }
    </p>
  }, [balanceData, currentUser, t])

  const totalBalance = useMemo(() => {
    if (!currentUser) return 0

    return currentUser.points
  }, [currentUser])

  const balance = useMemo(() => {
    if (!balanceData) return

    return (
      <div>
        <h2>{ t('account.points.balance.title', { balance: showWithdrawModal ? '' : totalBalance.toLocaleString() }) }</h2>
        <p>
          <span>{ t('account.points.balance.withdrawable') }</span><strong>{ showWithdrawModal ? '' : balanceData.withdrawableBalance.toLocaleString() }</strong>
        </p>
        { inEligibleNotice }
        {
          balanceData.withdrawableBalance > 0 && balanceData.eligibleForWithdrawal
            ? balanceData.withdrawableBalance > balanceData.withdrawalFee
              ? (
                <p>
                  <button onClick={ () => setShowWithdrawModal(true) } className='tool'>{ t('account.points.balance.button_withdraw') }</button>
                </p>
                )
              : <>
                <p>
                  <button className='tool' disabled>{ t('account.points.balance.button_withdraw') }</button>
                </p>
                <p>
                  { t('account.points.balance.balance_insufficient_for_fee') }
                </p>
              </>
            : null
        }
        <p><Link to="/about/fees">{ t('account.points.balance.about') }</Link></p>
      </div>
    )
  }, [balanceData, inEligibleNotice, showWithdrawModal, t, totalBalance])

  const closeModal = useCallback((didWithdraw: boolean) => {
    setShowWithdrawModal(false)

    if (didWithdraw) {
      getBalance()
    }
  }, [getBalance])

  const transactionHistoryPagination = useMemo(() => {
    return <div className="pagination">
      <span>{ t('Page:') }</span>
      {
        Array.apply(null, Array(pagination.totalPages)).map((p, i) => {
          if (
            i >= 1 &&
            i !== pagination.totalPages - 1 &&
            (pagination.currentPage > 4 || i > 4)
          ) {
            if (i <= pagination.currentPage - 4) return null
            if (i >= pagination.currentPage + 2) return null
          }
          const pageNumber = i === 0
            ? t('最初')
            : i === pagination.totalPages - 1 ? t('最後') : i + 1

          return <Link key= { i } className={ clsx('button', { selected: i + 1 === pagination.currentPage }) } to={ `/account/points${i > 0 ? `?page=${i + 1}` : ''}` }>{ pageNumber }</Link>
        })
      }
    </div>
  }, [pagination, t])

  const transactionHistory = useMemo(() => {
    if (balances.length === 0) {
      return <>
        <h2>{ t('account.points.history.title') }</h2>
        <p>{ t('account.points.history.none') }</p>
      </>
    }

    return (
      <>
        <h2>{ t('account.points.history.title') }</h2>
        <ul className='editable'>
          { balances.map((balance) => {
            return <li key={ balance.id }>
              <span>
                <strong className={ clsx(balance.direction === 'in' ? 'green' : 'yellow') }>{ t(`account.points.types.${balance.transactionType}`) }</strong>{
                  balance.orderId
                    ? <Link to={ `/orders/${balance.orderId}` }>注文番号 { balance.orderId }</Link>
                    : null
                }<br />
                <Moment format="YYYY-MM-DD">{ balance.createdAt }</Moment>
              </span>
              <span>{ balance.direction === 'in' ? '' : 'Δ' }{ balance.balanceOriginal.toLocaleString() }</span>
            </li>
          }) }
        </ul>
      </>
    )
  }, [balances, t])

  const [stripeAccount, setStripeAccount] = useState<StripeAccount>()
  const [loadingSellerAccounts, setLoadingSellerAccounts] = useState<boolean>(true)

  const [referrals, setReferrals] = useState<Referral[]>([])
  const [orderReferrals, setOrderReferrals] = useState<OrderReferral[]>([])
  const [referralsLoading, setReferralsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!loadingSellerAccounts) return

    usersRepository
      .getSelf()
      .then(({ stripeAccount }) => {
        setStripeAccount(stripeAccount)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoadingSellerAccounts(false)
      })
  }, [addError, loadingSellerAccounts, stripeAccount])

  const getReferrals = useCallback(() => {
    setReferralsLoading(true)

    referralsRepository
      .index({ params: { page: referralsPagination.currentPage, limit: 20 } })
      .then(({ orderReferrals, referrals, pagination }) => {
        setOrderReferrals(orderReferrals)
        setReferrals(referrals)
        if (pagination.totalPages !== referralsPagination.totalPages) {
          setReferralsPagination({
            ...referralsPagination,
            totalPages: pagination.totalPages
          })
        }
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setReferralsLoading(false)
      })
  }, [addError, referralsPagination])

  useEffect(() => {
    if (!stripeAccount) return

    getReferrals()
  }, [getReferrals, referralsPagination.currentPage, stripeAccount])

  // const hasPlatformReferral = useMemo(() => {
  //   return referrals.filter(r => r.creator === 'platform').length > 0
  // }, [referrals])

  const [lastLinkCopied, setLastLinkCopied] = useState<string>('')

  const copyDelay = 5
  const timer = useRef(setTimeout(() => {}, copyDelay * 1000))

  const resetLastLinkCopied = useCallback((text: string) => {
    setLastLinkCopied(text)
  }, [])

  const setTimer = useCallback((text: '') => {
    clearInterval(timer.current)
    timer.current = setInterval(() => resetLastLinkCopied(text), copyDelay * 1000)
  }, [resetLastLinkCopied])

  useEffect(() => {
    if (lastLinkCopied === '') return

    setTimer('')

    // clear on component unmount
    return () => {
      clearInterval(timer.current)
    }
  }, [lastLinkCopied, setTimer])

  const referralsCompleted = useMemo(() => {
    return orderReferrals.length === 0
      ? <p>報酬がまだありません</p>
      : <>
        <ul className="organised">
          {
            orderReferrals.map((o) => {
              return <li key={ o.id }>
                <span><strong className={ o.status === 'pending' ? 'yellow' : 'green' }>{ o.status }</strong><Moment format="YYYY-MM-DD">{ o.createdAt }</Moment></span>
                <span>{ Math.floor(o.commissionJpy) }円</span>
              </li>
            })
          }
        </ul>
        <div className="pagination">
          <span>{ t('Page:') }</span>
          {
            Array.apply(null, Array(referralsPagination.totalPages)).map((_, i) => {
              if (
                i >= 1 &&
                i !== referralsPagination.totalPages - 1 &&
                (referralsPagination.currentPage > 4 || i > 4)
              ) {
                if (i <= referralsPagination.currentPage - 4) return null
                if (i >= referralsPagination.currentPage + 2) return null
              }
              const pageNumber = i === 0
                ? t('最初')
                : i === referralsPagination.totalPages - 1 ? t('最後') : i + 1

              return <button key= { i } className={ clsx({ selected: i + 1 === referralsPagination.currentPage }) } onClick={ () => {
                setReferralsPagination({
                  ...referralsPagination,
                  currentPage: i + 1,
                })
              } }>{ pageNumber }</button>
            })
          }
        </div>
      </>
  }, [orderReferrals, referralsPagination, t])

  const referralCommission = useMemo(() => {
    return <ul className="organised">
      <li>
        <span>ご利用可能額</span>
        <span>{ Math.floor(Number(referrals.reduce((a, b) => a + +b.availableJpy, 0) ?? 0)) }円</span>
      </li>
      <li>
        <span>反映待ち</span>
        <span>{ Math.floor(Number(referrals.reduce((a, b) => a + +b.pendingJpy, 0) ?? 0)) }円</span>
      </li>
    </ul>
  }, [referrals])

  const referralsSection = useMemo(() => {
    if (referralsLoading) return

    if (!referrals && !orderReferrals) return

    const copyLink = (l: string) => {
      if (copyToClipboard(l)) {
        setLastLinkCopied(l)

        // better ux for in case user clicks multiple times:
        setTimer('')
      }
    }

    return <>
      <hr />
      <h2>アフィリエイトプログラム</h2>
      {
        referrals.length === 0
          ? <p>ただ今参加中のアフィリエイトプログラムはありません。</p>
          : <>
            {
              referrals?.map(r => {
                const startLink = r.isFuture && r.startDate ? `https://www.timeanddate.com/countdown/to?iso=${r.startDate.replaceAll('-', '')}T00&p0=248&font=cursive&csz=1` : ''
                const expiryLink = r.expiryDate ? `https://www.timeanddate.com/countdown/to?iso=${r.expiryDate.replaceAll('-', '')}T00&p0=248&font=cursive&csz=1` : ''

                const link = `https://holic.net/u/${r.sellerId}?ref=${r.code}`

                const inactive = r.isExpired || r.usesRemaining <= 0

                const title = r.creator === 'platform' ? 'HOLIC手数料キャッシュバック' : 'コミッション'

                return <ul className="editable" key={ r.id }>
                  <h3>{ title }</h3>
                  {
                    r.commissionRateOwnListings > 0
                      ? <li>
                        <span>ショップの売上に対するキャッシュバック</span>
                        <strong className='green'>{ r.commissionRateOwnListings }%</strong>
                      </li>
                      : null
                  }
                  {
                    r.commissionRate > 0
                      ? <li>
                        <span>{ r.commissionRateOwnListings > 0 ? 'その他の売上に対するコミッション' : 'コミッション' }</span>
                        <strong className='green'>{ r.commissionRate }%</strong>
                      </li>
                      : null
                  }
                  {
                    startLink
                      ? <li>
                        <span>開始日時</span>
                        <a href={ startLink } target="_blank" rel="noreferrer" className="helper"><Moment format="YYYY-MM-DD">{ r.startDate }</Moment> JST</a>
                      </li>
                      : null
                  }
                  {
                    inactive
                      ? <li>
                        <span>有効期限</span>
                        <span><Moment format="YYYY-MM-DD">{ r.expiryDate }</Moment> 0:00 JST (inactive)</span>
                      </li>
                      : expiryLink
                        ? <li>
                          <span>有効期限</span>
                          <a href={ expiryLink } target="_blank" rel="noreferrer" className="helper"><Moment format="YYYY-MM-DD">{ r.expiryDate }</Moment> 0:00 JST</a>
                        </li>
                        : null
                  }
                  <li className={ clsx({ expired: r.isExpired || r.usesRemaining <= 0 }) }>
                    <div>
                      <span>{ link }</span>
                      {
                        inactive
                          ? <span>(ご利用いただけません)</span>
                          : <button className="tool" onClick={ () => copyLink(link) }>{ lastLinkCopied === link ? '✔ コピー済' : 'リンクをコピー' }</button>
                      }
                    </div>
                  </li>
                  {
                    r.usesRemaining < 100 && r.usesRemaining > 0 && !r.isExpired
                      ? <li>
                        <span>利用可能回数</span>
                        <span>{ r.usesRemaining }</span>
                      </li>
                      : null
                  }
                </ul>
              })
            }
          </>
      }
      <h3>報酬履歴</h3>
      { referralsCompleted }
      <h3>報酬ご利用可能額</h3>
      { referralCommission }
    </>
  }, [lastLinkCopied, orderReferrals, referralCommission, referrals, referralsCompleted, referralsLoading, setTimer])

  if (loading) return <div>Loading...</div>

  return (
    <div>
      {
        balanceData && showWithdrawModal && (
          <WithdrawModal
            onClose={ closeModal }
            balance={ balanceData.withdrawableBalance }
            withdrawalsThisMonth={ balanceData.withdrawalsThisMonth }
            withdrawalFee={ balanceData.withdrawalFee }
          />
        )
      }
      <div className="narrow">
        { balance }
        <hr />
        { transactionHistory }
        { transactionHistoryPagination }
        { referralsSection }
      </div>
    </div>
  )
}

export { AccountPoints }
