import { TagShort } from '../types'

import lorcanatcg from '../logos/lorcanatcg.png'
import pokemontcg from '../logos/pokemontcg.png'
import pokemontcgEn from '../logos/pokemontcg-en.png'

type GameTag = {
  default?: string
  logo?: string
  displayOnTopPage: boolean
} & TagShort

let games: GameTag[] = []

if (process.env.REACT_APP_RAILS_ENV === 'production') {
  games = [
    { id: 1, name: 'pokemontcg-japanese', default: 'ja', displayOnTopPage: true, logo: pokemontcg },
    { id: 2150, name: 'pokemontcg-english', default: 'en', displayOnTopPage: true, logo: pokemontcgEn },
    { id: 4094, name: 'lorcana', displayOnTopPage: true, logo: lorcanatcg },
    { id: 4093, name: 'pokemon-topsun', displayOnTopPage: false },
    { id: 3884, name: 'pokemon-carddass', displayOnTopPage: false },
    { id: 4098, name: 'other', displayOnTopPage: false },
  ]
} else if (process.env.REACT_APP_RAILS_ENV === 'integration') {
  games = [
    { id: 1, name: 'pokemontcg-japanese', default: 'ja', displayOnTopPage: true, logo: pokemontcg },
    { id: 2150, name: 'pokemontcg-english', default: 'en', displayOnTopPage: true, logo: pokemontcgEn },
    { id: 3120, name: 'lorcana', displayOnTopPage: true, logo: lorcanatcg },
    { id: 3117, name: 'pokemon-topsun', displayOnTopPage: false },
    { id: 3884, name: 'pokemon-carddass', displayOnTopPage: false },
  ]
} else {
  games = [
    { id: 1, name: 'pokemontcg-japanese', default: 'ja', displayOnTopPage: true, logo: pokemontcg },
    { id: 2150, name: 'pokemontcg-english', default: 'en', displayOnTopPage: true, logo: pokemontcgEn },
    { id: 3120, name: 'lorcana', displayOnTopPage: true, logo: lorcanatcg },
    { id: 3117, name: 'pokemon-topsun', displayOnTopPage: false },
    { id: 3884, name: 'pokemon-carddass', displayOnTopPage: false },
    { id: 3124, name: 'other', displayOnTopPage: false },
  ]
}

export { games }
