import { axiosInstance } from '../lib/api_client'
import { StripeAccount } from '../types'

const PATH = '/stripe_account'

type UpdateParams = Readonly<{
  nationalityCode: string
  firstName: string
  lastName: string
  birthDate: string
  gender: string
  phone: string
  countryCode: string
  state: string
  city: string
  addressLine1: string
  addressLine2?: string
  zipCode: string
}>

type UpdateReponse = Readonly<{
  stripeAccount: StripeAccount
}>

type VerifyPhoneNumberParams = Readonly<{
  code: string
}>

type StripeAccountsRepository = Readonly<{
  update: (params: UpdateParams) => Promise<UpdateReponse>
  requestVerifyPhoneNumber: () => Promise<void>
  attemptVerifyPhoneNumber: (params: VerifyPhoneNumberParams) => Promise<void>
}>

const stripeAccountRepository: StripeAccountsRepository = {
  async update (params) {
    return await axiosInstance.put(PATH, { stripeAccount: params }).then(({ data }) => data)
  },
  async requestVerifyPhoneNumber () {
    return await axiosInstance.get(`${PATH}/request_verify_phone_number`)
  },
  async attemptVerifyPhoneNumber (params) {
    return await axiosInstance.put(`${PATH}/attempt_verify_phone_number`, { verify: params })
  },
}

export { stripeAccountRepository }
