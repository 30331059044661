import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalContext } from '../contexts/modal_context'

// TODO: Require Props definition (this is error)
const Modal = () => {
  const { modalState } = useContext(ModalContext)

  const renderScreen = useMemo(() => {
    switch (modalState) {
      // case 'collection_guest_limit_warning':
      //   return <ModalCollectionGuestLimitWarning />
      // case 'collection_guest_limit_reached':
      //   return <ModalCollectionGuestLimitReached />
      // case 'collection_add_login':
      //   return <ModalCollectionGuestLogin />
      // case 'collection_add_login_success':
      //   return <ModalCollectionGuestLoginSuccess />
      // case 'cards_add_error':
      //   return <ModalCardsAddError />
      case 'batch_saved':
        return <ModalBatchSaved />
      default:
        return null
    }
  }, [modalState])

  return (
    <div className={ `modal-container ${!modalState ? 'off' : ''}` }>
      <div className="modal">
        {
          renderScreen
        }
      </div>
    </div>
  )
}

/*
const ModalCollectionGuestLimitWarning = () => {
  const { t } = useTranslation()
  const { setModalState } = useContext(ModalContext)
  const navigate = useNavigate()

  const continueGuest = () => {
    setModalState?.('')
  }

  const register = () => {
    // // register and add card to collection on callback
    setModalState?.('')
    navigate('/register/nologin')
  }

  return (
    <div>
      <h1>{ t('modal.collection.guest_limit_warning.title') }</h1>
      <p>
        { t('modal.collection.guest_limit_warning.explanation') }
      </p>
      <p>
        { t('modal.collection.guest_limit_warning.limit1') }
      </p>
      <p>
        { t('modal.collection.guest_limit_warning.limit2') }<strong>{ t('modal.collection.guest_limit_warning.unlimited') }</strong>
      </p>
      <p>
        <button className="tool large" onClick={ continueGuest }>
          { t('modal.collection.guest_limit_warning.button_guest') }
        </button>
      </p>
      <p>
        <button className="full" onClick={ register }>
          { t('modal.collection.guest_limit_warning.button_register') }
        </button>
      </p>
    </div>
  )
}

const ModalCollectionGuestLimitReached = () => {
  const { t } = useTranslation()
  const { setModalState } = useContext(ModalContext)
  const navigate = useNavigate()

  const continueGuest = () => {
    setModalState?.('')
  }

  const register = () => {
    // // register and add card to collection on callback
    setModalState?.('')
    navigate('/register/nologin')
  }

  return (
    <div>
      <h1>{ t('modal.collection.guest_limit_reached.title') }</h1>
      <p>
        { t('modal.collection.guest_limit_reached.explanation1') }
      </p>
      <p>
        { t('modal.collection.guest_limit_reached.explanation2') }
      </p>
      <p>
        <button className="tool large" onClick={ continueGuest }>
          { t('modal.collection.guest_limit_reached.button_guest') }
        </button>
      </p>
      <p>
        <button className="full" onClick={ register }>
          { t('modal.collection.guest_limit_reached.button_register') }
        </button>
      </p>
    </div>
  )
}

const ModalCollectionGuestLogin = () => {
  const { t } = useTranslation()
  const { setModalState, modalVars } = useContext(ModalContext)
  const [loading, setLoading] = useState<boolean>(false)

  const discard = () => {
    setLoading(true)

    // { Authorization: this.props.auth.auth_token },
    collectionsRepository
      .delete(modalVars.collectionId)
      .then(() => {
        setModalState?.('')
        window.location.reload()
      })
      .catch((err: AxiosError) => {
        setModalState?.('')

        console.error(err)

        // TODO: make error notify common function.
        alert(err)
      })

    setModalState?.('')
  }

  const keep = () => {
    setLoading(true)

    // { Authorization: this.props.auth.auth_token },
    collectionsRepository
      .merge(modalVars.collectionId)
      .then(() => {
        setModalState?.('collection_add_login_success')
      })
      .catch((err: AxiosError) => {
        setModalState?.('cards_add_error')

        console.error(err)

        // TODO: make error notify common function.
        alert(err)
      })
  }

  if (loading) {
    return (
      <div>
        <h1>{ t('modal.collection.add_login.loading_title') }</h1>
        <p>
          { t('modal.collection.add_login.loading_explanation') }
        </p>
      </div>
    )
  }

  return (
    <div>
      <h1>{ t('modal.collection.add_login.title') }</h1>
      <p>
        { t('modal.collection.add_login.explanation1') }
      </p>
      <p>
        { t('modal.collection.add_login.explanation2') }
      </p>
      <p>
        <button className="full" onClick={ discard }>
          { t('modal.collection.add_login.button_discard') }
        </button>
      </p>
      <p>
        <button className="full" onClick={ keep }>
          { t('modal.collection.add_login.button_keep') }
        </button>
      </p>
    </div>
  )
}

const ModalCollectionGuestLoginSuccess = () => {
  const { t } = useTranslation()
  const { setModalState } = useContext(ModalContext)

  const continueButton = () => {
    setModalState?.('')

    // reload window due to changes potentially displaying incorrect card states
    // sorry not sorry
    window.location.reload()
  }

  return (
    <div>
      <h1>{ t('modal.collection.add_login_success.title') }</h1>
      <p>
        { t('modal.collection.add_login_success.explanation') }
      </p>
      <p>
        <button className="full" onClick={ continueButton }>
          { t('modal.collection.add_login_success.button') }
        </button>
      </p>
    </div>
  )
}

const ModalCardsAddError = () => {
  const { t } = useTranslation()
  const { setModalState } = useContext(ModalContext)

  const continueButton = () => {
    setModalState?.('')
  }

  return (
    <div>
      <h1>{ t('modal.cards_add_error.title') }</h1>
      <p>
        { t('modal.cards_add_error.explanation1') }
      </p>
      <p>
        { t('modal.cards_add_error.explanation2') }
      </p>
      <p>
        <button className="full" onClick={ continueButton }>
          { t('modal.cards_add_error.button') }
        </button>
      </p>
    </div>
  )
}
*/

const ModalBatchSaved = () => {
  const { t } = useTranslation()
  const { setModalState, modalVars } = useContext(ModalContext)

  const continueButton = () => {
    setModalState?.('')
  }

  return (
    <div>
      <h1>{ t('modal.batch_saved.title') }</h1>
      <p>
        { t('modal.batch_saved.cards_success', { cards: modalVars.saved_total }) }
      </p>
      {
        modalVars.saved_total < modalVars.batch_total
          ? (
            <p>
              { t('modal.batch_saved.cards_error', { cards: (modalVars.batch_total - modalVars.saved_total) }) }
            </p>
            )
          : null
      }
      <p>
        <button className="full" onClick={ continueButton }>
          { t('modal.batch_saved.button') }
        </button>
      </p>
    </div>
  )
}

export { Modal }
