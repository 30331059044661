import { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { games } from '../lib/games'
import { views } from '../lib/views'
import { cardTypes } from '../lib/card_types'
import { energyTypes } from '../lib/energy_types'
import { rarities } from '../lib/rarities'
import { stocks } from '../lib/stocks'
import { filterLink } from '../lib/filter_link'

import { queryStringFromSelection } from '../helpers/query_string_from_selection'
import { queryStringToNumberArray } from '../helpers/query_string_to_number_array'
import { queryStringToNumberArrayFromSelection } from '../helpers/query_string_to_number_array_from_selection'
import { queryStringToStringArrayFromSelection } from '../helpers/query_string_to_string_array_from_selection'
import { SearchContext } from '../contexts/search_context'

type UserFilters = Readonly<{
  params: string
  game: number|undefined
  view: string
  // categories: number[]
  cardTypes: number[]
  energyTypes: number[]
  rarities: number[]
  conditions: number[]
  stocks: string[]
  minPrice: number|undefined
  maxPrice: number|undefined
  userId: number|undefined
  searchString: string|undefined
}>

const useFilterQueryString = (): UserFilters => {
  const { filters, setFilters, filterMenuDisplayed, searchString, setSearchString } = useContext(SearchContext)

  const { i18n } = useTranslation()

  const searchParams = useSearchParams()[0]

  const [filterGame, setFilterGame] = useState<number|undefined>()
  const [filterView, setFilterView] = useState<string>(views[0])
  // const [filterChildren, setFilterChildren] = useState<number[]>([])
  const [filterCardType, setFilterCardType] = useState<number[]>([])
  const [filterEnergyType, setFilterEnergyType] = useState<number[]>([])
  const [filterRarities, setFilterRarities] = useState<number[]>([])
  const [filterConditions, setFilterConditions] = useState<number[]>([])
  const [filterStocks, setFilterStocks] = useState<string[]>([])
  const [filterMinPrice, setFilterMinPrice] = useState<number|undefined>()
  const [filterMaxPrice, setFilterMaxPrice] = useState<number|undefined>()
  const [filterUserId, setFilterUserId] = useState<number|undefined>()
  const [filterSearchString, setFilterSearchString] = useState<string|undefined>()

  // useEffect(() => {
  //   // original version (needs updating)
  //   const childrenFromParams = searchParams.get('children')
  //   if (childrenFromParams) {
  //     setFilterChildren(queryStringToNumberArray(childrenFromParams, Infinity))
  //   }
  // }, [searchParams])

  const [initialFilters, setInitialFilters] = useState<string[]>([])

  useEffect(() => {
    if (!setFilters) return

    if (filterMenuDisplayed) return

    let shouldUpdateFilters = false

    const searchParamsView = searchParams.get('view')
    const searchParamsCardTypes = searchParams.get('card_types')
    const searchParamsEnergyTypes = searchParams.get('energy_types')
    const searchParamsRarities = searchParams.get('rarities')
    const searchParamsConditions = searchParams.get('conditions')
    const searchParamsStocks = searchParams.get('stocks')
    const searchParamsMinPrice = searchParams.get('min_price')
    const searchParamsMaxPrice = searchParams.get('max_price')
    const searchParamsQuery = searchParams.get('query')
    const searchParamsChildren = searchParams.get('children')
    const searchParamsUserId = searchParams.get('user_id')
    const searchParamsGame = searchParams.get('game')

    // Test to make sure searchParams have changed
    // We don't care about anything else
    // If they haven't changed, do nothing
    const test = [
      searchParamsView ?? '',
      searchParamsCardTypes ?? '',
      searchParamsEnergyTypes ?? '',
      searchParamsRarities ?? '',
      searchParamsConditions ?? '',
      searchParamsStocks ?? '',
      searchParamsMinPrice ?? '',
      searchParamsMaxPrice ?? '',
      searchParamsQuery ?? '',
      searchParamsChildren ?? '',
      searchParamsUserId ?? '',
      searchParamsGame ?? '',
    ]

    // are the arrays the same?
    if (test.length === initialFilters.length && test.every((value, index) => value === initialFilters[index])) {
      // yes, they are
      return
    } else if (test.join('') === '') {
      // no query params... possibly moved to a different view
      return
    } else {
      // no, they are not
      setInitialFilters(test)
    }

    const possibleViews = ['listings', 'items']

    const selectedViewUnvalidated = searchParamsView ? queryStringFromSelection(searchParamsView, views) : ''
    const selectedView = possibleViews.includes(selectedViewUnvalidated) ? selectedViewUnvalidated : possibleViews[0]

    if (selectedView !== filters.selectedView) {
      shouldUpdateFilters = true
      setFilterView(selectedView)
    }

    const selectedCardTypes = searchParamsCardTypes ? queryStringToNumberArrayFromSelection(searchParamsCardTypes, cardTypes) : []

    if (selectedCardTypes !== filters.selectedCardTypes) {
      shouldUpdateFilters = true
      setFilterCardType(selectedCardTypes)
    }

    // Game
    // must return as number type
    const selectedGame = () => {
      let game

      if (searchParamsGame) {
        game = Number(queryStringFromSelection(searchParamsGame, games.map(g => g.id)))

        if (!isNaN(game)) {
          return game
        }
      }

      if (filters.selectedGame) {
        // if game was previously selected, return it
        return filters.selectedGame
      }

      // otherwise, set it to the default
      return games.find(g => g.default === i18n.language)?.id ?? games[0].id
    }

    if (selectedGame() !== filters.selectedGame) {
      shouldUpdateFilters = true
      setFilterGame(selectedGame())
    }

    // Energy types
    const selectedEnergyTypes = searchParamsEnergyTypes ? queryStringToNumberArrayFromSelection(searchParamsEnergyTypes, energyTypes) : []

    if (selectedEnergyTypes !== filters.selectedEnergyTypes) {
      shouldUpdateFilters = true
      setFilterEnergyType(selectedEnergyTypes)
    }

    // Rarities
    const selectedRarities = searchParamsRarities ? queryStringToNumberArrayFromSelection(searchParamsRarities, rarities) : []

    if (selectedRarities !== filters.selectedConditions) {
      shouldUpdateFilters = true
      setFilterRarities(selectedRarities)
    }

    // Conditions
    const selectedConditions = searchParamsConditions ? queryStringToNumberArray(searchParamsConditions, 100) : []

    if (selectedConditions !== filters.selectedConditions) {
      shouldUpdateFilters = true
      setFilterConditions(selectedConditions)
    }

    // Available/sold
    const selectedStocks = searchParamsStocks ? queryStringToStringArrayFromSelection(searchParamsStocks, stocks) : []

    if (selectedStocks !== filters.selectedStocks) {
      shouldUpdateFilters = true
      setFilterStocks(selectedStocks)
    }

    // Min price
    const min = !searchParamsMinPrice
      ? undefined
      : !isNaN(+searchParamsMinPrice)
          ? Number(searchParamsMinPrice)
          : 0

    if (min !== filters.priceRange.min) {
      shouldUpdateFilters = true
      setFilterMinPrice(min)
    }

    // Max price
    const max = !searchParamsMaxPrice
      ? undefined
      : !isNaN(+searchParamsMaxPrice)
          ? Number(searchParamsMaxPrice)
          : 0

    if (max !== filters.priceRange.max) {
      shouldUpdateFilters = true
      setFilterMaxPrice(max)
    }

    // useEffect(() => {
    //   // original version (needs updating)
    //   const searchStringFromParams = searchParams.get('query')
    //   if (searchStringFromParams) {
    //     setFilterSearchString(searchStringFromParams)
    //   }
    // }, [searchParams])

    // Search query
    const searchStringFromQueryParam = searchParamsQuery ?? ''

    if (searchStringFromQueryParam !== searchString) {
      setFilterSearchString(searchStringFromQueryParam)

      setSearchString?.(searchStringFromQueryParam)
    }

    // // Categories
    // const selectedChildren = searchParamsChildren ? queryStringToNumberArray(searchParamsChildren, Infinity) : []
    // const selectedCategories = selectedChildren.map((id) => {
    //   const category: TagRef = {
    //     id,
    //     nameShort: '',
    //     depth: 0,
    //     childrenCount: 0,
    //     ordinality: 0,
    //     shouldUpdateWithNewData: true,
    //   }

    //   return category
    // })

    // if (selectedChildren.sort((a, b) => a - b).join(',') !== filters.selectedCategories.map(c => c.id).sort((a, b) => a - b).join(',')) {
    //   shouldUpdateFilters = true

    //   setFilterChildren(selectedChildren)
    // }

    // User ID
    const selectedUserId = searchParamsUserId ? Number(searchParamsUserId) : undefined

    if (selectedUserId !== filters.selectedUserId) {
      shouldUpdateFilters = true

      setFilterUserId(selectedUserId)
    }

    if (!shouldUpdateFilters) return

    setFilters({
      ...filters,
      // selectedCategories,
      selectedGame: selectedGame(),
      selectedStocks,
      selectedConditions,
      selectedRarities,
      selectedEnergyTypes,
      selectedCardTypes,
      selectedView,
      priceRange: {
        max,
        min,
      },
      selectedUserId,
    })
  }, [filterMenuDisplayed, filters, i18n.language, initialFilters, searchParams, searchString, setFilters, setSearchString])

  const link = filterLink(
    filterView,
    // filterChildren,
    filterCardType,
    filterEnergyType,
    filterRarities,
    filterConditions,
    filterStocks,
    filterMinPrice,
    filterMaxPrice,
    filterUserId,
    filterSearchString,
    filterGame,
  )

  return {
    params: link,
    game: filterGame,
    view: filterView,
    // categories: filterChildren,
    cardTypes: filterCardType,
    energyTypes: filterEnergyType,
    rarities: filterRarities,
    conditions: filterConditions,
    stocks: filterStocks,
    minPrice: filterMinPrice,
    maxPrice: filterMaxPrice,
    searchString: filterSearchString,
    userId: filterUserId,
  }
}

export { useFilterQueryString }
