import clsx from 'clsx'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useMatch } from 'react-router-dom'
import Select from 'react-select'
import { ErrorsContext } from '../contexts/errors_context'
import { itemsRepository } from '../repositories/items_repository'
import { tagsRepository } from '../repositories/tags_repository'
import { Item, Tag } from '../types'
import i18n from './i18n'
import { ModalWrapper } from './modal_wrapper'
import '../stylesheets/Admin.scss'

type ListedTagItemProps = Readonly<{
  index: number
  item: Item
  tagId: number
  deleteItem: (itemId) => void
  moveItem: (direction, itemId) => void
}>

const ListedTagItem = ({ index, item, tagId, deleteItem, moveItem }: ListedTagItemProps) => {
  const image = item.images.length ? item.images[0] : undefined

  const imageUi = useMemo(() => {
    if (!image) return <span className='no-image'>No image</span>

    return <img src={ image.thumb2x } height={ 100 } />
  }, [image])

  return <>
    <td>
      { index + 1 }
    </td>
    <td>
      { imageUi }
    </td>
    <td>
      <span><Link to={ `/categories/${tagId}/items/${item.id}` }>#{ item.id }</Link>: {
        i18n.language === 'ja' && item.nameJa
          ? item.nameJa
          : item.nameEn
      }</span><br />
      <Link to={ `/admin/items/edit/${item.id}` } className='button tool'>Edit</Link> <button className='tool' onClick={ _ => deleteItem(item.id) }>Remove</button>
    </td>
    <td>
      <div className='arrows'>
        <button className='tool' onClick={ _ => { moveItem('up', item.id) } }>↑</button>
        <button className='tool' onClick={ _ => { moveItem('down', item.id) } }>↓</button>
      </div>
    </td>
  </>
}

type ItemShort = Readonly<{
  value: number
  label: string
}>

const AdminTagsItems = () => {
  const { i18n } = useTranslation()

  const { addError } = useContext(ErrorsContext)

  // get tag
  // get items

  const params = useMatch('/admin/tags/items/:tag_id')?.params

  const [loadingTag, setLoadingTag] = useState<boolean>(false)
  const [tagId, setTagId] = useState<number|undefined>()
  const [tag, setTag] = useState<Tag|undefined>()
  const loadingItems = useState<boolean>(false)[0]
  const [items, setItems] = useState<Item[]>([])
  const setLoadingItemsForList = useState<boolean>(false)[1]
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [recentItemsFull, setRecentItemsFull] = useState<Item[]>([])
  const [recentItems, setRecentItems] = useState<ItemShort[]>([])
  const [loadingRecentItems, setLoadingRecentItems] = useState<boolean>(false)
  const [modalDisplayed, setModalDisplayed] = useState<boolean>(false)

  const getItems = useCallback((tagId: number) => {
    setLoadingTag(true)

    tagsRepository
      .getItems(
        tagId,
        {
          page: 0,
          types: [],
          limit: 500,
          sort: 'release_date_asc',
        },
      )
      .then(({ items }) => {
        setItems(items)
      })
      .catch((err) => {
        addError?.(err)
      })
      .finally(() => {
        setLoadingTag(false)
      })
  }, [addError])

  const getRecentItems = useCallback(() => {
    setLoadingRecentItems(true)

    itemsRepository
      .index(
        {
          page: 0,
          limit: 500,
          sort: 'created_desc',
        },
      )
      .then(({ items }) => {
        setRecentItemsFull(items)
      })
      .catch((err) => {
        addError?.(err)
      })
      .finally(() => {
        setLoadingRecentItems(false)
      })
  }, [addError])

  useEffect(() => {
    const itemsForList = recentItemsFull.filter(i => !items.find(test => test.id === i.id)).map((i) => {
      return {
        value: i.id,
        label: `${String(i.id)}: ${(i18n.language === 'ja' && i.nameJa ? i.nameJa : i.nameEn) ?? ''}`,
      }
    })

    setRecentItems(itemsForList)
  }, [i18n.language, items, recentItemsFull])

  const getTag = useCallback((id: number) => {
    setLoadingTag(true)

    tagsRepository
      .get(id)
      .then(({ tag }) => {
        setTag(tag)
      })
      .catch((err) => {
        addError?.(err)
      })
      .finally(() => {
        setLoadingTag(false)
      })
  }, [addError])

  useEffect(() => {
    const t = Number(params?.tag_id) ?? 0

    if (t > 0) setTagId(t)
  }, [params])

  useEffect(() => {
    if (tagId && tagId !== tag?.id) {
      getTag(tagId)
    }
  }, [getTag, tag?.id, tagId])

  useEffect(() => {
    if (tag) {
      getItems(tag.id)
    }
  }, [getItems, tag])

  useEffect(() => {
    getRecentItems()
  }, [getRecentItems])

  const deleteItem = useCallback((itemId) => {
    setItems(items => items.filter((i) => i.id !== itemId))
  }, [])

  const [highlightedItem, setHighlightedItem] = useState<number|undefined>()
  const [newPosition, setNewPosition] = useState<number|undefined>()

  const highlight = useCallback((itemId) => {
    setHighlightedItem((original) => original === itemId ? undefined : itemId)
  }, [])

  const keyPressDelay = 0.5
  const timer = useRef(setTimeout(() => {}, keyPressDelay * 1000))

  const moveItem = useCallback((direction, itemId, position?) => {
    setNewPosition(undefined)
    clearInterval(timer.current)

    // TODO: scroll to this position

    const index = items.findIndex(i => i.id === itemId)
    const itemsMaxIndex = items.length - 1

    let newIndex = direction === 'up'
      ? index > 0 ? index - 1 : 0
      : index === itemsMaxIndex ? index : index + 1

    if (position) {
      if (position > itemsMaxIndex - 1) {
        newIndex = itemsMaxIndex
      } else {
        newIndex = position - 1
      }
    }

    setItems(items => {
      const itemsWithoutIndex = items.filter((_, i) => i !== index)
      itemsWithoutIndex.splice(newIndex, 0, items[index])
      return itemsWithoutIndex
    })

    setTimeout(() => {
      const elem = document.getElementById(`item-${String(itemId)}`)
      if (!elem) return

      const offsetHeight = elem?.offsetHeight ?? 0
      elem.scrollIntoView()
      window.scrollBy(0, offsetHeight * -1)
    }, 10)
  }, [items])

  const doSomething = useCallback((itemId, position) => {
    moveItem('', itemId, position)
  }, [moveItem])

  const setTimer = useCallback((digit: string) => {
    const position = Number(String(newPosition ?? '') + digit)
    setNewPosition(position)
    clearInterval(timer.current)
    timer.current = setInterval(() => doSomething(highlightedItem, position), keyPressDelay * 1000)
  }, [doSomething, highlightedItem, newPosition])

  useEffect(() => {
    // clear on component unmount
    return () => {
      clearInterval(timer.current)
    }
  }, [])

  const handleKeyPress = useCallback((e) => {
    if (!highlightedItem) return

    if (e.code.substring(0, 5) === 'Digit') {
      e.preventDefault()
      // clearInterval(timer.current)
      setTimer(e.code.substring(5))
      return
    }

    if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
      // scroll the window based on element height
      e.preventDefault()
      const dir = e.code === 'ArrowUp' ? 'up' : 'down'
      moveItem(dir, highlightedItem)
    }
  }, [highlightedItem, moveItem, setTimer])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  const itemsUi = useMemo(() => {
    if (loadingItems) {
      return <p>Loading...</p>
    }

    if (items.length === 0) {
      return <p>No items assigned.</p>
    }

    return <table>
      <tbody>
        {
          items.map((item, i) => {
            const image = item.images.length ? item.images[0] : undefined
            const isHighlighted = highlightedItem === item.id

            return <>
              <tr id={ `item-${item.id}` } key={ item.id } onClick={ _ => highlight(item.id) } className={ clsx({ highlighted: isHighlighted }) }>
                <ListedTagItem index={ i } item={ item } tagId={ tag?.id ?? 0 } deleteItem={ deleteItem } moveItem={ moveItem } />
              </tr>
              {
                isHighlighted && image
                  ? <tr key={ `image-${item.id}` }><td className='highlightedimage'><img src={ image.thumb2x } /></td></tr>
                  : null
              }
            </>
          })
        }
      </tbody>
    </table>
  }, [deleteItem, highlight, highlightedItem, items, loadingItems, moveItem, tag?.id])

  const {
    getValues,
    register,
  } = useForm()

  const appendByItemId = useCallback((value) => {
    let workingItemId
    if (typeof value === 'number') {
      workingItemId = value
    } else {
      workingItemId = getValues('manualid')
    }

    if (!workingItemId || workingItemId < 1) {
      alert('Please input a valid number')
      return
    }

    // do not add to the list if already here
    if (items.find(item => item.id === workingItemId)) {
      alert('Item is already associated with this tag')
      return
    }

    setLoadingItemsForList(true)

    itemsRepository
      .get(
        workingItemId ?? 0
      )
      .then(({ item }) => {
        setItems(i => i.concat([item]))
      })
      .catch((err) => {
        addError?.(err)
      })
      .finally(() => {
        setLoadingItemsForList(false)
        // setValue('manualid', '')
      })
  }, [addError, getValues, items, setLoadingItemsForList])

  const submitForm = useCallback(() => {
    if (!tag) return

    setLoadingSubmit(true)

    tagsRepository
      .updateItems({ id: tag.id, items: items.map(i => i.id) })
      .then(() => {
        setModalDisplayed(true)
      })
      .catch((err) => {
        addError?.(err)
      })
      .finally(() => {
        setLoadingSubmit(false)
      })
  }, [addError, items, tag])

  const tagForm = useMemo(() => {
    if (loadingTag) {
      return <p>Loading...</p>
    }

    if (!tag) {
      return <p>Tag could not be found.</p>
    }

    return <>
      <p>
        <strong>{
          i18n.language === 'ja' && tag.nameJa
            ? tag.nameJa
            : tag.nameEn
        }</strong> <Link to={ `/admin/tags/edit/${tag.id}` } className='button tool'>Edit</Link>
      </p>
      <hr />
      <form onSubmit={ e => { e.preventDefault(); return false } }>
        <div className="select">
          <label>Choose a recent card:</label>
          <Select
            options={ recentItems }
            isLoading={ loadingRecentItems }
            onChange={ val => appendByItemId(val?.value) }
          />
        </div>
        <p>
          <label>Or enter the ID:</label>
          <input type="number" min={ 0 } { ...register('manualid') } /> <button className='tool' onClick={ appendByItemId }>Append</button>
        </p>
      </form>
      <hr />
      { itemsUi }
      <hr />
      <p>
        <button disabled={ loadingSubmit } className='full' onClick={ submitForm }>Confirm above assignments</button>
      </p>
    </>
  }, [appendByItemId, i18n.language, itemsUi, loadingRecentItems, loadingSubmit, loadingTag, recentItems, register, submitForm, tag])

  const createdModal = useMemo(() => {
    if (!modalDisplayed) return

    return (
      <ModalWrapper>
        <h1>Success!</h1>
        <p>The items have been assigned to the tag.</p>
        <p>
          <Link to={ `/categories/${tag?.id ?? ''}?view=items` } className="button full white" onClick={ () => { setModalDisplayed(false) } }>View tag</Link>
        </p>
        <p>
          <Link to="/admin/tags/list" className="button full white" onClick={ () => { setModalDisplayed(false) } }>Return to tags list</Link>
        </p>
        <p>
          <button className="full" onClick={ () => { setModalDisplayed(false) } }>Continue editing</button>
        </p>
      </ModalWrapper>
    )
  }, [modalDisplayed, tag?.id])

  return (
    <div className="narrow">
      { createdModal }
      <h2>Tag-item assignment</h2>
      { tagForm }
    </div>
  )
}

export { AdminTagsItems }
