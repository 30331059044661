import { axiosInstance } from '../lib/api_client'

const PATH = '/images'

type CreateParams = Readonly<{
  imageData: string
  itemId: number
}>

type CreateResponse = Readonly<{
  id: number
  imageUrl: string
}>

type SessionRepository = Readonly<{
  create: (params: CreateParams) => Promise<CreateResponse>
}>

const itemImagesRepository: SessionRepository = {
  async create (params) {
    return await axiosInstance.post(PATH, params).then(({ data }) => data)
  },
}

export { itemImagesRepository }
