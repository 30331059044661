import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { conditions } from '../lib/conditions'

import { BasketContext } from '../contexts/basket_context'

type Props = Readonly<{
  onClose: () => void
  canView: boolean
  canDelete: boolean
}>

const UnitOrderSummary = ({ onClose, canView, canDelete }: Props): JSX.Element => {
  const { t, i18n } = useTranslation()

  const { basketUnits, removeUnitFromBasket } = useContext(BasketContext)

  if (basketUnits.length === 0) {
    return (
      <p className='center'>
        { t('checkout.bag_none') }
      </p>
    )
  }

  return (
    <ul className="organised">
      {
        basketUnits.map((basketUnit) => {
          const listing = basketUnit.unit.listings?.find(l => l.platform === 'holic')

          if (!listing) return null

          const condition = conditions.find(c => c.id === basketUnit.unit.condition)

          const deleteButton = canDelete ? <button className="remove" onClick={ () => removeUnitFromBasket?.(basketUnit.unit) }>x</button> : null

          // const itemName = (i18n.language === 'ja' && unit.item?.nameJa) ?? !unit.item?.nameEn ? unit.item?.nameJa : unit.item?.nameEn

          const itemName = basketUnit.unit.items?.map(item => {
            return (i18n.language === 'ja' && item.nameJa) ?? !item.nameEn ? item.nameJa : item.nameEn
          }).join(' / ') ?? ''

          const title: JSX.Element = canView ? <Link to={ `/u/${basketUnit.unit.userId ?? 0}/units/${basketUnit.unit.id ?? 0}` } onClick={ onClose }>{ itemName }</Link> : <>{ itemName }</>

          const quantity = basketUnit.quantity > 1 ? `${basketUnit.quantity}x ` : ''

          const price = Math.round(listing.priceJpy * basketUnit.quantity).toLocaleString()

          return (
            <li key={ basketUnit.unit.id }>
              <span>{ deleteButton } { quantity } { title } ({ t(`collections.conditions.long.${condition?.id ?? 0}`) })</span>
              <span>{ t('listings.price.price_with_currency_symbol', { price }) }</span>
            </li>
          )
        })
      }
    </ul>
  )
}

export { UnitOrderSummary }
