import { Region } from '../types'

const regions: Region[] = [
  // {
  //   id: 1,
  //   label: 'Europe',
  // },
  // {
  //   id: 2,
  //   label: 'USA and Canada',
  // },
  {
    id: 3,
    label: 'Japan',
  },
  // {
  //   id: 4,
  //   label: 'Oceania',
  // },
]

export { regions }
