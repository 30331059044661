import { CancelToken } from 'axios'
import { axiosInstance } from '../lib/api_client'
import { Item, Pagination, Tag } from '../types'

const PATH = '/tags'

type IndexParams = Readonly<{
  parentId?: number
  params?: Readonly<{
    q?: string
    type?: string
    tagIds?: number[]
    tagId?: number
    limit?: number
    sort?: string
    page: number
    // perPage: number
    mustHaveItems?: boolean
  }>
}>

type GetItemParams = Readonly<{
  platform?: string
  query?: string
  tagGroup1Ids?: number[]
  tagGroup2Ids?: number[]
  tagGroup3Ids?: number[]
  tagGroup4Ids?: number[]
  game?: number[]
  conditions?: number[]
  sold?: string
  minPrice?: number
  maxPrice?: number
  userId?: number
  withHolicListings?: string
  sort?: string
  page: number
  types: string[]
  limit?: number
}>

type IndexReponse = Readonly<{
  tags: Tag[]
  pagination: Pagination
}>

type GetReponse = Readonly<{
  tag: Tag
}>

type GetItemsReponse = Readonly<{
  items: Item[]
  pagination: Pagination
}>

type CreateReponse = Readonly<{
  tag: Tag
}>

type UpdateParams = Readonly<{
  id: number
  params: Tag
}>

type UpdateItemParams = Readonly<{
  id: number
  items: number[]
}>

type TagsRepository = Readonly<{
  index: (params: IndexParams, cancelToken?: CancelToken) => Promise<IndexReponse>
  get: (id: number, cancelToken?: CancelToken) => Promise<GetReponse>
  getItems: (id: number, params: GetItemParams, cancelToken?: CancelToken) => Promise<GetItemsReponse>
  follow: (id: number) => Promise<void>
  unfollow: (id: number) => Promise<void>
  create: (params: Tag) => Promise<CreateReponse>
  update: (params: UpdateParams) => Promise<void>
  updateItems: (params: UpdateItemParams) => Promise<void>
}>

const tagsRepository: TagsRepository = {
  async index ({ parentId, params }, cancelToken?: CancelToken) {
    if (parentId === undefined) {
      return await axiosInstance.get(PATH, { params, cancelToken }).then(({ data }) => data)
    }

    return await axiosInstance.get(`${PATH}/${String(parentId)}/tags`, { params }).then(({ data }) => data)
  },
  async get (id, cancelToken?: CancelToken) {
    return await axiosInstance.get(`${PATH}/${id}`, { cancelToken }).then(({ data }) => data)
  },
  async getItems (id, params, cancelToken) {
    return await axiosInstance.get(`${PATH}/${id}/items`, { params, cancelToken }).then(({ data }) => data)
  },
  async follow (id) {
    return await axiosInstance.post(`${PATH}/${String(id)}/follow`).then(({ data }) => data)
  },
  async unfollow (id) {
    return await axiosInstance.delete(`${PATH}/${String(id)}/follow`).then(({ data }) => data)
  },
  async create (params) {
    return await axiosInstance.post(PATH, params).then(({ data }) => data)
  },
  async update ({ params, id }) {
    return await axiosInstance.put(`${PATH}/${id}`, params)
  },
  async updateItems ({ items, id }) {
    return await axiosInstance.put(`${PATH}/${id}/items`, { item_ids: items }).then(({ data }) => data)
  },
}

export { tagsRepository }
