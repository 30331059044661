import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'
import Moment from 'react-moment'

import { AddressModal } from './address_modal'
import { Address, Order, Pagination } from '../types'
import { addressesRepository } from '../repositories/addresses_repository'
import { countries } from '../lib/countries'

import '../stylesheets/Account.scss'
import { ErrorsContext } from '../contexts/errors_context'
import { ordersRepository } from '../repositories/orders_repository'
import clsx from 'clsx'
import { BasketContext } from '../contexts/basket_context'
import { AuthContext } from '../contexts/auth_context'

type Props = Readonly<{
  currentPage: number
}>

const AccountBuy = ({ currentPage }: Props) => {
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)
  const { setShowOrderAddress } = useContext(BasketContext)
  const { sessionId } = useContext(AuthContext)

  const qS = queryString.parse(location.search)
  const queryModal = qS.modal as string
  const queryModalCallback = qS.modal_callback as string
  const querySessionId = qS.session_id as string

  const [addressModalShown, setAddressModalShown] = useState<boolean>(queryModal === 'address' && (querySessionId === sessionId || !querySessionId))
  const modalCallback = useState<boolean>(queryModalCallback === 'order_address' && querySessionId === sessionId)[0]
  const [editingAddress, setEditingAddress] = useState<Address|undefined>()
  const [addresses, setAddresses] = useState<Address[]>()
  const [addressesLoading, setAddressesLoading] = useState<boolean>(true)
  const [orders, setOrders] = useState<Order[]>()
  const [ordersLoading, setOrdersLoading] = useState<boolean>(true)

  // pagination
  const [pagination, setPagination] = useState<Pagination>({
    currentPage,
    totalPages: 1,
  })

  const toggleAddressModal = useCallback((address: Address|undefined, on: boolean) => {
    setEditingAddress(address)
    setAddressModalShown(on)
  }, [])

  const addressModalCallback = useCallback((address) => {
    const newAddress: Address = address

    setAddressModalShown(false)

    if (!newAddress) {
      setShowOrderAddress?.(modalCallback)
      return
    }

    let previousAddresses = addresses
    if (newAddress.default) {
      previousAddresses = previousAddresses?.map(a => {
        if (!a.default) return a

        const newAddress: Address = { ...a, default: false }
        return newAddress
      })
    }

    if (editingAddress) {
      setAddresses(previousAddresses?.map(a => a.id === editingAddress?.id ? newAddress : a))
    } else {
      setAddresses(previousAddresses?.concat([newAddress]))
    }

    // If user is adding their address after being requested to do so from the checkout screen:
    // show basket -> address selection screen
    setShowOrderAddress?.(modalCallback)
  }, [setShowOrderAddress, modalCallback, addresses, editingAddress])

  const getAddresses = useCallback(() => {
    setAddressesLoading(true)

    addressesRepository
      .index()
      .then(({ addresses }) => {
        setAddresses(addresses)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setAddressesLoading(false)
      })
  }, [addError])

  const deleteAddress = useCallback((address: Address) => {
    if (!address?.id) {
      return alert('error: please reload this page')
    }

    setAddressesLoading(true)

    addressesRepository
      .destroy(address.id ?? 0)
      .then(() => {
        const addressesNew = addresses?.filter(b => b.id !== address.id)

        if (addressesNew && addressesNew.length > 0 && address.default) {
          const a0: Address = {
            ...addressesNew[0],
            default: true,
          }
          addressesNew[0] = a0
        }

        setAddresses(addressesNew)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setAddressesLoading(false)
      })
  }, [addError, addresses])

  const deleteAddressConfirm = useCallback((a) => {
    if (confirm(t('account.buy.addresses.delete_confirm'))) {
      deleteAddress(a)
    }
  }, [deleteAddress, t])

  useEffect(() => {
    getAddresses()
  }, [getAddresses])

  const getOrders = useCallback(() => {
    setOrdersLoading(true)

    ordersRepository
      .index({ params: { page: currentPage, limit: 10 } })
      .then(({ orders, pagination }) => {
        setOrders(orders)
        setPagination(pagination)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setOrdersLoading(false)
      })
  }, [addError, currentPage])

  useEffect(() => {
    getOrders()
  }, [getOrders])

  const addressList = useMemo(() => {
    if (addressesLoading) {
      return (
        <p>{ t('account.buy.addresses.loading') }</p>
      )
    }

    if (!addresses || addresses?.length === 0) {
      return (
        <p>{ t('account.buy.addresses.none') }</p>
      )
    }

    return (
      <ul className="editable">
        {
          addresses.map(a => {
            const defaultAddress = a.default ? <strong>{ t('account.buy.addresses.default') }</strong> : null
            const country = countries.find(c => c.value === a.countryCode)
            const shortAddress = (a.zipCode ?? '') + ' ' + a.addressLine1 + (a.addressLine2 ?? '') + (a.addressLine3 ?? '') + (a.state ?? '')
            const editButton = <button className="tool" onClick={ () => { toggleAddressModal(a, true) } }>{ t('account.buy.addresses.edit') }</button>
            const deleteButton = <button className="tool" onClick={ () => { deleteAddressConfirm(a) } }>{ t('account.buy.addresses.delete') }</button>

            return (
              <li key={ a.id }>
                <span>{ defaultAddress }{ t(`account.countries.${country?.value ?? ''}`) } { shortAddress }</span>
                <span className="buttons">{ editButton } { deleteButton }</span>
              </li>
            )
          })
        }
      </ul>
    )
  }, [addresses, addressesLoading, t, toggleAddressModal, deleteAddressConfirm])

  const ordersList = useMemo(() => {
    if (ordersLoading) {
      return (
        <p>{ t('account.buy.orders.loading') }</p>
      )
    }

    if (!orders || orders?.length === 0) {
      return (
        <p>{ t('account.buy.orders.none') }</p>
      )
    }

    return (
      <>
        <ul className="editable">
          {
            orders.map((o) => {
              const statusColor = o?.status === 'shipped'
                ? 'green'
                : o?.status === 'succeeded'
                  ? 'yellow'
                  : ''

              return (
                <li key={ o.id }>
                  <span>
                    <strong className={ statusColor }>{ t(`account.order_status_buyer.${o.status}`) }</strong>
                    #{ o.id }<br />
                    <Moment format="YYYY-MM-DD">{ o.createdAt }</Moment>
                  </span>
                  <span className="buttons"><Link to={ `/orders/${o.id ?? 0}` } className="button tool">{ t('account.buy.orders.view') }</Link></span>
                </li>
              )
            })
          }
        </ul>
        <div className="pagination">
          <span>{ t('Page:') }</span>
          {
            Array.apply(null, Array(pagination.totalPages)).map((p, i) => {
              if (
                i >= 1 &&
                i !== pagination.totalPages - 1 &&
                (pagination.currentPage > 4 || i > 4)
              ) {
                if (i <= pagination.currentPage - 4) return null
                if (i >= pagination.currentPage + 2) return null
              }
              const pageNumber = i === 0
                ? t('最初')
                : i === pagination.totalPages - 1 ? t('最後') : i + 1

              return <Link key= { i } className={ clsx('button', { selected: i + 1 === pagination.currentPage }) } to={ `/account/buy${i > 0 ? `?page=${i + 1}` : ''}` }>{ pageNumber }</Link>
            })
          }
        </div>
      </>
    )
  }, [orders, ordersLoading, pagination.currentPage, pagination.totalPages, t])

  return (
    <div>
      <div className="narrow">
        <h2>{ t('account.buy.addresses.title') }</h2>
        { addressList }
        <button className="tool" onClick={ () => { toggleAddressModal(undefined, true) } }>{ t('account.buy.addresses.button_new') }</button>
        {
          addressModalShown
            ? <AddressModal onClose={ addressModalCallback } address={ editingAddress } addresses={ addresses ?? [] } />
            : null
        }
        <hr />
        <h2>{ t('account.buy.orders.title') }</h2>
        { ordersList }
      </div>
    </div>
  )
}

export { AccountBuy }
