import { CancelToken } from 'axios'
import { axiosInstance } from '../lib/api_client'
import { Pagination, Unit } from '../types'

const PATH = '/units'

type IndexParams = Readonly<{
  platform?: string
  query?: string
  tagGroup1Ids?: number[]
  tagGroup2Ids?: number[]
  tagGroup3Ids?: number[]
  tagGroup4Ids?: number[]
  game?: number[]
  conditions?: number[]
  sold?: string
  minPrice?: number
  maxPrice?: number
  userId?: number
  sort?: string
  page: number
  limit?: number
}>

type IndexForUserParams = Readonly<{
  userId: string|number
  grouped: boolean
  params?: Readonly<{
    limit?: number
    sort?: string
    page: number
    condition?: number

    // from filter menu
    platform?: string
    query?: string
    tagGroup1Ids?: number[]
    tagGroup2Ids?: number[]
    tagGroup3Ids?: number[]
    tagGroup4Ids?: number[]
    game?: number[]
    conditions?: number[]
    sold?: string
    minPrice?: number
    maxPrice?: number
    userId?: number
    types: string[]
  }>
}>

type GetReponse = Readonly<{
  unit: Unit
}>

type CreateParams = Readonly<{
  itemIds: number[]
  condition: number
  destruction?: string
  frontCorner?: string
  frontEdge?: string
  frontMark?: string
  frontSurface?: string
  frontScratchIllus?: string
  frontScratchOther?: string
  backCorner?: string
  backEdge?: string
  backMark?: string
  backSurface?: string
}>

type UpdateParams = Readonly<{
  id: number|string
  params: Readonly<{
    condition: number
    description: string
  }>
}>

type DeleteParams = Readonly<{
  itemIds: number[]
  condition: number
}>

type CreateResponse = Readonly<{
  id: number
  unit: Unit
}>

type IndexReponse = Readonly<{
  listings: Unit[]
  pagination: Pagination
}>

type UnitsRepository = Readonly<{
  index: (params: IndexParams, cancelToken?: CancelToken) => Promise<IndexReponse>
  indexForUser: (params: IndexForUserParams, cancelToken?: CancelToken) => Promise<IndexReponse>
  get: (id: number) => Promise<GetReponse>
  create: (params: CreateParams) => Promise<CreateResponse>
  update: (params: UpdateParams) => Promise<void>
  batchUpdateOrdinals: (params: { ids: number[] }) => Promise<void>
  delete: (id: number) => Promise<void>
  deleteByItemIdAndCondition: (params: DeleteParams) => Promise<void>
}>

const unitsRepository: UnitsRepository = {
  async index (params, cancelToken?: CancelToken) {
    return await axiosInstance.get(PATH, { params, cancelToken }).then(({ data }) => data)
  },
  async indexForUser ({ userId, grouped, params }, cancelToken?: CancelToken) {
    if (grouped) {
      return await axiosInstance.get(`/users/${String(userId)}/units`, { params, cancelToken }).then(({ data }) => data)
    }

    const paramsToUse = {
      ...params,
      userId,
    }

    return await axiosInstance.get(PATH, { params: paramsToUse, cancelToken }).then(({ data }) => data)
  },
  async get (id) {
    return await axiosInstance.get(`${PATH}/${id}`).then(({ data }) => data)
  },
  async create (params) {
    return await axiosInstance.post(PATH, params).then(({ data }) => data)
  },
  async update ({ params, id }) {
    return await axiosInstance.put(`${PATH}/${id}`, { unit: params }).then()
  },
  async batchUpdateOrdinals (params) {
    return await axiosInstance.put(`${PATH}/batch_update_ordinalities`, params).then()
  },
  async delete (id) {
    return await axiosInstance.delete(`${PATH}/${String(id)}`).then(({ data }) => data)
  },
  async deleteByItemIdAndCondition (params) {
    return await axiosInstance.post(`${PATH}/delete`, params).then(({ data }) => data)
  },
}

export { unitsRepository }
