import React, { useCallback, useContext, useState } from 'react'
import { ModalWrapper } from './modal_wrapper'
import { Link } from 'react-router-dom'
import { unitsRepository } from '../repositories/units_repository'
import { Unit } from '../types'
import { AxiosError } from 'axios'
import { ErrorsContext } from '../contexts/errors_context'
import { AuthContext } from '../contexts/auth_context'

type Props = Readonly<{
  onClose: () => void
  unit?: Unit
}>

const UnitBuyCompleteModal = ({ onClose, unit }: Props) => {
  const { addError } = useContext(ErrorsContext)
  const { currentUser } = useContext(AuthContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [inCollection, setInCollection] = useState<boolean>(false)

  const addToCollection = useCallback(() => {
    if (loading || !unit || !unit.items) return

    setLoading(true)

    // add a new user item
    unitsRepository
      .create({
        itemIds: unit.items.map((item) => item.id),
        condition: unit.condition,
      })
      .then(() => {
        setInCollection(true)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [loading, unit, addError])

  return (
    <ModalWrapper>
      <div>
        <h1>ご注文ありがとうございます</h1>
        <p>
          ご注文が確定されました。
        </p>
        <p>
          商品が発送されましたら、メールにてお知らせいたします。
        </p>
        {
          unit && currentUser
            ? inCollection
              ? <><p><strong>マイデッキに追加されました！</strong></p><p><Link to={ `/u/${currentUser.id}` } className="button full">マイデッキを見る</Link></p></>
              : <p><button className="full white" onClick={ addToCollection } disabled={ loading }>マイデッキに移動する</button></p>
            : null
        }
        <p>
          <Link to="/account/buy" className="button full white" onClick={ onClose }>
            注文の詳細を表示する
          </Link>
        </p>
        <p>
          <button className="full" onClick={ onClose }>
            閉じる
          </button>
        </p>
      </div>
    </ModalWrapper>
  )
}

export { UnitBuyCompleteModal }
