import { axiosInstance } from '../lib/api_client'

type CreateParams = Readonly<{
  imageId: number
  params: Readonly<{
    unitId?: number
    matchId: number
  }>
}>

type DeleteParams = Readonly<{
  imageId: number
  id: number
}>

type CreateResponse = Readonly<{
  id: number
}>

type ImageUnitsRepository = Readonly<{
  create: (params: CreateParams) => Promise<CreateResponse>
  delete: (params: DeleteParams) => Promise<void>
}>

const imageUnitsRepository: ImageUnitsRepository = {
  async create ({ params, imageId }) {
    return await axiosInstance.post(`/images/${imageId}/image_units`, params).then(({ data }) => data)
  },
  async delete ({ imageId, id }) {
    return await axiosInstance.delete(`/images/${imageId}/image_units/${id}`).then(({ data }) => data)
  },
}

export { imageUnitsRepository }
