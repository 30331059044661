import { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { ErrorsContext } from '../contexts/errors_context'

const PageActions = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { resetErrors } = useContext(ErrorsContext)

  const setPageTitle = useCallback(() => {
    document.title = t('app.title')
  }, [t])

  useEffect(() => {
    // when route changes, reset stuff
    setPageTitle()
    resetErrors?.()

    // FIXME: pull-down menu should always close when route changes
    // // reset menu state
    // this.props.global_functions.toggleMenu(false)

    // FIXME: currently using a global error reset function, to clear all errors when user changes route
    // // reset errors
    // this.props.errors.reset()
  }, [location.pathname, resetErrors, setPageTitle])

  return null
}

export { PageActions }
