import React, { createContext, useEffect, useState, useCallback } from 'react'
import { getLocalIsDarkTheme, setLocalIsDarkTheme } from '../lib/local_storage'
import { Navigation } from '../types'

type CategoriesLayoutType = 'panels' | 'list'
type CollectionLayoutType = 'grouped' | 'individual'

const defaultNavigation: Navigation = {
  style: 'search',
}

const SettingsContext = createContext<{
  categoriesLayout: CategoriesLayoutType
  setCategoriesLayout?: (layout: CategoriesLayoutType) => void
  collectionLayout: CollectionLayoutType
  setCollectionLayout?: (layout: CollectionLayoutType) => void
  dark: boolean
  setDark?: (value: boolean) => void
  minimalUi: boolean
  setMinimalUi?: (value: boolean) => void
  minimalNavigation: boolean
  setMinimalNavigation?: (value: boolean) => void
  navigation: Navigation
  setNavigation?: (navigation: Navigation) => void
}>({
      categoriesLayout: 'panels',
      collectionLayout: 'individual',
      dark: getLocalIsDarkTheme(),
      minimalUi: false,
      minimalNavigation: true,
      navigation: defaultNavigation,
    })

const SettingsProvider = ({ children }) => {
  const [categoriesLayout, setCategoriesLayout] = useState<CategoriesLayoutType>('panels')
  const [collectionLayout, setCollectionLayout] = useState<CollectionLayoutType>('individual')
  const [dark, _setDark] = useState<boolean>(getLocalIsDarkTheme())
  const [minimalUi, _setMinimalUi] = useState<boolean>(false)
  const [minimalNavigation, _setMinimalNavigation] = useState<boolean>(false)
  const [navigation, _setNavigation] = useState<Navigation>(defaultNavigation)

  const setDark = useCallback((value: boolean) => {
    _setDark(value)
    setLocalIsDarkTheme(value)
  }, [])

  useEffect(() => {
    document.body.classList.remove('dark')
    document.body.classList.remove('light')

    document.body.classList.add(dark ? 'dark' : 'light')
  }, [dark])

  const setMinimalUi = useCallback((value: boolean) => {
    _setMinimalNavigation(value)
    _setMinimalUi(value)
  }, [])

  const setMinimalNavigation = useCallback((value: boolean) => {
    _setMinimalNavigation(value)
  }, [])

  const setNavigation = useCallback((value: Navigation) => {
    _setNavigation(value)
  }, [])

  useEffect(() => {
    document.body.classList.remove('minimal_ui')

    if (minimalUi) {
      document.body.classList.add('minimal_ui')
    }
  }, [minimalUi])

  useEffect(() => {
    document.body.classList.remove('minimal_navigation')

    if (minimalNavigation) {
      document.body.classList.add('minimal_navigation')
    }
  }, [minimalNavigation])

  return (
    <SettingsContext.Provider value={ { categoriesLayout, setCategoriesLayout, collectionLayout, setCollectionLayout, dark, setDark, minimalUi, setMinimalUi, minimalNavigation, setMinimalNavigation, navigation, setNavigation } }>
      { children }
    </SettingsContext.Provider>
  )
}

export { SettingsProvider, SettingsContext }
