import { TagShort } from '../types'

const energyTypes: TagShort[] = [
  { id: 2133, name: 'grass' },
  { id: 2137, name: 'fire' },
  { id: 2138, name: 'water' },
  { id: 2132, name: 'lightning' },
  { id: 2136, name: 'psychic' },
  { id: 2139, name: 'fighting' },
  { id: 2134, name: 'darkness' },
  { id: 2142, name: 'metal' },
  { id: 2381, name: 'fairy' },
  { id: 2389, name: 'dragon' },
  { id: 2135, name: 'colorless' },
]

export { energyTypes }
