import { TagShort } from '../types'

const cardTypes: TagShort[] = [
  { id: 2366, name: 'Basic' },
  { id: 2382, name: 'Stage 1' },
  { id: 2383, name: 'Stage 2' },
  { id: 2387, name: 'V' },
  { id: 2388, name: 'VMAX' },
  { id: 3115, name: 'VSTAR' },
  { id: 3114, name: 'V-UNION' },
  { id: 2379, name: 'Single Strike' },
  { id: 2376, name: 'Rapid Strike' },
  { id: 3125, name: 'Fusion Strike' },
  { id: 2367, name: 'EX' },
  { id: 3121, name: 'Radiant' },
  { id: 3118, name: 'δ-Delta Species' },
  { id: 3133, name: 'C [Champion]' },
  { id: 3124, name: 'Dark' },
  { id: 3126, name: 'Light' },
  { id: 2370, name: 'Item' },
  { id: 2128, name: 'Supporter' },
  { id: 2131, name: 'Stadium' },
  { id: 2374, name: 'Pokémon Tool' },
  { id: 3136, name: 'Special Energy' },
  { id: 2368, name: 'GX' },
  { id: 2385, name: 'TAG TEAM' },
  { id: 3119, name: 'Ultra Beast' },
  { id: 3120, name: 'Prism Star' },
  { id: 2364, name: 'BREAK' },
  { id: 3122, name: 'Shining' },
  { id: 2365, name: 'Baby' },
  { id: 2377, name: 'Restored' },
  { id: 3116, name: 'ex' },
  { id: 3132, name: 'Pokémon SP' },
  { id: 3129, name: '4 [Elite] (四)' },
  { id: 3134, name: 'FB [Frontier Brain]' },
  { id: 3131, name: 'G [Galactic]' },
  { id: 3130, name: 'GL [Gym Leader]' },
  { id: 3135, name: 'M [Movie]' },
  { id: 2371, name: 'LEGEND' },
  { id: 3127, name: 'LV.X' },
  { id: 3123, name: 'Crystal' },
  { id: 3128, name: 'Prime' },
  { id: 3117, name: 'Star' },
  { id: 2129, name: 'Ace Spec' },
  { id: 2386, name: 'Technical Machine' },
  { id: 2369, name: 'Goldenrod Game Corner' },
  { id: 2375, name: 'Pokémon Tool F' },
  { id: 2378, name: 'RocketsSecretMachine' },
]

export { cardTypes }
