import { AxiosError } from 'axios'
import React, { useMemo, useCallback, useContext, useState, useEffect, useRef } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../contexts/auth_context'
import { ErrorsContext } from '../contexts/errors_context'
import { stripeAccountRepository } from '../repositories/stripe_accounts_repository'
import { usersRepository } from '../repositories/users_repository'
import { Filters, RegionShippingService, StripeAccount, Tag } from '../types'
import { EditStripeAccountModal } from './edit_stripe_account_modal'
import { Register } from './register'
import moment from 'moment'
import { ModalWrapper } from './modal_wrapper'
import { SettingsContext } from '../contexts/settings_context'
import { Link, useNavigate } from 'react-router-dom'
import { AboutPhoneVerification } from './static'
import topSell from '../hero/top_sell.svg'
import clsx from 'clsx'
import { shippingServices } from '../lib/shipping_services'
import { regions } from '../lib/regions'
import { regionShippingServicesRepository } from '../repositories/region_shipping_services_repository'
import { SetsList } from './sets_list'
import { tagsRepository } from '../repositories/tags_repository'
import { SearchContext } from '../contexts/search_context'

type VerifyPhoneNumberProps = Readonly<{
  resetPhone: () => void
  verifyCallback: (status: string) => void
  verificationStatus: string
  phoneNumber: string
  hasPhoneNumber: boolean
}>

const VerifyPhoneNumber = ({ phoneNumber, hasPhoneNumber, resetPhone, verifyCallback, verificationStatus }: VerifyPhoneNumberProps) => {
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)

  type VerificationForm = Readonly<{
    code: string
  }>

  const {
    register,
    handleSubmit,
  } = useForm<VerificationForm>()

  const codeResendDelay = 60

  const [codeRequestAvailableTime, setCodeRequestAvailableTime] = useState<number>(0)
  const [codeRequestAvailableCountdown, setCodeRequestAvailableCountdown] = useState<number>(0)

  const timer = useRef(setTimeout(() => {}, 1000))

  const setTimer = useCallback(() => {
    clearInterval(timer.current)

    timer.current = setInterval(() => {
      const now = moment().valueOf()

      if (codeRequestAvailableTime > now) {
        setCodeRequestAvailableCountdown(Math.ceil((codeRequestAvailableTime - now) / 1000))
        setTimer()
      } else {
        setCodeRequestAvailableCountdown(0)
      }
    }, 1000)
  }, [codeRequestAvailableTime])

  useEffect(() => {
    // clear on component unmount
    return () => {
      clearInterval(timer.current)
    }
  }, [])

  useEffect(() => {
    if (codeRequestAvailableTime > 0) {
      setTimer()
    }
  }, [codeRequestAvailableTime, setTimer])

  const [loading, setLoading] = useState<boolean>(false)

  const requestVerify = useCallback(() => {
    if (loading) return

    setLoading(true)

    stripeAccountRepository
      .requestVerifyPhoneNumber()
      .then(() => {
        setCodeRequestAvailableTime(moment().add(codeResendDelay, 'seconds').valueOf())
        verifyCallback('pending')
      })
      .catch((err: AxiosError) => {
        verifyCallback('failed')
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError, loading, verifyCallback])

  useEffect(() => {
    if (loading || !hasPhoneNumber) return

    if (verificationStatus === 'ready') {
      verifyCallback('loading')
      requestVerify()
    }
  }, [hasPhoneNumber, loading, requestVerify, verificationStatus, verifyCallback])

  const onSubmit = useCallback((params: VerificationForm) => {
    if (loading) return

    setLoading(true)

    stripeAccountRepository
      .attemptVerifyPhoneNumber(params)
      .then(() => {
        verifyCallback('ok')
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError, loading, verifyCallback])

  const resendCode = useCallback(() => {
    verifyCallback('ready')
  }, [verifyCallback])

  const formAvailable = useMemo(() => {
    return hasPhoneNumber && verificationStatus !== ''
  }, [hasPhoneNumber, verificationStatus])

  const resendButtonText = useMemo(() => {
    if (codeRequestAvailableCountdown < 1) return t('account.sell.edit_stripe_account.phone_verify.code_resend')

    return t('account.sell.edit_stripe_account.phone_verify.code_resend_delay', { seconds: codeRequestAvailableCountdown })
  }, [codeRequestAvailableCountdown, t])

  const modalClose = useCallback(() => {
    verifyCallback('ready')
  }, [verifyCallback])

  const modal = useMemo(() => {
    if (!hasPhoneNumber || verificationStatus !== '') return

    return <ModalWrapper>
      <h1>{ t('account.sell.edit_stripe_account.phone_verify.modal.title') }</h1>
      <p>{ t('account.sell.edit_stripe_account.phone_verify.modal.body1', { number: phoneNumber }) }</p>
      <p>{ t('account.sell.edit_stripe_account.phone_verify.modal.body2') }</p>
      <button className='full' onClick={ modalClose }>{ t('account.sell.edit_stripe_account.phone_verify.modal.button') }</button>
    </ModalWrapper>
  }, [hasPhoneNumber, modalClose, phoneNumber, t, verificationStatus])

  const [aboutModalDisplayed, setAboutModalDisplayed] = useState<boolean>(false)

  const showAboutModal = useCallback((e) => {
    e.preventDefault()
    setAboutModalDisplayed(true)
    return false
  }, [])

  if (verificationStatus === 'loading') {
    return <p>{ t('account.sell.edit_stripe_account.phone_verify.loading') }</p>
  }

  return <>
    { modal }
    {
      aboutModalDisplayed
        ? <ModalWrapper>
          <AboutPhoneVerification />
          <button onClick={ () => setAboutModalDisplayed(false) } className='full'>{ t('account.sell.edit_stripe_account.close_button') }</button>
        </ModalWrapper>
        : null
    }
    {
      formAvailable
        ? <div className='single-line'>
          <button className="tool" disabled={ codeRequestAvailableCountdown > 0 || loading } onClick={ resendCode }>{ resendButtonText }</button>
          <button className="tool" disabled={ loading } onClick={ resetPhone }>{ t('account.sell.edit_stripe_account.phone_verify.phone_modify') }</button>
        </div>
        : null
    }
    <form onSubmit={ handleSubmit(onSubmit) } className='full-height'>
      <p>
        <label className={ clsx({ disabled: !formAvailable }) }>{ t('account.sell.edit_stripe_account.verification_code') }</label>
        <input type="text" className='full' { ...register('code') } disabled={ !formAvailable } required />
      </p>
      <div className='bottom-element'>
        {
          formAvailable
            ? <p>
              <button className="full" disabled={ loading || !formAvailable }>{ t('account.sell.edit_stripe_account.phone_verify.button') }</button>
            </p>
            : null
        }
        <p className='center'><Link className='black small' target='_blank' to='/about/phone-verify' onClick={ showAboutModal }>電話番号の確認が必要な理由 →</Link></p>
      </div>
    </form>
  </>
}

type EditPhoneNumberProps = Readonly<{
  phoneCallback: (stripeAccount?: StripeAccount) => void
  stripeAccount?: StripeAccount
  hasPhoneNumber: boolean
}>

const EditPhoneNumber = ({ hasPhoneNumber, phoneCallback, stripeAccount }: EditPhoneNumberProps) => {
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)

  const {
    register,
    handleSubmit,
    reset,
    control,
  } = useForm<StripeAccount>()

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (stripeAccount) {
      reset(stripeAccount)
    }
  }, [reset, stripeAccount])

  const onSubmit = useCallback((params: StripeAccount) => {
    // TODO: Validation
    if (loading) return

    setLoading(true)

    stripeAccountRepository
      .update(params)
      .then(({ stripeAccount }) => {
        phoneCallback(stripeAccount)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError, loading, phoneCallback])

  const phoneNumber = useWatch({
    control,
    name: 'phone',
  })

  return <>
    <form onSubmit={ handleSubmit(onSubmit) }>
      <p className='hide-desktop'>
        本人確認のため、携帯電話のSMS（ショートメッセージサービス）を利用して認証を行います。電話番号が他のお客さまに公開されることはありません。
      </p>
      <p>
        <label>{ t('account.sell.edit_stripe_account.phone') }</label>
        <input type="text" className='full' { ...register('phone') } placeholder='090-0000-0000' required disabled={ !!hasPhoneNumber } />
      </p>
      {
        !hasPhoneNumber
          ? <p className='center'>
            <button className="tool" disabled={ loading || !(phoneNumber && phoneNumber.length > 8) }>{ t('account.sell.edit_stripe_account.phone_verify.code_send') }</button>
          </p>
          : null
      }
    </form>
  </>
}

type ShippingServicesListProps = Readonly<{
  loading: boolean
  providedServices: RegionShippingService[]
  saveCallback: () => void
  updateCallback: (service: RegionShippingService, add: boolean) => void
}>

const ShippingServicesList = ({ loading, providedServices, saveCallback, updateCallback }: ShippingServicesListProps) => {
  const { t } = useTranslation()

  const selectService = useCallback((s, add: boolean) => {
    const japanRegion = regions.find(r => r.id === 3)

    if (!japanRegion) return

    const service: RegionShippingService = {
      id: 0,
      region: japanRegion,
      shippingService: s,
      priceJpy: s.recommendedPrice,
      description: ''
    }

    updateCallback(service, add)
  }, [updateCallback])

  const onChangeField = useCallback((field: string, rss: RegionShippingService, e) => {
    const service: RegionShippingService = {
      ...rss,
      priceJpy: field === 'price' ? Number(e.target.value) : rss.priceJpy,
      description: field === 'description' ? e.target.value : rss.description,
    }

    updateCallback(service, true)
  }, [updateCallback])

  return <>
    <ul className='editable next-level full-height'>
      {
        shippingServices.map(s => {
          const selected = providedServices.find(ps => ps.shippingService.id === s.id)

          return <>
            <li key={ s.id }>
              <div className={ clsx('selectable', { selected }) } onClick={ _ => selectService(s, !(selected)) }>
                <span className='flex select-area'>
                  <div className='select-indicator'></div>
                  { t(`account.sell.shipping_options.options.${s.label}`) }
                </span>
                {
                  selected
                    ? '選択済み'
                    : ''
                }
              </div>
              {
                !selected
                  ? null
                  : <>
                    {
                      !s.summary
                        ? null
                        : <p>{ s.summary }</p>
                    }
                    <div className='price'>
                      <span>金額</span>
                      <span>
                        <input type='number' className='half' value={ selected.priceJpy } onChange={ e => { onChangeField('price', selected, e) } } /> 円
                      </span>
                    </div>
                    <div className='description'>
                      <span>説明</span>
                      <span>
                        <input type='text' placeholder='追加説明があれば入力してください' onChange={ e => { onChangeField('description', selected, e) } } />
                      </span>
                    </div>
                  </>
              }
            </li>
          </>
        })
      }
    </ul>
    <div className='bottom-element'>
      <button className="full" onClick={ saveCallback } disabled={ loading || providedServices.length === 0 }>{
        loading
          ? '読み込み中…'
          : '確定'
      }</button>
      <p className='center'><Link className='black small' target='_blank' to='/about/returns'>返品条件 →</Link></p>
    </div>
  </>
}

const Sell = () => {
  const { t } = useTranslation()

  const { addError } = useContext(ErrorsContext)
  const { currentUser, setCurrentUser, isAuthenticated } = useContext(AuthContext)
  const { setMinimalNavigation, setNavigation } = useContext(SettingsContext)

  const canSellHolic = useMemo(() => {
    return currentUser?.canSellHolic ?? false
  }, [currentUser?.canSellHolic])

  const hasRegionShippingServices = useMemo(() => {
    return currentUser?.hasRegionShippingServices ?? false
  }, [currentUser?.hasRegionShippingServices])

  const hasSellerCredentials = useMemo(() => {
    return currentUser?.hasSellerCredentials ?? false
  }, [currentUser?.hasSellerCredentials])

  const [stripeAccount, setStripeAccount] = useState<StripeAccount>()
  const [loadingSellerAccounts, setLoadingSellerAccounts] = useState<boolean>(false)

  const [stage, setStage] = useState<string|undefined>()

  const getSellerAccount = useCallback(() => {
    setLoadingSellerAccounts(true)

    usersRepository
      .getSelf()
      .then(({ stripeAccount, user }) => {
        setStripeAccount(stripeAccount)
        setCurrentUser?.(user)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoadingSellerAccounts(false)
      })
  }, [addError, setCurrentUser])

  useEffect(() => {
    if (!isAuthenticated || stage) return

    if (!stripeAccount) {
      setStage('no-stripe')
      getSellerAccount()
    }
  }, [getSellerAccount, isAuthenticated, loadingSellerAccounts, stage, stripeAccount])

  const editStripeAccountCallback = useCallback((newStripeAccount?: StripeAccount) => {
    if (!newStripeAccount) return

    setStage('has-stripe')
    setStripeAccount(newStripeAccount)

    getSellerAccount()
  }, [getSellerAccount])

  const editPhoneNumberCallback = useCallback((newStripeAccount?: StripeAccount) => {
    setStripeAccount(newStripeAccount)
    setHasPhoneNumber(!!newStripeAccount?.phoneWithDialingCode)
  }, [])

  const [hasPhoneNumber, setHasPhoneNumber] = useState<boolean>(false)
  const [verificationStatus, setVerificationStatus] = useState<string>('')

  useEffect(() => {
    setHasPhoneNumber(!!stripeAccount?.phoneWithDialingCode)
  }, [stripeAccount?.phoneWithDialingCode])

  useEffect(() => {
    if (verificationStatus === 'failed') {
      setHasPhoneNumber(false)
      setVerificationStatus('ready')
    }
  }, [stripeAccount, verificationStatus])

  const verifyCallback = useCallback((status: string) => {
    setVerificationStatus(status)
  }, [])

  const resetPhone = useCallback(() => {
    setHasPhoneNumber(false)
    setVerificationStatus('ready')
  }, [])

  const [regionShippingServices, setRegionShippingServices] = useState<RegionShippingService[]>([])

  const shippingServicesListUpdateCallback = useCallback((newService: RegionShippingService, add: boolean) => {
    setRegionShippingServices(rss => {
      const services = rss.filter(s => s.shippingService.id !== newService.shippingService.id)

      if (add) {
        services.push(newService)
      }

      return services
    })
  }, [])

  const [loading, setLoading] = useState<boolean>(false)

  const shippingServicesListSaveCallback = useCallback(() => {
    setLoading(true)

    if (loading) return

    regionShippingServices.forEach(rss => {
      regionShippingServicesRepository
        .create({
          ...rss,
          region: rss.region.id,
          shippingService: rss.shippingService.id,
        })
        .then(() => {
          currentUser && setCurrentUser?.({
            ...currentUser,
            hasRegionShippingServices: true,
          })
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }, [addError, currentUser, loading, regionShippingServices, setCurrentUser])

  useEffect(() => {
    if (verificationStatus === 'ok') {
      setStage('verified')
      getSellerAccount()
    }
  }, [getSellerAccount, verificationStatus])

  useEffect(() => {
    if (!setMinimalNavigation) return

    setMinimalNavigation(true)

    return () => {
      setMinimalNavigation(false)
    }
  }, [setMinimalNavigation])

  // const verifyPhoneNumberForm = useMemo(() => {
  //   return <VerifyPhoneNumber verificationStatus={ verificationStatus } verifyCallback={ verifyCallback } resetPhone={ resetPhone } />
  // }, [hasPhoneNumber, resetPhone, verificationStatus, verifyCallback])

  useEffect(() => {
    if (loadingSellerAccounts || !stage || !setNavigation) return

    let currentStep = 2
    let complete = false

    if (!hasSellerCredentials) {
      currentStep = 1
    } else if (!canSellHolic) {
      currentStep = 2
    } else if (!hasRegionShippingServices) {
      currentStep = 3
    } else {
      complete = true
    }

    setNavigation({
      style: 'steps',
      totalSteps: 3,
      currentStep,
      complete,
    })
  }, [canSellHolic, hasRegionShippingServices, hasSellerCredentials, loadingSellerAccounts, setNavigation, stage])

  useEffect(() => {
    // clear on component unmount
    return () => {
      setNavigation?.({
        style: 'search',
      })
    }
  }, [setNavigation])

  const navigate = useNavigate()

  const query = useState<string>()[0]

  type SearchForm = Readonly<{
    query: string
  }>

  const {
    register,
    handleSubmit,
  } = useForm<SearchForm>()

  const { filters, endpointForExistingFilters } = useContext(SearchContext)
  const categoriesLink = useCallback((q: string) => {
    if (!endpointForExistingFilters) return ''

    const p = '/categories'

    const f: Filters = {
      ...filters,
      selectedView: 'items',
    }

    const params = endpointForExistingFilters(f, q)

    return p + params
  }, [endpointForExistingFilters, filters])

  const search = useCallback((params) => {
    const query = params.query

    if (query === undefined || query.replace(/\s/g, '').length === 0) {
      alert('キーワードを入力してください')
      return
    }

    navigate(categoriesLink(query))
  }, [categoriesLink, navigate])

  const searchForm = useMemo(() => {
    return (
      <form onSubmit={ handleSubmit(search) }>
        <p>
          <input type="text" value={ query } className="full" placeholder='カード名を入力してください' { ...register('query') } />
        </p>
        <p>
          <input className='full' type='submit' value='検索' />
        </p>
      </form>
    )
  }, [handleSubmit, query, register, search])

  const [tagsTop, setTagsTop] = useState<Tag[]>([])
  const [tagsLatest1, setTagsLatest1] = useState<Tag[]>([])
  const [tagsLatest2, setTagsLatest2] = useState<Tag[]>([])
  const [, setTagsLoading] = useState<boolean>(false)

  const getTagsTop = useCallback(() => {
    setTagsLoading(true)

    tagsRepository
      .index({
        parentId: 1,
        params: {
          page: 1,
          limit: 999,
          sort: 'release_date_desc',
          mustHaveItems: true,
        },
      })
      .then(({ tags }) => {
        setTagsTop(tags)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
  }, [addError])

  const getTagsLatest1 = useCallback(() => {
    setTagsLoading(true)

    tagsRepository
      .index({
        parentId: 3709,
        params: {
          page: 1,
          limit: 12,
          sort: 'release_date_desc',
          mustHaveItems: true,
        },
      })
      .then(({ tags }) => {
        setTagsLatest1(tags)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
  }, [addError])

  const getTagsLatest2 = useCallback(() => {
    setTagsLoading(true)

    tagsRepository
      .index({
        parentId: 1923,
        params: {
          page: 1,
          limit: 12,
          sort: 'release_date_desc',
          mustHaveItems: true,
        },
      })
      .then(({ tags }) => {
        setTagsLatest2(tags)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
  }, [addError])

  useEffect(() => {
    if (tagsTop.length > 0 && tagsLatest1.length > 0 && tagsLatest2.length > 0) {
      setTagsLoading(false)
    }
  }, [tagsLatest1.length, tagsLatest2.length, tagsTop.length])

  useEffect(() => {
    if (canSellHolic) {
      getTagsLatest1()
      getTagsLatest2()
      getTagsTop()
    }
  }, [canSellHolic, getTagsLatest1, getTagsLatest2, getTagsTop])

  const sellUi = useMemo(() => {
    if (!isAuthenticated) {
      return <Register />
    }

    if (loadingSellerAccounts || !stage) {
      return <p>Loading...</p>
    }

    if (!hasSellerCredentials) {
      // input seller credentials
      // family name
      // first name
      // date of birth

      return <div className='slim personal-information'>
        <div className='left container'>
          <h1>本人情報の登録</h1>
          <p className='hide-desktop'>安心・安全にご利用いただくために、お客さまの本人情報の登録にご協力ください。他のお客さまに公開されることはありません。</p>
          <EditStripeAccountModal onClose={ editStripeAccountCallback } stripeAccount={ stripeAccount } showCancelButton={ false } buttonText={ t('account.sell.edit_stripe_account.next_button') } />
        </div>
        <div className='right hide-mobile text'>
          <p>安心・安全にご利用いただくために、お客さまの本人情報の登録にご協力ください。他のお客さまに公開されることはありません。</p>
          <p><Link className='black small' target='_blank' to='/about/personal-information'>本人情報の登録について →</Link></p>
          <img src={ topSell } />
        </div>
      </div>
    }

    if (!canSellHolic) {
      // verify phone number
      return <div className='slim verify-phone'>
        <div className='left container'>
          <h1>電話番号の確認</h1>
          <EditPhoneNumber hasPhoneNumber={ hasPhoneNumber } phoneCallback={ editPhoneNumberCallback } stripeAccount={ stripeAccount } />
          <VerifyPhoneNumber hasPhoneNumber={ hasPhoneNumber } verificationStatus={ verificationStatus } verifyCallback={ verifyCallback } resetPhone={ resetPhone } phoneNumber={ stripeAccount?.phone ?? '' } />
        </div>
        <div className='right container hide-mobile text'>
          <p>本人確認のため、携帯電話のSMS（ショートメッセージサービス）を利用して認証を行います。電話番号が他のお客さまに公開されることはありません。</p>
          <p><Link className='black small' target='_blank' to='/about/phone-verify'>電話番号の確認が必要な理由 →</Link></p>
        </div>
      </div>
    }

    if (!hasRegionShippingServices) {
      // add region shipping services
      return <div className='slim region-shipping-services'>
        <div className='left container'>
          { /* <h1>Shipping services</h1>
          <p>Please select which shipping services you would like to provide to your customers.</p>
          <p>You may customise the price to consider the cost of packing materials, etc.</p> */ }
          <h1>配送サービス</h1>
          <p>配送サービスを選択してください。梱包材などのコストを考慮し、価格をカスタマイズすることも可能です。<br />ただしその分、他の出品者に比べてコストが高くなる可能性がありますので、適切なバランスを見極めてください。</p>
          <ShippingServicesList saveCallback={ shippingServicesListSaveCallback } updateCallback={ shippingServicesListUpdateCallback } providedServices={ regionShippingServices } loading={ loading } />
        </div>
        <div className='right container hide-mobile text'>
          { /* <p>At HOLIC, customers can order many different items from your shop with a single shipping fee.</p>
          <p>To reduce the risk of returns, we recommend to favour services with tracking and compensation. However, this may increase the cost of your service compared to other sellers, so please find a suitable balance.</p>
          <p><Link className='black small' target='_blank' to='/about/returns'>Returns policy →</Link></p> */ }
          <p>HOLICでは、購入者は1回の送料で、あなたのショップの様々な商品を注文することができます。</p>
          <p>返品のリスクを減らすために、追跡や補償のある配送方法を選択することをお勧めします。</p>
          <p><Link className='black small' target='_blank' to='/about/returns'>返品条件 →</Link></p>
        </div>
      </div>
    }

    return <div className='full-height'>
      <h1>カードを出品しましょう！</h1>
      <div className='narrow'>
        <h2>カード名で探す</h2>
        <p>{ searchForm }</p>
      </div>
      <hr />
      <h2>シリーズ毎に探す</h2>
      <h3>最新のシリーズ</h3>
      <div className="panels sets-container">
        <SetsList tags={ tagsLatest1 } carousel={ false } cleanLinks={ true } />
      </div>
      <div className="panels sets-container">
        <SetsList tags={ tagsLatest2 } carousel={ false } cleanLinks={ true } />
      </div>
      <h3>全てのシリーズ</h3>
      <div className="panels sets-container">
        <SetsList tags={ tagsTop } carousel={ false } cleanLinks={ true } />
      </div>
    </div>
  }, [canSellHolic, editPhoneNumberCallback, editStripeAccountCallback, hasPhoneNumber, hasRegionShippingServices, hasSellerCredentials, isAuthenticated, loading, loadingSellerAccounts, regionShippingServices, resetPhone, searchForm, shippingServicesListSaveCallback, shippingServicesListUpdateCallback, stage, stripeAccount, t, tagsLatest1, tagsLatest2, tagsTop, verificationStatus, verifyCallback])

  return sellUi
}

export { Sell }
