import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { ErrorsContext } from '../contexts/errors_context'
import { blogsRepository } from '../repositories/blogs_repository'
import { Blog } from '../types'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

type Props = Readonly<{
  blog?: Blog
  callback: (blog: Blog) => void
}>

const BlogCreate = ({ blog, callback }: Props) => {
  const { addError } = useContext(ErrorsContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [_blog, setBlog] = useState<Blog>()

  useEffect(() => {
    setBlog(blog)
  }, [blog])

  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: blog,
  })

  const publishedAt = useWatch({
    control,
    name: 'publishedAt',
  })

  const updateBlog = useCallback((id: number, data: Blog) => {
    if (loading) return

    setLoading(true)

    blogsRepository
      .update({
        id,
        blog: data,
      })
      .then(() => {
        callback(data)
      })
      .catch((err) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [loading, callback, addError])

  const createBlog = useCallback((data: Blog) => {
    if (loading) return

    setLoading(true)

    blogsRepository
      .create({
        blog: data,
      })
      .then(({ blog }) => {
        setBlog(blog)
        callback(blog)
      })
      .catch((err) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [loading, callback, addError])

  const onSubmit = useCallback((data) => {
    if (loading) return

    const workingBlog = {
      ..._blog,
      ...data,
    }

    if (_blog?.id) {
      updateBlog(_blog.id, workingBlog)
    } else {
      createBlog(workingBlog)
    }
  }, [_blog, createBlog, loading, updateBlog])

  return (
    <form onSubmit={ handleSubmit(onSubmit) }>
      <div>
        <label htmlFor="title">タイトル</label>
        <input type="text" { ...register('title') } />
      </div>
      <div>
        <label htmlFor="body">本文</label>
        <textarea { ...register('body') } />
      </div>
      <div>
        <label htmlFor="published_at">公開日時</label>
        <DatePicker
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={ 60 }
          dateFormat="yyyy/MM/dd HH:mm"
          selected={ publishedAt ? moment(publishedAt).valueOf() : null }
          onChange={ (date) => {
            setValue('publishedAt', date)
          } }
        />
      </div>
      <p>
        <button type="submit" className='full'>投稿</button>
      </p>
    </form>
  )
}

export default BlogCreate
