import { ShippingService } from '../types'

const shippingServices: ShippingService[] = [
  {
    id: 1,
    label: 'Standard',
    recommendedPrice: 84,
  },
  {
    id: 3,
    label: 'standard50',
    recommendedPrice: 94,
  },
  {
    id: 4,
    label: 'standardlarge50',
    recommendedPrice: 120,
  },
  {
    id: 5,
    label: 'standardlarge100',
    recommendedPrice: 140,
  },
  {
    id: 2,
    label: 'Priority',
    recommendedPrice: 244,
    summary: '引受けを記録するので、郵便物等を差し出した記録を残したいときにおすすめです。料金の例はは25g以下の場合です。',
  },
  {
    id: 16,
    label: 'standardtrackedinsured',
    recommendedPrice: 404,
    summary: '引受けと配達のみを記録します。万一の場合の賠償額は、原則として5万円までの実損額となります。料金の例は25g以下の場合です。',
  },
  {
    id: 17,
    label: 'miniletter',
    recommendedPrice: 63,
  },
  {
    id: 18,
    label: 'smartletter',
    recommendedPrice: 180,
  },
  {
    id: 19,
    label: 'clickpost',
    recommendedPrice: 185,
  },
  // {
  //   id: 6,
  //   label: 'nekopost',
  //   recommendedPrice: 385,
  //   summary: 'Not available for individual customers. Businesses may register to use the service.',
  // },
  {
    id: 7,
    label: 'yuupacket',
    recommendedPrice: 250,
  },
  // {
  //   id: 8,
  //   label: 'yuupacketpost',
  // },
  {
    id: 9,
    label: 'taqbincompact',
    recommendedPrice: 374,
    summary: '料金の例は宅急便センター受け取りサービスを利用せず、その他の割引を受けた時のものです（箱料金70円を含む）。',
  },
  // {
  //   id: 10,
  //   label: 'taqbin60',
  // },
  // {
  //   id: 11,
  //   label: 'taqbin80',
  // },
  // {
  //   id: 12,
  //   label: 'yuupacketplus',
  // },
  // {
  //   id: 13,
  //   label: 'yuupacket60',
  // },
  // {
  //   id: 14,
  //   label: 'yuupacket80',
  // },
  // {
  //   id: 15,
  //   label: 'digital',
  // },
]

export { shippingServices }
