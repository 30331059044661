import { axiosInstance } from '../lib/api_client'
import { UserSelf } from '../types'

const CREATE_PATH = '/sessions'
const DESTROY_PATH = '/session'

type CreateParams = Readonly<{
  email: string
  password: string
  itemId?: number
}>

type CreateResponse = Readonly<{
  user: UserSelf
}>

type SessionsRepository = Readonly<{
  create: (params: CreateParams) => Promise<CreateResponse>
  destroy: () => Promise<void>
}>

const sessionsRepository: SessionsRepository = {
  async create (params) {
    return await axiosInstance.post(CREATE_PATH, params).then(({ data }) => data)
  },
  async destroy () {
    return await axiosInstance.delete(DESTROY_PATH).then()
  },
}

export { sessionsRepository }
