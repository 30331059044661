import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { AdminItemsCreate } from './admin_items_create'
import { AdminItemsList } from './admin_items_list'
import { AdminItemsImages } from './admin_items_images'

type Props = Readonly<{
  currentPage: number
  subpath?: string
}>

type AdminView = Readonly<{
  path: string
  showInNavigation: boolean
}>

const adminViews: AdminView[] = [
  {
    path: 'list',
    showInNavigation: true,
  },
  {
    path: 'create',
    showInNavigation: true,
  },
  {
    path: 'edit',
    showInNavigation: false,
  },
  {
    path: 'images',
    showInNavigation: false,
  },
]

const AdminItems = ({ currentPage, subpath }: Props) => {
  const { t } = useTranslation()

  const currentView = useMemo<AdminView>(() => {
    if (subpath === undefined) return adminViews[0]

    const v = adminViews.find(av => av.path === subpath)

    return v ?? adminViews[0]
  }, [subpath])

  const tabs = useMemo(() => {
    return (
      <div className="tabs">
        {
          // sort items by slug a-z
          adminViews.sort((a, b) => a.path.localeCompare(b.path)).map(a => {
            if (!a.showInNavigation) return null

            const text = t(`admin.paths.items.${a.path}`)
            const link = a === adminViews[0] ? '' : `/${a.path}`

            return <Link key={ a.path } to={ `/admin/items${link}` } className={ clsx({ selected: currentView === a }) }>{ text }</Link>
          })
        }
      </div>
    )
  }, [t, currentView])

  const subPage = useMemo(() => {
    if (subpath === 'list') {
      return <AdminItemsList currentPage={ currentPage } />
    } else if (subpath === 'images') {
      return <AdminItemsImages />
    }

    return <AdminItemsCreate />
  }, [currentPage, subpath])

  return (
    <>
      { tabs }
      { subPage }
    </>
  )
}

export { AdminItems }
