import { axiosInstance } from '../lib/api_client'
import { Blog } from '../types'

const PATH = '/blogs'

type IndexResponse = Readonly<{
  blogs: Blog[]
}>

type GetReponse = Readonly<{
  blog: Blog
}>

type CreateParams = Readonly<{
  blog: Blog
}>

type UpdateParams = Readonly<{
  id: number
  blog: Blog
}>

type BlogsRepository = Readonly<{
  index: (userId: number) => Promise<IndexResponse>
  get: (id: number, userId: number) => Promise<GetReponse>
  create: (params: CreateParams) => Promise<GetReponse>
  update: (params: UpdateParams) => Promise<void>
  delete: (id: number) => Promise<void>
}>

const blogsRepository: BlogsRepository = {
  async index (userId) {
    return await axiosInstance.get(`${PATH}?user_id=${userId}`).then(({ data }) => data)
  },
  async get (id, userId) {
    return await axiosInstance.get(`${PATH}/${id}?user_id=${userId}`).then(({ data }) => data)
  },
  async create ({ blog }) {
    return await axiosInstance.post(PATH, blog).then(({ data }) => data)
  },
  async update ({ blog, id }) {
    return await axiosInstance.put(`${PATH}/${id}`, blog).then()
  },
  async delete (id) {
    return await axiosInstance.delete(`${PATH}/${id}`)
  },
}

export { blogsRepository }
