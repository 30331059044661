import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import clsx from 'clsx'
// import { Link } from 'react-router-dom'
import { Controller, useForm, useWatch } from 'react-hook-form'
import Select from 'react-select'
import Moment from 'react-moment'
import { tagsRepository } from '../repositories/tags_repository'
import { AxiosError } from 'axios'
import { Tag, TagForm } from '../types'
import { ErrorsContext } from '../contexts/errors_context'
import { ModalWrapper } from './modal_wrapper'
import { Link, useMatch } from 'react-router-dom'
import i18n from './i18n'

type TagType = Readonly<{
  value: string
  label: string
}>

const tagTypes: TagType[] = [
  {
    value: 'CardLayout',
    label: 'CardLayout',
  },
  {
    value: 'CardType',
    label: 'CardType',
  },
  {
    value: 'Category',
    label: 'Category',
  },
  {
    value: 'Character',
    label: 'Character',
  },
  {
    value: 'Illustrator',
    label: 'Illustrator',
  },
  {
    value: 'Rarity',
    label: 'Rarity',
  },
  {
    value: 'Utility',
    label: 'Utility',
  },
]

const formDefaults: TagForm = {
  parentId: undefined,
  releaseDate: '',
  ordinality: 0,
  type: 'Category',
  nameEn: '',
  nameJa: '',
  nameEnShort: '',
  nameJaShort: '',
}

const AdminTagsCreate = () => {
  const { t } = useTranslation()

  const { addError, resetErrors } = useContext(ErrorsContext)

  const params = useMatch('/admin/tags/edit/:tag_id')?.params

  const tagId = useMemo<number|undefined>(() => {
    const id = parseInt(params?.tag_id ?? '')
    return isNaN(id) ? undefined : id
  }, [params])

  const setSelectedTagType = useState<string>(formDefaults.type)[1]
  const [parentTagsLoading, setParentTagsLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [tags, setTags] = useState<Tag[]>([])
  const [workingTag, setWorkingTag] = useState<Tag>()

  const getTag = useCallback((id: number) => {
    setLoading(true)

    tagsRepository
      .get(id)
      .then(({ tag }) => {
        setWorkingTag(tag)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError])

  const {
    register: registerForm,
    reset,
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm<TagForm>({
    defaultValues: formDefaults,
  })

  useEffect(() => {
    if (!tagId) {
      setWorkingTag(undefined)
      reset(formDefaults)
      return
    }

    getTag(tagId)
  }, [getTag, reset, tagId])

  useEffect(() => {
    reset(workingTag)
  }, [reset, workingTag])

  const [modalDisplayed, setModalDisplayed] = useState<string|undefined>()

  const closeModal = useCallback((resetForm) => {
    if (resetForm) {
      setWorkingTag(undefined)
      reset(formDefaults)
    }

    setModalDisplayed(undefined)
  }, [reset])

  const createdModal = useMemo(() => {
    if (!modalDisplayed) return

    return (
      <ModalWrapper>
        <h1>{ t(`admin.tags.${modalDisplayed}.modal.title`) }</h1>
        <p>{ t(`admin.tags.${modalDisplayed}.modal.body`) }</p>
        <p>
          <Link to={ '/admin/tags/create' } className="button full white" onClick={ () => { closeModal(true) } }>{ t('admin.tags.create.modal.buttons.new') }</Link>
        </p>
        <p>
          <Link to="/admin/tags/list" className="button full white" onClick={ () => { closeModal(false) } }>{ t('admin.tags.create.modal.buttons.list') }</Link>
        </p>
        <p>
          <Link to={ `/admin/tags/edit/${workingTag?.id ?? ''}` } className="button full" onClick={ () => { closeModal(false) } }>{ t('admin.tags.create.modal.buttons.edit') }</Link>
        </p>
      </ModalWrapper>
    )
  }, [modalDisplayed, t, closeModal, workingTag?.id])

  const onSubmit = useCallback((params) => {
    if (loading) return

    // TODO: Validation
    const newTag: Tag = params

    resetErrors?.()

    setLoading(true)

    if (workingTag) {
      tagsRepository
        .update({ id: workingTag.id, params })
        .then(() => {
          setModalDisplayed('update')
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      tagsRepository
        .create(newTag)
        .then(({ tag }) => {
          setModalDisplayed('create')
          setWorkingTag(tag)
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    setLoading(true)
  }, [loading, resetErrors, workingTag, addError])

  const type = useWatch({
    control,
    name: 'type',
  })

  const parentId = useWatch({
    control,
    name: 'parentId',
  })

  const ordinality = useWatch({
    control,
    name: 'ordinality',
  })

  const releaseDate = useWatch({
    control,
    name: 'releaseDate',
  })

  useEffect(() => {
    if (!type) return

    setSelectedTagType(type)
  }, [setSelectedTagType, type])

  const getTags = useCallback((type: string) => {
    setParentTagsLoading(true)

    // get all children sets
    tagsRepository
      .index({
        params: {
          page: 0,
          limit: 9999,
          type,
        }
      })
      .then(({ tags }) => {
        setTags(tags)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setParentTagsLoading(false)
      })
  }, [addError, setTags])

  const [tagsForSelect, setTagsForSelect] = useState<TagType[]>([])

  useEffect(() => {
    if (!tags) return

    const tagsFormatted = tags.map((t) => {
      const parent = t.parent && t.childrenCount === 0 && t.depth > 2
        ? ` (${((i18n.language === 'ja' && t.parent.nameJa) ?? !t.parent.nameEn ? t.parent.nameJa : t.parent.nameEn) ?? ''})`
        : ''

      const nameNative = ((i18n.language === 'ja' && t.nameJa) ?? !t.nameEn ? t.nameJa : t.nameEn) ?? ''

      const label = `${String(t.id)}: ${nameNative}${parent}`

      return {
        value: String(t.id),
        label,
      }
    })

    setTagsForSelect(tagsFormatted.filter(t => t.value !== String(workingTag?.id)))
  }, [tags, workingTag?.id])

  useEffect(() => {
    if (!type) return

    getTags(type)
  }, [type, getTags])

  const [siblingTags, setSiblingTags] = useState<Tag[]>()

  useEffect(() => {
    if (workingTag) return

    if (!parentId) {
      setValue('ordinality', 0)
      setValue('releaseDate', '')
      return
    }

    const newSelectedTag = tags.find((t) => t.id === Number(parentId))

    if (!newSelectedTag) return

    setValue('ordinality', newSelectedTag.childrenCount)

    if (newSelectedTag.releaseDate) {
      setValue('releaseDate', String(newSelectedTag.releaseDate))
    }
  }, [parentId, tags, setValue, workingTag])

  useEffect(() => {
    if (!workingTag) return

    setValue('parentId', workingTag?.parentId)
  }, [setValue, tagsForSelect, workingTag])

  useEffect(() => {
    if (!parentId) setSiblingTags([])

    setSiblingTags(tags.filter((t) => t.parentId === Number(parentId)))
  }, [parentId, tags, type])

  const siblingTag = useCallback((t: Tag) => {
    const nameNative = (i18n.language === 'ja' && t.nameJa) ?? !t.nameEn ? t.nameJa : t.nameEn

    return <li key={ t.id }>
      <a><p>
        { t.ordinality }&nbsp;&nbsp;{ t.releaseDate ? (<span className="small"><Moment format="YYYY-MM-DD">{ t.releaseDate }</Moment></span>) : null }
        <span>{ nameNative }</span>
      </p></a>
    </li>
  }, [])

  const siblingTagsUi = useMemo(() => {
    if (!siblingTags || siblingTags.length === 0) return

    const ordinalityNew = Number(ordinality)
    const releaseDateNew = new Date(releaseDate)

    const beforeTags = siblingTags.filter(compareTag => {
      const compareTagDate = new Date(compareTag.releaseDate)
      return (
        (
          compareTag.ordinality < ordinalityNew || (
            compareTag.ordinality === ordinalityNew &&
            compareTagDate.getTime() <= releaseDateNew.getTime()
          ))) &&
          compareTag.id !== workingTag?.id
    })
    const afterTags = siblingTags.filter(compareTag => !beforeTags.find(prevTag => prevTag.id === compareTag.id) && compareTag.id !== workingTag?.id)

    return (
      <div className="sets-container list">
        <ul className="sets-list">
          { beforeTags.map((t) => siblingTag(t)) }
          <li>
            <a><p><strong>
              →&nbsp;&nbsp;{ ordinalityNew }&nbsp;&nbsp;{ releaseDate ? (<span className="small"><Moment format="YYYY-MM-DD">{ releaseDate }</Moment></span>) : null }
              Tag will appear here
            </strong></p></a>
          </li>
          { afterTags.map((t) => siblingTag(t)) }
        </ul>
      </div>
    )
  }, [siblingTags, ordinality, releaseDate, workingTag?.id, siblingTag])

  return (
    <>
      { createdModal }
      <form onSubmit={ handleSubmit(onSubmit) } className="narrow">
        <p>
          <label>Name (English)</label>
          <input type="text" placeholder="Pokémon Trading Card Game" className="full" { ...registerForm('nameEn') } />
        </p>
        <p>
          <label>Name (English, short)</label>
          <input type="text" placeholder="Pokémon" className="full" { ...registerForm('nameEnShort') } />
        </p>
        <p>
          <label>Name (Japanese)</label>
          <input type="text" placeholder="ポケットモンスターカードゲーム" className="full" { ...registerForm('nameJa') } />
        </p>
        <p>
          <label>Name (Japanese, short)</label>
          <input type="text" placeholder="ポケモン" className="full" { ...registerForm('nameJaShort') } />
        </p>
        <div className="select">
          <label>
            Type
          </label>
          {
            <Controller
              control={ control }
              name='type'
              render={ ({ field }) => (
                <Select
                  options={ tagTypes }
                  onChange={ val => field.onChange(val?.value) }
                  placeholder={ t('defaults.select') }
                  value={ tagTypes.find(t => t.value === getValues('type')) }
                />
              ) }
            />
          }
        </div>
        <div className="select">
          <label>Parent tag</label>
          {
            <Controller
              control={ control }
              name='parentId'
              render={ ({ field }) => (
                <Select
                  options={ tagsForSelect }
                  onChange={ val => field.onChange(val?.value) }
                  placeholder={ t('defaults.select') }
                  isDisabled={ parentTagsLoading }
                  isLoading={ parentTagsLoading }
                  value={ tagsForSelect.find(t => Number(t.value) === getValues('parentId')) }
                />
              ) }
            />
          }
        </div>
        {
          (['CardLayout', 'CardType', 'Character', 'Illustrator', 'Rarity'].find((t) => type === t))
            ? null
            : <p>
              <label>Release date</label>
              <input type="text" className="full" { ...registerForm('releaseDate') } />
            </p>
        }
        <p>
          <label>Ordinality</label>
          <input type="number" className="full" min={ 0 } required { ...registerForm('ordinality') } />
        </p>
        { siblingTagsUi }
        { /* <p>
          <label>Listing name (English)</label>
          <input type="text" className="full" name="name_en_listing" ref={ registerForm } />
        </p> */ }
        { /* <p>
          <label>Slug</label>
          <input type="text" className="full" name="slug" ref={ registerForm } />
        </p> */ }
        <p>
          <button className="full" disabled={ loading }>
            { workingTag ? 'Update tag' : 'Save as a new tag' }
          </button>
        </p>
      </form>
    </>
  )
}

export { AdminTagsCreate }
