import React from 'react'
import { useTranslation } from 'react-i18next'

type ButtonSelectProps = Readonly<{
  buttons: string[]
  selected: string
  callback: (object) => void
}>

const ButtonSelect = ({ buttons, selected, callback }: ButtonSelectProps): JSX.Element => {
  const { t } = useTranslation()

  const toggleButton = (e, b) => {
    e.preventDefault()
    callback(b)
  }

  return (
    <div className="button container tool">
      {
        buttons.map((button) => {
          const isSelected = button === selected ? 'selected' : ''

          return <button key={ button.toLowerCase() } className={ isSelected } onClick={ (e) => { toggleButton(e, button) } }>{ t(button) }</button>
        })
      }
    </div>
  )
}

export { ButtonSelect }
