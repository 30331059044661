import { axiosInstance } from '../lib/api_client'

const PATH = '/stripe_account/account_link'

type GetReponse = Readonly<{
  url: string
}>

type StripeAccountLinksRepository = Readonly<{
  get: () => Promise<GetReponse>
}>

const stripeAccountLinkRepository: StripeAccountLinksRepository = {
  async get () {
    return await axiosInstance.get(PATH).then(({ data }) => data)
  },
}

export { stripeAccountLinkRepository }
