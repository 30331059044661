import { axiosInstance } from '../lib/api_client'
import { OrderReferral, Referral, Pagination } from '../types'

const PATH = '/referrals'

type IndexParams = Readonly<{
  params?: Readonly<{
    show?: string
    page: number
    limit?: number
  }>
}>

type IndexReponse = Readonly<{
  orderReferrals: OrderReferral[]
  referrals: Referral[]
  pagination: Pagination
}>

type ReferralsRepository = Readonly<{
  index: (params: IndexParams) => Promise<IndexReponse>
}>

const referralsRepository: ReferralsRepository = {
  async index ({ params }) {
    return await axiosInstance.get(PATH, { params }).then(({ data }) => data)
  },
}

export { referralsRepository }
