import { AxiosError } from 'axios'
import React, { useContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { conditions } from '../lib/conditions'
import { Item, MatchItem, Unit } from '../types'
import { AuthContext } from '../contexts/auth_context'

import { SelectUnitModal } from './select_unit_modal'
import { unitsRepository } from '../repositories/units_repository'
import { ErrorsContext } from '../contexts/errors_context'
import { GuestModal } from './guest_modal'

type Props = Readonly<{
  // cardCategoryVariant: CardCategoryVariant
  item?: Item
  matchItem?: MatchItem
  units: Unit[]
  buttonText?: string
  collectCallback?: (object) => void
  defaultCondition: number
  disabled?: boolean
}>

const ItemCollect = ({ item, units, matchItem, buttonText, collectCallback, defaultCondition, disabled }: Props) => {
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)

  const itemId = item ? item.id : matchItem ? matchItem.id : 0

  const [loading, setLoading] = useState<boolean>(false)
  const [condition, setCondition] = useState<number | undefined>(defaultCondition)
  const [currentViewOwned, setCurrentViewOwned] = useState<number>(0)
  const [conditionsOwned, setConditionsOwned] = useState<Object>({})
  const [guestModalShown, setGuestModalShown] = useState<boolean>(false)

  const { isAuthenticated } = useContext(AuthContext)
  const [isSelectUnitModalShown, setIsSelectUnitModalShown] = useState<boolean>(false)

  const openSelectUnitModal = useCallback(() => setIsSelectUnitModalShown(true), [])
  const closeSelectUnitModal = useCallback(() => setIsSelectUnitModalShown(false), [])

  useEffect(() => {
    const currentCondition = units?.filter((e) => e.condition === condition)

    setCurrentViewOwned(currentCondition?.length ?? 0)
  }, [condition, defaultCondition, units])

  useEffect(() => {
    const a = {}
    conditions.forEach(c => {
      const key = c.id
      a[key ?? 0] = units?.filter((e) => e.condition === key).length
    })

    setConditionsOwned(a)
  }, [defaultCondition, units])

  const addItem = useCallback(() => {
    if (loading) return

    if (!isAuthenticated) {
      setGuestModalShown(true)
      return
    }

    const workingCondition = condition ?? 0

    setLoading(true)

    // add a new user item
    unitsRepository
      .create({
        itemIds: [itemId],
        condition: workingCondition,
      })
      .then(({ id }) => {
        collectCallback?.({ condition: workingCondition, value: 1, id })
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [loading, isAuthenticated, condition, itemId, collectCallback, addError])

  const removeItemById = useCallback((unit: Unit) => {
    if (!unit.id) return
    if (loading) return

    const workingCondition = condition ?? 0

    const unitsArray = units

    const index = unitsArray?.findIndex(u => u.id === unit.id)

    if (typeof index === 'undefined' || index < 0) return

    setLoading(true)

    // delete an existing user item
    unitsRepository
      .delete(unit.id)
      .then(() => {
        const newOwnedCount = currentViewOwned - 1

        setCurrentViewOwned(newOwnedCount)

        const conditionsOwnedReplacement = {
          ...conditionsOwned,
          [workingCondition]: newOwnedCount,
        }
        setConditionsOwned(conditionsOwnedReplacement)

        collectCallback?.({ condition: workingCondition, value: -1, id: unit.id })

        if (newOwnedCount === 0) closeSelectUnitModal()
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError, closeSelectUnitModal, collectCallback, condition, conditionsOwned, currentViewOwned, loading, units])

  const removeItem = useCallback(() => {
    // FUTURE: Start by removing items which do not have a description/image (use array.reduce?)
    // If a user attempts to remove an item with a description/image, confirm they really want to delete it (use the modal)
    if (loading) return

    const workingCondition = condition ?? 0

    const itemToDeleteWithNoUserData = units?.find((e) => {
      return (
        e.condition === workingCondition &&
          !e.description &&
          !e.buyDate &&
          !e.sellDate &&
          !e.buyPriceJpy &&
          !e.sellPriceJpy &&
          (!e.listings || e.listings?.length === 0)
      )
    })

    if (!itemToDeleteWithNoUserData) {
      // are there any items which can be deleted with confirmation?
      const itemToDelete = units?.find((e) => e.condition === workingCondition)

      if (itemToDelete) {
        // alert('there are some items which can be deleted, confirm which should be deleted')
        openSelectUnitModal()
      }

      return
    }

    const newOwnedCount = currentViewOwned - 1

    setLoading(true)

    // delete an existing user item
    unitsRepository
      .deleteByItemIdAndCondition({
        itemIds: [itemId],
        condition: workingCondition,
      })
      .then(() => {
        setCurrentViewOwned(newOwnedCount)

        const conditionsOwnedReplacement = {
          ...conditionsOwned,
          [workingCondition]: newOwnedCount,
        }
        setConditionsOwned(conditionsOwnedReplacement)

        collectCallback?.({ condition: workingCondition, value: -1, id: null })
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [loading, condition, units, currentViewOwned, itemId, openSelectUnitModal, conditionsOwned, collectCallback, addError])

  const navigate = useNavigate()

  const changeCondition = useCallback((e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value === 'tool') {
      navigate(`/item/${itemId}/condition`)
      return
    }

    const newCondition = conditions.find(c => c.id === Number(e.currentTarget.value))
    setCondition(newCondition?.id ?? 0)
  }, [navigate, itemId])

  const conditionSelection = useMemo(() => {
    let defaultValue = condition ?? defaultCondition
    if (!defaultValue) {
      Object.keys(conditionsOwned).forEach(key => {
        if (conditionsOwned[key] > 0 && typeof defaultValue === 'undefined') {
          defaultValue = Number(key)
        }
      })
    }

    return (
      <div>
        <select onChange={ changeCondition } value={ defaultValue }>
          {
            conditions.map((c) => {
              if (conditionsOwned[c.id ?? 0] < 1 && !c.displayDefault) return null
              const conditionName = t(`collections.conditions.long.${String(c.id)}`)
              return (
                <option key={ c.id } value={ c.id }>{ `${String(conditionsOwned[c.id ?? 0] || '-')} ${conditionName}` }</option>
              )
            })
          }
          <option key="tool" value="tool">{ t('cards.condition_estimator') }</option>
        </select>
      </div>
    )
  }, [condition, defaultCondition, changeCondition, t, conditionsOwned])

  const collectButton = useMemo(() => {
    if (currentViewOwned > 1) {
      return (
        <div className="gold button container tool owned qty1">
          <button className="remove-collection w33" onClick={ removeItem } disabled= { !!loading }>-1</button>
          <span className="w33">{ currentViewOwned }</span>
          <button className="add w33" onClick={ addItem } disabled={ !!loading }>+1</button>
        </div>
      )
    }

    if (currentViewOwned > 0) {
      return (
        <div className="gold button container tool owned qty1">
          <button className="remove-collection w66" onClick={ removeItem } disabled= { !!loading }>{ t('sets.remove') }</button>
          <button className="add w33" onClick={ addItem } disabled={ !!loading }>+1</button>
        </div>
      )
    }

    return (
      <button className={ `${currentViewOwned > 0 ? 'gold' : ''} tool` } onClick={ addItem } disabled={ !!loading || disabled }>
        {
          buttonText ?? `+ ${t('sets.Add')}`
        }
      </button>
    )
  }, [addItem, buttonText, currentViewOwned, disabled, loading, removeItem, t])

  if (item) {
    return (
      <>
        {
          guestModalShown &&
            <GuestModal onClose={ () => { setGuestModalShown(false) } } item={ item } />
        }
        {
          isSelectUnitModalShown &&
            <SelectUnitModal onClose={ closeSelectUnitModal } units={ units?.filter((e) => {
              return (
                e.condition === condition &&
                  (
                    e.description ??
                    e.buyDate ??
                    e.sellDate ??
                    e.buyPriceJpy ??
                    e.sellPriceJpy ??
                    (e.listings && e.listings?.length > 0)
                  )
              )
            }) } removeItemById={ removeItemById }/>
        }
        { conditionSelection }
        { collectButton }
      </>
    )
  }

  return collectButton
}

export { ItemCollect }
