import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorsContext } from '../contexts/errors_context'
import { itemsRepository } from '../repositories/items_repository'
import { AxiosError } from 'axios'
import { Item } from '../types'
import { useMatch, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { itemImagesRepository } from '../repositories/item_images_repository'
import { uploadToS3 } from '../lib/s3'

// type ItemType = Readonly<{
//   value: string
//   label: string
// }>

type UploadForm = Readonly<{
  image: FileList
}>

const validMimeTypes = new Set(['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'])

const AdminItemsImages = () => {
  const { t, i18n } = useTranslation()

  const {
    register,
    handleSubmit,
  } = useForm<UploadForm>()

  const { addError } = useContext(ErrorsContext)

  const [itemId, setItemId] = useState<number|undefined>()
  const [loadingItem, setLoadingItem] = useState<boolean>(false)
  const [item, setItem] = useState<Item>()
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

  const params = useMatch('/admin/items/images/:item_id')?.params

  useEffect(() => {
    const t = Number(params?.item_id) ?? 0
    if (t > 0) setItemId(t)
  }, [params])

  const getItem = useCallback((id) => {
    if (!id) return

    setLoadingItem(true)

    itemsRepository
      .get(id)
      .then(({ item }) => {
        setItem(item)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoadingItem(false)
      })
  }, [addError])

  useEffect(() => {
    getItem(itemId)
  }, [getItem, itemId])

  const callback = useCallback(() => {
    getItem(itemId)
  }, [getItem, itemId])

  const onSubmit = async (data: UploadForm) => {
    const im = data.image[0]

    if (!item) return

    if (!im) {
      alert(t('image_upload.err_choose_image'))
      return
    }

    if (!validMimeTypes.has(im.type)) {
      alert(t('image_upload.err_not_image'))
      return
    }

    setLoadingSubmit(true)

    try {
      const uploadResult = await uploadToS3(im)

      // const itemImage = await itemImagesRepository.create({ ...uploadResult, itemId: item.id })
      await itemImagesRepository.create({ ...uploadResult, itemId: item.id })
      callback()
    } catch (error) {
      alert(t('image_upload.err_upload_failed'))
    } finally {
      setLoadingSubmit(false)
    }
  }

  const itemName = useMemo(() => item ? ((i18n.language === 'ja' && item.nameJa) ?? !item.nameEn ? item.nameJa : item.nameEn) : '', [i18n.language, item])

  const imagesUi = useMemo(() => {
    if (!item || item.images.length === 0) return <p>No images</p>

    return item?.images.map((image) => {
      return <p key={ image.id }><img width="230" src={ image.thumb2x } /></p>
    })
  }, [item])

  if (loadingItem) {
    return <div className="narrow">
      <p>Loading...</p>
    </div>
  }

  if (!item) {
    return <p>Error</p>
  }

  return (
    <div className="narrow">
      <h2>Image management</h2>
      <strong>{ itemName } <Link to={ `/admin/items/edit/${item.id}` } className='button tool'>Edit</Link></strong>
      <hr />
      <h3>Current images</h3>
      { imagesUi }
      <hr />
      <h3>Upload a new image</h3>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <input
            { ...register('image') }
            type='file'
            accept='image/*'
          />
        <p>
          <button className='full' disabled={ loadingSubmit }>Upload image</button>
        </p>
      </form>
    </div>
  )
}

export { AdminItemsImages }
