import { axiosInstance } from '../lib/api_client'
import { EbayAccount, EbayAccountProfile } from '../types'

const PATH = '/ebay_accounts'

type CreateParams = Readonly<{
  code: string
}>

type UpdateParams = Readonly<{
  params: EbayAccount
}>

type UpdateReponse = Readonly<{
  totalListings: number
}>

type CreateResponse = Readonly<{
  ebayAccount: EbayAccount
}>

type RefreshResponse = Readonly<{
  profiles: EbayAccountProfile[]
}>

type EbayRepository = Readonly<{
  create: (params: CreateParams) => Promise<CreateResponse>
  update: (params: UpdateParams) => Promise<UpdateReponse>
  refresh_profiles: () => Promise<RefreshResponse>
}>

const ebayRepository: EbayRepository = {
  async create (params) {
    return await axiosInstance.post(PATH, params).then(({ data }) => data)
  },
  async update ({ params }) {
    return await axiosInstance.put(`${PATH}/1`, params).then(({ data }) => data)
  },
  async refresh_profiles () {
    return await axiosInstance.get(`${PATH}/refresh`).then(({ data }) => data)
  },
}

export { ebayRepository }
