import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  number: number
  style: string
}>

const CardsLoading = ({ number, style }: Props) => {
  const { t } = useTranslation()

  if (style === 'listings') {
    return <ul className='listings loading'>
      {
        [...Array(number)].map((_, i) => (
          <li className="listing" key={ i }>
            <div>
              <div className="images loaded">
                <span className='no-image'>{ t('cards.no_image') }</span>
              </div>
              <div className='bottom'>
                <div className='item-name'>
                  <h3>{ t('cards.loading') }</h3>
                </div>
              </div>
            </div>
          </li>
        ))
      }
    </ul>
  }

  return (
    <ul className="cards-list loading">
      {
        [...Array(number)].map((_, i) => (
          <li className="card" key={ i }>
            <div className="images loaded">
              <span className='no-image'>{ t('cards.no_image') }</span>
            </div>
            <span className="card-title">{ t('cards.loading') }</span>
          </li>
        ))
      }
    </ul>
  )
}

export { CardsLoading }
