import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import '../stylesheets/Error.scss'

const GenericNotFound = () => {
  const { t } = useTranslation()

  return (
    <div className="generic-not-found">
      <div>
        <div className="art">
        </div>
        <h1>{ t('errors.generic_not_found.title') }</h1>
        <p>
          { t('errors.generic_not_found.body1') }
        </p>
        <p>
          { t('errors.generic_not_found.body2') }
        </p>
        <p>
          <Link to="/" className="button">{ t('errors.generic_not_found.button') }</Link>
        </p>
      </div>
    </div>
  )
}

export { GenericNotFound }
