import { AxiosError } from 'axios'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router'
import { Link } from 'react-router-dom'
import { ErrorsContext } from '../contexts/errors_context'
import { SearchContext } from '../contexts/search_context'
import { itemsRepository } from '../repositories/items_repository'

import '../stylesheets/Sets.scss'
import { Item, Tag, Unit } from '../types'
import { CardSingle } from './card_single'
import { GenericNotFound } from './generic_not_found'
import { ListingSingle } from './listing_single'

const cardSettingsItem = {
  conditionSelection: true,
  canCollect: true,
  imageResolution: '2x',
}

const Items = (): JSX.Element => {
  const { i18n, t } = useTranslation()

  const { filters, searchString, endpointForExistingFilters } = useContext(SearchContext)

  const { addError } = useContext(ErrorsContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [item, setItem] = useState<Item>()
  const [listingsLoading, setListingsLoading] = useState<boolean>(false)
  const [units, setUnits] = useState<Unit[]>()

  const params = useMatch('/categories/:category_id/items/:item_id')?.params

  // const categoryId = useMemo<number|undefined>(() => {
  //   const id = parseInt(params?.category_id ?? '')
  //   return isNaN(id) ? undefined : id
  // }, [params])

  const itemId = useMemo<number|undefined>(() => {
    const id = parseInt(params?.item_id ?? '')
    return isNaN(id) ? undefined : id
  }, [params])

  const getItemListings = useCallback(() => {
    if (!item) return

    setListingsLoading(true)

    itemsRepository
      .units(item.id, '')
      .then(({ listings }) => {
        setUnits(listings)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setListingsLoading(false)
      })
  }, [addError, item])

  const getItem = useCallback((id: number) => {
    setLoading(true)

    itemsRepository
      .get(id)
      .then(({ item }) => {
        setItem(item)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError])

  useEffect(() => {
    if (itemId) {
      getItem(itemId)
    } else {
      setLoading(false)
    }
  }, [getItem, itemId])

  useEffect(() => {
    getItemListings()
  }, [getItemListings, item])

  // determine category
  const category = useMemo<Tag|undefined>(() => {
    if (!item || item.tags.length === 0) return

    const categoryTags = item.tags.filter(t => t.type === 'Category')

    if (categoryTags.length === 0) return

    return categoryTags.reduce((prev, current) => prev.depth > current.depth ? prev : current)
  }, [item])

  const categoriesLink = useCallback((tagId: string) => {
    if (!endpointForExistingFilters) return ''

    const p = `/categories/${tagId}`

    const params = endpointForExistingFilters(filters, searchString)

    return p + params
  }, [endpointForExistingFilters, filters, searchString])

  const breadcrumbs = useMemo(() => {
    if (!category || !category.parentId) return

    let workingTag: Tag|undefined = category
    const links: JSX.Element[] = []

    while (workingTag) {
      const tagName = (i18n.language === 'ja' && workingTag.nameJa) ?? !workingTag.nameEn ? workingTag.nameJa : workingTag.nameEn

      const link = <Link key={ workingTag.id } to={ categoriesLink(String(workingTag.id)) }>{ tagName }</Link>
      links.unshift(link)

      workingTag = category.parents?.find(t => t.id === workingTag?.parentId)
    }

    return <p className='breadcrumbs'>{ links }</p>
  }, [categoriesLink, category, i18n.language])

  const itemName = useMemo(() => {
    if (!item) return

    return (i18n.language === 'ja' && item.nameJa) ?? !item.nameEn ? item.nameJa : item.nameEn
  }, [i18n.language, item])

  const categoryBreadcrumbs = useMemo<Tag[]>(() => {
    const tagsFiltered = item?.tags.filter(t => t.type === 'Category') ?? []

    return tagsFiltered.length > 0
      ? tagsFiltered.sort((prev, current) => prev.depth > current.depth ? 1 : -1)
      : []
  }, [item])

  useEffect(() => {
    if (!itemName) return

    let title = itemName

    const series = categoryBreadcrumbs.filter(c => c.depth !== 0).map(c => c.nameJa ?? '').join(' > ')

    title = title + ': ' + series

    document.title = title + ' • HOLIC'
  }, [categoryBreadcrumbs, itemName])

  const listings = useMemo(() => {
    if (!item) return

    if (listingsLoading) {
      return <p>{ t('cards.marketplace.loading') }</p>
    }

    if (!units || units.length === 0) {
      return (
        <>
          <p>{ t('cards.marketplace.none.body1') }</p>
          <p><strong>{ t('cards.marketplace.none.body2', { name: itemName }) }</strong></p>
          <p>{ t('cards.marketplace.none.body3') }</p>
        </>
      )
    }

    return (
      <ul className="listings">
        {
          units.map((ui, i) => {
            return <ListingSingle key={ i } editing={ false } items={ [item] } unit={ ui } />
          })
        }
      </ul>
    )
  }, [item, itemName, listingsLoading, t, units])

  if (loading) {
    return <p>{ t('cards.marketplace.loading') }</p>
  }

  if (!item) return <GenericNotFound />

  return (
    <div>
      { breadcrumbs }
      <h2>{ itemName }</h2>
      <div>
        <ul className="cards-list test">
          <CardSingle key={ item.id } item={ item } cardSettings={ cardSettingsItem } />
        </ul>
      </div>
      <hr />
      <div>
        <h2>{ t('cards.marketplace.title') }</h2>
        { listings }
      </div>
    </div>
  )
}

export { Items }
