import { TagShort } from '../types'

const rarities: TagShort[] = [
  { id: 22, name: 'Common' },
  { id: 23, name: 'Uncommon' },
  { id: 26, name: 'Rare Holo' },
  { id: 2363, name: 'Ultra Rare' },
  { id: 3017, name: 'Double Rare' },
  { id: 3018, name: 'Triple Rare' },
  { id: 3148, name: 'UR' },
  { id: 3019, name: 'Hyper Rare' },
  { id: 2345, name: 'Amazing Rare' },
  { id: 3026, name: 'Promo' },
  { id: 3021, name: 'Character Rare' },
  { id: 3020, name: 'Character Super Rare' },
  { id: 3024, name: 'Shiny Rare (K)' },
  { id: 2361, name: 'Shiny Rare' },
  { id: 2359, name: 'Secret Rare' },
  { id: 3022, name: 'Super Shiny Rare' },
  { id: 3025, name: 'Trainer Rare' },
  { id: 3014, name: 'Common (Mirror)' },
  { id: 3013, name: 'Uncommon (Mirror)' },
  { id: 3016, name: 'Rare (Mirror)' },
  { id: 3015, name: 'Mirror (no symbol)' },
  { id: 2144, name: 'Uncommon (white diamond)' },
  { id: 24, name: 'Rare' },
  { id: 2146, name: 'Rare (two stars)' },
  { id: 2145, name: 'Rare (three stars)' },
  { id: 2143, name: 'Rare (white star)' },
  { id: 27, name: 'Holo (no symbol)' },
  { id: 25, name: 'None' },
]

export { rarities }
