import { axiosInstance } from '../lib/api_client'
import { Order, OrderUpdate, Pagination } from '../types'

const PATH = '/orders'

type IndexParams = Readonly<{
  params?: Readonly<{
    show?: string
    page: number
    limit?: number
  }>
}>

type IndexReponse = Readonly<{
  orders: Order[]
  pagination: Pagination
}>

type GetReponse = Readonly<{
  order: Order
}>

type UpdateParams = Readonly<{
  id: number
  params: OrderUpdate
}>

type OrdersRepository = Readonly<{
  index: (params: IndexParams) => Promise<IndexReponse>
  get: (id: number) => Promise<GetReponse>
  update: (params: UpdateParams) => Promise<void>
}>

const ordersRepository: OrdersRepository = {
  async index ({ params }) {
    return await axiosInstance.get(PATH, { params }).then(({ data }) => data)
  },
  async get (id) {
    return await axiosInstance.get(`${PATH}/${id}`).then(({ data }) => data)
  },
  async update ({ params, id }) {
    return await axiosInstance.put(`${PATH}/${id}`, params).then()
  },
}

export { ordersRepository }
