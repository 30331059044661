import { AxiosError } from 'axios'
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { conditions } from '../lib/conditions'
import { countries } from '../lib/countries'
import { Listing, RegionShippingService, TagPreview, Unit } from '../types'
import { unitsRepository } from '../repositories/units_repository'
import queryString from 'query-string'

import { GenericNotFound } from './generic_not_found'
import { UnitBuyCompleteModal } from './unit_buy_complete_modal'
import { UnitBasketMultipleSellersModal } from './unit_basket_multiple_sellers_modal'

import '../stylesheets/CardsList.scss'
import '../stylesheets/CardSingle.scss'
import { listingsRepository } from '../repositories/listings_repository'
import { ListingModal } from './listing_modal'
import { AuthContext } from '../contexts/auth_context'
import { ErrorsContext } from '../contexts/errors_context'
import { BasketContext } from '../contexts/basket_context'
import { ItemImages } from './item_images'
import clsx from 'clsx'
import { UserBanner } from './user_banner'
import { SearchContext } from '../contexts/search_context'
import { regionShippingServicesRepository } from '../repositories/region_shipping_services_repository'

type SellForm = Readonly<{
  holicPrice?: number
  ebayPrice?: number
  ebayEnabled: boolean
}>

type ItemType = Readonly<{
  value: number
  label: string
}>

type UnitForm = Readonly<{
  description: string
  condition: number
  public: boolean
}>

type Props = Readonly<{
  id: number
  displayName?: string
  userId?: string
}>

const publicOptions = [
  { value: true, label: 'Public (anybody can view)' },
  { value: false, label: 'Private (only you can view)' },
]

const platforms = [
  {
    slug: 'cardrush',
    name: 'カードラッシュ',
    url: 'https://www.cardrush-pokemon.jp/product/+id+',
    minShippingFee: 110,
  },
  {
    slug: 'dragonstar',
    name: 'ドラゴンスター',
    url: 'https://dorasuta.jp/pokemon-card/product?pid=+id+',
    minShippingFee: 250,
  },
  {
    slug: 'fullcomp',
    name: 'フルコンプ',
    url: 'https://shopping.fullcomp.jp/shopdetail/+id+/pokemon/page1/order/',
    minShippingFee: 1,
  },
  {
    slug: 'hareruya2',
    name: '晴れる屋2',
    url: 'https://www.hareruya2.com/product/+id+',
    minShippingFee: 330,
  },
  {
    slug: 'mercari',
    name: 'メルカリ',
    url: 'https://jp.mercari.com/item/+id+',
    minShippingFee: 0,
  },
  {
    slug: 'yuyutei',
    name: '遊々亭',
    url: 'https://yuyu-tei.jp/game_poc/carddetail/cardpreview.php?VER=s03&CID=+id+&MODE=sell',
    minShippingFee: 500,
  },
  {
    slug: 'misendo',
    name: 'ミセン堂',
    url: 'https://misendo.stores.jp/items/',
    minShippingFee: 1,
  },
  {
    slug: 'cardshopbeems',
    name: 'カードショップビームス',
    url: 'https://www.cardshopbeems.com/SHOP/+id+.html',
    minShippingFee: 1,
  },
  {
    slug: 'spiral',
    name: 'カードショップすぱいらる',
    url: 'https://www.card-spiral.jp/product/+id+',
    minShippingFee: 1,
  },
  // {
  //   slug: 'cardkingdom',
  //   name: '',
  //   url: '',
  //   minShippingFee: 1,
  // },
  {
    slug: 'clabo',
    name: 'C-labo',
    url: 'https://www.c-labo-online.jp/product/+id+',
    minShippingFee: 1,
  },
  {
    slug: 'tgcshop193net',
    name: '193NET',
    url: 'https://193tcg.com/products/detail.php?product_id=+id+',
    minShippingFee: 1,
  },
  {
    slug: 'cardmax',
    name: 'CARDMAX',
    url: 'https://www.cardmax.jp/shopdetail/+id+',
    minShippingFee: 1,
  },
  {
    slug: 'masterssquare',
    name: 'マスターズスクウェア',
    url: 'https://www.tcgacademy.com/product/+id+',
    minShippingFee: 1,
  },
]

const UnitView = ({ id, userId }: Props) => {
  const { i18n, t } = useTranslation()
  const { currentUser, sessionId } = useContext(AuthContext)
  const { addError } = useContext(ErrorsContext)
  const { addUnitToBasket, setShowBasket, basketUnits } = useContext(BasketContext)

  const qS = queryString.parse(location.search)
  const result = qS.result as string

  const [unit, setUnit] = useState<Unit|undefined>()
  const [loading, setLoading] = useState<boolean>(true)

  const [showBasketWarning, setShowBasketWarning] = useState<boolean>(false)

  const [sellMode, setSellMode] = useState<boolean>(false)

  const [showOrderCompleteModal, setShowOrderCompleteModal] = useState<boolean>(result === 'success')

  const sold = useMemo(() => !!(unit?.listings?.find(l => !!l.soldAt)) || result === 'success', [result, unit?.listings])

  const forSale = useMemo(() => {
    return (
      unit?.listings &&
      unit?.listings.length &&
      !sold
    )
  }, [sold, unit?.listings])

  const listingHolic = useMemo(() => unit?.listings?.find(ul => ul.platform === 'holic'), [unit?.listings])
  const listingEbay = useMemo(() => unit?.listings?.find(ul => ul.platform === 'ebay'), [unit?.listings])

  const getItem = useCallback((_q) => {
    if (id === 0) {
      setLoading(false)
      return
    }

    setLoading(true)

    unitsRepository
      .get(id)
      .then(({ unit }) => {
        setUnit(unit)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError, id])

  useEffect(() => getItem(id), [id, getItem])

  useEffect(() => {
    getItem(id)
  }, [id, getItem])

  const [regionShippingServices, setRegionShippingServices] = useState<RegionShippingService[]>([])
  const [regionShippingServicesLoading, setRegionShippingServicesLoading] = useState<boolean>(false)

  const getRegionShippingServicesForUser = useCallback((userId: number) => {
    setRegionShippingServicesLoading(true)

    regionShippingServicesRepository
      .indexForUser(userId)
      .then((rss) => {
        setRegionShippingServices(rss.regionShippingServices)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setRegionShippingServicesLoading(false)
      })
  }, [addError])

  useEffect(() => {
    if (unit?.user?.hasRegionShippingServices && userId) {
      getRegionShippingServicesForUser(Number(userId))
    }
  }, [userId, getRegionShippingServicesForUser, unit?.user?.hasRegionShippingServices])

  const [showEbaySellUi, setShowEbaySellUi] = useState<boolean>(false)
  const [showHolicSellUi, setShowHolicSellUi] = useState<boolean>(false)

  const listing = useMemo(() => {
    const l = unit?.listings?.filter(l => !l.soldAt) ?? []

    if (l.length < 1) return

    return l[0]
  }, [unit?.listings])

  const addToBasketAndShowBasket = useCallback((quantity: number) => {
    if (!unit) return

    if (quantity < 1) {
      alert('Please enter a quantity greater than 0')
      return null
    }

    const success = addUnitToBasket?.(unit, false, quantity)

    if (success) {
      setShowBasket?.(true)
    } else {
      setShowBasket?.(false)
      setShowBasketWarning(true)
    }
  }, [addUnitToBasket, setShowBasket, unit])

  const shippingLocation = useMemo(() => {
    const country = countries.find(c => c.value === unit?.user?.countryCode)

    if (!country) return

    return <>{ t('cards.item.location', { country: t(`account.countries.${country.value}`) }) }</>
  }, [t, unit?.user?.countryCode])

  const platform = useMemo(() => listing && platforms.find(p => p.slug === listing.platform), [listing])

  const [quantityDesired, setQuantityDesired] = useState<number>(1)

  const inBasket = useMemo(() => {
    if (!unit) return 0

    return basketUnits.find(basketUnit => basketUnit.unit.id === unit?.id)?.quantity ?? 0
  }, [basketUnits, unit])

  useMemo(() => {
    setQuantityDesired(Math.max(1, inBasket))
  }, [inBasket])

  const quantitySelect = useMemo(() => {
    if (!listing || sold) return

    if (!listing.quantity || listing.quantity <= 1) return

    return <p>
      数量： <input type="number" value={ quantityDesired } onChange={ (e) => setQuantityDesired(Number(e.target.value)) } min={ 1 } max={ listing.quantity } />
    </p>
  }, [listing, quantityDesired, sold])

  const buyButton = useMemo(() => {
    if (!listing || sold || unit?.belongsToCurrentUser) return null

    // check if the user has already added this item to their basket
    const alreadyInBasket = basketUnits.find(basketUnit => basketUnit.unit.id === unit?.id)

    if (listing?.platform === 'holic') {
      if (
        quantityDesired !== alreadyInBasket?.quantity &&
        (
          quantityDesired > 1 ||
          (quantityDesired === 1 && alreadyInBasket)
        )
      ) {
        return <button onClick={ _ => addToBasketAndShowBasket(quantityDesired) }>{
          // listing.priceJpy > 0
          //   ? t('cards.item.buy_button')
          //   : t('cards.item.add_to_bag')
          t('cards.item.add_to_bag_quantity', { quantity: quantityDesired })
          }</button>
      }

      if (!alreadyInBasket) {
        return <button onClick={ _ => addToBasketAndShowBasket(quantityDesired) }>{
          // listing.priceJpy > 0
          //   ? t('cards.item.buy_button')
          //   : t('cards.item.add_to_bag')
          t('cards.item.add_to_bag')
          }</button>
      }

      if (alreadyInBasket.quantity > 1) {
        return <button onClick={ _ => addToBasketAndShowBasket(quantityDesired) }>{ t('cards.item.in_bag_quantity', { quantity: alreadyInBasket.quantity }) }</button>
      }

      return <button onClick={ _ => addToBasketAndShowBasket(quantityDesired) }>{ t('cards.item.in_bag') }</button>
    }

    if (!listing.platformId || !platform) return

    const link = platform.url.replace('+id+', listing.platformId)

    return <a className='button' href={ link } target='_blank' rel='noreferrer'>{ platform.name }から購入する</a>
  }, [addToBasketAndShowBasket, basketUnits, listing, platform, quantityDesired, sold, t, unit?.belongsToCurrentUser, unit?.id])

  const listings = useMemo(() => {
    if (!listing || sold) return

    // const freeShipping = listing.platform !== 'holic' && listing.platformId && platform && platform.minShippingFee === 0

    return (
      <>
        { quantitySelect }
        { buyButton }
        <span className='sale-price'>
          <span className='price'>{ Math.round(listing.priceJpy).toLocaleString() }</span>
          <span className='currency'>{ t('listings.price.currency_symbol') }</span>
          <span className='tax'>(税込)</span>
        </span>
      </>
    )
  }, [buyButton, listing, quantitySelect, sold, t])

  // TODO: Needs fixed? (Always true)
  const holicEnabled = useMemo(() => {
    return currentUser?.canSellHolic ?? false
  }, [currentUser?.canSellHolic])

  const ebayEnabled = useMemo(() => {
    return currentUser?.canSellEbay ?? false
  }, [currentUser?.canSellEbay])

  const collectionName = useMemo(() => {
    return unit?.user?.displayName ?? t('cards.item.default_display_name')
  }, [t, unit?.user?.displayName])

  const sellValues = useMemo(() => {
    const sellForm: SellForm = {
      holicPrice: listingHolic?.priceJpy,
      ebayPrice: listingEbay?.priceJpy,
      ebayEnabled: ebayEnabled,
    }

    return sellForm
  }, [ebayEnabled, listingEbay?.priceJpy, listingHolic?.priceJpy])

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<SellForm>({
    defaultValues: sellValues,
  })

  const {
    register: registerUnit,
    control: controlUnit,
    getValues,
    handleSubmit: handleSubmitUnit,
    setValue,
    // reset,
  } = useForm<UnitForm>()

  useEffect(() => {
    if (sellMode) reset(sellValues)
  }, [reset, sellValues, sellMode])

  const onSubmit = useCallback((params) => {
    if (!unit) {
      alert('error')
      return
    }

    if (params.holicPrice <= 0) {
      alert(t('cards.item.no_price'))
      return
    }

    const listing: Listing = {
      id: listingHolic?.id,
      platform: 'holic',
      priceJpy: params.holicPrice,
      unitId: unit.id,
    }

    setLoading(true)

    if (listingHolic?.id) {
      // editing existing listing
      listingsRepository
        .update({ id: listingHolic.id, params: listing })
        .then(({ children }) => {
          setUnit((unit) => {
            if (!unit) return
            const newUnit: Unit = {
              ...unit,
              listings: unit?.listings?.map(l => l.id === listingHolic.id ? listing : l)
            }
            return newUnit
          })

          setUnit((unit) => {
            if (!unit) return

            let listings = unit?.listings?.map(l => l.id === listingHolic.id ? listing : l)

            if (children && children.length > 0) {
              for (let i = 0; i < children.length; ++i) {
                const newChild = children[i]
                const oldChild = listings?.find(l => l.id === newChild.id)

                if (oldChild) {
                  listings = listings?.map(l => l.id === oldChild.id ? newChild : l)
                } else {
                  listings = [newChild, ...listings ?? []]
                }
              }
            }

            const newUnit: Unit = {
              ...unit,
              listings,
            }
            return newUnit
          })
          setSellMode(false)
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      // creating a new listing
      listingsRepository
        .create(listing)
        .then(({ listing }) => {
          setUnit((unit) => {
            if (!unit || !listing) return
            const newUnit: Unit = {
              ...unit,
              listings: [...unit?.listings ?? [], listing]
            }
            return newUnit
          })
          setSellMode(false)
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [unit, listingHolic, t, addError])

  const deleteListing = useCallback((listing: Listing) => {
    setLoading(true)

    if (!listing?.id) return alert('error')

    listingsRepository
      .destroy(listing?.id)
      .then(() => {
        if (unit) {
          const newUnit: Unit = {
            ...unit,
            listings: unit.listings?.filter(l => l.id !== listing.id)
          }

          setUnit(newUnit)
        }
        setSellMode(false)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError, unit])

  useEffect(() => {
    if (!sellMode) {
      setShowHolicSellUi(false)
      setShowEbaySellUi(false)
    }
  }, [sellMode])

  const confirmDeleteListing = useCallback((e, listing: Listing) => {
    e.preventDefault()

    if (confirm(t('cards.item.delete_confirm'))) {
      deleteListing(listing)
    }
  }, [deleteListing, t])

  const regionShippingServiceWarning = useMemo(() => {
    if (currentUser?.hasRegionShippingServices) return

    const newShippingMethodLink: string = `/account/sell?modal=shipping-services&session_id=${sessionId}`

    return (
      <>
        <h3 className="error">{ t('cards.item.shipping_warning.title') }</h3>
        <p>{ t('cards.item.shipping_warning.body1') }</p>
        <p>{ t('cards.item.shipping_warning.body2') }</p>
        <p>
          <Link to={ newShippingMethodLink } className="button tool">{ t('cards.item.shipping_warning.button') }</Link>
        </p>
        <hr />
      </>
    )
  }, [currentUser?.hasRegionShippingServices, sessionId, t])

  const sellModeUiHolic = useMemo(() => {
    if (!showHolicSellUi) return

    const forSaleButton =
      listingHolic
        ? <button onClick={ (e) => confirmDeleteListing(e, listingHolic) } className="tool">{ t('cards.item.sell_holic.button_remove') }</button>
        : null

    return (
      <form key={ 'holic' } onSubmit={ handleSubmit(onSubmit) }>
        <h2>{ t('cards.item.sell_holic.title') }</h2>
        { regionShippingServiceWarning }
        <p>
          <label>{ t('cards.item.sell_holic.price_label') }</label>
          <span>¥</span><input type="number" autoFocus={ !!currentUser?.hasRegionShippingServices } { ...register('holicPrice') } placeholder={ t('cards.item.sell_holic.price_placeholder') } min={ 0 } max={ 9999999 } />
        </p>
        <p>
          <button className="small" disabled={ !holicEnabled || loading }>{ t('cards.item.sell_holic.button_save') }</button> { forSaleButton }
        </p>
        <p>
          <button className="tool" onClick={ () => setShowHolicSellUi(false) }>{ t('cards.item.sell_holic.button_cancel') }</button>
        </p>
      </form>
    )
  }, [confirmDeleteListing, currentUser?.hasRegionShippingServices, handleSubmit, holicEnabled, listingHolic, loading, onSubmit, regionShippingServiceWarning, register, showHolicSellUi, t])

  const onListingModalClose = useCallback((listing: Listing|undefined) => {
    if (listing ?? listingEbay) {
      setUnit((unit) => {
        if (!unit) return

        let listings: Listing[] = []

        if (listing && listingEbay) {
          // update
          listings = unit.listings?.map(l => {
            if (l.platform === 'ebay') {
              return listing
            }

            return l
          }) ?? []
        } else if (listing) {
          // create
          listings = [listing, ...unit.listings ?? []]
        } else {
          // destroy
          listings = unit.listings?.filter(l => l.platform !== 'ebay') ?? []
        }

        const newUnit: Unit = {
          ...unit,
          listings,
        }

        return newUnit
      })
    } else if (listingEbay) {
      // listing was removed
      setUnit((unit) => {
        if (!unit) return

        const listings = unit.listings?.filter(l => l.platform !== 'ebay')

        const newUnit: Unit = {
          ...unit,
          listings,
        }

        return newUnit
      })
    }

    setShowEbaySellUi(false)
  }, [listingEbay])

  const buttonEditEbay = useMemo(() => {
    if (!ebayEnabled) {
      return <Link to="/account/sell" className="button tool">{ t('cards.item.sell_ebay.button_enable') }</Link>
    }

    return <button className="tool" onClick={ () => setShowEbaySellUi(true) }>{ listingEbay ? t('cards.item.sell_ebay.button_edit') : t('cards.item.sell_ebay.button_sell') }</button>
  }, [ebayEnabled, listingEbay, t])

  const buttonViewEbay = useMemo<JSX.Element|undefined>(() => {
    if (!listingEbay || !listingEbay.platformId) return

    const url: string = `https://ebay.com/itm/${listingEbay.platformId}`

    return (
      <a href={ url } target="_blank" rel="noreferrer">{ t('cards.item.sell_ebay.view_ebay') }</a>
    )
  }, [listingEbay, t])

  const [editMode, setEditMode] = useState<boolean>(false)

  const condition = useMemo(() => {
    if (!unit) return

    const c = conditions.find(c => c.id === unit.condition)

    return c?.id
  }, [unit])

  const conditionVerifiedUi = useMemo(() => {
    if (!unit?.verified) return null

    return <span className='grade-verified'>
      <span>HOLICで鑑定済</span>
    </span>
  }, [unit?.verified])

  const conditionUi = useMemo(() => {
    if (listing && listing.platform !== 'holic') {
      return <span className={ clsx('platform', [listing.platform]) }>{ listing.platform }</span>
    }

    if (!condition) {
      return <p className='grade-container' title={ t(`collections.conditions.long.${condition ?? 0}`) }>
        <span className={ clsx('grade', 'grade-0') }>
          <span className='score'>なし</span>
          <span className='text'>ランク</span>
        </span>
        { conditionVerifiedUi }
      </p>
    }

    return <p className='grade-container'>
      <span className={ clsx('grade', `grade-${condition}`) }>
        <span className='score'>{ t(`collections.conditions.short.${condition}`) }</span>
        <span className='text'>ランク</span>
      </span>
      { conditionVerifiedUi }
    </p>
  }, [condition, conditionVerifiedUi, listing, t])

  const firstItem = useMemo(() => {
    if (!unit || !unit.items || unit.items.length === 0) return

    return unit.items[0]
  }, [unit])

  const itemTypes = useMemo(() => {
    if (!unit || !unit.items) return

    return unit.items.map(i => i.type).filter((v, i, a) => a.indexOf(v) === i)
  }, [unit])

  const itemName = useMemo(() => {
    if (!firstItem) return

    return (i18n.language === 'ja' && firstItem.nameJa) ?? !firstItem.nameEn ? firstItem.nameJa : firstItem.nameEn
  }, [firstItem, i18n.language])
  const sellModeUi = useMemo(() => {
    if (!sellMode || !unit?.belongsToCurrentUser) return

    if (!unit.items) return

    if (editMode) return

    const buttonCancel = (
      !showHolicSellUi
        ? <p><button onClick={ () => setSellMode(false) } className="tool">{ t('cards.item.sell_holic.button_back') }</button></p>
        : null
    )

    const buttonHolic = (
      !holicEnabled
        ? <Link to="/account/sell" className="button tool">{ t('cards.item.sell_holic.button_enable') }</Link>
        : <button className="tool" onClick={ () => setShowHolicSellUi(true) }>{ listingHolic ? t('cards.item.sell_holic.button_edit') : t('cards.item.sell_holic.button_sell') }</button>
    )

    const textHolic = (
      !holicEnabled
        ? t('cards.item.sell_holic.not_enabled')
        : listingHolic
          ? t('listings.price.price_with_currency_symbol', { price: Math.round(listingHolic.priceJpy) })
          : '-'
    )

    const textEbay = (
      !ebayEnabled
        ? t('cards.item.sell_ebay.not_enabled')
        : listingEbay
          ? t('listings.price.price_with_currency_symbol', { price: Math.round(listingEbay.priceJpy) })
          : '-'
    )

    const sellHome = (
      !showHolicSellUi
        ? (
          <>
            <h2>{ itemName }</h2>
            { conditionUi }
            <h2>{ t('cards.item.sell.title') }</h2>
            <ul className="editable">
              <li>
                <span>{ t('cards.item.sell.holic') }{ textHolic }</span>
                { buttonHolic }
              </li>
              <li>
                <span>{ t('cards.item.sell.ebay') }{ textEbay }</span>
                { buttonViewEbay }
                { buttonEditEbay }
              </li>
            </ul>
            { buttonCancel }
          </>
          )
        : null
    )

    return (
      <>
        { sellHome }
        { sellModeUiHolic }
        {
          showEbaySellUi && <ListingModal onClose={ onListingModalClose } items={ unit.items } platform={ 'ebay' } unit={ unit } workingListing={ listingEbay } />
        }
      </>
    )
  }, [sellMode, unit, editMode, showHolicSellUi, t, holicEnabled, listingHolic, ebayEnabled, listingEbay, itemName, conditionUi, buttonViewEbay, buttonEditEbay, sellModeUiHolic, showEbaySellUi, onListingModalClose])

  const sellButton = useMemo(() => {
    if (!unit?.belongsToCurrentUser || sold) return

    if (editMode) return

    if (!unit.items) return

    if (unit?.public === false) {
      return <div>
        <p>
          <button className="tool" disabled>{ t('cards.item.sell.button_sell') }</button> (unavailable when private)
        </p>
      </div>
    }

    if (!ebayEnabled && !holicEnabled) {
      return (
        <div>
          <hr />
          <h3>{ t('cards.item.sell.no_seller_account.title') }</h3>
          <p>
            { t('cards.item.sell.no_seller_account.body', { name: itemName }) }
          </p>
          <p>
            <Link to="/account/sell" className="button tool">{ t('cards.item.sell.no_seller_account.button') }</Link>
          </p>
        </div>
      )
    }

    if (!firstItem || !unit?.id) return

    return (
      <div>
        <p>
          { /* <button onClick={ () => setSellMode(!sellMode) } className="tool">{ forSale ? t('cards.item.sell.button_edit') : t('cards.item.sell.button_sell') }</button> */ }
          <Link to={ `/items/${firstItem.id}/unit/${unit.id}/listing` } className="button tool">{ forSale ? t('cards.item.sell.button_edit') : t('cards.item.sell.button_sell') }</Link>
        </p>
      </div>
    )
  }, [unit, sold, editMode, ebayEnabled, holicEnabled, firstItem, forSale, t, itemName])

  const conditionsForForm = useMemo(() => {
    const c: ItemType[] = conditions.filter(c => c.displayDefault).map(c => {
      return {
        value: c.id,
        label: t(`collections.conditions.long.${c.id}`),
      }
    })

    return c
  }, [t])

  const onSubmitEdit = useCallback((params: UnitForm) => {
    if (!unit) return

    unitsRepository
      .update({
        id: unit.id ?? 0,
        params,
      })
      .then(() => {
        setUnit((ui) => {
          if (!ui) return
          const newUI: Unit = {
            ...ui,
            condition: params.condition,
            description: params.description,
            public: params.public,
          }
          return newUI
        })

        setEditMode(false)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })

    // if (listingHolic?.id) {
    //   // editing existing listing
    //   listingsRepository
    //     .update({ id: listingHolic.id, params: listing })
    //     .then(({ children }) => {
    //       setUnit((ui) => {
    //         if (!ui) return
    //         const newUI: Unit = {
    //           ...ui,
    //           listings: ui?.listings?.map(l => l.id === listingHolic.id ? listing : l)
    //         }
    //         return newUI
    //       })

    //       setUnit((ui) => {
    //         if (!ui) return

    //         let listings = ui?.listings?.map(l => l.id === listingHolic.id ? listing : l)

    //         if (children && children.length > 0) {
    //           for (let i = 0; i < children.length; ++i) {
    //             const newChild = children[i]
    //             const oldChild = listings?.find(l => l.id === newChild.id)

    //             if (oldChild) {
    //               listings = listings?.map(l => l.id === oldChild.id ? newChild : l)
    //             } else {
    //               listings = [newChild, ...listings ?? []]
    //             }
    //           }
    //         }

    //         const newUI: Unit = {
    //           ...ui,
    //           listings,
    //         }
    //         return newUI
    //       })
    //       setSellMode(false)
    //     })
    //     .catch((err: AxiosError) => {
    //       addError?.(err)
    //     })
    //     .finally(() => {
    //       setLoading(false)
    //     })
    // } else {
    //   // creating a new listing
    //   listingsRepository
    //     .create(listing)
    //     .then(({ listing }) => {
    //       setUnit((ui) => {
    //         if (!ui || !listing) return
    //         const newUI: Unit = {
    //           ...ui,
    //           listings: [...ui?.listings ?? [], listing]
    //         }
    //         return newUI
    //       })
    //       setSellMode(false)
    //     })
    //     .catch((err: AxiosError) => {
    //       addError?.(err)
    //     })
    //     .finally(() => {
    //       setLoading(false)
    //     })
    // }
  }, [addError, unit])

  useEffect(() => {
    // set value of condition
    if (unit?.condition) {
      setValue('condition', unit?.condition)
    }

    setValue('public', unit?.public ?? true)
  }, [setValue, unit?.condition, unit?.public])

  useEffect(() => {
    // set value of description
    if (unit?.description) {
      setValue('description', unit?.description)
    }
  }, [setValue, unit?.description])

  const editUi = useMemo(() => {
    if (!unit) return

    if (!editMode || sellMode) return

    return <>
      <form onSubmit={ handleSubmitUnit(onSubmitEdit) }>
        <h2>{ itemName }</h2>
        { conditionUi }
        <div className="select">
          <label>状態</label>
          {
            <Controller
              control={ controlUnit }
              name='condition'
              render={ ({ field }) => (
                <Select
                  options={ conditionsForForm }
                  onChange={ val => field.onChange(val?.value) }
                  placeholder={ t('defaults.select') }
                  value={ conditionsForForm.find(t => t.value === getValues('condition')) }
                />
              ) }
            />
          }
        </div>
        <p>
          <label>説明</label>
          <textarea rows={ 6 } { ...registerUnit('description') } />
        </p>
        <div className="select">
          {
            listing
              ? <label>Visibility settings (cannot be changed while for sale)</label>
              : <label>Visibility settings</label>
          }
          {
            <Controller
              control={ controlUnit }
              name='public'
              render={ ({ field }) => (
                <Select
                  options={ publicOptions }
                  onChange={ val => field.onChange(val?.value) }
                  placeholder={ t('defaults.select') }
                  value={ publicOptions.find(t => t.value === getValues('public')) }
                  isDisabled={ !!listing }
                />
              ) }
            />
          }
        </div>
        <p>
          <button className='full'>保存</button>
        </p>
      </form>
      <p className='center'>
        <button className='tool' onClick={ () => setEditMode(false) }>キャンセル</button>
      </p>
    </>
  }, [conditionUi, conditionsForForm, controlUnit, editMode, getValues, handleSubmitUnit, itemName, listing, onSubmitEdit, registerUnit, sellMode, t, unit])

  const editButton = useMemo(() => {
    if (!unit?.belongsToCurrentUser || sold) return

    if (editMode || sellMode) return

    return (
      <div>
        <p>
          <button onClick={ () => setEditMode(!editMode) } className="tool">商品の説明と状態を編集する</button>
        </p>
      </div>
    )
  }, [unit?.belongsToCurrentUser, sold, editMode, sellMode])

  const forSaleNotice = useMemo(() => {
    if (!unit?.belongsToCurrentUser) return

    return listing
      // ? <div className='sale-notice'>Your card is for sale</div>
      ? <div className='sale-notice'>出品中</div>
      : null
  }, [listing, unit?.belongsToCurrentUser])

  const categories = useMemo<TagPreview[]>(() => {
    if (!unit?.items) return []

    const categories = unit.items.map(i => {
      if (!i.tags) return undefined

      const tagsFiltered = i.tags.filter(t => t.type === 'Category') ?? []
      return tagsFiltered.length > 0
        ? tagsFiltered.reduce((prev, current) => prev.depth > current.depth ? prev : current)
        : undefined
    })

    return categories.filter(c => c !== undefined) as TagPreview[]
  }, [unit])

  const categoryBreadcrumbs = useMemo<TagPreview[]>(() => {
    if (!firstItem?.tags) return []

    const tagsFiltered = firstItem.tags.filter(t => t.type === 'Category') ?? []

    return tagsFiltered.length > 0
      ? tagsFiltered.sort((prev, current) => prev.depth > current.depth ? 1 : -1)
      : []
  }, [firstItem])

  useEffect(() => {
    if (!itemName) return

    let title = itemName

    const series = categoryBreadcrumbs.filter(c => c.depth !== 0).map(c => c.nameJa ?? '').join(' > ')

    title = title + ': ' + series

    document.title = title + ' • HOLIC'
  }, [categoryBreadcrumbs, itemName])

  const characters = useMemo<TagPreview[]>(() => {
    if (!unit?.items) return []

    const characters = unit.items.map(i => {
      if (!i.tags) return undefined

      const tagsFiltered = i.tags.filter(t => t.type === 'Character') ?? []
      return tagsFiltered.length > 0
        ? tagsFiltered.reduce((prev, current) => prev.depth > current.depth ? prev : current)
        : undefined
    })

    return characters.filter(c => c !== undefined) as TagPreview[]
  }, [unit])

  const { filters, searchString, endpointForExistingFilters } = useContext(SearchContext)
  const categoriesLink = useCallback((tagId?: number) => {
    if (!endpointForExistingFilters) return ''
    if (!tagId) return ''

    const p = `/categories/${tagId}`

    const params = endpointForExistingFilters(filters, searchString)

    return p + params
  }, [endpointForExistingFilters, filters, searchString])

  const categoryUi = useMemo(() => {
    return categories.map(character => {
      const title = (i18n.language === 'ja' && character.nameJa) ?? !character.nameEn ? character.nameJa : character.nameEn

      return <Link to={ categoriesLink(character?.id) } className="card-character" key={ character.id }>{ title }</Link>
    })
  }, [categories, i18n.language, categoriesLink])

  const characterUi = useMemo(() => {
    return characters.map(character => {
      const title = (i18n.language === 'ja' && character.nameJa) ?? !character.nameEn ? character.nameJa : character.nameEn

      return <Link to={ categoriesLink(character?.id) } className="card-character" key={ character.id }>{ title }</Link>
    })
  }, [characters, i18n.language, categoriesLink])

  const cardLink = useMemo(() => {
    if (!unit?.items) return

    const item = unit.items[0]

    if (!item.tags) return

    const category = item.tags.find(t => t.type === 'Category')

    if (!category) return

    const link = categoriesLink(category.id).split('?')

    return <Link to={ `${link[0]}/items/${item.id}${link.length > 1 ? `?${link[1]}` : ''}` }>{ itemName }</Link>
  }, [unit, categoriesLink, itemName])

  const description = useMemo(() => {
    if (!unit?.description) return

    return (
      <>
        <h3>{ t('cards.item.description') }</h3>
        <div className='description'>
          <p>{ unit.description }</p>
        </div>
      </>
    )
  }, [t, unit])

  const realImage = useMemo(() => {
    if (!itemTypes) return

    if (!unit?.images || unit.images.length === 0) return

    const realImage = itemTypes.find(i => i === 'CardSingle')

    if (!realImage) return

    return <p>✅ お届けするカードの実際の写真です</p>
  }, [itemTypes, unit?.images])

  const returnsUi = useMemo(() => {
    return <tr>
      <td>返品条件</td>
      <td>
        {
          unit?.user?.returns
            ? unit?.user?.returns === 2
              ? 'お客様都合での返品可 (一部返金)'
              : 'お客様都合での返品可 (全額返金)'
            : 'お客様都合での返品不可'
        }<br />
        <Link to='/about/returns'>詳細を確認する</Link>
      </td>
    </tr>
  }, [unit?.user?.returns])

  const shippingInformation = useMemo(() => {
    const onlyDigital = itemTypes?.length === 1 && itemTypes[0] === 'DigitalDownload'

    return <>
      <tr>
        <td>発送元の地域</td>
        <td>{
          onlyDigital
            ? 'メールでのコード送付'
            : shippingLocation
        }</td>
      </tr>
      <tr>
        <td>発送までの日数</td>
        <td>{
          onlyDigital
            ? '10日以内に送付'
            : '1~2日で発送'
        }</td>
      </tr>
      <tr>
        <td>配送オプション</td>
        <td>
          {
            onlyDigital
              ? '-'
              : regionShippingServicesLoading
                ? <span>読み込み中...</span>
                : regionShippingServices.sort((a, b) => a.priceJpy > b.priceJpy ? 1 : -1).map(s => <span key={ s.id }>
                  { t(`account.sell.shipping_options.options.${s.shippingService.label}`) }：{ Math.round(s.priceJpy) }円<br />
                </span>)
          }
        </td>
      </tr>
    </>
  }, [itemTypes, regionShippingServices, regionShippingServicesLoading, shippingLocation, t])

  const saleInformationUi = useMemo(() => {
    if (!listing) return

    return <>
      { realImage }
      <table>
        <tbody>
          { shippingInformation }
          { returnsUi }
        </tbody>
      </table>
      <hr />
    </>
  }, [listing, realImage, returnsUi, shippingInformation])

  const itemDetailUi = useMemo(() => {
    return <>
      <hr />
      { saleInformationUi }
      <table>
        <tbody>
          <tr>
            <td>{ t('cards.item.card') }</td>
            <td>{ cardLink }</td>
          </tr>
          {
            categoryUi
              ? <tr>
                <td>{ t('cards.item.series') }</td>
                <td>{ categoryUi }</td>
              </tr>
              : null
          }
          {
            characterUi.length > 0
              ? <tr>
                <td>{ t('cards.item.character') }</td>
                <td>{ characterUi }</td>
              </tr>
              : null
          }
        </tbody>
      </table>
    </>
  }, [cardLink, categoryUi, characterUi, saleInformationUi, t])

  const visibility = useMemo(() => {
    if (!unit) return

    if (unit.public) return

    return <div className='private-notice'>
      { t('cards.item.private') }
    </div>
  }, [t, unit])

  const listingUi = useMemo(() => {
    if (!unit) return

    if (editMode || sellMode) return

    return <div>
      { forSaleNotice }
      <UserBanner platformName={ platform?.name } userId={ userId } displayName={ collectionName } salesLevel={ unit.user?.salesLevel } salesRating={ unit.user?.salesRating } />
      { visibility }
      <div className='title-condition'>
        <h2>{ itemName }</h2>
        { conditionUi }
      </div>
      <div className='description-desktop'>
        { description }
      </div>
      <div className={ clsx('item-details', { listing }, { mine: unit?.belongsToCurrentUser }) }>
        <div>
          { listings }
        </div>
      </div>
      <div className='description-mobile'>
        { description }
      </div>
      { editButton }
      { sellButton }
      { itemDetailUi }
    </div>
  }, [collectionName, conditionUi, description, editButton, editMode, forSaleNotice, itemDetailUi, itemName, listing, listings, platform?.name, sellButton, sellMode, userId, unit, visibility])

  useLayoutEffect(() => {
    const preventRightClick = (e) => {
      e.preventDefault()
    }

    window.addEventListener('contextmenu', preventRightClick)

    return () => window.removeEventListener('contextmenu', preventRightClick)
  }, [])

  const imagesContainer = useMemo(() => {
    if (!unit) return

    return <ItemImages images={ unit.images } resolution={ 'standard' } navigationStyle='image' carousel={ true } />
  }, [unit])

  if (loading) {
    return (
      <div>
        <p>{ t('cards.item.loading') }</p>
      </div>
    )
  }

  if (!unit) {
    return <GenericNotFound />
  }

  return (
    <div className={ clsx('user-item', { forSale }) }>
      {
        showBasketWarning && <UnitBasketMultipleSellersModal unit={ unit } onClose={ () => setShowBasketWarning(false) } />
      }
      {
        showOrderCompleteModal &&
          <UnitBuyCompleteModal onClose={ () => setShowOrderCompleteModal(false) } unit={ unit } />
      }
      {
        imagesContainer
          ? <div className="images-container">
            { imagesContainer }
          </div>
          : null
      }
      <div className="user-item-right">
        { sellModeUi }
        { listingUi }
        { editUi }
      </div>
    </div>
  )
}

export { UnitView }
