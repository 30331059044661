import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getAddressByZip } from 'japan-address-autofill'
import { AxiosError } from 'axios'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { ModalWrapper } from './modal_wrapper'
import { useTranslation } from 'react-i18next'

import { Address, CountryCode } from '../types'
import { addressesRepository } from '../repositories/addresses_repository'
import { countries } from '../lib/countries'
import { ErrorsContext } from '../contexts/errors_context'
import Select from 'react-select'
import { japanPrefectures } from '../lib/japan_prefectures'

type Props = Readonly<{
  onClose: (address: Address|undefined) => void
  address?: Address
  addresses: Address[]
}>

// type AddressForm= Readonly<{
//   address?: Address
// }>

const AddressModal = ({ onClose, address, addresses }: Props) => {
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)

  const setLoading = useState<boolean>(true)[1]

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm<Address>({
    defaultValues: {
      countryCode: 'JP',
    }
  })

  const zipCode = useWatch({
    control,
    name: 'zipCode',
  })

  const countryCode = useWatch({
    control,
    name: 'countryCode',
  })

  const state = useWatch({
    control,
    name: 'state',
  })

  const addressLine2 = useWatch({
    control,
    name: 'addressLine2',
  })

  const addressLine3 = useWatch({
    control,
    name: 'addressLine3',
  })

  useEffect(() => {
    reset(address)
  }, [address, reset])

  useEffect(() => {
    // getAddressByZip('164-0011')
    if (countryCode !== 'JP' || !zipCode) return

    if (state ?? addressLine2 ?? addressLine3) return

    const workingZipCode = zipCode
      .replace(
        /[\uff01-\uff5e]/g,
        (ch) => { return String.fromCharCode(ch.charCodeAt(0) - 0xfee0) }
      )
      .replace(/\D/g, '')

    if (workingZipCode.length >= 7) {
      getAddressByZip(workingZipCode)
        .then((res) => {
          setValue('state', res.prefecture)
          setValue('addressLine3', res.city)
          setValue('addressLine2', res.area)
        })
        // .catch((err) => {
        //   // TODO: Error reporting
        //   // For now, maybe we don't need it
        //   console.error(err)
        // })
    }
  }, [addressLine2, addressLine3, countryCode, setValue, state, zipCode])

  const workingAddressIsFirstAddress = useMemo(() => {
    return addresses.length === 0
  }, [addresses.length])

  const workingAddressIsOnlyAddress = useMemo(() => {
    return addresses.length === 1 && address && addresses[0].id === address?.id
  }, [address, addresses])

  const onSubmit = useCallback((params) => {
    // TODO: Validation
    const newAddress: Address = { ...params, id: address?.id }

    if (address) {
      // editing existing address
      if (!address.id) return

      addressesRepository
        .update({ id: address.id, params: newAddress })
        .then(() => {
          onClose(newAddress)
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      // creating a new address
      addressesRepository
        .create(newAddress)
        .then(({ address }) => {
          onClose(address)
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    setLoading(true)
  }, [addError, address, onClose, setLoading])

  const addressTitle = useMemo(() => {
    return address ? t('account.buy.addresses.modal.title_edit') : t('account.buy.addresses.modal.title_new')
  }, [t, address])

  const countriesLocalised = useMemo(() => {
    return countries.map(c => {
      const a: CountryCode = { ...c, label: t(`account.countries.${c.value}`) }
      return a
    })
  }, [t])

  const japanPrefecturesObject = japanPrefectures.map((p) => {
    return { value: p, label: p }
  })

  return (
    <ModalWrapper>
      <div>
        <h1>{ addressTitle }</h1>
        <form onSubmit={ handleSubmit(onSubmit) } className="single-line">
          <p>
            <label>
              { t('account.buy.addresses.modal.name') }
            </label>
            <input type="text" { ...register('name') } />
          </p>
          <p>
            <label>
              { t('account.buy.addresses.modal.postcode') }
            </label>
            <input placeholder={ t('account.buy.addresses.modal.postcode_placeholder') } type="text" { ...register('zipCode') } />
          </p>
          <div className='select'>
            <label>
              { t('account.buy.addresses.modal.state') }
            </label>
            {
              countryCode === 'JP'
                ? <Controller
                  control={ control }
                  name='state'
                  render={ ({ field }) => (
                    <Select
                      isClearable
                      options={ japanPrefecturesObject }
                      onChange={ val => field.onChange(val?.value) }
                      placeholder={ t('defaults.select') }
                      value={ japanPrefecturesObject.find(t => t.value === state) }
                    />
                  ) }
                />
                : <input type="text" { ...register('state') } />
            }
          </div>
          <p>
            <label>
              { t('account.buy.addresses.modal.address') }
            </label>
            <input placeholder={ t('account.buy.addresses.modal.line3_placeholder') } type="text" { ...register('addressLine3') } />
          </p>
          <p>
            <label></label>
            <input placeholder={ t('account.buy.addresses.modal.line2_placeholder') } type="text" { ...register('addressLine2') } />
          </p>
          <p>
            <label></label>
            <input placeholder={ t('account.buy.addresses.modal.line1_placeholder') } type="text" { ...register('addressLine1') } />
          </p>
          <div className="select">
            <label>
              { t('account.buy.addresses.modal.country') }
            </label>
            <Controller
              control={ control }
              name='countryCode'
              render={ ({ field }) => (
                <Select
                  options={ countriesLocalised }
                  onChange={ val => field.onChange(val?.value) }
                  placeholder={ t('defaults.select') }
                  value={ countriesLocalised.find(t => t.value === countryCode) }
                />
              ) }
            />
          </div>
          <p>
            <label>
              { t('account.buy.addresses.modal.phone') }
            </label>
            <input type="text" { ...register('telephone') } />
          </p>
          <p>
            <label></label>
            <label>
              <input type="checkbox" defaultChecked={ address?.default ?? workingAddressIsFirstAddress } disabled={ workingAddressIsFirstAddress || workingAddressIsOnlyAddress } { ...register('default') } /> { t('account.buy.addresses.modal.default') }
            </label>
          </p>
          <p>
            <button className="full">
              { t('account.buy.addresses.modal.save') }
            </button>
          </p>
        </form>
        <p>
          <button className="large tool" onClick={ () => { onClose(undefined) } }>
            { t('account.buy.addresses.modal.cancel') }
          </button>
        </p>
      </div>
    </ModalWrapper>
  )
}

export { AddressModal }
