const queryStringToNumberArrayFromSelection = (q: string, selection): number[] => {
  const qSplit = q.split(',')

  const numbers = selection.filter(s => {
    if (typeof s === 'object') {
      if ('id' in s) return qSplit.includes(String(s.id))
      return false
    }
    return qSplit.includes(String(s))
  })

  return numbers.map(s => {
    if (typeof s === 'object') return s.id
    return s
  }) ?? []
}

export { queryStringToNumberArrayFromSelection }
