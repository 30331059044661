// import xml2js from 'xml2js'
import axios, { AxiosResponse } from 'axios'

// interface IErrorMessages {
//   [key: string]: string[]
// }

// NOTE: For direct uploading, we can't convert key cases.
const axiosClientNoConversion = axios.create()

let apiEndpoint = ''

if (process.env.REACT_APP_RAILS_ENV === 'development' || !process.env.REACT_APP_API_PREFIX || !process.env.REACT_APP_API_SUFFIX) {
  apiEndpoint = String(process.env.REACT_APP_API_BASE_URL)
} else {
  const hostname = window.location.hostname.replace('integration.', '')
  apiEndpoint = process.env.REACT_APP_API_PREFIX + hostname + process.env.REACT_APP_API_SUFFIX
}

const preSignedPostParams = async (file: File): Promise<AxiosResponse<PutGCSParams>> => {
  return await axiosClientNoConversion.request({
    url: `${apiEndpoint}/gcs/params?filename=${file.name}&type=${file.type}&service=match`,
    method: 'get',
  })
}

interface PutGCSParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers: any
  url: string
  method: string
}

interface GCSUploadResult {
  imageData: string
}

const preSignedPostUpload = async (
  data: PutGCSParams,
  file: File,
  // imageDataName: string,
  // progress: ProgressMethods
): Promise<AxiosResponse<any>> => {
  const options = {
    headers: {
      'Content-Type': file.type,
    },
  }

  return await axios.put(
    data.url,
    file,
    options
  )
    .then((res) => {
      return res
    })
    .catch((error) => {
      throw error
    })
}

const getOrientation = async (file: File) => {
  const reader = new FileReader()
  reader.readAsArrayBuffer(file)

  return await new Promise((resolve, reject) => {
    reader.onload = (event: ProgressEvent) => {
      if (!event.target) {
        resolve(null)
      }

      const file = event.target as FileReader
      const view = new DataView(file.result as ArrayBuffer)

      if (view.getUint16(0, false) !== 0xFFD8) {
        resolve(-2)
      }

      const length = view.byteLength
      let offset = 2

      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) resolve(-1)
        const marker = view.getUint16(offset, false)
        offset += 2

        if (marker === 0xFFE1) {
          if (view.getUint32(offset += 2, false) !== 0x45786966) {
            resolve(-1)
          }

          const little = view.getUint16(offset += 6, false) === 0x4949

          offset += view.getUint32(offset + 4, little)

          const tags = view.getUint16(offset, little)

          offset += 2

          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + (i * 12), little) === 0x0112) {
              resolve(view.getUint16(offset + (i * 12) + 8, little))
            }
          }
        } else if ((marker & 0xFF00) !== 0xFF00) {
          break
        } else {
          offset += view.getUint16(offset, false)
        }
      }

      resolve(-1)
    }

    reader.onerror = reject
  })
}

const imageDataJsonString = (file: File, params: PutGCSParams, orientation, imageDimensions, key) => {
  let storage, id

  if (params.fields.key) {
    const keyComponents = params.fields.key.split('/')
    storage = keyComponents.shift()
    id = keyComponents.join('/')
  }

  return JSON.stringify({
    id: id ?? key,
    storage,
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type,
      lastModified: file.lastModified,
      orientation,
      width: imageDimensions.width,
      height: imageDimensions.height,
    },
  })
}

// const reorientateImageBase64 = async (file: File, orientation: number) => {
//   const reader2 = new FileReader()
//   reader2.readAsDataURL(file)

//   return await new Promise<string>((resolve, reject) => {
//     reader2.onload = function (e: ProgressEvent) {
//       const file = e.target as FileReader

//       const srcBase64 = file.result as string

//       const img: HTMLImageElement = new Image()

//       img.onload = function () {
//         const width = img.width
//         const height = img.height
//         const canvas = document.createElement('canvas')
//         const ctx = canvas.getContext('2d')

//         if (!ctx) {
//           return reject
//         }

//         // set proper canvas dimensions before transform & export
//         if (orientation > 4 && orientation < 9) {
//           canvas.width = height
//           canvas.height = width
//         } else {
//           canvas.width = width
//           canvas.height = height
//         }

//         // transform context before drawing image
//         switch (orientation) {
//           case 2: ctx.transform(-1, 0, 0, 1, width, 0); break
//           case 3: ctx.transform(-1, 0, 0, -1, width, height); break
//           case 4: ctx.transform(1, 0, 0, -1, 0, height); break
//           case 5: ctx.transform(0, 1, 1, 0, 0, 0); break
//           case 6: ctx.transform(0, 1, -1, 0, height, 0); break
//           case 7: ctx.transform(0, -1, -1, 0, height, width); break
//           case 8: ctx.transform(0, -1, 1, 0, 0, width); break
//           default: break
//         }

//         // draw image
//         ctx.drawImage(img, 0, 0)

//         resolve(canvas.toDataURL())
//       }

//       img.src = srcBase64
//     }
//   })
// }

const getImageDimensions = async (file: File) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)

  return await new Promise((resolve) => {
    reader.onload = function (e: ProgressEvent) {
      const file = e.target as FileReader

      const srcBase64 = file.result as string

      const img: HTMLImageElement = new Image()

      img.onload = function () {
        const width = img.width
        const height = img.height

        resolve({ width, height })
      }

      img.src = srcBase64
    }
  })
}

// const dataUrlToFile = async (dataUrl: string, fileName: string): Promise<File> => {
//   const res: Response = await fetch(dataUrl)
//   const blob: Blob = await res.blob()
//   return new File([blob], fileName, {
//     type: 'image/png',
//     lastModified: Date.now(),
//   })
// }

const uploadToGCS = async (
  // data: PutGCSParams,
  file: File,
  // imageDataName: string,
  // progress: ProgressMethods
): Promise<GCSUploadResult> => {
  // let fileProcessed = file
  // if (typeof orientation === 'number' && orientation > 0) {
  //   const fileRotatedBase64 = await reorientateImageBase64(file, orientation)

  //   fileProcessed = await dataUrlToFile(fileRotatedBase64, 'default')
  // }

  try {
    const presignResponse = await preSignedPostParams(file)

    const urlNoParams = presignResponse.data.url.split('?')[0]
    const urlNoSlash = urlNoParams.split('/')
    const key = urlNoSlash[urlNoSlash.length - 1]

    const uploadResult = await preSignedPostUpload(
      presignResponse.data,
      file,
      // 'image_data',
    // this.$Progress
    )

    if (uploadResult.status === 200 || uploadResult.status === 204) {
      const orientation = await getOrientation(file)
      const imageDimensions = await getImageDimensions(file)

      return await Promise.resolve({
        imageData: imageDataJsonString(file, presignResponse.data, orientation, imageDimensions, key)
      })
    } else {
      return await Promise.reject(new Error())
    }
  } catch (error) {
    return await Promise.reject(error)
  }
}

// const uploadToGCS = async (
//   // data: PutGCSParams,
//   file: File,
//   // imageDataName: string,
//   // progress: ProgressMethods
// ): Promise<GCSUploadResult> => {
//   const o = await getOrientation(file)

//   const f = await uploadToGCSB(file)

//   return f
// }

export { uploadToGCS }
