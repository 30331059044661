import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, Navigate, useMatch } from 'react-router-dom'
import { GenericNotFound } from './generic_not_found'
import { AuthContext } from '../contexts/auth_context'
import { Feedback, Message, Order, OrderUpdate } from '../types'
import { ordersRepository } from '../repositories/orders_repository'
import { AxiosError } from 'axios'
import { ErrorsContext } from '../contexts/errors_context'
import { useTranslation } from 'react-i18next'
import { conditions } from '../lib/conditions'
import { countries } from '../lib/countries'
import Moment from 'react-moment'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { OrderFeedback } from './order_feedback'

import '../stylesheets/Messages.scss'
import { OrderMessages } from './order_messages'
import Select from 'react-select'
import { SearchContext } from '../contexts/search_context'

type Status = Readonly<{
  value: string
  label: string
}>

const Orders = () => {
  const { t, i18n } = useTranslation()
  const { addError } = useContext(ErrorsContext)

  const params = useMatch('/orders/:id')?.params

  const [order, setOrder] = useState<Order|undefined>()
  const [orderLoading, setOrderLoading] = useState<boolean>(true)
  const [statusUpdateLoading, setStatusUpdateLoading] = useState<boolean>(false)

  const orderId = useMemo<number>(() => {
    if (params === undefined) return 0

    const orderId = parseInt(params.id ?? '')

    return isNaN(orderId) ? 0 : orderId
  }, [params])

  const { isAuthenticated } = useContext(AuthContext)

  const getOrder = useCallback(() => {
    setOrderLoading(true)

    ordersRepository
      .get(orderId)
      .then(({ order }) => {
        setOrder(order)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setOrderLoading(false)
      })
  }, [addError, orderId])

  useEffect(() => {
    getOrder()
  }, [getOrder])

  const totalPrice = useMemo(() => {
    if (!order) return 0

    return +(order.itemPriceJpy ?? 0) + +(order.shippingPriceJpy ?? 0)
  }, [order])

  const country = useMemo(() => {
    if (!order?.address?.countryCode) return

    return countries.find(c => c.value === order?.address?.countryCode)
  }, [order])

  const support = useMemo(() => {
    if (order?.role !== 'buyer') return

    return <>
      <hr />
      <p className='center'>
        <a href="mailto:support@holic.net" className="button tool">{ t('order.view.help') }</a>
      </p>
    </>
  }, [order?.role, t])

  const {
    handleSubmit,
    control,
  } = useForm<OrderUpdate>()

  const status = useWatch({
    control,
    name: 'status',
  })

  const onSubmit = useCallback((params) => {
    if (!order?.id || statusUpdateLoading) return

    // TODO: Validation
    const orderUpdate: OrderUpdate = { ...params }

    ordersRepository
      .update({ id: order.id, params: orderUpdate })
      .then(() => {
        setOrder({
          ...order,
          status: params.status
        })
        alert(t('order.view.status_updated'))
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setStatusUpdateLoading(false)
      })

    setStatusUpdateLoading(true)
  }, [addError, order, statusUpdateLoading, t])

  const updateOrder = useMemo(() => {
    if (order?.status === 'canceled') return

    if (order?.role !== 'seller') return

    const statuses: Status[] = [
      {
        label: t('order.view.status_shipped'),
        value: 'shipped',
      },
      {
        label: t('order.view.status_succeeded'),
        value: 'succeeded',
      },
    ]

    return (
      <>
        <hr />
        <form onSubmit={ handleSubmit(onSubmit) }>
          <h3>{ t('order.view.status_update') }</h3>
          <div className="select">
            <Controller
              control={ control }
              name='status'
              render={ ({ field }) => (
                <Select
                  options={ statuses }
                  onChange={ val => field.onChange(val?.value) }
                  placeholder={ t('defaults.select') }
                  value={ statuses.find(t => t.value === status) }
                />
              ) }
            />
          </div>
          <p>
            <button className="full" disabled={ statusUpdateLoading }>{ t('order.view.status_save') }</button>
          </p>
        </form>
      </>
    )
  }, [control, handleSubmit, onSubmit, order?.role, order?.status, status, statusUpdateLoading, t])

  const { filters, endpointForExistingFilters } = useContext(SearchContext)
  const categoriesLink = useCallback((tagId: number) => {
    if (!endpointForExistingFilters) return ''

    const p = `/categories/${tagId}`

    const params = endpointForExistingFilters(filters, '')

    return p + params
  }, [endpointForExistingFilters, filters])

  const items = useMemo(() => {
    return order?.units?.map((u) => {
      if (!u.userId || !u.id) return null

      // TODO: should only have one listing here
      const listingPrice = u.listings?.map(l => l.priceJpy).reduce((previous, current) => previous + current, 0) ?? 0

      // const itemName = (i18n.language === 'ja' && ui.item?.nameJa) ?? !ui.item?.nameEn ? ui.item?.nameJa : ui.item?.nameEn

      const itemName = u.items?.map(i => {
        return (i18n.language === 'ja' && i.nameJa) ?? !i.nameEn ? i.nameJa : i.nameEn
      }).join(' / ') ?? ''

      const condition = conditions.find(c => c.id === u.condition)
      const conditionName = t(`collections.conditions.long.${condition?.id ?? 0}`)

      const tags = u.items?.flatMap(i => i.tags)
      const tag = tags && tags.length > 0 ? tags.reduce((previous, current) => current.depth > previous.depth && current.type === 'Category' ? current : previous) : undefined

      const tagUi: JSX.Element = !tag
        ? <></>
        : <span key={ tag.id }>- <Link to={ categoriesLink(tag.id) }>{
            (i18n.language === 'ja' && tag.nameJa) ?? !tag.nameEn ? tag.nameJa : tag.nameEn
          }</Link></span>

      return (
        <li key={ u.id }>
          <span><Link to={ `/u/${u.userId}/units/${u.id}` }>{ itemName }</Link> ({ conditionName }){ tagUi }</span>
          <span>{ Math.round(listingPrice) }</span>
        </li>
      )
    })
  }, [categoriesLink, i18n.language, order?.units, t])

  const shippingMethod = useMemo(() => {
    if (order?.role !== 'seller') return

    return (
      <>
        <p>
          { t('order.view.shipping_method') }<strong>{ t(`account.sell.shipping_options.options.${order?.regionShippingService?.shippingService.label ?? ''}`) }</strong>
        </p>
      </>
    )
  }, [order?.regionShippingService?.shippingService.label, order?.role, t])

  const returnsPolicy = useMemo(() => {
    if (!order) return

    return <>
      <hr />
      <ul className="organised">
        <li>
          <span>返品条件</span>
          {
            order.returns > 0
              ? order.returns === 2
                ? 'お客様都合での返品可 (一部返金)'
                : 'お客様都合での返品可 (全額返金)'
              : 'お客様都合での返品不可'
          }
        </li>
        <li>
          <span></span>
          <Link to='/about/returns'>詳細を確認する</Link>
        </li>
      </ul>
    </>
  }, [order])

  const feeSummary = useMemo(() => {
    if (!order || order.role !== 'seller') return

    const referralSummary = order.orderReferrals && order.orderReferrals.length > 0
      ? <>
        <h3>{ t('order.view.referral.title') }</h3>
        <table>
          <thead>
            <tr>
              <td>{ t('order.view.referral.headings.user') }</td>
              <td>{ t('order.view.referral.headings.status') }</td>
            </tr>
          </thead>
          <tbody>
            {
              order.orderReferrals.map((r) => {
                return (
                  <tr key={ r.id }>
                    <td>
                      {
                        r.referral.user
                          ? <Link to={ `/u/${r.referral.userId}` }>{ r.referral.user?.displayName ?? t('cards.item.default_display_name') }</Link>
                          : '?'
                      }
                    </td>
                    <td>{ t(`order.view.referral.status.${r.status}`) }</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </>
      : null

    return <>
      <hr />
      { referralSummary }
      { /* <p>この注文の販売手数料は{ Number(order.feeJpy) }円です。</p> */ }
    </>
  }, [order, t])

  const actionRequired = useMemo(() => order?.role === 'seller' && order?.status === 'succeeded', [order?.role, order?.status])

  const statusColor = useMemo(() => {
    if (order?.status === 'shipped') {
      return 'green'
    } else if (order?.status === 'succeeded' && order?.role === 'buyer') {
      return 'yellow'
    } else if (actionRequired) {
      return 'red'
    }

    return ''
  }, [actionRequired, order?.role, order?.status])

  const actionExplanation = useMemo(() => {
    if (!actionRequired) return

    return (
      <div className="attention">
        <p><strong>{ t('order.view.advice.ship.title') }</strong></p>
        <p>{ t('order.view.advice.ship.body1') }</p>
        <p>{ t('order.view.advice.ship.body2') }</p>
        <p>{ t('order.view.advice.ship.body3') }</p>
      </div>
    )
  }, [actionRequired, t])

  const newFeedbackCallback = useCallback((f: Feedback) => {
    if (!order) return

    const newOrder: Order = {
      ...order,
      feedbacks: [f]
    }

    setOrder(newOrder)
  }, [order])

  const newMessageCallback = useCallback((m: Message) => {
    if (!order) return

    const mMine = { ...m, isMe: true }

    const newOrder: Order = {
      ...order,
      messages: order.messages?.concat([mMine])
    }

    setOrder(newOrder)
  }, [order])

  const feedback = useMemo(() => {
    if (
      (order?.role === 'seller' && (!order.feedbacks || order.feedbacks.length < 1)) ||
      (order?.role === 'buyer' && order.status !== 'shipped') ||
      !order
    ) return

    return <><hr /><OrderFeedback order={ order } callback={ (f) => newFeedbackCallback(f) } /></>
  }, [newFeedbackCallback, order])

  if (!isAuthenticated) return <Navigate to='/register' />

  if (orderLoading) {
    return <div>
      <h1>{ t('order.view.loading') }</h1>
    </div>
  }

  if (!order) {
    return <GenericNotFound />
  }

  return (
    <div>
      <div className="narrow">
        <p>
          {
            order.role === 'buyer'
              ? <>{ t('order.view.my_account') } &gt; <Link to="/account/buy">{ t('order.view.buying') }</Link></>
              : <>{ t('order.view.my_account') } &gt; <Link to="/account/sell">{ t('order.view.selling') }</Link></>
          }
        </p>
        <h2>{ t('order.view.title', { number: order.id }) }</h2>
        <ul className="organised">
          <li>
            <span>{ t('order.view.date_created') }<Moment format="YYYY-MM-DD HH:mm">{ order.createdAt }</Moment></span>
          </li>
          <li>
            <span>{ t('order.view.date_updated') }<Moment format="YYYY-MM-DD HH:mm">{ order.updatedAt }</Moment></span>
          </li>
          <li>
            <span>{ t('order.view.status') }<strong className={ statusColor }>{
              order.role === 'buyer'
                ? t(`account.order_status_buyer.${order.status}`)
                : t(`account.order_status_seller.${order.status}`)
            }</strong></span>
          </li>
        </ul>
        { actionExplanation }
        { feedback }
        <hr />
        <h3>{ t('order.view.contents') }</h3>
        <ul className="editable">
          { items }
          <li>
            <span>{ t('order.view.shipping_price') }</span>
            <span>{ t('listings.price.price_with_currency_symbol', { price: Math.round(order.shippingPriceJpy ?? 0) }) }</span>
          </li>
          <li>
            <span>{ t('order.view.total_price') }</span><span>{ t('listings.price.price_with_currency_symbol', { price: Math.round(totalPrice) }) }</span>
          </li>
        </ul>
        <h3>{ t('order.view.address.title') }</h3>
        <ul className="organised">
          { country && <li><span>{ t('order.view.address.country') }</span><span>{ t(`account.countries.${country.value}`) }</span></li> }
          { order.address?.zipCode && <li><span>{ t('order.view.address.zip_code') }</span><span>{ order.address.zipCode }</span></li> }
          { order.address?.state && <li><span>{ t('order.view.address.address') }</span><span>{ order.address.state }</span></li> }
          { order.address?.addressLine3 && <li><span>{ !order.address.addressLine1 && !order.address.addressLine2 && t('order.view.address.address') }</span><span>{ order.address.addressLine3 }</span></li> }
          { order.address?.addressLine2 && <li><span>{ !order.address.addressLine1 && t('order.view.address.address') }</span><span>{ order.address.addressLine2 }</span></li> }
          { order.address?.addressLine1 && <li><span></span><span>{ order.address.addressLine1 }</span></li> }
          { order.address?.name && <li><span>{ t('order.view.address.name') }</span><span>{ order.address.name }</span></li> }
          { order.address?.telephone && <li><span>{ t('order.view.address.phone') }</span><span>{ order.address.telephone }</span></li> }
        </ul>
        { shippingMethod }
        { returnsPolicy }
        { updateOrder }
        <hr />
        <OrderMessages orderId={ order.id ?? 0 } orderStatus={ order.status } messages={ order.messages } callback={ (m) => newMessageCallback(m) } role={ order.role ?? '' } />
        { support }
        { feeSummary }
      </div>
    </div>
  )
}

export { Orders }
