const copySuccessMessage = (element) => {
  if (element) {
    document.body.removeChild(element)
  }
  // alert('Copied to clipboard')
  return true
}
const copyFailedMessage = (element) => {
  if (element) {
    document.body.removeChild(element)
  }
  alert('Could not copy. Please try again.')
  return false
}

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')
    if (successful) {
      return copySuccessMessage(textArea)
    } else {
      return copyFailedMessage(textArea)
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Fallback: unable to copy', err)
    return copyFailedMessage(textArea)
  }
}

const copyToClipboard = (text) => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text)
  }

  return navigator.clipboard.writeText(text)
    .then(() => {
      return copySuccessMessage(null)
    }, (err) => {
      // eslint-disable-next-line no-console
      console.error('Async: Could not copy text: ', err)
      return copyFailedMessage(null)
    })
}

export { copyToClipboard }
