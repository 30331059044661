import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import '../stylesheets/Photos.scss'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { uploadToGCS } from '../lib/gcp'
import { Matchv2, MatchList, MatchItem, MatchTag } from '../types'

import { doc, onSnapshot } from 'firebase/firestore'
import { firebase } from '../lib/firebase'
import { SettingsContext } from '../contexts/settings_context'
import { Link } from 'react-router-dom'

import * as tf from '@tensorflow/tfjs'
import * as automl from '@tensorflow/tfjs-automl'

import '../stylesheets/Match.scss'

import { useViewportDimensions } from '../lib/use_viewport_dimensions'
import clsx from 'clsx'
import { ItemCollect } from './item_collect'
import { AuthContext } from '../contexts/auth_context'

const getIsDocumentVisible = () => {
  return !document.hidden
}

const videoFps = 30

const minimumDetectionScore = 0.9

const videoWidth = { min: 640, max: 1280 }
const videoHeight = { min: 320, max: 640 }

const viewportWidthInitial = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
const viewportHeightInitial = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

const orientation = viewportHeightInitial > viewportWidthInitial ? 'portrait' : 'landscape'

const constraints = {
  audio: false,
  video: {
    // width: { ideal: 4096 }, // treated like "exact" in iOS
    // height: { ideal: 2160 },
    width: orientation === 'portrait' ? videoHeight : videoWidth,
    height: orientation === 'portrait' ? videoWidth : videoHeight,
    facingMode: 'environment',
    frameRate: { min: 30, max: 60 },
  },
}

type Detection = Readonly<{
  image: string
  width: number
  height: number
  x: number
  y: number
}>

type AppStreamCamProps = Readonly<{
  selectImagesCallback: (images: string[]) => void
}>

const AppStreamCam = ({ selectImagesCallback }: AppStreamCamProps) => {
  const videoElem = useRef<HTMLVideoElement>(null)
  const cxtRef = useRef<HTMLCanvasElement>(null)

  const [stream, setStream] = useState<MediaStream|undefined>()
  const [detectedCards, setDetectedCards] = useState<Detection[]>([])

  const [model, setModel] = useState<automl.ObjectDetectionModel>()

  const [viewportWidth, viewportHeight] = useViewportDimensions()

  const [supportedDevice, setSupportedDevice] = useState<boolean>(true)

  // prevent video/matching when document is in background
  const [isVisible, setIsVisible] = useState<boolean>(getIsDocumentVisible())
  const onVisibilityChange = () => setIsVisible(getIsDocumentVisible())
  document.addEventListener('visibilitychange', onVisibilityChange)

  const loadModel = async () => {
    const model = await automl.loadObjectDetection('/model/model.json')
    setModel(model)
  }

  useEffect(() => {
    if (!model) {
      tf.ready().then(() => {
        loadModel()
      })
    }
  }, [model])

  useEffect(() => {
    // detect cards
    if (!model) return

    const timerIntervalId2 = setInterval(() => {
      (async () => {
        if (
          cxtRef.current
        ) {
          const frame = cxtRef.current

          const detections = await model.detect(frame)

          const detectedCards: Detection[] = []
          if (detections.length > 0) {
            detections.filter(d => d.score >= minimumDetectionScore).forEach((detection) => {
              if (!cxtRef.current) return

              const left = Math.max(0, detection.box.left)
              const top = Math.max(0, detection.box.top)

              const width = detection.box.width
              const height = detection.box.height

              const tempCanvas = document.createElement('canvas')
              const tempCanvasCtx = tempCanvas.getContext('2d')

              if (!tempCanvasCtx) return

              tempCanvas.width = width
              tempCanvas.height = height

              tempCanvasCtx.drawImage(frame, left * -1, top * -1)

              const img = tempCanvas.toDataURL('image/png')

              const canvasWidth = cxtRef.current.width
              const canvasHeight = cxtRef.current.height

              const d: Detection = {
                image: img,
                width: width / canvasWidth * 100,
                height: height / canvasHeight * 100,
                x: left / canvasWidth * 100,
                y: top / canvasHeight * 100,
              }

              detectedCards.push(d)
            })
          }

          setDetectedCards(detectedCards)
        }
      })()
    }, Math.round(1000 / videoFps))

    return () => {
      clearInterval(timerIntervalId2)
    }
  }, [model])

  useEffect(() => {
    // draw video to canvas
    const timerIntervalId = setInterval(() => {
      (async () => {
        // const returnTensors = !true

        if (
          videoElem.current &&
          videoElem.current.readyState === 4 &&
          cxtRef.current
        ) {
          const video = videoElem.current

          const { videoWidth, videoHeight } = video

          const canvasWidth = cxtRef.current.width
          const canvasHeight = cxtRef.current.height

          const childRatio = videoWidth / videoHeight
          const parentRatio = canvasWidth / canvasHeight

          let width = canvasWidth
          let height = canvasHeight

          if (childRatio < parentRatio) {
            height = width / childRatio
          } else {
            width = height * childRatio
          }

          const offsetX = (canvasWidth - width) * 0.5
          const offsetY = (canvasHeight - height) * 0.5

          const ctx = cxtRef.current.getContext('2d')

          if (!ctx) return

          ctx.drawImage(
            video,
            offsetX,
            offsetY,
            width,
            height,
          )
        }
      })()
    }, Math.round(1000 / videoFps))

    return () => {
      clearInterval(timerIntervalId)
    }
  }, [model])

  useEffect(() => {
    // update canvas size
    if (!cxtRef.current) return

    cxtRef.current.width = viewportWidth
    cxtRef.current.height = viewportHeight
  }, [viewportHeight, viewportWidth])

  const streamCamVideo = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setSupportedDevice(false)
      return
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((mediaStream) => {
        if (videoElem.current) {
          setStream(mediaStream)

          videoElem.current.srcObject = mediaStream
          videoElem.current.onloadedmetadata = () => {
            if (videoElem.current) {
              videoElem.current.play()
            }
          }
        }
      })
      .catch((err) => {
        alert(err.name)
        alert(err.message)
      })
  }

  useEffect(() => {
    return function cleanup () {
      if (!stream) return

      stream.getTracks().forEach((track) => {
        track.stop()
      })
    }
  }, [stream])

  useEffect(() => {
    if (videoElem.current === null) return

    if (!isVisible) {
      // tab is not visible; stop stream
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop()
        })
        setStream(undefined)
      }
    } else {
      // tab is visible; restart stream
      if (!stream) {
        streamCamVideo()
      }
    }
  }, [isVisible, stream])

  const selectAllCards = useCallback(() => {
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop()
      })
      setStream(undefined)
    }

    if (detectedCards.length === 0) return

    return selectImagesCallback([detectedCards[0].image])
  }, [detectedCards, selectImagesCallback, stream])

  const selectCard = useCallback((images) => {
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop()
      })
      setStream(undefined)
    }

    return selectImagesCallback(images)
  }, [selectImagesCallback, stream])

  if (!supportedDevice) {
    return <>
      <h2>Device not supported</h2>
      <p>Sorry, but HOLIC Live Match is not available on your device.</p>
      <p>Please try an alternative device, or upload an image.</p>
    </>
  }

  return (
    <>
      <video ref={ videoElem } autoPlay={ true } muted={ true } playsInline={ true } />
      {
        // imageUrls.map((imageUrl, i) => {
        //   return <img key={ i } src={ imageUrl } />
        // })
      }
      <div className={ clsx('detect-area') }>
        <div className='detections'>
          {
            detectedCards.map((detection, i) => {
              // console.log(detection)

              const borderWidth = Math.max(4, 12 * detection.width / 100)

              return <div
                key={ i } style={ {
                  top: `${detection.y}%`,
                  left: `${detection.x}%`,
                  width: `${detection.width}%`,
                  height: `${detection.height}%`,
                  borderWidth: `${borderWidth}px`,
                  borderRadius: `${borderWidth * 2}px`,
                  marginLeft: `${-borderWidth}px`,
                  marginTop: `${-borderWidth}px`,
                } }
                onClick={ _ => { selectCard([detection.image]) } }
              ></div>
            })
          }
        </div>
        <canvas ref={ cxtRef }></canvas>
      </div>
      <div className='bottom-element'>
        <p>
          <button className='full' onClick={ selectAllCards } disabled={ detectedCards.length === 0 }>
            {
              detectedCards.length > 0
                ? '見つけた！'
                : 'カードはどこかな？'
            }
          </button>
        </p>
      </div>
    </>
  )
}

type UploadForm = Readonly<{
  image: FileList
}>

type ImageAttrsMetadata = Readonly<{
  filename: string
  height: number
  lastModified: number
  mime_type: string
  orientation: number
  size: number
  width: number
}>

type ImageAttrs = Readonly<{
  id: string
  metadata: ImageAttrsMetadata
}>

const validMimeTypes = new Set(['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'])

type ImageUploadFormProps = Readonly<{
  callback: (ImageAttrs) => void
  setFile: (File) => void
}>

const ImageUploadForm = ({ callback, setFile }: ImageUploadFormProps) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
  } = useForm<UploadForm>()

  const [loading, setLoading] = useState<boolean>(false)

  const submitForm = useCallback(async (data: UploadForm) => {
    const im = data.image[0]

    setFile(im)

    if (!im) {
      alert(t('image_upload.err_choose_image'))
      return
    }

    if (!validMimeTypes.has(im.type)) {
      alert(t('image_upload.err_not_image'))
      return
    }

    setLoading(true)

    try {
      const res = await uploadToGCS(im)

      callback(JSON.parse(res.imageData))

      return res
    } catch (error) {
      alert(t('image_upload.err_upload_failed'))
    } finally {
      setLoading(false)
    }
  }, [callback, setFile, t])

  const onSubmit = async (data: UploadForm) => {
    submitForm(data)
  }

  // useEffect(() => {
  //   if (detectedImages.length > 0) {
  //     submitForm()
  //   }
  // }, [detectedImages, submitForm])

  const uploadButton = useMemo(() => {
    if (loading) {
      return (
        <p>
          <button className='small full' disabled>{ t('image_upload.upload_progress') }</button>
        </p>
        // <p>
        //   { t('image_upload.upload_progress') }
        // </p>
      )
    }

    // const uploadText: string = t('image_upload.upload')

    // return (
    //   <p>
    //     <input type='submit' value={ uploadText } className='full' />
    //   </p>
    // )

    return <p></p>
  }, [loading, t])

  return (
    <form onChange={ handleSubmit(onSubmit) }>
      <p>
        { t('image_upload.help') }
      </p>
      <p>
        <input
          { ...register('image') }
          type='file'
          accept='image/*;capture=camera'
        />
      </p>
      { uploadButton }
    </form>

  )
}

const MagicMatch = () => {
  const { t } = useTranslation()

  // const { addError } = useContext(ErrorsContext)

  const isLoading = useState<boolean>(false)[0]
  const currentImage = useState<boolean>()[0]
  const [imageAttrs, setImageAttrs] = useState<ImageAttrs|undefined>()
  const [matchRecord, setMatchRecord] = useState<Matchv2|undefined>()

  const [imagePreviewSrc, setImagePreviewSrc] = useState<string|undefined>()
  const [uploadMethod, setUploadMethod] = useState<string>('')
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [totalSteps, setTotalSteps] = useState<number>(0)

  const { setMinimalNavigation, setNavigation } = useContext(SettingsContext)
  const { currentUser } = useContext(AuthContext)

  useEffect(() => {
    if (!setMinimalNavigation) return

    setMinimalNavigation(true)

    return () => {
      setMinimalNavigation(false)
    }
  }, [setMinimalNavigation])

  useEffect(() => {
    if (!setNavigation || currentStep < 1 || totalSteps < 1) return

    setNavigation({
      style: 'steps',
      totalSteps,
      currentStep,
      complete: false,
    })
  }, [currentStep, setNavigation, totalSteps])

  useEffect(() => {
    setCurrentStep(uploadMethod ? 1 : 0)

    if (matchRecord) {
      setCurrentStep(2)
    }

    if (uploadMethod === 'magic') {
      setTotalSteps(4)
    } else if (uploadMethod === 'file') {
      setTotalSteps(4)
    } else {
      setTotalSteps(0)
    }
  }, [matchRecord, uploadMethod])

  const uploadImagesCallback = useCallback((attrs) => {
    setImageAttrs(attrs)
  }, [])

  useEffect(() => {
    if (!imageAttrs) return

    const imageRef = doc(firebase, 'imagedata', imageAttrs.id)

    // TODO: Close this listener
    const unsubscribe = onSnapshot(imageRef, (snapshot) => {
      const d = snapshot.data()

      if (d) {
        let matches: MatchList[]

        let stage = 2
        if ('stage' in d) {
          stage = d.stage
        }

        if ('matches' in d) {
          matches = d.matches.map(m => {
            const match: MatchItem[] = m.match.map(m2 => {
              const tags = m2.tags.map(t => {
                const matchItem: MatchTag = {
                  id: t.id,
                  nameEn: t.name_en,
                  nameJa: t.name_ja,
                  depth: t.depth,
                }
                return matchItem
              })

              const matchItem: MatchItem = {
                tags: tags,
                id: m2.id,
                image: m2.image,
                nameEn: m2.name_en,
                nameJa: m2.name_ja,
              }
              return matchItem
            })

            const matchList: MatchList = {
              match: match,
            }
            return matchList
          })

          const r: Matchv2 = {
            complete: d.complete,
            matches,
            stage,
          }
          setMatchRecord(r)
        } else {
          const r: Matchv2 = {
            complete: d.complete,
            stage,
          }
          setMatchRecord(r)
        }
      }
    })

    return () => {
      unsubscribe()
    }
  }, [imageAttrs])

  const setImagePreview = useCallback((file: File) => {
    // console.log('preview:')
    // console.log(file)
    // console.log(typeof file)
    // console.log(URL.createObjectURL(file))

    setImagePreviewSrc(URL.createObjectURL(file))

    // var reader = new FileReader()
    // reader.onload = () => {
    //   var output = document.getElementById('output')
    //   output.src = reader.result;
    // }

    // reader.readAsDataURL(event.target.files[0])
  }, [])

  const [detectedImages, setDetectedImages] = useState<string[]>([])

  const setLoading = useState<boolean>(false)[1]

  const selectImages = useCallback((images: string[]) => {
    setDetectedImages(images)
  }, [])

  const videoElement = useMemo(() => {
    return <AppStreamCam selectImagesCallback={ selectImages } />
  }, [selectImages])

  const submitForm = useCallback(async () => {
    const blob = await (await fetch(detectedImages[0])).blob()
    const file = new File([blob], 'detected.png', { type: 'image/png' })
    const im = file

    setImagePreview(im)

    if (!im) {
      alert(t('image_upload.err_choose_image'))
      return
    }

    if (!validMimeTypes.has(im.type)) {
      alert(t('image_upload.err_not_image'))
      return
    }

    setLoading(true)

    try {
      const res = await uploadToGCS(im)

      uploadImagesCallback(JSON.parse(res.imageData))

      return res
    } catch (error) {
      alert(t('image_upload.err_upload_failed'))
    } finally {
      setLoading(false)
    }
  }, [detectedImages, setImagePreview, setLoading, t, uploadImagesCallback])

  useEffect(() => {
    if (detectedImages.length > 0) {
      submitForm()
    }
  }, [detectedImages, submitForm])

  const [selectedImage, setSelectedImage] = useState<MatchList>()
  const [selectedMatchItem, setSelectedMatchItem] = useState<MatchItem>()
  const [selectedMatchItemTag, setSelectedMatchItemTag] = useState<MatchTag>()

  useEffect(() => {
    if (!matchRecord?.matches || matchRecord.matches.length < 1) return

    if (matchRecord.matches && matchRecord.matches.length > 0) {
      setSelectedImage(matchRecord.matches[0])
    }
  }, [matchRecord?.matches, selectedImage])

  const setMatch = useCallback((match: MatchList) => {
    setSelectedImage(match)
  }, [])

  useEffect(() => {
    if (!selectedImage) {
      setSelectedMatchItem(undefined)
      return
    }

    setSelectedMatchItem(selectedImage.match.length > 0 ? selectedImage.match[0] : undefined)
  }, [selectedImage])

  useEffect(() => {
    if (!selectedMatchItem) {
      setSelectedMatchItemTag(undefined)
      return
    }

    const series = selectedMatchItem.tags.length > 0 ? selectedMatchItem.tags.reduce((prev, next) => prev.depth > next.depth ? prev : next) : undefined

    setSelectedMatchItemTag(series)
  }, [selectedMatchItem])

  const navigatePotentialMatches = useCallback((direction: number) => {
    if (!selectedImage) return

    const index = selectedImage.match.findIndex(m => m.id === selectedMatchItem?.id)

    let newIndex = index + direction
    if (direction === -1 && index === 0) {
      // select last item
      newIndex = selectedImage.match.length
    } else if (direction === 1 && index === selectedImage.match.length - 1) {
      // select first item
      newIndex = 0
    }

    setSelectedMatchItem(selectedImage.match[newIndex])
  }, [selectedImage, selectedMatchItem?.id])

  const resetMatch = useCallback(() => {
    setSelectedImage(undefined)
    setMatchRecord(undefined)
    setImageAttrs(undefined)
    setUploadMethod('')
  }, [])

  const [unitId, setUnitId] = useState<number>()

  const addRemoveCallbackItem = useCallback((params) => {
    if (params.id) {
      setUnitId(params.id)
      alert('このカードはマイデッキに保存されました')
      return
    }

    alert('error')
  }, [])

  const noMatch = useMemo(() => {
    return <div className='full-height'>
      <div className='my-images'>
        <img className='preview' src={ imagePreviewSrc } />
      </div>
      <p>Could not find any cards.</p>
      <p>Please try again with another image.</p>
      <div className='bottom-element'>
        <p>
          <button className='full white' onClick={ resetMatch }>戻る</button>
        </p>
      </div>
    </div>
  }, [imagePreviewSrc, resetMatch])

  if (selectedImage && matchRecord) {
    if (
      matchRecord.stage === 3 ||
      !(matchRecord.matches && matchRecord.matches.length > 0)
    ) {
      return <>{ noMatch }</>
    }

    if (matchRecord.stage >= 2 && matchRecord.matches && matchRecord.matches.length > 0) {
      if (matchRecord.matches.length < 1 && matchRecord.stage === 10) {
        return <div className='full-height'>
          <div className='my-images'>
            <img className='preview' src={ imagePreviewSrc } />
          </div>
          <p>Could not find a match for your card.</p>
          <p>Please try again.</p>
          <div className='bottom-element'>
            <p>
              <button className='full' onClick={ resetMatch }>Try again</button>
            </p>
          </div>
        </div>
      }

      return <div className='full-height'>
        <div className='match-area'>
          <div className='my-images'>
            {
              matchRecord.matches.map((match, i) => {
                return <img key={ i } className='preview' src={ imagePreviewSrc } onClick={ () => { setMatch(match) } } />
              })
            }
          </div>
          <div className='match'>
            {
              !selectedMatchItem
                ? <p>Matching...</p>
                : <div className='card'>
                  {
                    selectedMatchItem.image !== ''
                      ? <img src={ selectedMatchItem.image } />
                      : <span>{ selectedMatchItem.nameJa }</span>
                  }
                </div>
            }
          </div>
        </div>
        {
          !selectedMatchItem
            ? null
            : <div className='match-title'>
              {
                selectedImage.match.length === 1
                  ? null
                  : <button className='tool' onClick={ _ => { navigatePotentialMatches(-1) } }>←</button>
              }
              <strong>{ selectedMatchItem.nameJa }</strong>
              {
                selectedMatchItemTag
                  // ? <Link to={ `/categories/${selectedMatchItemTag.id}` }>{ selectedMatchItemTag.nameJa }</Link>
                  ? <span>{ selectedMatchItemTag.nameJa }</span>
                  : null
              }
              {
                selectedImage.match.length === 1
                  ? null
                  : <button className='tool' onClick={ _ => { navigatePotentialMatches(1) } }>→</button>
              }
            </div>
        }
        <div className='price-data'>
          <p>Price data coming soon!</p>
        </div>
        <div className='bottom-element'>
          <p>
            {
              unitId && currentUser
                ? <Link className='button full' to={ `/u/${currentUser.id}/units/${unitId}` }>カードを売る</Link>
                : <button className='full' onClick={ _ => { setUploadMethod('') } } disabled>カードを売る</button>
            }
          </p>
          <p>
            <button className='white' onClick={ resetMatch }>スキップ</button>
            {
              selectedMatchItem
                ? <ItemCollect collectCallback={ addRemoveCallbackItem } units={ [] } matchItem={ selectedMatchItem } defaultCondition={ 0 } buttonText={ unitId ? '保存済' : '保存' } disabled={ !!unitId } />
                : null
            }
          </p>
        </div>
      </div>
    }

    return <div className='full-height'>
      <img src={ imagePreviewSrc } />
      <h2>HOLICマッチ中</h2>
      <p>カードを見つけています…</p>
    </div>
  }

  if (imageAttrs) {
    // image upload was successful; get the database record
    return <div className='full-height'>
      <img src={ imagePreviewSrc } />
      <h2>アップロード中</h2>
      <p>写真がアップロードされるまで<br />お待ちください。</p>
    </div>
  }

  if (uploadMethod === 'magic') {
    return <>
      <div className='full-height'>
        { videoElement }
        <div className='bottom-element'>
          <p>
            <button className='full white' onClick={ _ => { setUploadMethod('') } }>戻る</button>
          </p>
        </div>
      </div>
    </>
  } else if (uploadMethod === 'demo') {
    return <div className='full-height'>
      <h1>HOLIC Snap</h1>
      <h2>デモビデオ</h2>
      <p>デモビデオ準備中。</p>
      <div className='bottom-element'>
        <p>
          <button className='full white' onClick={ _ => { setUploadMethod('') } }>戻る</button>
        </p>
      </div>
    </div>
  } else if (uploadMethod === 'file') {
    return (
      <div className='full-height'>
        {
          isLoading
            ? <p>Uploading, please wait...</p>
            : currentImage
              ? (
                <>
                  <PhotoIndividual image={ currentImage } />
                  <hr />
                </>
                )
              : null
        }
        <ImageUploadForm
          callback={ uploadImagesCallback }
          setFile={ setImagePreview }
        />
        <div className='bottom-element'>
          <p>
            <button className='full white' onClick={ _ => { setUploadMethod('') } }>戻る</button>
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className='full-height'>
      <h1>Ready? Snap!</h1>
      <p>
        これはどのカード？値段はどれくらい？<br />
        カードにカメラを合わせたら、<br />
        あとはHOLICに全部おまかせ！
      </p>
      <div className='bottom-element'>
        <p>
          <button className='full white' onClick={ _ => { setUploadMethod('demo') } }>デモビデオを見る</button>
        </p>
        <p>
          <button className='full white' onClick={ _ => { setUploadMethod('file') } }>画像をアップロードする</button>
        </p>
        <p>
          <button className='full' onClick={ _ => { setUploadMethod('magic') } }>ライブマッチ</button>
        </p>
      </div>
    </div>
  )
}

type PhotoIndividualProps = Readonly<{
  image: boolean
}>

const PhotoIndividual = ({ image }: PhotoIndividualProps) => {
  return <>
    { image }
  </>
}

export { MagicMatch }
