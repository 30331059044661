import { CancelToken } from 'axios'
import { axiosInstance } from '../lib/api_client'
import { Item, Pagination, Unit } from '../types'

const PATH = '/items'

type IndexParams = Readonly<{
  query?: string
  itemIds?: number[]
  types?: string[]
  platform?: string
  tagGroup1Ids?: number[]
  tagGroup2Ids?: number[]
  tagGroup3Ids?: number[]
  tagGroup4Ids?: number[]
  game?: number[]
  conditions?: number[]
  sold?: string
  minPrice?: number
  maxPrice?: number
  userId?: number
  limit?: number
  page: number
  sort?: string
}>

type IndexReponse = Readonly<{
  items: Item[]
  pagination: Pagination
}>

type UnitsReponse = Readonly<{
  listings: Unit[]
}>

type GetReponse = Readonly<{
  item: Item
}>

type CreateReponse = Readonly<{
  item: Item
}>

type UpdateParams = Readonly<{
  id: number
  params: Item
}>

type ItemsRepository = Readonly<{
  index: (params: IndexParams, cancelToken?: CancelToken) => Promise<IndexReponse>
  get: (id: number) => Promise<GetReponse>
  get_for_edit: (id: number) => Promise<GetReponse>
  units: (id: number, platform: string) => Promise<UnitsReponse>
  create: (params: Item) => Promise<CreateReponse>
  update: (params: UpdateParams) => Promise<void>
}>

const itemsRepository: ItemsRepository = {
  async index (params: IndexParams, cancelToken?: CancelToken) {
    return await axiosInstance.get(PATH, { params, cancelToken }).then(({ data }) => data)
  },
  async get (id) {
    return await axiosInstance.get(`${PATH}/${id}`).then(({ data }) => data)
  },
  async get_for_edit (id) {
    return await axiosInstance.get(`${PATH}/${id}/admin`).then(({ data }) => data)
  },
  async units (id, platform) {
    return await axiosInstance.get(`${PATH}/${id}/units?platform=${platform}`).then(({ data }) => data)
  },
  async create (params) {
    return await axiosInstance.post(PATH, params).then(({ data }) => data)
  },
  async update ({ params, id }) {
    return await axiosInstance.put(`${PATH}/${id}`, params).then()
  },
}

export { itemsRepository }
