import React, { useEffect, useState, useCallback, useMemo, useLayoutEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'

import { Filters, Tag } from '../types'

import '../stylesheets/Sets.scss'
import clsx from 'clsx'
import moment from 'moment'
import { SearchContext } from '../contexts/search_context'

const isTouchDevice = ('ontouchstart' in window) ||
  (navigator.maxTouchPoints > 0) ||
  ((navigator as any).msMaxTouchPoints > 0)

const swiperBreakpoints = {
  1235: {
    slidesPerView: 6.5,
    slidesPerGroup: 5,
    max: 99999,
  },
  975: {
    slidesPerView: 5.33,
    slidesPerGroup: 4,
    max: 1234,
  },
  735: {
    slidesPerView: 4.33,
    slidesPerGroup: 3,
    max: 974,
  },
  535: {
    slidesPerView: 3.25,
    slidesPerGroup: 3,
    max: 734,
  },
  0: {
    slidesPerView: 2.25,
    slidesPerGroup: 2,
    max: 534,
  }
}

const swiperFreeModeOptions = {
  enabled: false,
  // sticky: false,
}

type SetProps = Readonly<{
  tag: Tag
  cleanLinks: boolean
}>

const Set = ({ tag, cleanLinks }: SetProps): JSX.Element => {
  const { i18n } = useTranslation()

  let title = tag.nameEn
  if (i18n.language === 'ja' && (tag.nameJaShort ?? tag.nameJa)) {
    title = tag.nameJaShort ?? tag.nameJa
  } else if (tag.nameEnShort) {
    title = tag.nameEnShort
  }

  let date = <></>
  let setComingSoon = false
  let setNew = false

  if (tag.releaseDate) {
    const releaseDate = moment(tag.releaseDate)

    setComingSoon = releaseDate.isAfter()
    setNew = releaseDate.add(1, 'months').isAfter() && !setComingSoon

    const setLast1Year = releaseDate.add(1, 'year').isAfter()
    const setCurrentYear = releaseDate.startOf('year').isAfter()

    if (setCurrentYear) {
      date = <span className="small"><Moment format="M月D日">{ tag.releaseDate }</Moment></span>
    } else if (setLast1Year) {
      date = <span className="small"><Moment format="YYYY年M月">{ tag.releaseDate }</Moment></span>
    } else {
      date = <span className="small"><Moment format="YYYY年">{ tag.releaseDate }</Moment></span>
    }
  }

  const { categoriesLink, endpointForExistingFilters, filters } = useContext(SearchContext)

  let categoryLink = categoriesLink?.(String(tag.id)) ?? ''

  if (cleanLinks) {
    const path = `/categories/${String(tag.id)}`

    const f: Filters = {
      ...filters,
      selectedView: 'items',
    }

    const newUrl = endpointForExistingFilters?.(f, '') ?? ''

    categoryLink = path + newUrl
  }

  return (
    <div key={ tag.id } className={ clsx(`set${tag.id}`, 'set', { soon: setComingSoon }, { new: setNew }) } style={ { backgroundImage: tag.images[0]?.thumb2x && `url(${tag.images[0]?.thumb2x})` } }>
      <Link to={ categoryLink }>
        <p>{ date }<span>{ title }</span></p>
      </Link>
    </div>
  )
}

type Props = Readonly<{
  carousel: boolean
  tags: Tag[]
  cleanLinks?: boolean
}>

const SetsList = ({ carousel, tags, cleanLinks }: Props): JSX.Element => {
  const [viewportWidth, setViewportWidth] = useState<number>(window.innerWidth)

  useLayoutEffect(() => {
    const updateSize = () => {
      setViewportWidth(window.innerWidth)
    }

    window.addEventListener('resize', updateSize)

    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const currentSliderView = useMemo(() => {
    const currentViewKey = Object.keys(swiperBreakpoints).find((r) => {
      return viewportWidth >= parseInt(r) && viewportWidth <= swiperBreakpoints[r].max
    })

    return currentViewKey ? swiperBreakpoints[currentViewKey] : swiperBreakpoints[0]
  }, [viewportWidth])

  const setsList = useMemo(() => {
    let tagsForSlider: Tag[][] = []

    if (!carousel) {
      return tags.map((s) => <div key={ s.id } className='set-outer'><Set tag={ s } cleanLinks={ cleanLinks ?? false } /></div>)
    }

    if (tags.length > currentSliderView.slidesPerGroup * 2) {
      // 1 3 5 7 9 1
      // 2 4 6 8 0 2
      tagsForSlider = tags.reduce((accumulator: Tag[][], _, currentIndex, thisArray) => {
        if (currentIndex % 2 === 0) {
          accumulator.push(thisArray.slice(currentIndex, currentIndex + 2))
        }
        return accumulator
      }, [])
    } else {
      // 1 2 3 4 5 6
      // 7 8 9 0 1 2
      const currentViewItems: number = currentSliderView.slidesPerGroup

      tagsForSlider = tags.reduce((accumulator: Tag[][], currentValue, currentIndex, thisArray) => {
        if (currentIndex < currentViewItems) {
          accumulator.push([currentValue, thisArray?.[currentIndex + currentViewItems]])
        }
        return accumulator
      }, [])
    }

    return tagsForSlider.map((c, index) => <SwiperSlide className='sets-column' key={ index }>
      { c.map((s) => s ? <Set key={ s.id } tag={ s } cleanLinks={ cleanLinks ?? false } /> : null) }
    </SwiperSlide>)
  }, [carousel, cleanLinks, currentSliderView.slidesPerGroup, tags])

  const [sliderLeftFadeVisible, setSliderLeftFadeVisible] = useState<boolean>(false)
  const [sliderRightFadeVisible, setSliderRightFadeVisible] = useState<boolean>(true)

  useEffect(() => {
    setSliderLeftFadeVisible(false)
    setSliderRightFadeVisible(true)
  }, [tags])

  const sliderTransitionStart = useCallback((swiper) => {
    setSliderLeftFadeVisible(true)
    setSliderRightFadeVisible(true)

    if (swiper.isBeginning) {
      setSliderLeftFadeVisible(false)
      return
    }

    if (swiper.isEnd) {
      setSliderRightFadeVisible(false)
    }
  }, [])

  if (setsList.length > currentSliderView.slidesPerGroup && carousel) {
    // needs slider
    return <>
      <Swiper
        className={ clsx({ leftFadeVisible: sliderLeftFadeVisible }, { rightFadeVisible: sliderRightFadeVisible }, { touchDevice: isTouchDevice }) }
        modules={ [Navigation] }
        spaceBetween={ 4 }
        breakpoints={ swiperBreakpoints }
        navigation={ !isTouchDevice }
        onTransitionStart={ sliderTransitionStart }
        simulateTouch={ false }
        freeMode={ swiperFreeModeOptions }
      >
        { setsList }
      </Swiper>
    </>
  }

  return <div className={ clsx('sets-list') }>
    { setsList }
  </div>
}

export { SetsList }
