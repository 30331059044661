import { axiosInstance } from '../lib/api_client'

type CreateParams = Readonly<{
  imageData: string
  detect: boolean
}>

type CreateResponse = Readonly<{
  id: number
  imageUrl: string
}>

type UnitImageRepository = Readonly<{
  create: (unitId: number, params: CreateParams) => Promise<CreateResponse>
  delete: (unitId: number, unitImageId: number) => Promise<void>
}>

const unitImagesRepository: UnitImageRepository = {
  async create (unitId, params) {
    return await axiosInstance.post(`/units/${unitId}/unit_images`, params).then(({ data }) => data)
  },
  async delete (unitId, unitImageId) {
    return await axiosInstance.delete(`/units/${unitId}/unit_images/${unitImageId}`)
  },
}

export { unitImagesRepository }
