import { axiosInstance } from '../lib/api_client'
import { RegionShippingService, RegionShippingServiceForm } from '../types'

const PATH = '/region_shipping_services'

type IndexReponse = Readonly<{
  regionShippingServices: RegionShippingService[]
}>

type UpdateParams = Readonly<{
  id: number
  params: RegionShippingServiceForm
}>

type CreateReponse = Readonly<{
  regionShippingService: RegionShippingService
}>

type RegionShippingServicesRepository = Readonly<{
  index: () => Promise<IndexReponse>
  indexForUser: (id: number) => Promise<IndexReponse>
  create: (params: RegionShippingServiceForm) => Promise<CreateReponse>
  update: (params: UpdateParams) => Promise<void>
}>

const regionShippingServicesRepository: RegionShippingServicesRepository = {
  async index () {
    return await axiosInstance.get(PATH).then(({ data }) => data)
  },
  async indexForUser (id) {
    return await axiosInstance.get(`/users/${id}/region_shipping_services`).then(({ data }) => data)
  },
  async create (params) {
    return await axiosInstance.post(PATH, params).then(({ data }) => data)
  },
  async update ({ params, id }) {
    return await axiosInstance.put(`${PATH}/${id}`, params).then()
  },
}

export { regionShippingServicesRepository }
