import axios, { AxiosError, CancelTokenSource } from 'axios'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'

import { usersRepository } from '../repositories/users_repository'
import { Filters, Pagination, Unit, User } from '../types'

import '../stylesheets/CardsList.scss'
import '../stylesheets/Collections.scss'
import { ErrorsContext } from '../contexts/errors_context'
import { AuthContext } from '../contexts/auth_context'
import { unitsRepository } from '../repositories/units_repository'
import { UnitView } from './unit'
import { CardsLoading } from './cards_loading'
import { Register } from './register'
import { GenericNotFound } from './generic_not_found'
import clsx from 'clsx'
import { ListingSingle } from './listing_single'
import { SearchContext } from '../contexts/search_context'

import Draggabilly from 'draggabilly'
import Packery from 'packery'
import { useViewportDimensions } from '../lib/use_viewport_dimensions'

const defaultCardsLoading = 8
const defaultPageLimit = 40

const breakpoints = [
  { width: 1280, unitsPerRow: 8, margin: 8 },
  { width: 960, unitsPerRow: 6, margin: 8 },
  { width: 720, unitsPerRow: 5, margin: 5 },
  { width: 600, unitsPerRow: 4, margin: 5 },
  { width: 500, unitsPerRow: 4, margin: 3 },
  { width: 351, unitsPerRow: 3, margin: 3 },
  { width: 0, unitsPerRow: 3, margin: 2 },
]

type Props = Readonly<{
  userId: string
  user?: User
  slug?: string
  unitId?: number
  sort?: string
  organising?: boolean
  setOrganising?: (organising: boolean) => void
}>

const Units = ({ userId, user, unitId, sort, organising, setOrganising }: Props) => {
  const { addError } = useContext(ErrorsContext)
  const { isAuthenticated } = useContext(AuthContext)

  const { t } = useTranslation()

  const [unitsLoading, setUnitsLoading] = useState<boolean>(false)

  // pagination
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
  })

  const [thisUser, setThisUser] = useState<User|undefined>()

  const dynamicCardsLoading = useMemo(() => {
    if (pagination.totalPages + 1 > pagination.currentPage) {
      return defaultPageLimit
    }

    return defaultCardsLoading
  }, [pagination.currentPage, pagination.totalPages])

  useEffect(() => {
    if (!userId || isNaN(+userId)) return

    if (user) {
      setThisUser(user)
    }

    usersRepository
      .getUser(parseInt(userId))
      .then(({ user }) => {
        setThisUser(user)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
  }, [addError, user, userId])

  const [units, setUnits] = useState<Unit[]>([])
  // const [oldAxiosCancelToken, setOldAxiosCancelToken] = useState<CancelTokenSource>()
  const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource>()

  // useEffect(() => {
  //   if (oldAxiosCancelToken && oldAxiosCancelToken !== axiosCancelToken) {
  //     console.log('cancel axios cancel token')
  //     const t = oldAxiosCancelToken
  //     t.cancel()
  //     setOldAxiosCancelToken(axiosCancelToken)
  //   }

  //   if (!oldAxiosCancelToken) {
  //     setOldAxiosCancelToken(axiosCancelToken)
  //   }
  // }, [axiosCancelToken, oldAxiosCancelToken])

  const { filters, searchString, filterMenuDisplayed, endpointForExistingFilters } = useContext(SearchContext)

  const [lastEndpoint, setLastEndpoint] = useState<string>('')

  const getListings = useCallback((page = 1, reset: boolean) => {
    if (unitId ?? !userId) return

    if (userId === 'my' && !isAuthenticated) return

    const params = {
      page,
      limit: defaultPageLimit,
      sort,
      types: [],
      query: searchString,
      tagGroup1Ids: filters.selectedCategories.map(t => t.id),
      tagGroup2Ids: filters.selectedCardTypes,
      tagGroup3Ids: filters.selectedEnergyTypes,
      tagGroup4Ids: filters.selectedRarities,
      game: filters.selectedGame ? [filters.selectedGame] : undefined,
      sold: filters.selectedStocks.length === 1 ? filters.selectedStocks[0] : undefined,
      minPrice: filters.priceRange.min,
      maxPrice: filters.priceRange.max,
      conditions: filters.selectedConditions,
      userId: filters.selectedUserId,
    }

    const endpointString = JSON.stringify(params)

    if (endpointString === lastEndpoint) return

    setLastEndpoint(endpointString)

    setUnitsLoading(true)

    if (axiosCancelToken) {
      axiosCancelToken.cancel()
    }

    const newAxiosCancelToken = axios.CancelToken.source()
    setAxiosCancelToken(newAxiosCancelToken)

    if (reset) {
      setUnits([])
      setPagination({
        currentPage: 1,
        totalPages: 1,
      })
    }

    unitsRepository
      .indexForUser({
        params,
        grouped: false,
        userId,
      }, newAxiosCancelToken.token)
      .then(({ listings, pagination: p }) => {
        if (reset) {
          setUnits(listings)
        } else {
          setUnits((prev) => prev.concat(...listings))
        }

        setPagination(p)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setUnitsLoading(false)
      })
  }, [unitId, userId, isAuthenticated, sort, searchString, filters.selectedCategories, filters.selectedCardTypes, filters.selectedEnergyTypes, filters.selectedRarities, filters.selectedGame, filters.selectedStocks, filters.priceRange.min, filters.priceRange.max, filters.selectedConditions, filters.selectedUserId, lastEndpoint, axiosCancelToken, addError])

  const [initialFilters, setInitialFilters] = useState<Filters>(filters)
  const [initialSearchString, setInitialSearchString] = useState<string>(searchString)
  const filtersChanged = useMemo(() => JSON.stringify([initialFilters, initialSearchString]) !== JSON.stringify([filters, searchString]), [filters, initialFilters, initialSearchString, searchString])

  useEffect(() => {
    if (!filterMenuDisplayed && filtersChanged) {
      setInitialFilters(filters)
      setInitialSearchString(searchString)

      getListings(1, true)
    }
  }, [filterMenuDisplayed, filters, filtersChanged, getListings, searchString])

  const [prevSort, setPrevSort] = useState<string>('')

  useEffect(() => {
    if (!sort || prevSort === sort) return

    setPrevSort(sort)

    getListings(1, true)
  }, [sort, prevSort, getListings])

  const noCollection = useMemo(() => {
    if (!userId) return false
  }, [userId])

  const categoriesLink = useMemo(() => {
    if (!endpointForExistingFilters) return ''

    const p = '/categories'

    const f: Filters = {
      ...filters,
      selectedView: 'items',
    }

    const params = endpointForExistingFilters(f, searchString)

    return p + params
  }, [endpointForExistingFilters, filters, searchString])

  const noCards = useMemo(() => {
    if (!categoriesLink) return

    return (
      <div>
        <p>
          <strong>{ t('collections.empty.title') }</strong>
        </p>
        <p>{ t('collections.empty.body1') }</p>
        <p>{ t('collections.empty.body2') }</p>
        <p>
          <Link to={ categoriesLink } className="button">{ t('collections.empty.cta') }</Link>
        </p>
      </div>
    )
  }, [categoriesLink, t])

  const hasMoreUnits = useMemo(() => pagination.totalPages > pagination.currentPage, [pagination.currentPage, pagination.totalPages])

  const handleLoadMore = useCallback((page) => {
    if (unitsLoading) {
      return
    }

    getListings(page, false)
  }, [getListings, unitsLoading])

  useEffect(() => {
    // initial load
    if (units.length > 0 || unitsLoading) return

    getListings(1, true)
  }, [getListings, units.length, unitsLoading])

  const containerRef = useRef<Element|null>(null)
  const packeryRef = useRef<Packery|null>(null)

  useEffect(() => {
    if (containerRef.current) return

    const l = document.querySelector('.listings')

    if (!l) return

    containerRef.current = l

    return () => {
      containerRef.current = null
    }
  }, [])

  const orderPackeryElementsByPosition = useCallback((packeryElements: Element[]) => {
    return packeryElements.sort((a, b) => {
      const aRect = a.getBoundingClientRect()
      const bRect = b.getBoundingClientRect()

      if (aRect.top < bRect.top) return -1

      if (aRect.top > bRect.top) return 1

      if (aRect.left < bRect.left) return -1

      if (aRect.left > bRect.left) return 1

      return 0
    })
  }, [])

  const [dragging, setDragging] = useState<boolean>(false)
  const [wantsToReorder, setWantsToReorder] = useState<boolean>(false)

  useEffect(() => {
    if (!packeryRef.current) return

    if (dragging) return

    if (!wantsToReorder) return

    const packeryElements = packeryRef.current.getUnitElements()

    const orderedPackeryElements = orderPackeryElementsByPosition(packeryElements)

    const newUnits = units.map((unit) => {
      const index = orderedPackeryElements.findIndex((el) => {
        const id = el.id

        if (!id) {
          return null
        }

        return Number(id) === unit.id
      })

      if (index === -1) return unit

      return {
        ...unit,
        units: [
          {
            ...unit,
            ordinality: index,
          },
        ],
      }
    })

    setUnits(newUnits)

    setWantsToReorder(false)
  }, [dragging, units, orderPackeryElementsByPosition, wantsToReorder])

  const viewportWidth = useViewportDimensions()[0]

  const currentBreakpoint = useMemo(() => {
    return breakpoints.find(b => viewportWidth >= b.width) ?? breakpoints[0]
  }, [viewportWidth])

  useEffect(() => {
    if (!organising) return

    if (!containerRef.current) return
    if (packeryRef.current) return

    // get size of container
    const containerWidth = containerRef.current.getBoundingClientRect().width

    // set width of listing elements
    // const width = Math.floor((containerWidth - (currentBreakpoint.unitsPerRow - 1) * currentBreakpoint.margin) / currentBreakpoint.unitsPerRow) - 2 // consideration for borders (maybe)

    const width = containerWidth / currentBreakpoint.unitsPerRow - currentBreakpoint.margin

    const margin = Math.floor((containerWidth - (width * currentBreakpoint.unitsPerRow)) / (currentBreakpoint.unitsPerRow - 1))

    containerRef.current.querySelectorAll('.listing').forEach((listingElement: Element) => {
      // Assert that listingElement is non-nullable and has the style property
      const el = (listingElement as HTMLElement)

      if (!el) return

      el.style.width = `${width}px`
    })

    // initialise Packery
    const pckry = new Packery(containerRef.current, {
      itemSelector: '.listing',
      columnWidth: width,
      gutter: margin,
    })

    packeryRef.current = pckry

    const draggies: Draggabilly[] = []

    pckry.getUnitElements().forEach(unitElem => {
      const draggie = new Draggabilly(unitElem, {
        containment: true,
      })
      pckry.bindDraggabillyEvents(draggie)

      draggies.push(draggie)

      draggie.on('dragStart', () => {
        setDragging(true)
      })
    })

    pckry.on('dragItemPositioned', () => {
      pckry.layout()

      setDragging(false)
    })

    pckry.on('layoutComplete', () => {
      setWantsToReorder(true)
    })

    return () => {
      draggies.forEach(draggie => {
        draggie.destroy()
      })

      pckry.destroy()

      packeryRef.current = null
    }
  }, [currentBreakpoint.unitsPerRow, currentBreakpoint.margin, orderPackeryElementsByPosition, organising, viewportWidth])

  // useEffect(() => {
  //   if (!packeryRef) return

  //   const draggableElems = document.querySelectorAll('.listing')

  //   const draggiesFromElems = Array.from(draggableElems).map((el) => {
  //     return new Draggabilly(el, {
  //       containment: true,
  //     })
  //   })

  //   draggiesFromElems.forEach((d) => {
  //     console.log('yo!')
  //     packeryRef.bindDraggabillyEvents(d)
  //   })

  //   setDraggies(draggiesFromElems)

  //   packeryRef.layout()

  //   return () => {
  //     console.log('unbind')
  //     draggiesFromElems.forEach((d) => {
  //       packeryRef.unbindDraggabillyEvents(d)
  //       d.destroy()
  //     })

  //     packeryRef.destroy()

  //     setDraggies([])
  //   }
  // }, [items.length, packery])

  // useEffect(() => {
  //   if (!packery) return

  //   console.log('hello')

  //   packery.getUnitElements().forEach(itemElem => {
  //     console.log('hello 2')
  //     const draggie = new Draggabilly(itemElem)
  //     packery.bindDraggabillyEvents(draggie)
  //   })

  //   return () => {
  //     packery.getUnitElements().forEach(itemElem => {
  //       const draggie = new Draggabilly(itemElem)
  //       packery.unbindDraggabillyEvents(draggie)
  //     })
  //   }
  // }, [packery, unitsList])

  // useEffect(() => {
  //   if (!packery) return
  //   if (draggies.length === 0) return

  //   draggies.forEach((d) => {
  //     packery.bindDraggabillyEvents(d)
  //   })

  //   return () => {
  //     draggies.forEach((d) => {
  //       packery.unbindDraggabillyEvents(d)
  //     })
  //   }
  // }, [draggies, packery])

  const [unitsSorted, setUnitsSorted] = useState<Unit[]>([])

  const reSortUnits = useCallback(() => {
    if (!units) return null

    // sort by unit's position
    const unitsSorted = units.sort((a, b) => {
      if (!a) return 1
      if (!b) return -1

      const aPosition = a.ordinality
      const bPosition = b.ordinality

      if (aPosition === undefined) return 1
      if (bPosition === undefined) return -1

      return aPosition - bPosition
    })

    setUnitsSorted(unitsSorted)
  }, [units])

  useEffect(() => {
    reSortUnits()
  }, [reSortUnits, units])

  useEffect(() => {
    if (!organising) {
      // remove inline styles from items
      if (!containerRef.current) return

      containerRef.current.querySelectorAll('.listing').forEach((listingElement: Element) => {
        // Assert that listingElement is non-nullable and has the style property
        const el = (listingElement as HTMLElement)

        if (!el) return

        el.removeAttribute('style')
      })
    }
  }, [organising])

  const unitsList = useMemo(() => {
    return unitsSorted?.map((unit) => {
      if (!unit) return null

      return <ListingSingle key={ unit.id } editing={ !!organising } items={ unit.items ?? [] } unit={ unit } />
    })
  }, [unitsSorted, organising])

  const cardsList = useMemo(() => {
    return <InfiniteScroll
      pageStart={ pagination.currentPage }
      hasMore={ hasMoreUnits }
      className={ clsx('listings', { organising }) }
      element={ 'ul' }
      initialLoad={ false }
      loadMore={ organising ? null : () => handleLoadMore(pagination.currentPage + 1) }
      threshold={ 700 }
    >
      { unitsList }
    </InfiniteScroll>
  }, [handleLoadMore, hasMoreUnits, unitsList, organising, pagination.currentPage])

  const saveLayout = useCallback(() => {
    // send a list of units to save their positions
    const unitIds = unitsSorted.map((unit) => {
      return !unit ? null : unit.id
    }).filter((id) => id !== null) as number[]

    unitsRepository
      .batchUpdateOrdinals({ ids: unitIds })
      .then(() => {
        setOrganising?.(false)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
  }, [addError, unitsSorted, setOrganising])

  const saveLayoutButton = useMemo(() => {
    if (!organising || !setOrganising) return null

    return <>
      <div className='center'>
        <button className="save-layout" onClick={ () => saveLayout() }>{ t('collections.view.layout_organise.button_start') }</button>
        <button className="cancel-layout white" onClick={ () => setOrganising(false) }>{ t('collections.view.layout_organise.button_cancel') }</button>
      </div>
      <hr />
    </>
  }, [organising, saveLayout, setOrganising, t])

  if (noCollection) {
    return <GenericNotFound />
  }

  if (userId === 'my' && !isAuthenticated) {
    return <Register />
  }

  if (unitId) {
    return <UnitView id={ unitId } displayName={ thisUser?.displayName } userId={ userId } />
  }

  // if (unitsLoading && units.length === 0) {
  //   return <CardsLoading number={ dynamicCardsLoading } style={ 'listings' } />
  // }

  return (
    <div className={ clsx({ collection: !unitId }) }>
      { saveLayoutButton }
      { cardsList }
      {
        unitsLoading
          ? <CardsLoading number={ dynamicCardsLoading } style={ 'listings' } />
          : null
      }
      {
        units.length === 0 && !unitsLoading
          ? noCards
          : null
      }
    </div>
  )
}

export { Units }
