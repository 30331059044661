import { CountryCode } from '../types'

const countries: CountryCode[] = [
  // {
  //   id: 1,
  //   regionId: 1,
  //   value: 'DE',
  //   label: 'Germany',
  //   callingCode: 49,
  // },
  // {
  //   id: 2,
  //   regionId: 2,
  //   value: 'CA',
  //   label: 'Canada',
  //   callingCode: 1,
  // },
  {
    id: 3,
    regionId: 3,
    value: 'JP',
    label: 'Japan',
    familyNameFirst: true,
    callingCode: 81,
  },
  // {
  //   id: 4,
  //   regionId: 1,
  //   value: 'GB',
  //   label: 'United Kingdom',
  //   callingCode: 44,
  // },
  // {
  //   id: 5,
  //   regionId: 2,
  //   value: 'US',
  //   label: 'United States',
  //   callingCode: 1,
  // },
]

export { countries }
