import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorsContext } from '../contexts/errors_context'

const Errors = () => {
  const { t } = useTranslation()

  const { errors, resetErrors } = useContext(ErrorsContext)

  if (errors.length === 0) return null

  return (
    <ul className="errors">
      {
        errors.map((error, i) => {
          return (
            <li key={ i }>
              <span className="message">{ error.message }</span>
              {
                error.errors
                  ? (
                      Object.keys(error.errors).map((problem, j) => {
                        return <p key={ j } className="problem">{ error.errors?.[problem] }</p>
                      })
                    )
                  : null
              }
              <button className="tool white" onClick={ () => { resetErrors?.(error) } }>{ t('errors.button_clear') }</button>
            </li>
          )
        })
      }
    </ul>
  )
}

export { Errors }
