import { axiosInstance } from '../lib/api_client'
import { Image, Pagination } from '../types'

const PATH = '/images'

type IndexParams = Readonly<{
  limit?: number
  page: number
}>

type IndexReponse = Readonly<{
  images: Image[]
  pagination: Pagination
}>

type CreateParams = Readonly<{
  imageData: string
  detect: boolean
}>

type SessionRepository = Readonly<{
  index: (params: IndexParams) => Promise<IndexReponse>
  create: (params: CreateParams) => Promise<Image>
  get: (id: number) => Promise<Image>
}>

const imagesRepository: SessionRepository = {
  async index (params: IndexParams) {
    return await axiosInstance.get('/users/6/images', { params }).then(({ data }) => data)
  },
  async create (params) {
    return await axiosInstance.post(PATH, params).then(({ data }) => data)
  },
  async get (id: number) {
    return await axiosInstance.get(`${PATH}/${id}`).then(({ data }) => data)
  },
}

export { imagesRepository }
