const filterLink = (
  filterView: string,
  // filterChildren: number[],
  filterCardType: number[],
  filterEnergyType: number[],
  filterRarities: number[],
  filterConditions: number[],
  filterStocks: string[],
  minPrice: number|undefined,
  maxPrice: number|undefined,
  userId: number|undefined,
  searchString: string|undefined,
  filterGame: number|undefined,
) => {
  let path = `view=${filterView}&`

  if (searchString) {
    path += `query=${searchString}&`
  }

  // if (filterChildren.length > 0) {
  //   path += `children=${filterChildren.join(',')}&`
  // }

  if (filterCardType.length > 0) {
    path += `card_types=${filterCardType.join(',')}&`
  }

  if (filterGame) {
    path += `game=${filterGame}&`
  }

  if (filterEnergyType.length > 0) {
    path += `energy_types=${filterEnergyType.join(',')}&`
  }

  if (filterRarities.length > 0) {
    path += `rarities=${filterRarities.join(',')}&`
  }

  if (filterConditions.length > 0) {
    path += `conditions=${filterConditions.join(',')}&`
  }

  if (filterStocks.length > 0) {
    path += `stocks=${filterStocks.join(',')}&`
  }

  if (minPrice) {
    path += `min_price=${minPrice}&`
  }

  if (maxPrice) {
    path += `max_price=${maxPrice}&`
  }

  if (userId) {
    path += `user_id=${userId}&`
  }

  return path.replace(/&+$/, '')
}

export { filterLink }
