import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, FreeMode, Lazy, Thumbs, Pagination } from 'swiper'

import { useViewportDimensions } from '../lib/use_viewport_dimensions'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { Image } from '../types'
import clsx from 'clsx'

const isTouchDevice = ('ontouchstart' in window) ||
  (navigator.maxTouchPoints > 0) ||
  ((navigator as any).msMaxTouchPoints > 0)

type Props = Readonly<{
  images?: Image[]
  resolution: string
  link?: string
  navigationStyle: string
  carousel?: boolean
}>

const ItemImages = ({ carousel, images, resolution, link, navigationStyle }: Props) => {
  const { t } = useTranslation()
  const [imageLoaded, setImageLoaded] = useState(false)
  const [workingImage, setWorkingImage] = useState<Image|undefined>()

  const imagesSorted = useMemo(() => {
    if (!images || images.length === 0) return

    return images.sort((a, b) => a.id - b.id)
  }, [images])

  useEffect(() => {
    setWorkingImage(imagesSorted?.[0])
  }, [imagesSorted])

  const toggleImage = useCallback((e: React.MouseEvent, direction = 'left', index = undefined) => {
    if (!imagesSorted || imagesSorted.length < 2) return

    e.preventDefault()

    const currentImageIndex = imagesSorted.findIndex(i => i.id === workingImage?.id)
    if (currentImageIndex === undefined) return

    if (typeof index === 'undefined') {
      index = direction === 'right'
        ? currentImageIndex === imagesSorted.length - 1 ? 0 : currentImageIndex + 1
        : currentImageIndex === 0 ? imagesSorted.length - 1 : currentImageIndex - 1
    }

    if (currentImageIndex === index) return

    setImageLoaded(false)
    setWorkingImage(imagesSorted[index])
  }, [workingImage, imagesSorted])

  const imageToggleButtonLeft = useMemo(() => {
    if (!imagesSorted || imagesSorted.length < 3) {
      if (imagesSorted?.length === 2) return <button className="selected change-image"></button>
      return
    }

    return <button onClick={ e => { toggleImage(e) } } className="selected change-image left"></button>
  }, [imagesSorted, toggleImage])

  const imageToggleButtonRight = useMemo(() => {
    if (!imagesSorted || imagesSorted.length < 2) return

    return <button onClick={ (e) => { toggleImage(e, 'right') } } className="selected change-image right"></button>
  }, [toggleImage, imagesSorted])

  const imageSrc = useMemo(() => {
    if (resolution === 'standard' && workingImage?.standard) {
      return workingImage.standard
    }

    return workingImage?.thumb2x ?? workingImage?.imageUrl
  }, [resolution, workingImage])

  const image = useMemo(() => {
    if (!workingImage) {
      return <span className='no-image'>{ t('sets.no_image') }</span>
    }

    return <img
      src={ imageSrc }
      alt=""
      width={ workingImage.width ?? '' }
      height={ workingImage.height ?? '' }
      onLoad={ () => setImageLoaded(true) }
      onClick={ (e) => { toggleImage(e, 'right') } }
    />
  }, [imageSrc, t, toggleImage, workingImage])

  useEffect(() => {
    setImageLoaded(!images || images?.length === 0)
  }, [images])

  const imagesForCarousel = useMemo(() => {
    return imagesSorted?.map((im) => {
      if (!im) return null

      return <SwiperSlide key={ im.id }>
        <img
          data-src={ im.imageUrl }
          className={ clsx({ selected: im.id === workingImage?.id }, 'swiper-lazy') }
        />
        <div className='swiper-lazy-preloader'></div>
      </SwiperSlide>
    })
  }, [imagesSorted, workingImage?.id])

  const [thumbsDesktopSwiper, setThumbsDesktopSwiper] = useState<SwiperCore>()

  const viewportWidth = useViewportDimensions()[0]

  const thumbsCarouselDesktop = useMemo(() => {
    if (viewportWidth < 750) return

    return <Swiper
      className={ 'thumbs toptobottom' }
      onSwiper={ setThumbsDesktopSwiper }
      slidesPerView={ 5.5 }
      direction={ 'vertical' }
      spaceBetween={ 10 }
      // freeMode={ true }
      // navigation={ true }
      watchSlidesProgress={ true }
      modules={ [FreeMode, Navigation, Thumbs] }
      preloadImages={ false }
    >
      {
        imagesSorted
          ? imagesSorted.map((im, index) => {
            if (!im) return null

            return <SwiperSlide key={ im.id }>
              <img
                src={ im.thumb2x }
                onClick={ e => { toggleImage(e, '', index) } }
                className={ clsx({ selected: im.id === workingImage?.id }) }
              />
            </SwiperSlide>
          })
          : []
      }
    </Swiper>
  }, [imagesSorted, toggleImage, viewportWidth, workingImage?.id])

  const imageCarouselDesktop = useMemo(() => {
    if (!imagesForCarousel) return null

    return <Swiper
      className={ 'main' }
      modules={ [Navigation, Thumbs, Lazy] }
      slidesPerView={ 1 }
      navigation={ !isTouchDevice }
      simulateTouch={ false }
      thumbs={ { swiper: thumbsDesktopSwiper } }
      preloadImages={ false }
      watchSlidesProgress={ true }
      lazy={
        {
          enabled: true,
          loadPrevNext: true,
          loadPrevNextAmount: 1,
        }
      }
    >
      { imagesForCarousel }
    </Swiper>
  }, [imagesForCarousel, thumbsDesktopSwiper])

  const imageCarouselMobile = useMemo(() => {
    if (!imagesForCarousel) return null

    return <>
      <Swiper
        className={ 'main' }
        modules={ [Lazy, Pagination] }
        slidesPerView={ 1 }
        simulateTouch={ true }
        pagination={ true }
        preloadImages={ false }
        watchSlidesProgress={ true }
        lazy={
          {
            enabled: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1,
          }
        }
      >
        { imagesForCarousel }
      </Swiper>
    </>
  }, [imagesForCarousel])

  // const thumbsCarouselDesktop = useMemo(() => {
  //   if (!images) return null

  //   return <Swiper
  //     className={ 'thumbs toptobottom' }
  //     onSwiper={ setThumbsDesktopSwiper }
  //     slidesPerView={ 5.5 }
  //     direction={ 'vertical' }
  //     spaceBetween={ 10 }
  //     // freeMode={ true }
  //     // navigation={ true }
  //     watchSlidesProgress={ true }
  //     modules={ [FreeMode, Navigation, Thumbs] }
  //   >
  //     {
  //       images.map((im, index) => {
  //         return <SwiperSlide key={ im.id }>
  //           <img
  //             src={ im.thumb2x }
  //             onClick={ e => { toggleImage(e, '', index) } }
  //             className={ clsx({ selected: im.id === workingImage?.id }) }
  //           />
  //         </SwiperSlide>
  //       })
  //     }
  //   </Swiper>
  // }, [images, toggleImage, workingImage?.id])

  // const imageCarouselDesktop = useMemo(() => {
  //   if (!imagesForCarousel) return null

  //   return <>
  //     <Swiper
  //       className={ 'main' }
  //       modules={ [Navigation, Thumbs] }
  //       slidesPerView={ 1 }
  //       navigation={ !isTouchDevice }
  //       simulateTouch={ false }
  //       thumbs={ { swiper: thumbsDesktopSwiper } }
  //     >
  //       { imagesForCarousel }
  //     </Swiper>
  //   </>
  // }, [imagesForCarousel, thumbsDesktopSwiper])

  const imageWithLink = useMemo(() => {
    if (!link) return <div>{ image }</div>

    return <Link to={ link }>{ image }</Link>
  }, [image, link])

  const imageNavigation = useMemo(() => {
    if (navigationStyle === 'none') return null

    if (!imagesSorted) return

    if (navigationStyle === 'image' && imagesSorted.length > 1) {
      return <ul className='navigation images'>
        {
          imagesSorted.map((im, index) => {
            return <li key={ im.id }>
              <img
                src={ im.thumb2x }
                onClick={ e => { toggleImage(e, '', index) } }
                className={ clsx({ selected: im.id === workingImage?.id }) }
              />
            </li>
          })
        }
      </ul>
    }

    if (navigationStyle === 'individual') {
      return <>
        {
          imagesSorted.map((im, index) => {
            return <button key={ index } onClick={ e => { toggleImage(e, '', index) } } className={ workingImage && clsx('change-image left', { selected: im.id === workingImage.id }) }></button>
          })
        }
      </>
    }

    return <>
      { imageToggleButtonLeft }
      { imageToggleButtonRight }
    </>
  }, [imageToggleButtonLeft, imageToggleButtonRight, imagesSorted, navigationStyle, toggleImage, workingImage])

  const carouselContainerDesktop = useMemo(() => {
    if (!imagesForCarousel) return null

    return <div className={ clsx('images desktop', { loaded: imageLoaded }, { multiple: imagesSorted && imagesSorted?.length > 1 }) }>
      { thumbsCarouselDesktop }
      { /* { imageCarouselDesktop } */ }
      { imageCarouselDesktop }
    </div>
  }, [imageCarouselDesktop, imageLoaded, imagesForCarousel, imagesSorted, thumbsCarouselDesktop])

  const carouselContainerMobile = useMemo(() => {
    if (!imagesForCarousel) return null

    return <div className={ clsx('images mobile', { loaded: imageLoaded }, { multiple: imagesSorted && imagesSorted?.length > 1 }) }>
      { imageCarouselMobile }
    </div>
  }, [imageCarouselMobile, imageLoaded, imagesForCarousel, imagesSorted])

  if (carousel) {
    return <>
      { carouselContainerDesktop }
      { carouselContainerMobile }
    </>
  }

  // if (carousel) {
  //   return <>
  //     <div className={ clsx('images desktop', { loaded: imageLoaded || !workingImage }, { multiple: imagesSorted && imagesSorted?.length > 1 }) }>
  //       { imageCarouselDesktop }
  //     </div>
  //     <div className={ clsx('images mobile', { loaded: imageLoaded || !workingImage }, { multiple: imagesSorted && imagesSorted?.length > 1 }) }>
  //       { imageCarouselMobile }
  //     </div>
  //   </>
  // }

  return (
    <>
      <div className={ clsx('images', { loaded: imageLoaded }, { multiple: imagesSorted && imagesSorted?.length > 1 }) }>
        { imageWithLink }
      </div>
      {
        imageNavigation
          ? <div className="flex image-navigation">
            { imageNavigation }
          </div>
          : null
      }
    </>
  )
}

export { ItemImages }
