import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from './modal_wrapper'
import { Link } from 'react-router-dom'
import { AuthContext } from '../contexts/auth_context'

type Props = Readonly<{
  onClose: () => void
}>

const StripeLinkedModal = ({ onClose }: Props) => {
  const { t } = useTranslation()

  const { currentUser } = useContext(AuthContext)

  return (
    <ModalWrapper>
      <h1>{ t('account.sell.stripe_linked.title') }</h1>
      <p>{ t('account.sell.stripe_linked.description') }</p>
      {
        currentUser
          ? <p>
            <Link className="button full" to={ `/u/${currentUser.id}` }>{ t('account.sell.stripe_linked.button') }</Link>
          </p>
          : null
      }
      <p>
        <button className="full white" onClick={ onClose }>{ t('account.sell.stripe_linked.button_back') }</button>
      </p>
    </ModalWrapper>
  )
}

export { StripeLinkedModal }
