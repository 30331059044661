import axios, { AxiosError } from 'axios'
import React, { createContext, useCallback, useState } from 'react'
import { RequestError } from '../types'

const ErrorsContext = createContext<{
  errors: RequestError[]
  addError?: (error: AxiosError) => void
  resetErrors?: (er?: RequestError) => void
}>({ errors: [] })

const ErrorsProvider = ({ children }) => {
  const [errors, setErrors] = useState<RequestError[]>([])

  const addError = useCallback((error: AxiosError) => {
    // if (error.response) {
    //   // The request was made and the server responded with a status code
    //   // that falls out of the range of 2xx
    //   console.log(error.response.data)
    //   console.log(error.response.status)
    // }

    if (axios.isCancel(error)) return

    const errorJson: any = error.toJSON()

    const errorRes: RequestError = {
      statusCode: error.response?.status ?? parseInt(errorJson.code),
      message: error.response?.data.message ?? errorJson.message,
      errors: error.response?.data.errors,
    }

    setErrors(e => [...e ?? [], errorRes])
  }, [])

  const resetErrors = useCallback((er: RequestError|undefined) => {
    if (er) {
      setErrors(e => e.filter(e2 => e2 !== er))
    } else {
      setErrors([])
    }
  }, [])

  return (
    <ErrorsContext.Provider value={ { errors, addError, resetErrors } }>
      { children }
    </ErrorsContext.Provider>
  )
}

export { ErrorsProvider, ErrorsContext }
