import { AxiosError } from 'axios'
import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'

import { Item } from '../types'
import { conditions } from '../lib/conditions'
import { ButtonSelect } from '../helpers/button-select'
import { itemsRepository } from '../repositories/items_repository'
import { unitsRepository } from '../repositories/units_repository'
import { GenericNotFound } from './generic_not_found'

import '../stylesheets/CardsCondition.scss'

import cardExampleIllus from '../card-examples/illus.jpg'
import cardExampleOther from '../card-examples/other.jpg'
import cardExampleCorners from '../card-examples/corners.jpg'
import cardExampleEdges from '../card-examples/edges.jpg'
import cardExampleSurface from '../card-examples/surface.jpg'
import { CardAddedModal } from './card_added_modal'
import { ErrorsContext } from '../contexts/errors_context'

const ConditionCalculator = (): JSX.Element => {
  // const location = useLocation()
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)

  const [itemLoading, setItemLoading] = useState<boolean>(true)
  const [item, setItem] = useState<Item>()

  const params = useMatch('/item/:id/condition')?.params

  const itemId = useMemo<number|undefined>(() => {
    if (params === undefined) return undefined

    const intId = parseInt(params.id ?? '')

    return isNaN(intId) ? undefined : intId
  }, [params])

  const getItem = useCallback((id: number) => {
    setItemLoading(true)

    itemsRepository
      .get(id)
      .then(({ item }) => {
        setItem(item)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setItemLoading(false)
      })
  }, [addError])

  useEffect(() => {
    if (itemId) {
      getItem(itemId)
    } else {
      setItemLoading(false)
    }
  }, [getItem, itemId])

  const card = useMemo(() => {
    if (itemLoading) {
      return (
        <div>
          <h2>{ t('cards.calculator.loading') }</h2>
        </div>
      )
    }

    if (!item) {
      return <GenericNotFound />
    }

    return <CardCondition item={ item } />
  }, [item, itemLoading, t])

  return (
    <div>
      { card }
    </div>
  )
}

type Props = Readonly<{
  item: Item
}>

const damageMultipliers = {
  None: 0,
  Slight: 1,
  Moderate: 3,
  Severe: 5,
}

const categoryMultipliers = {
  destruction: 4,
  frontMark: 2.5,
  frontScratchIllus: 2.5,
  frontScratchOther: 1.5,
  frontCorner: 1.8,
  frontEdge: 1.8,
  frontSurface: 2.5,
  backMark: 3,
  backCorner: 1.5,
  backEdge: 1.5,
  backSurface: 1,
}

const sideMultipliers = {
  front: 1,
  back: 0.75,
}

const defaultAttribute = 'None'

const CardCondition = ({ item }: Props): JSX.Element => {
  const { t } = useTranslation()

  const helpers = useMemo(() => {
    return {
      destruction: {
        title: t('cards.calculator.helpers.destruction.title'),
        text: t('cards.calculator.helpers.destruction.text'),
        image: '',
      },
      frontMark: {
        title: t('cards.calculator.helpers.front_mark.title'),
        text: t('cards.calculator.helpers.front_mark.text'),
        image: '',
      },
      frontScratchIllus: {
        title: t('cards.calculator.helpers.front_scratch_illus.title'),
        text: t('cards.calculator.helpers.front_scratch_illus.text'),
        image: cardExampleIllus,
      },
      frontScratchOther: {
        title: t('cards.calculator.helpers.front_scratch_other.title'),
        text: t('cards.calculator.helpers.front_scratch_other.text'),
        image: cardExampleOther,
      },
      frontCorner: {
        title: t('cards.calculator.helpers.front_corner.title'),
        text: t('cards.calculator.helpers.front_corner.text'),
        image: cardExampleCorners,
      },
      frontEdge: {
        title: t('cards.calculator.helpers.front_edge.title'),
        text: t('cards.calculator.helpers.front_edge.text'),
        image: cardExampleEdges,
      },
      frontSurface: {
        title: t('cards.calculator.helpers.front_surface.title'),
        text: t('cards.calculator.helpers.front_surface.text'),
        image: cardExampleSurface,
      },
      backMark: {
        title: t('cards.calculator.helpers.back_mark.title'),
      },
      backCorner: {
        title: t('cards.calculator.helpers.back_corner.title'),
      },
      backEdge: {
        title: t('cards.calculator.helpers.back_edge.title'),
      },
      backSurface: {
        title: t('cards.calculator.helpers.back_surface.title'),
      },
    }
  }, [t])

  const { addError } = useContext(ErrorsContext)
  const [workingCondition, setWorkingCondition] = useState<number>(conditions[0].id)
  const [currentHelpView, setCurrentHelpView] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const [cardAddedModalShown, setCardAddedModalShown] = useState<boolean>(false)

  const [destruction, setDestruction] = useState<string>(defaultAttribute)
  const [frontMark, setFrontMark] = useState<string>(defaultAttribute)
  const [frontScratchIllus, setFrontScratchIllus] = useState<string>(defaultAttribute)
  const [frontScratchOther, setFrontScratchOther] = useState<string>(defaultAttribute)
  const [frontCorner, setFrontCorner] = useState<string>(defaultAttribute)
  const [frontEdge, setFrontEdge] = useState<string>(defaultAttribute)
  const [frontSurface, setFrontSurface] = useState<string>(defaultAttribute)

  const [backMark, setBackMark] = useState<string>(defaultAttribute)
  const [backCorner, setBackCorner] = useState<string>(defaultAttribute)
  const [backEdge, setBackEdge] = useState<string>(defaultAttribute)
  const [backSurface, setBackSurface] = useState<string>(defaultAttribute)

  const buttonLabels = Object.keys(damageMultipliers).map((k) => k)

  const conditionScore = useMemo(() => {
    let frontTotal = 0

    frontTotal += damageMultipliers[destruction] * categoryMultipliers.destruction
    frontTotal += damageMultipliers[frontMark] * categoryMultipliers.frontMark
    frontTotal += damageMultipliers[frontScratchIllus] * categoryMultipliers.frontScratchIllus
    frontTotal += damageMultipliers[frontScratchOther] * categoryMultipliers.frontScratchOther
    frontTotal += damageMultipliers[frontCorner] * categoryMultipliers.frontCorner
    frontTotal += damageMultipliers[frontEdge] * categoryMultipliers.frontEdge
    frontTotal += damageMultipliers[frontSurface] * categoryMultipliers.frontSurface

    frontTotal = sideMultipliers.front * frontTotal

    let backTotal = 0

    backTotal += damageMultipliers[backMark] * categoryMultipliers.backMark
    backTotal += damageMultipliers[backCorner] * categoryMultipliers.backCorner
    backTotal += damageMultipliers[backEdge] * categoryMultipliers.backEdge
    backTotal += damageMultipliers[backSurface] * categoryMultipliers.backSurface

    backTotal = sideMultipliers.back * backTotal

    const total = frontTotal + backTotal

    let count = 0
    if (destruction !== defaultAttribute) ++count
    if (frontMark !== defaultAttribute) ++count
    if (frontScratchIllus !== defaultAttribute) ++count
    if (frontScratchOther !== defaultAttribute) ++count
    if (frontCorner !== defaultAttribute) ++count
    if (frontEdge !== defaultAttribute) ++count
    if (frontSurface !== defaultAttribute) ++count
    if (backMark !== defaultAttribute) ++count
    if (backCorner !== defaultAttribute) ++count
    if (backEdge !== defaultAttribute) ++count
    if (backSurface !== defaultAttribute) ++count

    return [count, total]
  }, [backCorner, backEdge, backMark, backSurface, frontCorner, destruction, frontEdge, frontMark, frontScratchIllus, frontScratchOther, frontSurface])

  const condition = useMemo(() => {
    const [count, score] = conditionScore

    if (score <= 1) {
      return 100
    }

    if (score <= 4.7 && count <= 3) {
      return 90
    }

    if (score <= 9 &&
      count > 1 &&
      count <= 4) {
      return 80
    }

    if (score <= 12.75) {
      return 70
    }

    if (score <= 17.5) {
      return 60
    }

    if (score <= 30) {
      return 50
    }

    if (score <= 40) {
      return 40
    }

    if (score <= 45) {
      return 30
    }

    if (score <= 50) {
      return 20
    }

    // poor
    return 10
  }, [conditionScore])

  useMemo(() => {
    setWorkingCondition(condition)
  }, [condition])

  const conditionLabel = useMemo(() => {
    return conditions.find(c => c.id === workingCondition)
  }, [workingCondition])

  const changeCondition = useCallback((e: React.FormEvent<HTMLSelectElement>) => {
    const newCondition = Number(e.currentTarget.value)
    setWorkingCondition(newCondition)
  }, [])

  const conditionSelection = useMemo(() => {
    return (
      <select onChange={ changeCondition } value={ workingCondition }>
        {
          conditions.map((c) => {
            return (
              <option key={ c.id } value={ c.id }>{ t(`collections.conditions.long.${c.id}`) }</option>
            )
          })
        }
      </select>
    )
  }, [changeCondition, t, workingCondition])

  const showHelp = useCallback((e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setCurrentHelpView(e.currentTarget.value)
  }, [])

  const help = useMemo(() => {
    if (currentHelpView === '') return

    return (
      <div className="condition-help">
        <p>
          <strong>{ helpers[currentHelpView].title }</strong>
          <button className="helper" value="" onClick={ showHelp }>{ t('cards.calculator.help.close') }</button>
        </p>
        <p>
          { helpers[currentHelpView].text }
        </p>
        <ul>
          <li>
            <Trans
              i18nKey="cards.calculator.help.none"
              values={ { title: t('condition_help.none') } }
              components={ { bold: <strong /> } }
            />
          </li>
          <li>
            <Trans
              i18nKey="cards.calculator.help.slight"
              values={ { title: t('condition_help.slight') } }
              components={ { bold: <strong /> } }
            />
          </li>
          <li>
            <Trans
              i18nKey="cards.calculator.help.moderate"
              values={ { title: t('condition_help.moderate') } }
              components={ { bold: <strong /> } }
            />
          </li>
          <li>
            <Trans
              i18nKey="cards.calculator.help.severe"
              values={ { title: t('condition_help.severe') } }
              components={ { bold: <strong /> } }
            />
          </li>
        </ul>
        {
          helpers[currentHelpView].image
            ? <img src={ helpers[currentHelpView].image } alt="" />
            : null
        }
      </div>
    )
  }, [currentHelpView, helpers, showHelp, t])

  const resetAllAttributes = useCallback(() => {
    setDestruction(defaultAttribute)
    setFrontMark(defaultAttribute)
    setFrontScratchIllus(defaultAttribute)
    setFrontScratchOther(defaultAttribute)
    setFrontCorner(defaultAttribute)
    setFrontEdge(defaultAttribute)
    setFrontSurface(defaultAttribute)
    setBackMark(defaultAttribute)
    setBackCorner(defaultAttribute)
    setBackEdge(defaultAttribute)
    setBackSurface(defaultAttribute)
  }, [])

  const submitForm = useCallback(() => {
    setLoading(true)

    unitsRepository
      .create({
        itemIds: [item.id],
        condition: workingCondition,
        destruction: destruction.replace('None', ''),
        frontCorner: frontCorner.replace('None', ''),
        frontEdge: frontEdge.replace('None', ''),
        frontMark: frontMark.replace('None', ''),
        frontSurface: frontSurface.replace('None', ''),
        frontScratchIllus: frontScratchIllus.replace('None', ''),
        frontScratchOther: frontScratchOther.replace('None', ''),
        backCorner: backCorner.replace('None', ''),
        backEdge: backEdge.replace('None', ''),
        backMark: backMark.replace('None', ''),
        backSurface: backSurface.replace('None', ''),
      })
      .then(() => {
        setCardAddedModalShown(true)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [item.id, workingCondition, destruction, frontCorner, frontEdge, frontMark, frontSurface, frontScratchIllus, frontScratchOther, backCorner, backEdge, backMark, backSurface, addError])

  return (
    <div className="condition-container">
      <h1>{ t('cards.calculator.title') }</h1>
      <p>
        <Trans
          i18nKey="cards.calculator.card"
          values={ { name: item.nameEn } }
          components={ { bold: <strong /> } }
        />
      </p>
      <p>{ t('cards.calculator.instructions') }</p>
      { help }
      <div className="condition-form">
        <div>
          <h2>{ t('cards.calculator.front_title') }</h2>
          <p>
            <label>
              { helpers.frontCorner.title }
              <button className="helper" value="frontCorner" onClick={ showHelp }>?</button>
            </label>
            <ButtonSelect buttons={ buttonLabels } selected={ frontCorner } callback={ setFrontCorner } />
          </p>
          <p>
            <label>
              { helpers.frontEdge.title }
              <button className="helper" value="frontEdge" onClick={ showHelp }>?</button>
            </label>
            <ButtonSelect buttons={ buttonLabels } selected={ frontEdge } callback={ setFrontEdge } />
          </p>
          <p>
            <label>
              { helpers.frontMark.title }
              <button className="helper" value="frontMark" onClick={ showHelp }>?</button>
            </label>
            <ButtonSelect buttons={ buttonLabels } selected={ frontMark } callback={ setFrontMark } />
          </p>
          <p>
            <label>
              { helpers.frontSurface.title }
              <button className="helper" value="frontSurface" onClick={ showHelp }>?</button>
            </label>
            <ButtonSelect buttons={ buttonLabels } selected={ frontSurface } callback={ setFrontSurface } />
          </p>
          <p>
            <label>
              { helpers.frontScratchIllus.title }
              <button className="helper" value="frontScratchIllus" onClick={ showHelp }>?</button>
            </label>
            <ButtonSelect buttons={ buttonLabels } selected={ frontScratchIllus } callback={ setFrontScratchIllus } />
          </p>
          <p>
            <label>
              { helpers.frontScratchOther.title }
              <button className="helper" value="frontScratchOther" onClick={ showHelp }>?</button>
            </label>
            <ButtonSelect buttons={ buttonLabels } selected={ frontScratchOther } callback={ setFrontScratchOther } />
          </p>
        </div>
        <div>
          <h2>{ t('cards.calculator.back_title') }</h2>
          <p>
            <label>
              { helpers.backCorner.title }
            </label>
            <ButtonSelect buttons={ buttonLabels } selected={ backCorner } callback={ setBackCorner } />
          </p>
          <p>
            <label>
              { helpers.backEdge.title }
            </label>
            <ButtonSelect buttons={ buttonLabels } selected={ backEdge } callback={ setBackEdge } />
          </p>
          <p>
            <label>
              { helpers.backMark.title }
            </label>
            <ButtonSelect buttons={ buttonLabels } selected={ backMark } callback={ setBackMark } />
          </p>
          <p>
            <label>
              { helpers.backSurface.title }
            </label>
            <ButtonSelect buttons={ buttonLabels } selected={ backSurface } callback={ setBackSurface } />
          </p>
        </div>
        <div>
          <h2>{ t('cards.calculator.both_title') }</h2>
          <p>
            <label>
              { helpers.destruction.title }
              <button className="helper" value="destruction" onClick={ showHelp }>?</button>
            </label>
            <ButtonSelect buttons={ buttonLabels } selected={ destruction } callback={ setDestruction } />
          </p>
        </div>
      </div>
      <hr />
      <p>
        <Trans
          i18nKey="cards.calculator.condition"
          values={ { name: conditionLabel?.name } }
          components={ { bold: <strong /> } }
        />
      </p>
      <p>
        { t('cards.calculator.override') }{ conditionSelection }
      </p>
      <p>
        <button onClick={ submitForm } disabled={ loading }>{ t('cards.calculator.button_add') }</button>
      </p>
      {
        cardAddedModalShown &&
          <CardAddedModal item={ item } onClose={ () => { resetAllAttributes(); setCardAddedModalShown(false) } } condition={ conditionLabel } />
      }
    </div>
  )
}

export { ConditionCalculator }
