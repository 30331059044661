import { axiosInstance } from '../lib/api_client'
import { Address } from '../types'

const PATH = '/addresses'

type IndexReponse = Readonly<{
  addresses: Address[]
}>

type UpdateParams = Readonly<{
  id: number
  params: Address
}>

type CreateReponse = Readonly<{
  address: Address
}>

type AddressesRepository = Readonly<{
  index: () => Promise<IndexReponse>
  create: (params: Address) => Promise<CreateReponse>
  update: (params: UpdateParams) => Promise<void>
  destroy: (id: number) => Promise<void>
}>

const addressesRepository: AddressesRepository = {
  async index () {
    return await axiosInstance.get(PATH).then(({ data }) => data)
  },
  async create (params) {
    return await axiosInstance.post(PATH, params).then(({ data }) => data)
  },
  async update ({ params, id }) {
    return await axiosInstance.put(`${PATH}/${id}`, params).then()
  },
  async destroy (id) {
    return await axiosInstance.delete(`${PATH}/${id}`).then()
  },
}

export { addressesRepository }
