import React, { createContext, useState, useCallback, useMemo } from 'react'
import { Filters, TagRef } from '../types'
import { filterLink } from '../lib/filter_link'

const defaultFilters: Filters = {
  selectedGame: undefined,
  selectedCategories: [],
  selectedCardTypes: [],
  selectedEnergyTypes: [],
  selectedView: 'listings',
  selectedRarities: [],
  priceRange: {},
  selectedConditions: [],
  selectedStocks: [],
}

const defaultSearchBoxPlaceholder = '何をお探しですか？'

const SearchContext = createContext<{
  searchString: string
  setSearchString?: (s: string) => void
  setFilters?: (filters: Filters) => void
  defaultFilters: Filters
  filters: Filters
  filterMenuDisplayed: boolean
  setFilterMenuDisplayed?: (s: boolean) => void
  hasFilters: boolean
  cachedTagRefs: TagRef[]
  setCachedTagRefs?: (refs: TagRef[]) => void
  appendCachedTagRefs?: (refs: TagRef[]) => void
  pendingInitialFilterCategories: number[]
  setPendingInitialFilterCategories?: (tagIds: number[]) => void
  // deepestSelectedCategoryDepth?: (f: Filters) => number
  endpointForExistingFilters?: (f: Filters, query: string) => string
  categoriesLink?: (tid?: string, page?: number) => string
  searchboxPlaceholder: string
  setSearchboxPlaceholder?: (text?: string) => void
  isUserProfile: boolean
  setIsUserProfile?: (value: boolean) => void
}>({
      filterMenuDisplayed: false,
      searchString: '',
      defaultFilters: defaultFilters,
      filters: defaultFilters,
      hasFilters: false,
      cachedTagRefs: [],
      pendingInitialFilterCategories: [],
      searchboxPlaceholder: defaultSearchBoxPlaceholder,
      isUserProfile: false,
    })

const SearchProvider = ({ children }) => {
  const [searchString, _setSearchString] = useState<string>('')
  const [filters, _setFilters] = useState<Filters>(defaultFilters)
  const [filterMenuDisplayed, _setFilterMenuDisplayed] = useState<boolean>(false)
  const [cachedTagRefs, _setCachedTagRefs] = useState<TagRef[]>([])
  const [pendingInitialFilterCategories, _setPendingInitialFilterCategories] = useState<number[]>([])
  const [searchboxPlaceholder, _setSearchboxPlaceholder] = useState<string>(defaultSearchBoxPlaceholder)
  const [isUserProfile, _setIsUserProfile] = useState<boolean>(false)

  const setSearchString = useCallback((s: string) => {
    _setSearchString(s)
  }, [])

  const setFilters = useCallback((filters: Filters) => {
    _setFilters(filters)
  }, [])

  const setFilterMenuDisplayed = useCallback((s: boolean) => {
    _setFilterMenuDisplayed(s)
  }, [])

  const setCachedTagRefs = useCallback((refs: TagRef[]) => {
    _setCachedTagRefs(refs)
  }, [])

  const appendCachedTagRefs = useCallback((refs: TagRef[]) => {
    _setCachedTagRefs(c => {
      const r = refs.filter(workingRef => !c.find(c => c.id === workingRef.id))
      return c.concat(r)
    })
  }, [])

  const setPendingInitialFilterCategories = useCallback((tagIds: number[]) => {
    _setPendingInitialFilterCategories(tagIds)
  }, [])

  // const deepestSelectedCategoryDepth = useCallback((f: Filters) => {
  //   // maybe here
  //   if (f.selectedCategories.length === 0) return 0

  //   return f.selectedCategories.reduce((prev, curr) => {
  //     return prev.depth > curr.depth ? prev : curr
  //   }).depth
  // }, [])

  const endpointForExistingFilters = useCallback((f: Filters, query: string) => {
    // let category: Number|undefined

    // const deepestCategories = f.selectedCategories.sort((prev, next) => prev.id < next.id ? 1 : -1).filter(t => t.depth === deepestSelectedCategoryDepth(f))

    // if (deepestCategories.length > 1) {
    //   // more than one tag selected; choose the parent
    //   category = deepestCategories[0].parentId
    // } else if (deepestCategories.length === 1) {
    //   // only one selected; choose this
    //   category = deepestCategories[0].id
    // }

    let p = ''

    // const cats = deepestCategories.map(t => t.id).filter(n => n !== category)

    // if (cats.length === 1) {
    //   p += `/categories/${String(category)}`
    // } else if (cats.length > 1) {
    //   p = '/categories'
    // }

    // if (cats.length > 1) {
    //   p = '/categories'
    // }

    // if (category) {
    //   p += `/${String(category)}`
    // }

    p += '?'

    p += filterLink(
      f.selectedView,
      // cats,
      f.selectedCardTypes,
      f.selectedEnergyTypes,
      f.selectedRarities,
      f.selectedConditions,
      f.selectedStocks,
      f.priceRange.min,
      f.priceRange.max,
      f.selectedUserId,
      query,
      f.selectedGame,
    )

    return p
  }, [])

  const categoriesLink = useCallback((tid?: string, page?: number) => {
    const f: Filters = {
      ...filters,
      selectedCategories: [],
    }

    let endpoint = '/categories'

    if (tid) {
      endpoint += `/${tid}`
    }

    endpoint += endpointForExistingFilters(f, searchString)

    if (page && page > 0) {
      endpoint += `&page=${String(page)}&`
    }

    return endpoint
  }, [endpointForExistingFilters, filters, searchString])

  // useEffect(() => {
  //   console.log('filters changed')
  //   console.log(filters)
  // }, [filters])

  // const queryString = useMemo(() => {

  // }, [])

  const setSearchboxPlaceholder = useCallback((value?: string) => {
    _setSearchboxPlaceholder(value ?? defaultSearchBoxPlaceholder)
  }, [])

  const setIsUserProfile = useCallback((value: boolean) => {
    _setIsUserProfile(value)
  }, [])

  const hasFilters = useMemo(() => {
    // N.B.: does not consider view (listings/items)
    return (
      !!filters.priceRange.min ||
      !!filters.priceRange.max ||
      filters.selectedCardTypes.length > 0 ||
      filters.selectedConditions.length > 0 ||
      filters.selectedEnergyTypes.length > 0 ||
      filters.selectedRarities.length > 0 ||
      filters.selectedStocks.length > 0 ||
      searchString !== '' ||
      !!filters.selectedGame
    )
  }, [filters.priceRange.max, filters.priceRange.min, filters.selectedCardTypes.length, filters.selectedConditions.length, filters.selectedEnergyTypes.length, filters.selectedGame, filters.selectedRarities.length, filters.selectedStocks.length, searchString])

  // const setChildrenFilterLabel = useCallback((tag: Tag) => {
  //   // make sure this is still relevant
  //   if (filters.selectedCategories.find(c => c.id === tag.id && c.shouldUpdateWithNewData)) {
  //     console.log('should update')

  //     const selectedCategories = filters.selectedCategories.map((c) => {
  //       if (c.id !== tag.id) return c

  //       const newTag: TagRef = {
  //         id: tag.id,
  //         parentId: tag.parentId,
  //         nameShort: TagTitle(tag, 'ja'),
  //         depth: tag.depth,
  //         childrenCount: tag.childrenCount,
  //         shouldUpdateWithNewData: false,
  //         ordinality: tag.ordinality,
  //       }

  //       return newTag
  //     })

  //     _setFilters({
  //       ...filters,
  //       selectedCategories,
  //     })
  //   }
  // }, [filters])

  // const setChildrenFilterLabels = useCallback((t: TagRef) => {
  //   tagsRepository
  //     .get(t.id)
  //     .then(({ tag }) => {
  //       setChildrenFilterLabel(tag)
  //     })
  // }, [setChildrenFilterLabel])

  // useEffect(() => {
  //   const tagsToUpdate = filters.selectedCategories.filter(t => t.shouldUpdateWithNewData)

  //   tagsToUpdate.forEach((t) => {
  //     setChildrenFilterLabels(t)
  //   })
  // }, [filters, setChildrenFilterLabels])

  return (
    <SearchContext.Provider value={ { searchString, setSearchString, defaultFilters, filters, setFilters, filterMenuDisplayed, setFilterMenuDisplayed, hasFilters, cachedTagRefs, setCachedTagRefs, appendCachedTagRefs, pendingInitialFilterCategories, setPendingInitialFilterCategories, endpointForExistingFilters, categoriesLink, searchboxPlaceholder, setSearchboxPlaceholder, isUserProfile, setIsUserProfile } }>
      { children }
    </SearchContext.Provider>
  )
}

export { SearchProvider, SearchContext }
