import React from 'react'
import { Errors } from './errors'

const ModalWrapper = ({ children }) => {
  return (
    <div className="modal-container">
      <div className="modal">
        <Errors />
        { children }
      </div>
    </div>
  )
}

export { ModalWrapper }
