import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { tagsRepository } from '../repositories/tags_repository'
import axios, { AxiosError, CancelTokenSource } from 'axios'
import { ErrorsContext } from '../contexts/errors_context'
import { Tag } from '../types'
import { Link } from 'react-router-dom'

type Props = Readonly<{
  currentPage: number
}>

type SelectOption = Readonly<{
  value: string
  label: string
}>

const tagTypes: SelectOption[] = [
  {
    value: 'CardLayout',
    label: 'CardLayout',
  },
  {
    value: 'CardType',
    label: 'CardType',
  },
  {
    value: 'Category',
    label: 'Category',
  },
  {
    value: 'Character',
    label: 'Character',
  },
  {
    value: 'Illustrator',
    label: 'Illustrator',
  },
  {
    value: 'Rarity',
    label: 'Rarity',
  },
]

const tagSorts: SelectOption[] = [
  {
    value: 'ordinality',
    label: 'Ordinality',
  },
  {
    value: 'name_en_asc',
    label: 'English name (A first)',
  },
  {
    value: 'name_en_desc',
    label: 'English name (Z first)',
  },
  {
    value: 'name_ja_asc',
    label: 'Japanese name (あ first)',
  },
  {
    value: 'name_ja_desc',
    label: 'Japanese name (ワ first)',
  },
  {
    value: 'created_asc',
    label: 'Created (oldest first)',
  },
  {
    value: 'created_desc',
    label: 'Createst (newest first)',
  },
]

const AdminTagsList = ({ currentPage }: Props) => {
  const { t, i18n } = useTranslation()

  const { addError } = useContext(ErrorsContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [tags, setTags] = useState<Tag[]>()
  const [type, setType] = useState<string>('')
  const [sort, setSort] = useState<string>('')
  const [query, setQuery] = useState<string>('')
  const [parentId, setParentId] = useState<number|undefined>()
  const [totalPages, setTotalPages] = useState<number>(0)

  const [axiosCancelTokens, setAxiosCancelTokens] = useState<CancelTokenSource[]>([])

  const resetAxios = useCallback((axiosCancelTokens) => {
    for (const t of axiosCancelTokens) t.cancel()
  }, [])

  const getTags = useCallback((type: string, query: string, sort: string, parentId: number|undefined, page: number) => {
    setLoading(true)

    const newAxiosCancelToken = axios.CancelToken.source()
    setAxiosCancelTokens((t) => [...t, newAxiosCancelToken])

    // get all children sets
    tagsRepository
      .index({
        parentId,
        params: {
          type,
          q: query,
          page,
          sort,
        },
      },
      newAxiosCancelToken.token)
      .then(({ tags, pagination }) => {
        setTags(tags)
        setTotalPages(pagination.totalPages)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError])

  useEffect(() => {
    getTags(type, query, sort, parentId, currentPage)
  }, [type, currentPage, getTags, parentId, query, sort])

  const onChangeQuery = useCallback((e) => {
    resetAxios(axiosCancelTokens)
    setAxiosCancelTokens([])

    setQuery(e.target.value)
  }, [axiosCancelTokens, resetAxios])

  useEffect(() => {
    // reset search query to avoid no results after search
    setQuery('')
  }, [parentId])

  const onChangeParentId = useCallback((e) => {
    e.preventDefault()
    setParentId(e.target.value ? Number(e.target.value) : undefined)
  }, [])

  const pagination = useMemo(() => {
    if (totalPages <= 1) return null

    return (
      <div className="pagination">
        <span>{ t('pagination_page') }</span>
        {
          [...Array(totalPages)].map((p, i) => {
            return (
              <Link
                key={ i }
                className={ `button ${i + 1 === currentPage ? 'selected' : ''}` }
                to={ `/admin/tags/list?page=${i + 1}` }
              >
                { i + 1 }
              </Link>
            )
          })
        }
      </div>
    )
  }, [totalPages, t, currentPage])

  const tagsUi = useMemo(() => {
    if (loading) {
      return <>
        <p>Loading...</p>
      </>
    }

    return <>
      <ul className="editable">
        {
          tags?.map((t) => {
            const parentTitle = t.parent ? <><strong>{ t.parent.nameJa }</strong><br /></> : ''

            const title = (i18n.language === 'ja' && t.nameJa) ?? !t.nameEn ? t.nameJa : t.nameEn

            return (
              <li key={ t.id }>
                <span>
                  { parentTitle }<Link to={ `/categories/${t.id}` }>#{ t.id }</Link>: { title } <strong className="yellow">{ t.ordinality ?? '0' }</strong> {
                    t.childrenCount > 0
                      ? <a onClick={ () => setParentId(t.id) }><strong className="red">{ t.childrenCount } children</strong></a>
                      : null
                  }
                </span>
                <span className="buttons">
                  <Link to={ `/admin/tags/items/${t.id}` } className="button tool">Items</Link> <Link to={ `/admin/tags/edit/${t.id}` } className="button small">Edit</Link>
                </span>
              </li>
            )
          })
        }
      </ul>
      { pagination }
    </>
  }, [i18n.language, loading, pagination, tags])

  const filterUi = useMemo(() => {
    return <>
      <div className="select">
        <Select
          options={ tagTypes }
          onChange={ (p) => setType(p?.value ?? '') }
          value={ tagTypes.find(t => t.value === type) }
          placeholder={ t('defaults.filter') }
          isClearable={ true }
          isDisabled={ loading }
        />
      </div>
      <div className="select">
        <Select
          options={ tagSorts }
          onChange={ (p) => setSort(p?.value ?? '') }
          value={ tagSorts.find(t => t.value === sort) }
          placeholder={ t('defaults.sort') }
          isClearable={ true }
          isDisabled={ loading }
        />
      </div>
      <p>
        <input type="text" className="full" placeholder={ t('defaults.search') } name="query" value={ query } onChange={ onChangeQuery } />
      </p>
      <p>
        <input type="text" className="full" placeholder="Parent ID" name="parentId" value={ parentId } onChange={ onChangeParentId } />
      </p>
    </>
  }, [loading, onChangeParentId, onChangeQuery, parentId, query, sort, t, type])

  return (
    <div className="narrow">
      { filterUi }
      { tagsUi }
    </div>
  )
}

export { AdminTagsList }
