import React, { useMemo, useContext, useState, useEffect } from 'react'
import { Link, useMatch } from 'react-router-dom'
import clsx from 'clsx'
import queryString from 'query-string'

import { AdminItems } from './admin_items'
import { AdminTags } from './admin_tags'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../contexts/auth_context'
import { Register } from './register'

type AdminView = Readonly<{
  path: string
}>

const adminViews: AdminView[] = [
  {
    path: 'items'
  },
  {
    path: 'tags'
  },
]

const Admin = () => {
  const { t } = useTranslation()
  const { isAuthenticated } = useContext(AuthContext)

  const qS = queryString.parse(location.search)
  const page = qS.page as string
  const [currentPage, setCurrentPage] = useState<number>(Number(page ?? 1))

  const [currentPath, setCurrentPath] = useState<string|undefined>()
  const [currentSubpath, setCurrentSubpath] = useState<string|undefined>()
  // const [currentId, setCurrentId] = useState<string|undefined>()

  // TODO: Is there a better way to do param matching?
  // React-Router v6 seems like a step back:
  // https://github.com/remix-run/react-router/issues/7285

  const paramsId = useMatch('/admin/:path/:subpath/:id')?.params
  const paramsSubpath = useMatch('/admin/:path/:subpath')?.params
  const paramsPath = useMatch('/admin/:path')?.params

  useEffect(() => {
    setCurrentSubpath(paramsId?.subpath ?? paramsSubpath?.subpath)
    setCurrentPath(paramsId?.path ?? paramsSubpath?.path ?? paramsPath?.path)
  }, [paramsId, paramsSubpath, paramsPath])

  const currentView = useMemo<AdminView>(() => {
    if (currentPath === undefined) return adminViews[0]

    const v = adminViews.find(av => av.path === currentPath)

    return v ?? adminViews[0]
  }, [currentPath])

  const subpath = useMemo<string|undefined>(() => {
    if (currentSubpath === undefined) return

    return currentSubpath
  }, [currentSubpath])

  const tabs = useMemo(() => {
    return (
      <div className="tabs">
        {
          // sort tags by slug a-z
          adminViews.sort((a, b) => a.path.localeCompare(b.path)).map(a => {
            const link = a === adminViews[0] ? '' : `/${a.path}`
            const text = t(`admin.paths.${a.path}.top`)

            return <Link key={ a.path } to={ `/admin${link}` } className={ clsx({ selected: currentView === a }) }>{ text }</Link>
          })
        }
      </div>
    )
  }, [t, currentView])

  useEffect(() => {
    setCurrentPage(Number(page) || 1)
  }, [page])

  const settingsPage = useMemo(() => {
    if (currentView.path === 'items') {
      return <AdminItems currentPage={ currentPage } subpath={ subpath } />
    }

    return <AdminTags currentPage={ currentPage } subpath={ subpath } />
  }, [currentPage, currentView.path, subpath])

  if (!isAuthenticated) {
    return <Register />
  }

  return (
    <div>
      <div className="narrow">
        <h1>{ t('admin.title') }</h1>
      </div>
      { tabs }
      { settingsPage }
    </div>
  )
}

export { Admin }
