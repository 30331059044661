import { axiosInstance } from '../lib/api_client'
import { Balance, BalanceData, Pagination } from '../types'

type WithdrawParams = Readonly<{
  userId: number
  amount: number
  withdrawalFee: number
}>

type IndexParams = Readonly<{
  userId: number
  params?: Readonly<{
    show?: string
    page: number
    limit?: number
  }>
}>

type IndexReponse = Readonly<{
  balances: Balance[]
  balanceData: BalanceData
  pagination: Pagination
}>

type BalancesRepository = Readonly<{
  index: (params: IndexParams) => Promise<IndexReponse>
  withdraw: (params: WithdrawParams) => Promise<void>
}>

const balancesRepository: BalancesRepository = {
  async index ({ userId, params }) {
    return await axiosInstance.get(`/users/${userId}/balances`, { params }).then(({ data }) => data)
  },
  async withdraw ({ userId, amount, withdrawalFee }) {
    return await axiosInstance.post(`/users/${userId}/balances/withdraw`, { amount, withdrawalFee })
  }
}

export { balancesRepository }
