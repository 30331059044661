import React, { useCallback, useContext, useState } from 'react'
import { AxiosError } from 'axios'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { ModalWrapper } from './modal_wrapper'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { StripeAccount } from '../types'
import { ErrorsContext } from '../contexts/errors_context'
import { stripeAccountRepository } from '../repositories/stripe_accounts_repository'

type Props = Readonly<{
  onClose: (stripeAccount: StripeAccount) => void
  stripeAccount: StripeAccount
}>

type ReturnsForm = Readonly<{
  returns: number
  enabled: boolean
}>

const ReturnsOptionsModal = ({ onClose, stripeAccount }: Props) => {
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)

  const [loading, setLoading] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    getValues,
  } = useForm<ReturnsForm>({
    defaultValues: {
      returns: stripeAccount.returns,
      enabled: stripeAccount.returns > 0,
    }
  })

  const onSubmit = useCallback((params: ReturnsForm) => {
    // TODO: Validation
    const newStripeAccount: StripeAccount = {
      ...stripeAccount,
      returns: params.enabled ? params.returns : 0,
    }

    if (loading) return

    if (newStripeAccount.returns === stripeAccount.returns) {
      return onClose(newStripeAccount)
    }

    stripeAccountRepository
      .update(newStripeAccount)
      .then(() => {
        onClose(newStripeAccount)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })

    setLoading(true)
  }, [addError, loading, onClose, stripeAccount])

  const returnsEnabled = useWatch({
    control,
    name: 'enabled',
  })

  const returnsOption = useWatch({
    control,
    name: 'returns',
  })

  const returnsOptions = [
    {
      value: 1,
      label: t('account.sell.returns.burden.seller'),
    },
    {
      value: 2,
      label: t('account.sell.returns.burden.customer'),
    },
  ]

  const enabledOptions = [
    {
      value: false,
      label: t('account.sell.returns.enabled.false'),
    },
    {
      value: true,
      label: t('account.sell.returns.enabled.true'),
    },
  ]

  return (
    <ModalWrapper>
      <div>
        <h1>{ t('account.sell.returns.title') }</h1>
        <p>{ t('account.sell.returns.description1') }</p>
        <form onSubmit={ handleSubmit(onSubmit) } className="single-line">
          <p className='select'>
            <label>
              { t('account.sell.returns.enabled.title') }
            </label>
            <Controller
              control={ control }
              name='enabled'
              render={ ({ field }) => (
                <Select
                  options={ enabledOptions }
                  onChange={ val => field.onChange(val?.value) }
                  placeholder={ t('defaults.select') }
                  value={ enabledOptions.find(t => t.value === getValues('enabled')) }
                />
              ) }
            />
          </p>
          {
            !returnsEnabled
              ? null
              : <>
                <p>{ t('account.sell.returns.description2') }</p>
                <div className="select">
                  <label>
                    { t('account.sell.returns.burden.title') }
                  </label>
                  <Controller
                    control={ control }
                    name='returns'
                    render={ ({ field }) => (
                      <Select
                        options={ returnsOptions }
                        onChange={ val => field.onChange(val?.value) }
                        placeholder={ t('defaults.select') }
                        value={ returnsOptions.find(t => t.value === getValues('returns')) }
                      />
                    ) }
                  />
                </div>
              </>
          }
          <p>
            <button className="full" disabled={ loading || !(returnsOption > 0) }>
              { t('account.sell.returns.button_save') }
            </button>
          </p>
        </form>
        <p>
          <button className="large tool" onClick={ () => { onClose(stripeAccount) } } disabled={ loading }>
            { t('account.sell.returns.button_cancel') }
          </button>
        </p>
      </div>
    </ModalWrapper>
  )
}

export { ReturnsOptionsModal }
