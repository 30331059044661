import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

// Resources(English)
import enAccount from '../locales/en/account.json'
import enAdmin from '../locales/en/admin.json'
import enApp from '../locales/en/app.json'
import enCards from '../locales/en/cards.json'
import enCheckout from '../locales/en/checkout.json'
import enCollections from '../locales/en/collections.json'
import enErrors from '../locales/en/errors.json'
import enFooter from '../locales/en/footer.json'
import enHeader from '../locales/en/header.json'
import enImageUpload from '../locales/en/image_upload.json'
import enListings from '../locales/en/listings.json'
import enLogin from '../locales/en/login.json'
import enModal from '../locales/en/modal.json'
import enOrder from '../locales/en/order.json'
import enRegister from '../locales/en/register.json'
import enSearch from '../locales/en/search.json'
import enSets from '../locales/en/sets.json'
import enTop from '../locales/en/top.json'
import enTranslation from '../locales/en/translation.json'

// Resources(Japanese)
import jaApp from '../locales/ja/app.json'
import jaAdmin from '../locales/ja/admin.json'
import jaAccount from '../locales/ja/account.json'
import jaCards from '../locales/ja/cards.json'
import jaCheckout from '../locales/ja/checkout.json'
import jaCollections from '../locales/ja/collections.json'
import jaErrors from '../locales/ja/errors.json'
import jaFooter from '../locales/ja/footer.json'
import jaHeader from '../locales/ja/header.json'
import jaImageUpload from '../locales/ja/image_upload.json'
import jaListings from '../locales/ja/listings.json'
import jaLogin from '../locales/ja/login.json'
import jaModal from '../locales/ja/modal.json'
import jaOrder from '../locales/ja/order.json'
import jaRegister from '../locales/ja/register.json'
import jaSearch from '../locales/ja/search.json'
import jaSets from '../locales/ja/sets.json'
import jaTop from '../locales/ja/top.json'
import jaTranslation from '../locales/ja/translation.json'

const enResources = {
  ...enAccount,
  ...enAdmin,
  ...enApp,
  ...enCards,
  ...enCheckout,
  ...enCollections,
  ...enErrors,
  ...enFooter,
  ...enHeader,
  ...enImageUpload,
  ...enListings,
  ...enLogin,
  ...enModal,
  ...enOrder,
  ...enRegister,
  ...enSearch,
  ...enSets,
  ...enTop,
  ...enTranslation,
}

const jaResources = {
  ...jaAccount,
  ...jaApp,
  ...jaAdmin,
  ...jaCards,
  ...jaCheckout,
  ...jaCollections,
  ...jaErrors,
  ...jaFooter,
  ...jaHeader,
  ...jaImageUpload,
  ...jaListings,
  ...jaLogin,
  ...jaModal,
  ...jaOrder,
  ...jaRegister,
  ...jaSearch,
  ...jaSets,
  ...jaTop,
  ...jaTranslation,
}

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: { translation: enResources },
      ja: { translation: jaResources },
    },
    fallbackLng: 'ja',
    lng: 'ja',
    // debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  })

export { i18n }
export default i18n
