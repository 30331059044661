const IsAuthenticatedKey = 'IsAuthenticated'
const IsDarkThemeKey = 'IsDarkTheme'
const ReferralKey = 'Referral'

const setLocalIsAuthenticated = (isAuthenticated: boolean) => {
  localStorage.setItem(IsAuthenticatedKey, String(isAuthenticated))
}

const getLocalIsAuthenticated = (): boolean => {
  return localStorage.getItem(IsAuthenticatedKey) === 'true'
}

const setLocalIsDarkTheme = (isDark: boolean) => {
  localStorage.setItem(IsDarkThemeKey, String(isDark))
}

const getLocalIsDarkTheme = (): boolean => {
  return localStorage.getItem(IsDarkThemeKey) === 'true'
}

const setLocalReferral = (referral: string) => {
  localStorage.setItem(ReferralKey, referral)
}

const getLocalReferral = (): string => {
  return localStorage.getItem(ReferralKey) ?? ''
}

export {
  setLocalIsAuthenticated,
  getLocalIsAuthenticated,
  setLocalIsDarkTheme,
  getLocalIsDarkTheme,
  setLocalReferral,
  getLocalReferral,
}
