import { Condition } from '../types'

const conditions: Condition[] = [
  {
    id: 0,
    name: 'No condition',
    displayDefault: true,
    slug: 'none',
  },
  {
    id: 100,
    name: 'Gem Mint',
    nameListing: 'GEM MINT',
    displayDefault: false,
    slug: 'gem-mint',
  },
  {
    id: 90,
    name: 'Mint',
    nameListing: 'MINT',
    displayDefault: true,
    slug: 'mint',
  },
  {
    id: 80,
    name: 'Near Mint - Mint',
    nameListing: 'NM/MINT',
    displayDefault: true,
    slug: 'near-mint-mint',
  },
  {
    id: 70,
    name: 'Near Mint',
    nameListing: 'NEAR MINT',
    displayDefault: true,
    slug: 'near-mint',
  },
  {
    id: 60,
    name: 'Excellent - Mint',
    nameListing: 'EXC/MINT',
    displayDefault: true,
    slug: 'excellent-mint',
  },
  {
    id: 50,
    name: 'Excellent',
    nameListing: 'EXC',
    displayDefault: true,
    slug: 'excellent',
  },
  {
    id: 40,
    name: 'Very Good - Excellent',
    nameListing: 'VG/EXC',
    displayDefault: true,
    slug: 'very-good-excellent',
  },
  {
    id: 30,
    name: 'Very Good',
    nameListing: 'VERY GOOD',
    displayDefault: true,
    slug: 'very-good',
  },
  {
    id: 20,
    name: 'Good',
    nameListing: 'GOOD',
    displayDefault: true,
    slug: 'good',
  },
  {
    id: 15,
    name: 'Fair',
    nameListing: 'FAIR',
    displayDefault: true,
    slug: 'fair',
  },
  {
    id: 10,
    name: 'Poor',
    nameListing: 'POOR',
    displayDefault: true,
    slug: 'poor',
  },
]

export { conditions }
