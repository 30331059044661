import { AxiosError } from 'axios'
import React, { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ErrorsContext } from '../contexts/errors_context'
import { ratings } from '../lib/ratings'
import { feedbacksRepository } from '../repositories/feedbacks_repository'
import { Feedback, Order } from '../types'
import { FeedbackView } from './feedback'

type Props = Readonly<{
  order: Order
  callback: (feedback: Feedback) => void
}>

const OrderFeedback = ({ order, callback }: Props) => {
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)

  const [loading, setLoading] = useState<boolean>()

  const {
    handleSubmit,
    register,
  } = useForm<Feedback>()

  const onSubmit = useCallback((params) => {
    if ((!order.id || (order.feedbacks && order.feedbacks?.length > 0)) ?? loading) return
    // TODO: Validation

    feedbacksRepository
      .create(order.id, params)
      .then(() => {
        callback(params)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })

    setLoading(true)
  }, [addError, callback, loading, order.feedbacks, order.id])

  if (order?.feedbacks && order.feedbacks.length > 0) {
    const fb = order.feedbacks[0]

    return <FeedbackView feedback={ fb } />
  }

  return (
    <>
      <h3>{ t('order.feedback.title') }</h3>
      <p>
        { t('order.feedback.summary') }
      </p>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <div className="select">
          <label>
            { t('order.feedback.rating') }
          </label>
          <select { ...register('rating') }>
            {
              ratings.map((rating) => {
                return <option key={ rating.value } value={ rating.value }>{ rating.label } { t(`order.feedback.${rating.value}`) }</option>
              })
            }
          </select>
          {
          /* <Controller
            control={ control }
            name="rating"
            defaultValue= { ratings.find(r => r.value === 'positive')?.value }
            render={ ({ onChange, value, ref }) => (
              <Select
                ref={ ref }
                options={ ratings }
                value={ ratings.find(r => r.value === value) }
                onChange={ (r) => onChange(r?.value) }
                placeholder={ t('defaults.select') }
              />
            ) }
          /> */
          }
        </div>
        <p>
          <label>
            { t('order.feedback.description') }
          </label>
          <textarea { ...register('description') } />
        </p>
        <p>
          <button disabled={ loading } className="full">{ t('order.feedback.submit') }</button>
        </p>
      </form>
    </>
  )
}

export { OrderFeedback }
