import { axiosInstance } from '../lib/api_client'
import { Feedback, Pagination } from '../types'

type IndexParams = Readonly<{
  userId: string|number
  params?: Readonly<{
    limit?: number
    page: number
  }>
}>

type IndexReponse = Readonly<{
  feedbacks: Feedback[]
  pagination: Pagination
}>

type CreateParams = Readonly<{
  description: string
}>

type FeedbacksRepository = Readonly<{
  index: (params: IndexParams) => Promise<IndexReponse>
  create: (orderId: number, params: CreateParams) => Promise<void>
}>

const feedbacksRepository: FeedbacksRepository = {
  async index ({ userId, params }) {
    return await axiosInstance.get(`/users/${String(userId)}/feedbacks`, { params }).then(({ data }) => data)
  },
  async create (orderId, params) {
    return await axiosInstance.post(`/orders/${orderId}/feedbacks`, params).then(({ data }) => data)
  },
}

export { feedbacksRepository }
