import { axiosInstance } from '../lib/api_client'
import { TagGroup } from '../types'

const PATH = '/tag_groups'

type GetReponse = Readonly<{
  tagGroup: TagGroup
}>

type TagGroupsRepository = Readonly<{
  get: (id: number) => Promise<GetReponse>
}>

const tagGroupsRepository: TagGroupsRepository = {
  async get (id) {
    return await axiosInstance.get(`${PATH}/${id}`).then(({ data }) => data)
  },
}

export { tagGroupsRepository }
