import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import clsx from 'clsx'
// import { Link } from 'react-router-dom'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import Select from 'react-select'
import { AxiosError } from 'axios'
import { Item, ItemForm, Tag } from '../types'
import { ErrorsContext } from '../contexts/errors_context'
import { ModalWrapper } from './modal_wrapper'
import { Link, useMatch } from 'react-router-dom'
import { itemsRepository } from '../repositories/items_repository'
import { tagsRepository } from '../repositories/tags_repository'

type ItemType = Readonly<{
  value: string
  label: string
}>

const typesArray = [
  'colorless',
  'grass',
  'fire',
  'water',
  'lightning',
  'psychic',
  'fighting',
  'darkness',
  'metal',
  'dragon',
  'fairy',
  'plus',
]

const gbArray = [
  'プロモーションカード',
  'ポケモンコロシアム',
  'ポケモンラボ',
  '幻のポケモン',
  '未収録',
  '進化のふしぎ',
]

const gbpacks: ItemType[] = gbArray.sort().map(s => {
  return {
    value: s,
    label: s,
  }
})

const gb2Array = [
  'そらとぶポケモン',
  'でんせつのちから',
  'はじめてのポケモン',
  'われらロケット団',
  'プロモーションカード',
  'プロモーションカード（幻のカード）',
  'ロケット団のやぼう',
  '化石のしま',
  '未収録',
  '超バトル',
]

const gb2packs: ItemType[] = gb2Array.sort().map(s => {
  return {
    value: s,
    label: s,
  }
})

const supertypesArray = [
  // pokemon tcg + carddass
  ['Pokémon', 'ポケモン'],
  ['Trainer', 'トレーナーズ'],
  ['Energy', 'エネルギー'],
  ['Special Card', 'スペシャルカード'],
  ['Illustrator', 'イラストレーター'],
  ['Extra Rule', 'エクストラルール'],
  ['Pass Card', 'パースカード'],
  ['Deck List', 'デッキリスト'],
  // carddass only
  ['Map', 'マップ'],
  // carddass anime collection
  ['Pikachu', 'Pikachu'],
  ['Friends', 'Friends'],
  ['Rocket', 'Rocket'],
  ['Satoshi', 'Satoshi'],
  ['Guest', 'Guest'],
  ['Satoshi\'s Pokemon', 'Satoshi\'s Pokemon'],
  ['Rival', 'Rival'],
  ['Battle', 'Battle'],
  ['Nangoku Pokemon', 'Nangoku Pokemon'],
  ['Movie', 'Movie'],
]

const utilitiesArray = [
  {
    value: 'auto-announcement',
    label_en: 'New card announcement',
    label_ja: '新ポケカ公開',
  },
  // ['Card price guide', 'ポケカ値段情報'],
]

const regulationArray = [
  'G',
  'F',
  'E',
  'D',
  'C',
  'B',
  'A',
  'Ditto',
]

const regulations: ItemType[] = regulationArray.map(s => {
  return {
    value: s,
    label: s,
  }
})

const formDefaults: ItemForm = {
  releaseDate: '',
  type: '',
  nameEn: '',
  nameJa: '',
  utilities: [],
  itemData: {
    body: {
      abilities: [],
      ancientTrait: undefined,
      artist: '',
      attacks: [],
      convertedRetreatCost: 0,
      evolvesFrom: '',
      flavorText: '',
      gb2Pack: '',
      gbPack: '',
      hp: '',
      id: '',
      level: '',
      name: '',
      nationalPokedexNumbers: [],
      number: '',
      rarity: '',
      regulation: '',
      resistances: [],
      retreatCost: [],
      rules: [],
      series: [],
      subtypes: [],
      supertype: '',
      types: [],
      weaknesses: [],
      wikiUrl: '',
    }
  }
}

const AdminItemsCreate = () => {
  const { t, i18n } = useTranslation()

  const types: ItemType[] = typesArray.map(s => {
    return {
      value: s.charAt(0).toUpperCase() + s.slice(1),
      label: t(`admin.items.energy.${s}`),
    }
  })

  const supertypes: ItemType[] = supertypesArray.map(s => {
    return {
      value: s[0],
      label: i18n.language === 'ja' ? s[1] : s[0],
    }
  })

  const utilities: ItemType[] = utilitiesArray.map(s => {
    return {
      value: s.value,
      label: i18n.language === 'ja' ? s.label_ja : s.label_en,
    }
  })

  const itemTypes: ItemType[] = [
    {
      value: 'CardSingle',
      label: i18n.language === 'ja' ? 'カード' : 'Card',
    },
    {
      value: 'CardBooster',
      label: i18n.language === 'ja' ? '拡張パック' : 'Booster',
    },
    {
      value: '',
      label: '',
    },
  ]

  const { addError, resetErrors } = useContext(ErrorsContext)

  const params = useMatch('/admin/items/edit/:item_id')?.params

  const itemId = useMemo<number|undefined>(() => {
    const id = parseInt(params?.item_id ?? '')
    return isNaN(id) ? undefined : id
  }, [params])

  // const [selectedItemType, setSelectedItemType] = useState<string>(formDefaults.type)
  const [loading, setLoading] = useState<boolean>(false)
  const [workingItem, setWorkingItem] = useState<Item>()
  const [workingItemId, setWorkingItemId] = useState<number>()
  const [showOldData, setShowOldData] = useState<boolean>(false)

  const getItem = useCallback((id: number) => {
    setLoading(true)

    itemsRepository
      .get_for_edit(id)
      .then(({ item }) => {
        setWorkingItem(item)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError])

  const {
    register: registerForm,
    reset,
    handleSubmit,
    control,
    setValue,
    getValues,
    setFocus,
  } = useForm<ItemForm>({
    defaultValues: formDefaults,
  })

  useEffect(() => {
    if (!itemId) {
      setWorkingItem(undefined)
      reset(formDefaults)
      return
    }

    getItem(itemId)
  }, [getItem, reset, itemId])

  useEffect(() => {
    setWorkingItemId(workingItem?.id)
  }, [workingItem])

  useEffect(() => {
    if (workingItem && formDefaults.itemData) {
      const d = formDefaults.itemData.body
      let b = workingItem.itemData?.body

      if (!b) {
        b = d
      }

      const utilities = workingItem.tags?.filter(t => t.type === 'Utility').map(t => t.slug) ?? []

      const newWorkingItem: ItemForm = {
        releaseDate: workingItem?.releaseDate ?? formDefaults.releaseDate,
        type: workingItem?.type ?? formDefaults.type,
        nameEn: workingItem?.nameEn ?? formDefaults.nameEn,
        nameJa: workingItem?.nameJa ?? formDefaults.nameJa,
        utilities: utilities ?? formDefaults.utilities,
        itemData: {
          ...workingItem.itemData,
          body: {
            // sorry, maybe there is an easier way to do the below such as mapping Object.keys
            abilities: b.abilities ?? d.abilities,
            ancientTrait: b.ancientTrait ?? d.ancientTrait,
            artist: b.artist ?? d.artist,
            attacks: b.attacks ?? d.attacks,
            convertedRetreatCost: b.convertedRetreatCost ?? d.convertedRetreatCost,
            evolvesFrom: b.evolvesFrom ?? d.evolvesFrom,
            flavorText: b.flavorText ?? d.flavorText,
            gb2Pack: b.gb2Pack ?? d.gb2Pack,
            gbPack: b.gbPack ?? d.gbPack,
            hp: b.hp ?? d.hp,
            id: b.id ?? d.id,
            level: b.level ?? d.level,
            name: b.name ?? d.name,
            nationalPokedexNumbers: b.nationalPokedexNumbers ? b.nationalPokedexNumbers.map(d => String(d)) ?? d.nationalPokedexNumbers : d.nationalPokedexNumbers,
            number: b.number ?? d.number,
            rarity: b.rarity ?? d.rarity,
            regulation: b.regulation ?? d.regulation,
            resistances: b.resistances ?? d.resistances,
            retreatCost: b.retreatCost ?? d.retreatCost,
            rules: b.rules ? Array.isArray(b.rules) ? b.rules : [b.rules] : d.rules,
            series: b.series ?? d.series,
            subtypes: b.subtypes ?? d.subtypes,
            supertype: b.supertype ?? d.supertype,
            types: b.types ?? d.types,
            weaknesses: b.weaknesses ?? d.weaknesses,
            wikiUrl: b.wikiUrl ?? d.wikiUrl,
          }
        },
      }

      reset(newWorkingItem)
    } else {
      reset(formDefaults)
    }
  }, [reset, workingItem])

  const [modalDisplayed, setModalDisplayed] = useState<string|undefined>()

  const onSubmit = useCallback((params) => {
    if (loading) return

    // TODO: Validation
    const newItem: Item = {
      ...params,
      itemData: {
        ...params.itemData,
        body: params.type !== 'CardSingle'
          ? undefined
          : {
              ...params.itemData.body,
              convertedRetreatCost: params.itemData?.body.retreatCost.length ?? 0,
            },
      }
    }

    resetErrors?.()

    setLoading(true)

    if (workingItemId) {
      itemsRepository
        .update({ id: workingItemId, params })
        .then(() => {
          setModalDisplayed('update')
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      itemsRepository
        .create(newItem)
        .then(({ item }) => {
          setModalDisplayed('create')
          setWorkingItem(item)
        })
        .catch((err: AxiosError) => {
          addError?.(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [addError, loading, resetErrors, workingItemId])

  const supertype = useWatch({
    control,
    name: 'itemData.body.supertype',
  })

  // const utilitiesField = useWatch({
  //   control,
  //   name: 'utilities',
  // })

  // const evolvesFrom = useWatch({
  //   control,
  //   name: 'itemData.body.evolvesFrom',
  // })

  const nationalPokedexNumbers = useWatch({
    control,
    name: 'itemData.body.nationalPokedexNumbers',
  })

  const utilitiesField = useWatch({
    control,
    name: 'utilities',
  })

  const type = useWatch({
    control,
    name: 'type',
  })

  const nameEn = useWatch({
    control,
    name: 'nameEn',
  })

  const nameJa = useWatch({
    control,
    name: 'nameJa',
  })

  // const cardName = useWatch({
  //   control,
  //   name: 'itemData.body.name',
  // })

  const artist = useWatch({
    control,
    name: 'itemData.body.artist',
  })

  const nameBlur = useCallback(() => {
    // prioritises Japanese name
    setValue('itemData.body.name', nameJa !== '' ? nameJa : nameEn)
  }, [nameEn, nameJa, setValue])

  // const numbers = useWatch({
  //   control,
  //   name: 'itemData.body.numbers',
  // })

  const rules = useWatch({
    control,
    name: 'itemData.body.rules',
  })

  const retreatCost = useWatch({
    control,
    name: 'itemData.body.retreatCost',
  })

  const rarity = useWatch({
    control,
    name: 'itemData.body.rarity',
  })

  const subtype = useWatch({
    control,
    name: 'itemData.body.subtypes',
  })

  const {
    fields: abilities,
    append: appendAbility,
    remove: removeAbility,
  } = useFieldArray({
    control,
    name: 'itemData.body.abilities',
  })

  useWatch({
    control,
    name: 'itemData.body.attacks',
  })

  const {
    fields: attacks,
    append: appendAttack,
    remove: removeAttack,
  } = useFieldArray({
    control,
    name: 'itemData.body.attacks',
  })

  const {
    fields: resistances,
    append: appendResistance,
    remove: removeResistance,
  } = useFieldArray({
    control,
    name: 'itemData.body.resistances',
  })

  const {
    fields: weaknesses,
    append: appendWeakness,
    remove: removeWeakness,
  } = useFieldArray({
    control,
    name: 'itemData.body.weaknesses',
  })

  // useEffect(() => {
  //   if (!type) return

  //   setSelectedItemType(type)
  // }, [type])

  const [characters, setCharacters] = useState<Tag[]>([])
  const [charactersLoading, setCharactersLoading] = useState<boolean>(false)
  const [artists, setArtists] = useState<Tag[]>([])
  const [artistsLoading, setArtistsLoading] = useState<boolean>(false)
  const [categories, setCategories] = useState<Tag[]>([])
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(false)
  const [subtypes, setSubtypes] = useState<Tag[]>([])
  const [subtypesLoading, setSubtypesLoading] = useState<boolean>(false)
  const [rarities, setRarities] = useState<Tag[]>([])
  const [raritiesLoading, setRaritiesLoading] = useState<boolean>(false)

  const getTags = useCallback((type: string, sort: string) => {
    let parentId

    if (type === 'Character') {
      if (charactersLoading) return
      setCharactersLoading(true)
      // parentId = 6
    }

    if (type === 'Illustrator') {
      if (artistsLoading) return
      setArtistsLoading(true)
    }

    if (type === 'Category') {
      if (categoriesLoading) return
      setCategoriesLoading(true)
    }

    if (type === 'CardLayout') {
      if (subtypesLoading) return
      setCategoriesLoading(true)
    }

    if (type === 'Rarity') {
      if (raritiesLoading) return
      setRaritiesLoading(true)
    }

    tagsRepository
      .index({
        params: {
          page: 0,
          limit: 9999,
          type,
          sort,
          tagId: parentId,
        }
      })
      .then(({ tags }) => {
        if (type === 'Character') setCharacters(tags)
        if (type === 'Illustrator') setArtists(tags)
        if (type === 'Category') setCategories(tags)
        if (type === 'CardLayout') setSubtypes(tags)
        if (type === 'Rarity') setRarities(tags)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        if (type === 'Character') setCharactersLoading(false)
        if (type === 'Illustrator') setArtistsLoading(false)
        if (type === 'Category') setCategoriesLoading(false)
        if (type === 'CardLayout') setSubtypesLoading(false)
        if (type === 'Rarity') setRaritiesLoading(false)
      })
  }, [addError, artistsLoading, categoriesLoading, charactersLoading, raritiesLoading, subtypesLoading])

  useEffect(() => {
    if (characters.length > 0 || (supertype !== 'Pokémon' && supertype !== 'Trainer')) return

    getTags('Character', 'ordinality')
  }, [characters.length, getTags, supertype])

  useEffect(() => {
    if (artists.length !== 0 || artistsLoading) return

    getTags('Illustrator', 'name_en_asc')
  }, [artists, artistsLoading, getTags])

  useEffect(() => {
    if (categories.length !== 0 || categoriesLoading) return

    getTags('Category', 'created_desc')
  }, [categories, categoriesLoading, getTags])

  useEffect(() => {
    if (subtypes.length !== 0 || subtypesLoading) return

    getTags('CardLayout', 'ordinality_asc')
  }, [getTags, subtypes.length, subtypesLoading])

  useEffect(() => {
    if (rarities.length !== 0 || raritiesLoading) return

    getTags('Rarity', 'name_ja_asc')
  }, [getTags, rarities.length, raritiesLoading])

  useEffect(() => {
    // update old nationalPokedexNumbers to match holic tag ids
    if (characters.length === 0) return

    if (nationalPokedexNumbers.length > 0 && nationalPokedexNumbers[0].substring(0, 6) !== 'holic-') {
      setValue('itemData.body.nationalPokedexNumbers', nationalPokedexNumbers.map(n => `holic-${characters.find(c => c.ordinality === Number(n))?.id ?? ''}`))
    }
  }, [characters, nationalPokedexNumbers, setValue])

  useEffect(() => {
    // update old artists to match holic tag ids
    if (artists.length === 0) return

    if (artists.length > 0 && artist.length > 0 && artist.split('holic-').length === 1) {
      setValue('itemData.body.artist', artist.split(/, |\/| + /).map(a => {
        return `holic-${artists.find(artist => artist.nameEn === a)?.id ?? ''}`
      }).join(', '))
    }
  }, [artist, artists, characters, nationalPokedexNumbers, setValue])

  const nationalPokedexNumbersForSelect = useMemo(() => {
    return characters.map(c => {
      return {
        label: i18n.language === 'ja' && c.nameJa ? `${c.nameJa} ・ ${c.nameEn ?? ''}` : `${c.nameEn ?? ''} ・ ${c.nameJa ?? ''}`,
        value: `holic-${c.id}`,
      }
    })
  }, [characters, i18n.language])

  const artistsForSelect = useMemo(() => {
    return artists.map(c => {
      return {
        label: i18n.language === 'ja' && c.nameJa ? c.nameJa : c.nameEn,
        value: `holic-${c.id}`,
      }
    })
  }, [artists, i18n.language])

  // const categoriesForSelect = useMemo(() => {
  //   return categories.map(c => {
  //     return {
  //       label: c.nameEn,
  //       value: c.nameEn,
  //     }
  //   })
  // }, [categories])

  const subtypesForSelect = useMemo(() => {
    return subtypes.map(c => {
      return {
        label: i18n.language === 'ja' && c.nameJa ? c.nameJa : c.nameEn,
        value: c.nameEn,
      }
    })
  }, [i18n.language, subtypes])

  const raritiesForSelect = useMemo(() => {
    return rarities.map(c => {
      return {
        label: i18n.language === 'ja' && c.nameJa ? c.nameJa : c.nameEn,
        value: c.nameEn,
      }
    })
  }, [i18n.language, rarities])

  const selectedCharacters = useMemo(() => {
    return nationalPokedexNumbersForSelect.filter(t => nationalPokedexNumbers.find(v => v === t.value))
  }, [nationalPokedexNumbers, nationalPokedexNumbersForSelect])

  const selectedSubtypes = useMemo(() => {
    return subtypesForSelect.filter(t => subtype.find(v => v === t.value))
  }, [subtype, subtypesForSelect])

  const selectedArtist = useMemo(() => {
    return artistsForSelect.filter(t => artist.split(/, |\/| + /).find(v => v === t.value))
  }, [artist, artistsForSelect])

  const selectedRarity = useMemo(() => {
    return raritiesForSelect.find(t => t.value === rarity)
  }, [raritiesForSelect, rarity])

  const selectedUtilities = useMemo(() => {
    return utilitiesField.map(u => {
      const utility = utilities.find(t => t.value === u)
      return {
        label: utility?.label ?? '',
        value: u,
      }
    })
  }, [utilities, utilitiesField])

  // const addNumber = useCallback(() => {
  //   setValue('itemData.body.numbers', numbers ? numbers.concat([0]) : [0])
  // }, [numbers, setValue])

  // const deleteNumber = useCallback((index) => {
  //   if (!numbers || numbers.length <= 1) {
  //     setValue('itemData.body.numbers', [])
  //     return
  //   }

  //   setValue('itemData.body.numbers', numbers.filter((_, i) => i !== index))
  // }, [numbers, setValue])

  const addRule = useCallback(() => {
    setValue('itemData.body.rules', rules ? rules.concat(['']) : [''])
  }, [rules, setValue])

  useEffect(() => {
    if (!rules || rules.length === 0) return

    setFocus(`itemData.body.rules.${rules?.length - 1}`)
  }, [rules?.length, rules, setFocus])

  const deleteRule = useCallback((index) => {
    if (!rules || rules.length <= 1) {
      setValue('itemData.body.rules', [])
      return
    }

    setValue('itemData.body.rules', rules.filter((_, i) => i !== index))
  }, [rules, setValue])

  // useEffect(() => {
  //   if (!numbers || numbers.length === 0) return

  //   setFocus(`itemData.body.numbers.${numbers?.length - 1}`)
  // }, [numbers, numbers?.length, setFocus])

  const setCost = useCallback((ref, val) => {
    const currentCost = getValues(ref) ?? []

    setValue(ref, currentCost.concat([val.value]))
  }, [getValues, setValue])

  const deleteCost = useCallback((ref, index) => {
    const currentCost = getValues(ref) ?? []

    setValue(ref, currentCost.filter((_, i) => i !== index))
  }, [getValues, setValue])

  const setRetreatCost = useCallback((val) => {
    setValue('itemData.body.retreatCost', retreatCost.concat([val.value]))
  }, [retreatCost, setValue])

  const deleteRetreatCost = useCallback((index) => {
    setValue('itemData.body.retreatCost', retreatCost.filter((_, i) => i !== index))
  }, [retreatCost, setValue])

  const closeModal = useCallback((resetForm) => {
    if (resetForm) {
      setWorkingItem(undefined)
      reset(formDefaults)
    }

    setModalDisplayed(undefined)
  }, [reset])

  const createdModal = useMemo(() => {
    if (!modalDisplayed) return

    return (
      <ModalWrapper>
        <h1>{ t(`admin.items.${modalDisplayed}.modal.title`) }</h1>
        <p>{ t(`admin.items.${modalDisplayed}.modal.body`) }</p>
        <p>
          <Link to={ '/admin/items/create' } className="button full white" onClick={ () => { closeModal(true) } }>{ t('admin.items.create.modal.buttons.new') }</Link>
        </p>
        <p>
          <Link to="/admin/items/list" className="button full white" onClick={ () => { closeModal(false) } }>{ t('admin.items.create.modal.buttons.list') }</Link>
        </p>
        <p>
          <Link to={ `/admin/items/images/${workingItem?.id ?? ''}` } className="button full" onClick={ () => { closeModal(true) } }>{ t('admin.items.create.modal.buttons.images') }</Link>
        </p>
        <p>
          <Link to={ `/admin/items/edit/${workingItem?.id ?? ''}` } className="button full" onClick={ () => { closeModal(false) } }>{ t('admin.items.create.modal.buttons.edit') }</Link>
        </p>
      </ModalWrapper>
    )
  }, [modalDisplayed, t, closeModal, workingItem?.id])

  return (
    <>
      { createdModal }
      <form onSubmit={ handleSubmit(onSubmit) } className="narrow">
        <h2>{ workingItem ? 'Edit an item' : 'New item' }</h2>
        <p>
          <label>{ t('admin.items.create.fields.name_en') }</label>
          <input type="text" className="full" { ...registerForm('nameEn') } placeholder="English" onBlur={ _ => nameBlur() } />
        </p>
        <p>
          <label>{ t('admin.items.create.fields.name_ja') }</label>
          <input type="text" className="full" { ...registerForm('nameJa') } placeholder="日本語" onBlur={ _ => nameBlur() } />
        </p>
        {
          (['CardLayout', 'CardType', 'Character', 'Illustrator', 'Rarity'].find((t) => type === t))
            ? null
            : <p>
              <label>{ t('admin.items.create.fields.release_date') }</label>
              <input type="date" className="full" { ...registerForm('releaseDate') } />
            </p>
        }
        <div className="select">
          <label>
            { t('admin.items.create.fields.type') }
          </label>
          {
            <Controller
              control={ control }
              name='type'
              render={ ({ field }) => (
                <Select
                  isClearable
                  options={ itemTypes }
                  onChange={ val => field.onChange(val?.value) }
                  placeholder={ t('defaults.select') }
                  value={ itemTypes.find(t => t.value === getValues('type')) }
                />
              ) }
            />
          }
        </div>
        {
          type !== 'CardSingle'
            ? null
            : <>
              <hr />
              <h3>{ t('admin.items.create.titles.card_data') }</h3>
              <p>
                <label>{ t('admin.items.create.fields.wiki_url') }</label>
                <input type="text" className="full" { ...registerForm('itemData.body.wikiUrl') } />
              </p>
              <p>
                <label>{ t('admin.items.create.fields.supertype') }</label>
                {
                  <Controller
                    control={ control }
                    name='itemData.body.supertype'
                    render={ ({ field }) => (
                      <Select
                        isClearable
                        options={ supertypes }
                        onChange={ val => field.onChange(val?.value) }
                        placeholder={ t('defaults.select') }
                        value={ supertypes.find(t => t.value === getValues('itemData.body.supertype')) }
                      />
                    ) }
                  />
                }
              </p>
              {
                supertype === 'Energy' || (nationalPokedexNumbersForSelect.length === 0 && !charactersLoading)
                  ? null
                  : <p>
                    <label>{ t('admin.items.create.fields.national_pokedex_numbers') }</label>
                    {
                      <Controller
                        control={ control }
                        name='itemData.body.nationalPokedexNumbers'
                        render={ ({ field }) => (
                          <Select
                            options={ nationalPokedexNumbersForSelect }
                            isLoading={ charactersLoading }
                            isMulti
                            placeholder={ t('defaults.select') }
                            onChange= { val => field.onChange(val.map(v => v.value)) }
                            value={ selectedCharacters }
                          />
                        ) }
                      />
                    }
                  </p>
              }
              <p>
                <label>{ t('admin.items.create.fields.card_name') }</label>
                <input type="text" className="full" { ...registerForm('itemData.body.name') } />
              </p>
              <p>
                <label>{ t('admin.items.create.fields.subtypes') }</label>
                {
                  <Controller
                    control={ control }
                    name='itemData.body.subtypes'
                    render={ ({ field }) => (
                      <Select
                        options={ subtypesForSelect }
                        isMulti
                        placeholder={ t('defaults.select') }
                        onChange= { val => field.onChange(val.map(v => v.value)) }
                        value={ selectedSubtypes }
                      />
                    ) }
                  />
                }
              </p>
              {
                supertype === 'Energy' || supertype === 'Trainer' || nationalPokedexNumbers.length > 1
                  ? null
                  : <p>
                    <label>{ t('admin.items.create.fields.evolves_from') }</label>
                    {
                      <input type="text" className="full" { ...registerForm('itemData.body.evolvesFrom') } />
                    }
                  </p>
              }
              {
                supertype === 'Energy'
                  ? null
                  : <p>
                    <label>{ t('admin.items.create.fields.hp') }</label>
                    <input type="text" className="full" { ...registerForm('itemData.body.hp') } />
                  </p>
              }
              {
                supertype === 'Trainer'
                  ? null
                  : <p>
                    <label>{ t('admin.items.create.fields.types') }</label>
                    {
                      <Controller
                        control={ control }
                        name='itemData.body.types'
                        render={ ({ field }) => (
                          <Select
                            options={ types }
                            isMulti
                            placeholder={ t('defaults.select') }
                            onChange= { val => field.onChange(val.map(v => v.value)) }
                            value={ types.filter(t => getValues('itemData.body.types').find(v => v === t.value)) }
                          />
                        ) }
                      />
                    }
                  </p>
              }
              <p>
                <label>{ t('admin.items.create.fields.rule') }</label>
                <ul className='editable'>
                  {
                    rules?.map((rule, i) => {
                      return <li key={ i }>
                        <input value={ rule } key={ `itemData.body.rules.${i}` } type="text" { ...registerForm(`itemData.body.rules.${i}`) } />
                        <button className='tool' onClick={ e => { deleteRule(i); e.preventDefault() } }>⌫</button>
                      </li>
                    })
                  }
                </ul>
                <button className='tool' onClick={ e => { addRule(); e.preventDefault() } }>{ t('admin.items.create.fields.rules.add') }</button>
              </p>
              {
                supertype === 'Energy'
                  ? null
                  : <p>
                    <label>{ t('admin.items.create.fields.ability') }</label>
                    {
                      abilities.map((item, index) => (
                        <ul key={ `ability-${index}` } className='editable'>
                          <li>
                            <strong>{ t('admin.items.create.fields.abilities.title', { i: index + 1 }) }</strong>
                            <button className='tool' onClick={ (e) => { removeAbility(index); e.preventDefault() } }>⌫</button>
                          </li>
                          <li>
                            <label>{ t('admin.items.create.fields.abilities.name') }</label>
                            <input type="text" { ...registerForm(`itemData.body.abilities.${index}.name`) } />
                          </li>
                          <li>
                            <label>{ t('admin.items.create.fields.abilities.text') }</label>
                            <label><textarea { ...registerForm(`itemData.body.abilities.${index}.text`) } /></label>
                          </li>
                        </ul>
                      ))
                    }
                    <button className='tool' onClick={ (e) => { appendAbility({}); e.preventDefault() } }>{ t('admin.items.create.fields.abilities.add') }</button>
                  </p>
              }
              {
                supertype === 'Energy'
                  ? null
                  : <p>
                    <label>{ t('admin.items.create.fields.attack') }</label>
                    {
                      attacks.map((item, index) => {
                        const costs = getValues(`itemData.body.attacks.${index}.cost`) ?? []

                        return <ul key={ `attack-${index}` } className='editable'>
                          <li>
                            <strong>{ t('admin.items.create.fields.attacks.title', { i: index + 1 }) }</strong>
                            <button className='tool' onClick={ (e) => { removeAttack(index); e.preventDefault() } }>⌫</button>
                          </li>
                          <li>
                            <label>{ t('admin.items.create.fields.attacks.cost') }</label>
                            <span>{
                              costs.map((cost, i) => {
                                return <button className='tool' key={ i } onClick={ e => { deleteCost(`itemData.body.attacks.${index}.cost`, i); e.preventDefault() } }>{ t(`admin.items.energy.${cost.toLowerCase()}`) } ⌫</button>
                              })
                            }</span>
                          </li>
                          <li>
                            <label>{ t('admin.items.create.fields.attacks.cost_add') }</label>
                            <Select
                              options={ types }
                              placeholder={ t('defaults.select') }
                              onChange= { val => setCost(`itemData.body.attacks.${index}.cost`, val) }
                            />
                            <input value={ costs.length } type="hidden" { ...registerForm(`itemData.body.attacks.${index}.convertedEnergyCost`) } />
                          </li>
                          <li>
                            <label>{ t('admin.items.create.fields.attacks.name') }</label>
                            <label><input type="text" { ...registerForm(`itemData.body.attacks.${index}.name`) } /></label>
                          </li>
                          <li>
                            <label>{ t('admin.items.create.fields.attacks.text') }</label>
                            <label><textarea { ...registerForm(`itemData.body.attacks.${index}.text`) } /></label>
                          </li>
                          <li>
                            <label>{ t('admin.items.create.fields.attacks.damage') }</label>
                            <label><input type="text" { ...registerForm(`itemData.body.attacks.${index}.damage`) } /></label>
                          </li>
                        </ul>
                      })
                    }
                    <button className='tool' onClick={ (e) => { appendAttack({}); e.preventDefault() } }>{ t('admin.items.create.fields.attacks.add') }</button>
                  </p>
              }
              {
                supertype === 'Energy' || supertype === 'Trainer'
                  ? null
                  : <>
                    <label>{ t('admin.items.create.fields.retreat_cost') }</label>
                    <p>
                      {
                        retreatCost.map((cost, i) => {
                          return <button className='tool' key={ i } onClick={ e => { deleteRetreatCost(i); e.preventDefault() } }>{ t(`admin.items.energy.${cost.toLowerCase()}`) } ⌫</button>
                        })
                      }
                      {
                        retreatCost.length === 0
                          ? t('admin.items.create.fields.retreat_costs.none')
                          : null
                      }
                    </p>
                    <ul className='editable'>
                      <li>
                        <label>{ t('admin.items.create.fields.retreat_costs.add') }</label>
                        <Select
                          options={ types }
                          placeholder={ t('defaults.select') }
                          onChange= { val => setRetreatCost(val) }
                        />
                      </li>
                    </ul>
                  </>
              }
              {
                supertype === 'Energy' || supertype === 'Trainer'
                  ? null
                  : <p>
                    <label>{ t('admin.items.create.fields.weakness') }</label>
                    {
                      weaknesses.map((item, index) => (
                        <ul key={ `weakness-${index}` } className='editable'>
                          <li>
                            <strong>{ t('admin.items.create.fields.weaknesses.title', { i: index + 1 }) }</strong>
                            <button className='tool' onClick={ (e) => { removeWeakness(index); e.preventDefault() } }>⌫</button>
                          </li>
                          <li>
                            <label>{ t('admin.items.create.fields.weaknesses.type') }</label>
                            {
                              <Controller
                                control={ control }
                                name={ `itemData.body.weaknesses.${index}.type` }
                                render={ ({ field }) => (
                                  <Select
                                    options={ types }
                                    placeholder={ t('defaults.select') }
                                    onChange= { val => field.onChange(val?.value) }
                                    value={ types.filter(t => getValues(`itemData.body.weaknesses.${index}.type`) === t.value) }
                                  />
                                ) }
                              />
                            }
                          </li>
                          <li>
                            <label>{ t('admin.items.create.fields.weaknesses.value') }</label>
                            <input type="text" { ...registerForm(`itemData.body.weaknesses.${index}.value`) } />
                          </li>
                        </ul>
                      ))
                    }
                    <button className='tool' onClick={ (e) => { appendWeakness({}); e.preventDefault() } }>{ t('admin.items.create.fields.weaknesses.add') }</button>
                  </p>
              }
              {
                supertype === 'Energy' || supertype === 'Trainer'
                  ? null
                  : <p>
                    <label>{ t('admin.items.create.fields.resistance') }</label>
                    {
                      resistances.map((item, index) => (
                        <ul key={ `resistance-${index}` } className='editable'>
                          <li>
                            <strong>{ t('admin.items.create.fields.resistances.title', { i: index + 1 }) }</strong>
                            <button className='tool' onClick={ (e) => { removeResistance(index); e.preventDefault() } }>⌫</button>
                          </li>
                          <li>
                            <label>{ t('admin.items.create.fields.resistances.type') }</label>
                            {
                              <Controller
                                control={ control }
                                name={ `itemData.body.resistances.${index}.type` }
                                render={ ({ field }) => (
                                  <Select
                                    options={ types }
                                    placeholder={ t('defaults.select') }
                                    onChange= { val => field.onChange(val?.value) }
                                    value={ types.filter(t => getValues(`itemData.body.resistances.${index}.type`) === t.value) }
                                  />
                                ) }
                              />
                            }
                          </li>
                          <li>
                            <label>{ t('admin.items.create.fields.resistances.value') }</label>
                            <input type="text" { ...registerForm(`itemData.body.resistances.${index}.value`) } />
                          </li>
                        </ul>
                      ))
                    }
                    <button className='tool' onClick={ (e) => { appendResistance({}); e.preventDefault() } }>{ t('admin.items.create.fields.resistances.add') }</button>
                  </p>
              }
              <p>
                <label>{ t('admin.items.create.fields.artist') }</label>
                {
                  <Controller
                    control={ control }
                    name='itemData.body.artist'
                    render={ ({ field }) => (
                      <Select
                        options={ artistsForSelect }
                        isMulti
                        placeholder={ t('defaults.select') }
                        onChange= { val => field.onChange(val.map(v => v.value).join(', ')) }
                        value={ selectedArtist }
                      />
                    ) }
                  />
                }
              </p>
              { /* <p>
                <label>Series</label>
                {
                  <Controller
                    control={ control }
                    name='itemData.body.series'
                    render={ ({ field }) => (
                      <Select
                        options={ categoriesForSelect }
                        isMulti
                        placeholder={ t('defaults.select') }
                        onChange= { val => field.onChange(val.map(v => v.value)) }
                        value={ categoriesForSelect.filter(t => getValues('itemData.body.series').find(v => v === t.value)) }
                      />
                    ) }
                  />
                }
              </p> */ }
              <p>
                <label>{ t('admin.items.create.fields.rarity') }</label>
                {
                  <Controller
                    control={ control }
                    name='itemData.body.rarity'
                    render={ ({ field }) => (
                      <Select
                        options={ raritiesForSelect }
                        onChange={ val => field.onChange(val?.value) }
                        placeholder={ t('defaults.select') }
                        value={ selectedRarity }
                      />
                    ) }
                  />
                }
              </p>
              <p>
                <label>{ t('admin.items.create.fields.number') }</label>
                <input type="text" className="full" { ...registerForm('itemData.body.number') } />
              </p>
              {
                <p>
                  <label>{ t('admin.items.create.fields.flavor_text') }</label>
                  <textarea className="full" { ...registerForm('itemData.body.flavorText') } />
                </p>
              }
              {
                // supertype === 'Energy'
                //   ? null
                //   : <p>
                //     <label>Numbers</label>
                //     <ul className='editable'>
                //       {
                //         numbers?.map((number, i) => {
                //           return <li key={ i }>
                //             <input value={ number } key={ `itemData.body.numbers.${i}` } type="number" { ...registerForm(`itemData.body.numbers.${i}`) } />
                //             <button className='tool' onClick={ e => { deleteNumber(i); e.preventDefault() } }>⌫</button>
                //           </li>
                //         })
                //       }
                //     </ul>
                //     <button className='tool' onClick={ e => { addNumber(); e.preventDefault() } }>Add number</button>
                //   </p>
              }
              <p>
                <label>{ t('admin.items.create.fields.regulation') }</label>
                {
                  <Controller
                    control={ control }
                    name='itemData.body.regulation'
                    render={ ({ field }) => (
                      <Select
                        isClearable
                        options={ regulations }
                        onChange={ val => field.onChange(val?.value) }
                        placeholder={ t('defaults.select') }
                        value={ regulations.find(t => t.value === getValues('itemData.body.regulation')) }
                      />
                    ) }
                  />
                }
              </p>
              <p>
                <label>{ t('admin.items.create.titles.additional') }</label>
                <button className='tool' onClick={ e => { setShowOldData(v => !v); e.preventDefault() } }>{ showOldData ? 'Hide' : 'Show' }</button>
              </p>
              {
                !showOldData
                  ? null
                  : <>
                    {
                      supertype === 'Energy' || supertype === 'Trainer'
                        ? null
                        : <p>
                          <label>{ t('admin.items.create.fields.level') }</label>
                          <input type="text" className="full" { ...registerForm('itemData.body.level') } />
                        </p>
                    }
                    <p>
                      <label>{ t('admin.items.create.fields.id') }</label>
                      <input type="text" className="full" { ...registerForm('itemData.body.id') } />
                    </p>
                    {
                      supertype === 'Energy'
                        ? null
                        : <p>
                          <label>{ t('admin.items.create.fields.gb_pack') }</label>
                          {
                            <Controller
                              control={ control }
                              name='itemData.body.gbPack'
                              render={ ({ field }) => (
                                <Select
                                  isClearable
                                  options={ gbpacks }
                                  onChange={ val => field.onChange(val?.value) }
                                  placeholder={ t('defaults.select') }
                                  value={ gbpacks.find(t => t.value === getValues('itemData.body.gbPack')) }
                                />
                              ) }
                            />
                          }
                        </p>
                    }
                    {
                      supertype === 'Energy'
                        ? null
                        : <p>
                          <label>{ t('admin.items.create.fields.gb2_pack') }</label>
                          {
                            <Controller
                              control={ control }
                              name='itemData.body.gb2Pack'
                              render={ ({ field }) => (
                                <Select
                                  isClearable
                                  options={ gb2packs }
                                  onChange={ val => field.onChange(val?.value) }
                                  placeholder={ t('defaults.select') }
                                  value={ gb2packs.find(t => t.value === getValues('itemData.body.gb2Pack')) }
                                />
                              ) }
                            />
                          }
                        </p>
                    }
                  </>
              }
              <hr />
              <h3>{ t('admin.items.create.fields.utilities') }</h3>
              <p>
                {
                  <Controller
                    control={ control }
                    name='utilities'
                    render={ ({ field }) => (
                      <Select
                        isClearable
                        options={ utilities }
                        onChange={ val => field.onChange(val.map(v => v.value)) }
                        placeholder={ t('defaults.select') }
                        value={ selectedUtilities }
                        isMulti
                      />
                    ) }
                  />
                }
              </p>
              <hr />
            </>
        }
        <p>
          <button className="full" disabled={ loading }>
            { workingItem ? t('admin.items.create.edit_button') : t('admin.items.create.save_button') }
          </button>
        </p>
        {
          workingItem
            ? <>
              <hr />
              <p>
                <button className="tool" disabled={ loading } onClick={ _ => { setWorkingItemId(undefined) } }>
                  Save as a new item
                </button>
              </p>
            </>
            : null
        }
      </form>
    </>
  )
}

export { AdminItemsCreate }
