import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Item } from '../types'
import { ModalWrapper } from './modal_wrapper'

type Props = Readonly<{
  // title: string
  // url: string
  onClose: () => void
  item: Item
}>

enum GuestModalScreen {
  ChooseAction = 1,
  SetupCollection = 2,
  SetupCompleted = 3,
}

// TODO: need to fix auth type definition
// TODO: need to pass globalFunctions (we should omit globalFunctions later)
const GuestModal = ({ onClose, item }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [screen] = useState<GuestModalScreen>(GuestModalScreen.ChooseAction)

  // FIXME
  // if (!auth.settings.add_card) {
  //   globalFunctions.addSetting('add_card', modalVars)
  // }

  // const continueAsGuest = useCallback(() => {
  //   setScreen(GuestModalScreen.SetupCollection)
  // }, [])

  const goToLogin = useCallback(() => {
    navigate(`/login?item_id=${item.id}`)
    onClose()
  }, [navigate, item.id, onClose])

  const goToRegister = useCallback(() => {
    navigate(`/register/nologin?item_id=${item.id}`)
    onClose()
  }, [navigate, item.id, onClose])

  // useEffect(() => {
  //   if (screen !== GuestModalScreen.SetupCollection) return

  //   collectionsRepository
  //     .create()
  //     .then(({ id }) => {
  //       // TODO: We can store collection_id in session. And add some endpoint to fetch it. ( '/u/me' or someting)

  //       localStorage.setItem('collection_id', String(id))

  //       // const collectionObject = collection
  //       // collectionObject.id = id
  //       // collectionObject.public = true

  //       // set the collection
  //       // globalFunctions.collection_update(collectionObject)

  //       // add the card
  //       // FIXME: need to pass the add_card object
  //       // globalFunctions.collection_add_card_after_auth(this.props.auth.settings.add_card)

  //       // globalFunctions.callModal('collection_guest_ready')

  //       setScreen(GuestModalScreen.SetupCompleted)
  //     })
  //     .catch((err: AxiosError) => {
  //       // console.error(err)
  //       // TODO: make error notify common function.
  //       alert(err)
  //     })
  // }, [screen])

  const chooseActionView = useMemo(() =>
    <>
      <h1>{ t('modal.collection.add_unauthenticated.title') }</h1>
      <p>
        { t('modal.collection.add_unauthenticated.explanation') }
        <strong>{ t('modal.collection.add_unauthenticated.nice') }</strong>
      </p>
      <p>
        { t('modal.collection.add_unauthenticated.question') }
      </p>
      { /* <p>
        <button className="tool large" onClick={ continueAsGuest }>
          { t('modal.collection.add_unauthenticated.button_guest') }
        </button>
      </p> */ }
      <p>
        <button className="tool large" onClick={ goToLogin }>
          { t('modal.collection.add_unauthenticated.button_login') }
        </button>
      </p>
      <p>
        <button className="full" onClick={ goToRegister }>
          { t('modal.collection.add_unauthenticated.button_register') }
        </button>
      </p>
      <p>
        <button className="tool large" onClick={ onClose }>
          { t('modal.collection.add_unauthenticated.button_close') }
        </button>
      </p>
    </>
  , [goToLogin, goToRegister, onClose, t])

  const setupCollectionView = useMemo(() =>
    <>
      <h1>{ t('modal.collection.guest_setup.title') }</h1>
      <p>
        { t('modal.collection.guest_setup.explanation') }
      </p>
      <p>
      </p>
    </>
  , [t])

  const setupCompletedView = useMemo(() =>
    <>
      <h1>{ t('modal.collection.guest_ready.title') }</h1>
      <p>
        { t('modal.collection.guest_ready.explanation') }
      </p>
      <p>
        <button className="full" onClick={ onClose }>
          { t('modal.collection.guest_ready.button') }
        </button>
      </p>
    </>
  , [onClose, t])

  const currentScreen = useMemo(() => {
    switch (screen) {
      case GuestModalScreen.ChooseAction:
        return chooseActionView
      case GuestModalScreen.SetupCollection:
        return setupCollectionView
      case GuestModalScreen.SetupCompleted:
        return setupCompletedView
      default:
        return null
    }
  }, [chooseActionView, screen, setupCollectionView, setupCompletedView])

  return (
    <ModalWrapper>
      { currentScreen }
    </ModalWrapper>
  )
}

export { GuestModal }
