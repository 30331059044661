import { AxiosError } from 'axios'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ErrorsContext } from '../contexts/errors_context'
import { messagesRepository } from '../repositories/messages_repository'
import { Message } from '../types'
import Moment from 'react-moment'
import joypixels from 'emoji-toolkit'
import 'moment/locale/ja'
import { emojiPattern } from '../lib/emoji_pattern'

type Props = Readonly<{
  orderId: number
  orderStatus: string
  messages?: Message[]
  callback: (m: Message) => void
  role: string
}>

const OrderMessages = ({ orderId, orderStatus, messages, callback, role }: Props) => {
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)

  const [sendingMessage, setSendingMessage] = useState<boolean>(false)

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
  } = useForm<Message>()

  // const getMessages = useCallback(() => {
  // }, [])

  // useEffect(() => {
  //   getMessages()
  // }, [getMessages])

  const onSubmit = useCallback((params) => {
    if (sendingMessage) return
    // TODO: Validation

    messagesRepository
      .create(orderId, params)
      .then(() => {
        callback(params)
        setValue('body', '')
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setSendingMessage(false)
      })

    setSendingMessage(true)
  }, [addError, callback, orderId, sendingMessage, setValue])

  const messageList = useMemo(() => {
    if (!messages) {
      return <div className='message'>
        <p>No messages</p>
      </div>
    }

    return messages.map((m, index) => {
      const nextMessage = index + 1 < messages.length ? messages[index + 1] : undefined
      const prevMessage = index > 0 ? messages[index - 1] : undefined

      const thisDate = new Date(m.createdAt)
      const nextDate = nextMessage ? new Date(nextMessage?.createdAt) : new Date()

      // show date if:
      // this is the last message in a chain of messages from one side, or
      // the next message in the chain was posted more than 24h ago, or
      // no next message
      const showDate = !nextMessage || nextMessage?.isMe !== m.isMe || (nextDate.getTime() - thisDate.getTime()) > 60 * 60 * 1000 * 24

      return <div key={ m.id } className={ clsx('message', { mine: m.isMe }, { lastInChain: !nextMessage || nextMessage?.isMe !== m.isMe }, { showDate }) }>
        {
          !m.isMe && prevMessage?.isMe !== m.isMe
            ? <div className='details'>
              <span className='username'>
                {
                  role === 'buyer'
                    ? t('order.messages.names.seller')
                    : t('order.messages.names.buyer')
                }
              </span>
            </div>
            : null
        }
        <div className='body'>
          { /* <Twemoji options={ { className: 'twemoji' } }>{ m.body }</Twemoji> */ }
          <div>
            {
              m.body.split(emojiPattern).map(str => {
                const strEmoji = joypixels.toShort(str)

                return strEmoji.match(/^:[a-z0-9_]+:$/i)
                  ? <span className='emoji' dangerouslySetInnerHTML={ { __html: joypixels.toImage(str) } } />
                  : strEmoji
              })
            }
          </div>
        </div>
        {
          showDate
            ? <div className='details'>
              <Moment interval={ 1000 } fromNow>{ m.createdAt }</Moment>
            </div>
            : null
        }
      </div>
    })
  }, [messages, role, t])

  const [bodyLength, setBodyLength] = useState<number>(0)

  const resizeTextArea = useCallback((e: HTMLTextAreaElement) => {
    const l = getValues('body').length

    if (l < bodyLength) {
      e.style.height = '1px'
    }

    e.style.height = String(Number(e.scrollHeight)) + 'px'

    setBodyLength(l)
  }, [bodyLength, getValues])

  const messageForm = useMemo(() => {
    if (orderStatus === 'canceled') {
      return <p className='small center'>注文はキャンセルされたため、新しいメッセージを送信できません</p>
    }

    if (sendingMessage) {
      return <p>メッセージを送信中です…</p>
    }

    return <form onSubmit={ handleSubmit(onSubmit) }>
      <p>
        <textarea { ...register('body') } onKeyUp={ e => resizeTextArea(e.currentTarget) } />
      </p>
      <p className='send-button'>
        <input type='submit' className='small' value='メッセージを送る' />
      </p>
    </form>
  }, [handleSubmit, onSubmit, orderStatus, register, resizeTextArea, sendingMessage])

  return (
    <>
      <h3>{ t('order.messages.title') }</h3>
      <div className='messages'>
        { messageList }
        { messageForm }
      </div>
    </>
  )
}

export { OrderMessages }
