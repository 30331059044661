import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { itemsRepository } from '../repositories/items_repository'
import axios, { AxiosError, CancelTokenSource } from 'axios'
import { ErrorsContext } from '../contexts/errors_context'
import { Item } from '../types'
import { Link } from 'react-router-dom'

type Props = Readonly<{
  currentPage: number
}>

type SelectOption = Readonly<{
  value: string
  label: string
}>

const itemTypes: SelectOption[] = [
  {
    value: 'CardSingle',
    label: 'Card',
  },
  {
    value: 'CardBooster',
    label: 'Booster',
  },
]

const itemSorts: SelectOption[] = [
  {
    value: 'name_ja_asc',
    label: 'Japanese name (あ first)',
  },
  {
    value: 'name_ja_desc',
    label: 'Japanese name (ワ first)',
  },
  {
    value: 'name_en_asc',
    label: 'English name (A first)',
  },
  {
    value: 'name_en_desc',
    label: 'English name (Z first)',
  },
  {
    value: 'release_date_asc',
    label: 'Release date (oldest first)',
  },
  {
    value: 'release_date_desc',
    label: 'Release date (latest first)',
  },
  {
    value: 'created_asc',
    label: 'Created (oldest first)',
  },
  {
    value: 'created_desc',
    label: 'Createst (newest first)',
  },
]

const AdminItemsList = ({ currentPage }: Props) => {
  const { t, i18n } = useTranslation()

  const { addError } = useContext(ErrorsContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [items, setItems] = useState<Item[]>()
  const [type, setType] = useState<string>('')
  const [sort, setSort] = useState<string>('')
  const [query, setQuery] = useState<string>('')
  const [totalPages, setTotalPages] = useState<number>(0)

  const [axiosCancelTokens, setAxiosCancelTokens] = useState<CancelTokenSource[]>([])

  const resetAxios = useCallback((axiosCancelTokens) => {
    for (const t of axiosCancelTokens) t.cancel()
  }, [])

  const getItems = useCallback((type: string, query: string, sort: string, page: number) => {
    setLoading(true)

    const newAxiosCancelToken = axios.CancelToken.source()
    setAxiosCancelTokens((t) => [...t, newAxiosCancelToken])

    // get all items
    itemsRepository
      .index({
        query,
        page,
        sort,
        types: type ? [type] : [],
      },
      newAxiosCancelToken.token)
      .then(({ items, pagination }) => {
        setItems(items)
        setTotalPages(pagination.totalPages)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError])

  useEffect(() => {
    getItems(type, query, sort, currentPage)
  }, [type, currentPage, getItems, query, sort])

  const onChangeQuery = useCallback((e) => {
    resetAxios(axiosCancelTokens)
    setAxiosCancelTokens([])

    setQuery(e.target.value)
  }, [axiosCancelTokens, resetAxios])

  // useEffect(() => {
  //   // reset search query to avoid no results after search
  //   setQuery('')
  // }, [])

  const pagination = useMemo(() => {
    if (totalPages <= 1) return null

    return (
      <div className="pagination">
        <span>{ t('pagination_page') }</span>
        {
          [...Array(totalPages)].map((p, i) => {
            return (
              <Link
                key={ i }
                className={ `button ${i + 1 === currentPage ? 'selected' : ''}` }
                to={ `/admin/items/list?page=${i + 1}` }
              >
                { i + 1 }
              </Link>
            )
          })
        }
      </div>
    )
  }, [totalPages, t, currentPage])

  const itemsUi = useMemo(() => {
    if (loading) {
      return <>
        <p>Loading...</p>
      </>
    }

    return <>
      <ul className="editable">
        {
          items?.map((item) => {
            const title = (i18n.language === 'ja' && item.nameJa) ?? !item.nameEn ? item.nameJa : item.nameEn

            return (
              <li key={ item.id }>
                <span>
                  #{ item.id }: { title }
                </span>
                <span className="buttons">
                  <Link to={ `/admin/items/images/${item.id}` } className="button tool">Images{ item.images.length > 0 ? ` (${item.images.length})` : '' }</Link>
                  <Link to={ `/admin/items/edit/${item.id}` } className="button small">Edit</Link>
                </span>
              </li>
            )
          })
        }
      </ul>
      { pagination }
    </>
  }, [i18n.language, loading, pagination, items])

  const filterUi = useMemo(() => {
    return <>
      <div className="select">
        <Select
          options={ itemTypes }
          onChange={ (p) => setType(p?.value ?? '') }
          value={ itemTypes.find(t => t.value === type) }
          placeholder={ t('defaults.filter') }
          isClearable={ true }
          isDisabled={ loading }
          defaultValue= { itemTypes.find(s => s.value === 'CardSingle') }
        />
      </div>
      <div className="select">
        <Select
          options={ itemSorts }
          onChange={ (p) => setSort(p?.value ?? '') }
          value={ itemSorts.find(t => t.value === sort) }
          placeholder={ t('defaults.sort') }
          isClearable={ true }
          isDisabled={ loading }
          defaultValue= { itemSorts.find(s => s.value === 'created_desc') }
        />
      </div>
      <p>
        <input type="text" className="full" placeholder={ t('defaults.search') } name="query" value={ query } onChange={ onChangeQuery } />
      </p>
    </>
  }, [loading, onChangeQuery, query, sort, t, type])

  return (
    <div className="narrow">
      { filterUi }
      { itemsUi }
    </div>
  )
}

export { AdminItemsList }
