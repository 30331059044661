import { axiosInstance } from '../lib/api_client'

type CreateParams = Readonly<{
  body: string
}>

type MessagesRepository = Readonly<{
  create: (orderId: number, params: CreateParams) => Promise<void>
}>

const messagesRepository: MessagesRepository = {
  async create (orderId, params) {
    return await axiosInstance.post(`/orders/${orderId}/messages`, params).then(({ data }) => data)
  },
}

export { messagesRepository }
