import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Image, Item, Unit } from '../types'
import clsx from 'clsx'
import { ItemImages } from './item_images'

import '../stylesheets/ListingSingle.scss'

type Props = Readonly<{
  // cardCategoryVariant: CardCategoryVariant
  editing: boolean
  items: Item[]
  unit: Unit
}>

const ListingSingle = ({ editing, items, unit }: Props) => {
  const { t, i18n } = useTranslation()

  const [images, setImages] = useState<Image[]>()
  const [userImages, setUserImages] = useState<boolean>(false)

  // determine category
  // const category = useMemo<Tag|undefined>(() => {
  //   if (item.tags.length === 0) return

  //   return item.tags.reduce((prev, current) => prev.depth > current.depth || current.type !== 'Category' ? prev : current)
  // }, [item.tags])

  const itemName = useMemo(() => {
    return items.map(item => {
      if (i18n.language === 'ja') {
        return item.nameJa
      }

      return item.nameEn
    }).join(' / ')
  }, [i18n.language, items])

  // const itemCategoryLink = useMemo(() => {
  //   if (!category) return

  //   let title: string|undefined = category.slug ? category.slug.split('_')[0] : undefined

  //   if (!title || title.length < 1) {
  //     title = (i18n.language === 'ja' && category.nameJa) ?? !category.nameEn ? category.nameJa : category.nameEn
  //   }

  //   return <Link to={ `/categories/${category.id}` } className="card-category"><h4>{ title }</h4></Link>
  // }, [category, i18n.language])

  useEffect(() => {
    if (unit.images && unit.images.length > 0) {
      setImages(unit.images)
      setUserImages(true)
      return
    }

    setImages(items.map(item => item.images[0]))
    setUserImages(false)
  }, [items, unit.images])

  // const holicListing = useMemo(() => {
  //   if (!unit.listings) return null

  //   return unit.listings.find(l => l.platform === 'holic')
  // }, [unit.listings])

  const listing = useMemo(() => {
    if (!unit.listings) return null

    return unit.listings[0]
  }, [unit.listings])

  const price = useMemo(() => {
    if (!listing) return

    return Math.round(listing.priceJpy)
  }, [listing])

  const priceUi = useMemo(() => {
    if (unit?.public === false) {
      return <span className='private'></span>
    }

    if (typeof price === 'undefined') return

    if (price < 1) {
      return <span className='price'><span className='number'>{ t('listings.price.free') }</span></span>
    }

    return <span className='price'><span className='number'>{ price.toLocaleString() }</span><span className='symbol'>{ t('listings.price.currency_symbol') }</span></span>
  }, [price, t, unit?.public])

  const sold = useMemo(() => {
    // console.log('listing', !!listing?.soldAt)
    return !!listing?.soldAt
  }, [listing])

  const unitLink = useMemo(() => {
    if (!unit || !unit.userId || !unit.id) return ''

    return `/u/${unit.userId}/units/${unit.id}`
  }, [unit])

  const grade = useMemo(() => {
    if (listing && listing.platform !== 'holic') {
      return <span className={ clsx('platform', [listing.platform]) }>{ listing.platform }</span>
    }

    if (unit.condition === 0) {
      return <span className='grade none' title={ t(`collections.conditions.long.${unit.condition}`) }>
        -
      </span>
    }

    return <span className={ `grade grade-${unit.condition}` }>
      <span className='score'>{ t(`collections.conditions.short.${unit.condition}`) }</span>
    </span>
  }, [listing, t, unit.condition])

  const hasImages = useMemo(() => {
    if (!listing) return false // while loading

    if (!images || images.length === 0) return false

    return true
  }, [images, listing])

  const noImages = useMemo(() => {
    if (hasImages) return

    return <div className='no-images'>{ t('listings.no_images') }</div>
  }, [hasImages, t])

  // const setUploading = useState<boolean>(false)[1]

  // const uploadImagesCallback = useCallback((image: Image) => {
  //   setUserImages(true)

  //   setImages(images => [image, ...images ?? []])
  // }, [])

  // const closeImageUploadModal = useCallback(() => {
  //   setUploading(false)
  // }, [setUploading])

  const firstImageAsArray = useMemo(() => {
    if (!images || images.length === 0) return []

    return [images.sort((a, b) => a.id - b.id)[0]]
  }, [images])

  const listingContents = useMemo(() => {
    return <>
      { noImages }
      <ItemImages images={ firstImageAsArray } resolution='2x' navigationStyle='none' />
      <div className='bottom'>
        <div className='item-name'>
          <h3>{ itemName }</h3>
          { /* itemCategoryLink */ }
        </div>
        {
          // editing && unit
          //   ? <div>
          //     <ImageUploadModal
          //       unit={ unit }
          //       callback= { uploadImagesCallback }
          //       onClose={ closeImageUploadModal }
          //       showCancelButton={ false }
          //   />
          //   </div>
          //   : null
        }
        <div className={ clsx('item-details', price ? `price-digits-${price.toString().length}` : '') }>
          { grade }
          { priceUi }
        </div>
      </div>
    </>
  }, [firstImageAsArray, grade, itemName, noImages, price, priceUi])

  return (
    <li className={ clsx('listing', { userImages }, { private: unit?.public === false }, { 'for-sale': listing }, { sold }) } id={ `${unit.id ?? 0}` }>
      {
        editing
          ? <div>{ listingContents }</div>
          : <Link to={ unitLink }>
            { listingContents }
          </Link>
      }
    </li>
  )
}

export { ListingSingle }
