import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { AxiosError } from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { CardSettings, Filters, Item, Tag, Unit } from '../types'
import { itemsRepository } from '../repositories/items_repository'
import { CardSingle } from './card_single'
import { CardsLoading } from './cards_loading'

import '../stylesheets/Top.scss'
import { tagsRepository } from '../repositories/tags_repository'
import { ErrorsContext } from '../contexts/errors_context'
import { unitsRepository } from '../repositories/units_repository'
import { ListingSingle } from './listing_single'
import { SetsList } from './sets_list'
import { AuthContext } from '../contexts/auth_context'
import { SearchContext } from '../contexts/search_context'
import { useViewportDimensions } from '../lib/use_viewport_dimensions'

const maxListingsPerRow = 8

const totalListingsTopPage = maxListingsPerRow * 4

const breakpoints = [
  { width: 1235, itemsPerRow: maxListingsPerRow, topRows: 1 },
  { width: 976, itemsPerRow: 6, topRows: 1 },
  { width: 736, itemsPerRow: 5, topRows: 1 },
  { width: 515, itemsPerRow: 4, topRows: 1 },
  { width: 0, itemsPerRow: 3, topRows: 3 },
]

type SampleCard = Readonly<{
  name?: string
  itemIdsEn: number[]
  itemIdsJa: number[]
}>

const Top = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { isAuthenticated } = useContext(AuthContext)
  const { filters, endpointForExistingFilters, filterMenuDisplayed, setSearchString } = useContext(SearchContext)
  const { addError } = useContext(ErrorsContext)
  const [query, setQuery] = useState<string>('')

  const sampleCards: SampleCard[] = [
    {
      itemIdsJa: [21, 277, 930, 449, 298, 390],
      itemIdsEn: [1897, 4640, 2858, 2291, 4650, 4655],
    },
    {
      name: 'new',
      itemIdsJa: [4879, 10117, 4867, 10035, 4887, 10207],
      itemIdsEn: [4879, 10117, 4867, 10035, 4887, 10207],
    },
    // {
    //   name: 'pikachu',
    //   itemIdsJa: [40, 930, 1002, 985, 518, 583],
    //   itemIdsEn: [985, 1953, 4634, 4628, 3142, 1002],
    // },
    // {
    //   name: 'squirtle',
    //   itemIdsJa: [926, 1615, 129, 212, 28, 1251],
    //   itemIdsEn: [2933, 3040, 925, 2223, 3059, 4703],
    // },
    // {
    //   name: 'charizard',
    //   itemIdsJa: [21, 349, 1630, 214, 1499, 694],
    //   itemIdsEn: [2229, 1897, 3060, 1630, 694, 1941],
    // },
    // {
    //   name: 'eevee',
    //   itemIdsJa: [1637, 607, 223, 224, 697, 1280],
    //   itemIdsEn: [2627, 2461, 3103, 2594, 607, 1637],
    // },
    // {
    //   name: 'mewtwo',
    //   itemIdsJa: [1089, 213, 55, 1265, 935, 738],
    //   itemIdsEn: [4644, 4632, 2238, 4646, 1089, 1377],
    // },
    {
      name: '',
      itemIdsJa: [21, 277, 930, 449, 298, 390],
      itemIdsEn: [1897, 4640, 2858, 2291, 4650, 4655],
    },
  ]

  const selectedSampleCard = useState<SampleCard>(sampleCards[0])[0]
  const [sampleCardItems, setSampleCardItems] = useState<Item[]>([])
  const [itemsLoading, setItemsLoading] = useState<boolean>(true)
  const [tags, setTags] = useState<Tag[]>([])
  const [tagsLoading, setTagsLoading] = useState<boolean>(true)
  const [listingsNew, setListingsNew] = useState<Unit[]>([])
  const [listingsNewLoading, setListingsNewLoading] = useState<boolean>(true)
  // const [cardsLanguage, setCardsLanguage] = useState<string>(i18n.language)

  const cardSettings: CardSettings = useMemo(() => {
    return {
      conditionSelection: false,
      defaultCondition: 0,
      canCollect: true,
      imageResolution: '2x',
    }
  }, [])

  const navigateSearch = useCallback((s: string) => {
    if (!endpointForExistingFilters) return

    let newUrl = endpointForExistingFilters(filters, s) ?? ''

    newUrl = `/categories/${newUrl}`

    navigate(newUrl)
  }, [endpointForExistingFilters, filters, navigate])

  const keyPressDelay = 0.75
  const keyPressTimer = useRef(setTimeout(() => {}, keyPressDelay * 1000))

  const doSomething = useCallback((q: string) => {
    clearInterval(keyPressTimer.current)

    if (!setSearchString || filterMenuDisplayed || q.length < 1) return

    setSearchString(q)

    navigateSearch(q)
  }, [filterMenuDisplayed, navigateSearch, setSearchString])

  const onChangeQuery = useCallback((e) => {
    const q = e.target.value
    setQuery(q)
  }, [])

  useEffect(() => {
    clearInterval(keyPressTimer.current)

    keyPressTimer.current = setInterval(() => { doSomething(query) }, keyPressDelay * 1000)
  }, [doSomething, query])

  const onChangeSampleCard = useCallback((itemIds) => {
    setItemsLoading(true)

    itemsRepository
      .index({ itemIds: itemIds, limit: 6, page: 1 })
      .then(({ items }) => {
        setSampleCardItems(items)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setItemsLoading(false)
      })
  }, [addError])

  const cardsBoosterItems = useState<Item[]>([])
  const cardsBoosterLoading = useState<boolean>(true)
  const cardsNewItems = useState<Item[]>([])
  const cardsNewLoading = useState<boolean>(true)

  const categoriesLinkItems = useCallback((tagId: number, sort?: string) => {
    const path = `/categories/${tagId}`

    if (!endpointForExistingFilters) return path

    const f: Filters = {
      ...filters,
      selectedView: 'items',
    }

    const newUrl = endpointForExistingFilters(f, '') ?? ''

    return path + newUrl + (sort ? `&sort=${sort}` : '')
  }, [endpointForExistingFilters, filters])

  const cards = useMemo(() => {
    return {
      new: {
        itemIds: [37208, 37197, 37195, 37196, 37213, 37209],
        items: cardsNewItems,
        loading: cardsNewLoading,
        titleText: 'ハイクラスパック VSTARユニバース',
        buttonText: 'もっと見る',
        buttonLink: categoriesLinkItems(3371),
      },
      booster: {
        itemIds: [31211, 11501, 32146, 4867, 30452, 31406],
        items: cardsBoosterItems,
        loading: cardsBoosterLoading,
        titleText: 'ポケモンカードゲーム ソード&シールド',
        buttonText: 'もっと見る',
        buttonLink: categoriesLinkItems(1923),
      },
    }
  }, [cardsBoosterItems, cardsBoosterLoading, cardsNewItems, cardsNewLoading, categoriesLinkItems])

  useEffect(() => {
    Object.keys(cards).map(key => {
      const l = cards[key]

      if (l.items[0].length === 0 && l.loading[0]) {
        itemsRepository
          .index({ itemIds: l.itemIds, limit: l.itemIds.length, page: 1 })
          .then(({ items }) => {
            l.items[1](items)
          })
          .catch((err: AxiosError) => {
            addError?.(err)
            l.items[1]([])
          })
          .finally(() => {
            l.loading[1](false)
          })
      }

      return []
    })
  }, [addError, cards])

  const cardsList = useMemo(() => {
    return Object.keys(cards).map(key => {
      const l = cards[key]

      return <div className="featured-cards" key={ key }>
        <div className="heading">
          <h2>{ l.titleText }</h2>
          <Link className="button tool" to={ l.buttonLink }>{ l.buttonText }</Link>
        </div>
        { l.loading[0]
          ? <CardsLoading number={ 6 } style={ 'items' } />
          : l.items[0].length > 0
            ? (
              <ul className='cards-list'>
                {
                  l.items[0].map((item, i) => {
                    return <CardSingle key={ i } item={ item } cardSettings={ cardSettings } />
                  })
                }
              </ul>
              )
            : null
        }
      </div>
    })
  }, [cardSettings, cards])

  const getTags = useCallback(() => {
    const tagIds = {
      en: [2255, 2253, 2254, 2252, 2248, 2247, 2167, 2173, 2183, 2224, 2212, 2195],
      ja: [
        3709, // Scarlet & Violet top
        3371, // V-Star Universe
        3280, // Paradigm Trigger
        3269, // 白熱のアルカナ
        3048, // Pokémon Go
        3027, // Dark Phantasma
        1923, // Sword & Shield top
        1922, // Sun & Moon top
        1920, // XY top
        1910, // Neo top
        42, // Unnumbered Promotional cards
        3, // Expansion Pack
      ],
    }

    const tagsIndexParams = {
      // tagIds: tagIds[cardsLanguage],
      tagIds: tagIds[i18n.language],
      page: 0,
    }

    setTagsLoading(true)

    tagsRepository
      .index({ params: tagsIndexParams })
      .then(({ tags }) => {
        setTags(tags)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setTagsLoading(false)
      })
  }, [addError, i18n.language])

  useEffect(() => {
    // get cards for this search query
    onChangeSampleCard(i18n.language === 'ja' ? selectedSampleCard.itemIdsJa : selectedSampleCard.itemIdsEn)
  }, [onChangeSampleCard, i18n.language, selectedSampleCard.itemIdsJa, selectedSampleCard.itemIdsEn])

  useEffect(() => {
    getTags()
  }, [getTags])

  const searchForm = useMemo(() => {
    return (
      <input type="text" name="query" value={ query } onChange={ onChangeQuery } className="full" />
    )
  }, [onChangeQuery, query])

  // const toggleCardsLanguage = useCallback(() => {
  //   setCardsLanguage(l => l === 'en' ? 'ja' : 'en')
  // }, [])

  const [selectedAboutCard, setSelectedAboutCard] = useState<number>(0)
  const [aboutCardCarouselActive, setAboutCardCarouselActive] = useState<boolean>(true)

  const aboutCardOptions = useMemo(() => [
    {
      label: 'build',
      title: t('top.about.titles.build'),
      text: <strong><>A digital collection</> of cards you own.</strong>,
    },
    {
      label: 'show',
      title: t('top.about.titles.show'),
      text: <strong><>Show off your cards</> to friends and rivals.</strong>,
    },
    {
      label: 'buy',
      title: t('top.about.titles.buy'),
      text: <strong><>Grab the missing cards</> to complete your sets.</strong>,
    },
    {
      label: 'sell',
      title: t('top.about.titles.sell'),
      text: <strong><>Super low fees</> when you sell your cards.</strong>,
    },
  ], [t])

  const aboutDelay = 3
  const timer = useRef(setTimeout(() => {}, aboutDelay * 1000))

  const hoverAboutCard = useCallback((i: number) => {
    clearInterval(timer.current)
    setSelectedAboutCard(i)
  }, [])

  const aboutCards = useMemo(() => {
    return <ul className={ `cards ${i18n.language} selected${selectedAboutCard}` } onMouseOver={ () => setAboutCardCarouselActive(false) } onMouseLeave={ () => setAboutCardCarouselActive(true) }>
      { aboutCardOptions.map((o, i) => <li key={ o.label } className={ clsx({ selected: i === selectedAboutCard }, { wasSelected: i < selectedAboutCard }, o.label) } onMouseOver={ () => hoverAboutCard(i) }>
        <h3>{ o.title }</h3>
      </li>) }
    </ul>
  }, [aboutCardOptions, hoverAboutCard, i18n.language, selectedAboutCard])

  const aboutCardExplanations = useMemo(() => {
    return <ul className="explanations" onMouseOver={ () => setAboutCardCarouselActive(false) } onMouseLeave={ () => setAboutCardCarouselActive(true) }>
      { aboutCardOptions.map((o, i) => <li key={ o.label } className={ clsx({ selected: i === selectedAboutCard }) } onMouseOver={ () => hoverAboutCard(i) }>
        <Trans i18nKey={ `top.about.text.${o.label}` }>
          { o.text }
        </Trans>
      </li>) }
    </ul>
  }, [aboutCardOptions, hoverAboutCard, selectedAboutCard])

  const changeAboutCard = useCallback(() => {
    if (!aboutCardCarouselActive) return

    const newSelectedAboutCard = selectedAboutCard + 1 === aboutCardOptions.length ? 0 : selectedAboutCard + 1
    setSelectedAboutCard(newSelectedAboutCard)
  }, [aboutCardCarouselActive, aboutCardOptions.length, selectedAboutCard])

  useEffect(() => {
    // useRef value stored in .current property
    timer.current = setInterval(() => changeAboutCard(), aboutDelay * 1000)

    // clear on component unmount
    return () => {
      clearInterval(timer.current)
    }
  }, [changeAboutCard])

  const width = useViewportDimensions()[0]

  const currentBreakpoint = useMemo(() => {
    return breakpoints.find(b => width >= b.width) ?? breakpoints[0]
  }, [width])

  const topListingsCount = useMemo(() => {
    return currentBreakpoint.itemsPerRow * currentBreakpoint.topRows
  }, [currentBreakpoint])

  const bottomListingsCount = useMemo(() => {
    const listingCountRaw = listingsNew.length < topListingsCount ? listingsNew.length : listingsNew.length - topListingsCount

    return Math.floor(listingCountRaw / currentBreakpoint.itemsPerRow) * currentBreakpoint.itemsPerRow + currentBreakpoint.itemsPerRow * currentBreakpoint.topRows
  }, [currentBreakpoint.itemsPerRow, currentBreakpoint.topRows, listingsNew.length, topListingsCount])

  const getListings = useCallback((limit, page) => {
    setListingsNewLoading(true)

    unitsRepository
      .index({ platform: 'holic', limit, page })
      .then(({ listings }) => {
        const listingsShuffled = listings
          .map(value => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value)

        setListingsNew(listingsShuffled)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setListingsNewLoading(false)
      })
  }, [addError])

  useEffect(() => {
    getListings(totalListingsTopPage, 0)
  }, [getListings])

  const newListings = useMemo(() => {
    if (listingsNewLoading) {
      return <CardsLoading number={ topListingsCount } style={ 'listings' } />
    }

    return <ul className='listings'>{
      listingsNew.slice(0, topListingsCount)?.map(listing => {
        if (!listing.items) return null

        return <ListingSingle key={ listing.id } editing={ false } items={ listing.items } unit={ listing } />
      })
    }</ul>
  }, [listingsNew, listingsNewLoading, topListingsCount])

  const moreListings = useMemo(() => {
    if (listingsNewLoading) {
      return <CardsLoading number={ bottomListingsCount } style={ 'listings' } />
    }

    return <ul className='listings'>{
      listingsNew.slice(topListingsCount, bottomListingsCount).map(listing => {
        if (!listing.items) return null

        return <ListingSingle key={ listing.id } editing={ false } items={ listing.items } unit={ listing } />
      })
    }</ul>
  }, [bottomListingsCount, listingsNew, listingsNewLoading, topListingsCount])

  const whyJoin = useMemo(() => {
    if (isAuthenticated) return

    return <>
      <hr />
      <div className="center">
        <p>{ t('top.join_why') }</p>
        <p>
          <Link to="register/nologin" className="button">{ t('top.join_button') }</Link>
        </p>
      </div>
    </>
  }, [isAuthenticated, t])

  // const linkNewListings = useMemo(() => {
  //   const path = '/categories'

  //   if (!endpointForExistingFilters) return path

  //   const f: Filters = {
  //     ...filters,
  //     selectedView: 'listings',
  //   }

  //   const newUrl = endpointForExistingFilters(f, '') ?? ''

  //   return path + newUrl + '&sort=created_desc'
  // }, [endpointForExistingFilters, filters])

  const linkCategories = useMemo(() => {
    const path = `/categories/${i18n.language}`

    if (!endpointForExistingFilters) return path

    const f: Filters = {
      ...filters,
      selectedView: 'items',
      // selectedCategories: [],
    }

    const newUrl = endpointForExistingFilters(f, '') ?? ''

    return path + newUrl + '&sort=release_date_desc'
  }, [endpointForExistingFilters, filters, i18n.language])

  const linkOriginalSeries = useMemo(() => {
    const tagId = i18n.language === 'ja' ? 2 : 2152

    return categoriesLinkItems(tagId, 'release_date_asc')
  }, [categoriesLinkItems, i18n.language])

  return (
    <>
      { /* <div className="carousel">
        <h1 className={ `cards ${i18n.language}` }>HOLIC: All your cards from anywhere.</h1>
      </div>
      <div className="carousel">
        <Link to={ '/categories/3048' }><img src={ banner } /></Link>
      </div>
       */ }
      <div>
        <div className="heading">
          <h2>新しい</h2>
          { /* <Link to={ linkNewListings } className="button tool">もっと見る</Link> */ }
          { /* <Link className="button tool" to={ `/categories/${i18n.language === 'ja' ? 2 : 2152}` }>もっと見る</Link> */ }
        </div>
        { newListings }
      </div>
      <div className="center narrow">
        <p>
          <Link to="/categories?view=listings" className="button full">販売中のカードを見る</Link>
        </p>
      </div>
      { cardsList }
      <div className="about-holic">
        <h2>{ t('top.about.title') }</h2>
        { aboutCards }
        { aboutCardExplanations }
        <p>
          <Link to="/register" className="button">{ t('top.about.cta') }</Link>
        </p>
      </div>
      <div className="heading">
        <h2>カード商品</h2>
        <Link to={ linkCategories } className="button tool">もっと見る</Link>
      </div>
      <div className="panels sets-container">
        {
          tagsLoading
            ? <p>{ t('top.loading') }</p>
            : <SetsList tags={ tags } carousel={ currentBreakpoint.width < 1235 } />
        }
      </div>
      <div>
        { /* <div className="heading">
          <h2>新着商品一覧</h2>
        </div> */ }
        { moreListings }
      </div>
      <div className="center narrow">
        <p>
          <Link to="/categories?view=listings" className="button full">販売中のカードを見る</Link>
        </p>
      </div>
      <div className="featured-cards">
        <div className="heading">
          <h2>1番目のシリーズ</h2>
          <Link className="button tool" to={ linkOriginalSeries }>もっと見る</Link>
        </div>
        {
          itemsLoading
            ? <CardsLoading number={ 6 } style={ 'items' } />
            : sampleCardItems.length > 0
              ? (
                <ul className='cards-list'>
                  {
                    sampleCardItems.map((item) => {
                      // return (
                      //   <p key={ i.id }>{ i.nameEn }</p>
                      // )
                      return <CardSingle key={ item.id } item={ item } cardSettings={ cardSettings } />
                    })
                  }
                </ul>
                )
              : null
        }
        { /* <div className="center">
          <p>
            <button className="tool" onClick={ toggleCardsLanguage }>{ cardsLanguage === 'ja' ? 'Switch back' : 'Switch to Japanese cards' }</button>
          </p>
        </div> */ }
      </div>
      <hr />
      <div className="narrow">
        <h2>{ t('top.card_search') }</h2>
        <p>{ t('top.card_search_description') }</p>
        <p>{ searchForm }</p>
        <button onClick={ _ => { doSomething(query) } } className={ clsx('full') } disabled={ query.length < 1 }>検索</button>
      </div>
      { whyJoin }
    </>
  )

  // return (
  //   <div>
  //     <div className="narrow">
  //       <div className="promo">
  //         <h1><span>{ t('top.headline') }</span></h1>
  //       </div>
  //       <p>
  //         { t('top.add_card') }
  //       </p>
  //       <div className="sample-cards">
  //         {
  //           sampleCards.map((sc, i) => {
  //             if (!sc.name) return null

  //             return (
  //               <div
  //                 key={ i }
  //                 className={ clsx({ selected: sc === selectedSampleCard }) }
  //               >
  //                 <button
  //                   className={ clsx('tool selected', sc.name) }
  //                   onClick={ () => setSelectedSampleCard(sc) }
  //                 >
  //                   { sc.name }
  //                 </button>
  //               </div>
  //             )
  //           })
  //         }
  //       </div>
  //     </div>
  // )
}

export { Top }
