import { axiosInstance } from '../lib/api_client'
import { Listing, ListingFee, ListingWarning } from '../types'

const PATH = '/listings'

type UpdateParams = Readonly<{
  id: number
  params: Listing
}>

type CreateUpdateReponse = Readonly<{
  listing?: Listing
  fees: ListingFee[]
  warnings: ListingWarning[]
  children?: Listing[]
}>

type ListingsRepository = Readonly<{
  create: (params: Listing) => Promise<CreateUpdateReponse>
  update: (params: UpdateParams) => Promise<CreateUpdateReponse>
  destroy: (id: number) => Promise<void>
  batch_update_ebay: () => Promise<CreateUpdateReponse>
}>

const listingsRepository: ListingsRepository = {
  async create (params) {
    return await axiosInstance.post(PATH, params).then(({ data }) => data)
  },
  async update ({ params, id }) {
    return await axiosInstance.put(`${PATH}/${id}`, params).then(({ data }) => data)
  },
  async destroy (id) {
    return await axiosInstance.delete(`${PATH}/${id}`).then()
  },
  async batch_update_ebay () {
    return await axiosInstance.get(`${PATH}/batch_update_ebay`).then(({ data }) => data)
  },
}

export { listingsRepository }
