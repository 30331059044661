import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react'
import { renderToString } from 'react-dom/server'
import { Parser } from 'bbcode-to-react'

import { ErrorsContext } from '../contexts/errors_context'
import { blogsRepository } from '../repositories/blogs_repository'
import { Blog } from '../types'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/auth_context'
import { GenericNotFound } from './generic_not_found'
import BlogCreate from './blog_create'

import { emojiPattern } from '../lib/emoji_pattern'
import joypixels from 'emoji-toolkit'

import '../stylesheets/Blog.scss'

type Props = Readonly<{
  userId: number
  id: number
  editMode?: boolean
  _blog?: Blog
}>

const BlogView = ({ id, userId, editMode, _blog }: Props) => {
  const { addError } = useContext(ErrorsContext)
  const { currentUser } = useContext(AuthContext)

  const [blog, setBlog] = useState<Blog|null|undefined>()
  const [loading, setLoading] = useState<boolean>(false)

  const fetchBlog = useCallback((blogId: number) => {
    blogsRepository
      .get(blogId, userId)
      .then(({ blog }) => {
        setBlog(blog)
      })
      .catch((err) => {
        addError?.(err)
        setBlog(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError, userId])

  useEffect(() => {
    if (_blog) {
      setBlog(_blog)
    } else {
      setLoading(true)
    }
  }, [_blog])

  useEffect(() => {
    if (loading) {
      fetchBlog(id)
    }
  }, [fetchBlog, id, blog, loading])

  const editButton = useMemo(() => {
    if (!blog?.id) return null

    // used for blogs list only
    if (_blog) return null

    if (blog.userId !== currentUser?.id) return null

    return (
      <Link to={ `/u/${blog.userId}/blog/${blog.id}/edit` } className={ 'button tool' }>
        編集
      </Link>
    )
  }, [_blog, blog?.id, blog?.userId, currentUser?.id])

  const bodyAsHtml = useMemo(() => {
    if (!blog?.body) return ''

    // strip any html
    const bodySafe = blog.body.replace(/(<([^>]+)>)/ig, '')

    const parser = new Parser()

    const body = parser.toReact(bodySafe)

    return renderToString(body)
  }, [blog?.body])

  const bodyWithEmoji = useMemo(() => {
    if (!bodyAsHtml) return ''

    const prep = bodyAsHtml.split(emojiPattern).map(str => {
      const strEmoji = joypixels.toShort(str)

      return strEmoji.match(/^:[a-z0-9_]+:$/i)
        ? joypixels.toImage(str).replace('joypixels', 'emoji joypixels')
        : strEmoji
    })

    // dangerously convert to html
    return <div className='body' dangerouslySetInnerHTML={ { __html: prep.join('') } } />
  }, [bodyAsHtml])

  const navigate = useNavigate()

  const exitEditMode = useCallback((blog: Blog) => {
    if (!blog.id) return

    setBlog(blog)
    navigate(`/u/${blog.userId}/blog/${blog.id}`)
  }, [navigate])

  if (loading) {
    return <div>Loading...</div>
  }

  if (!blog) {
    return <GenericNotFound />
  }

  if (editMode) {
    return <BlogCreate blog={ blog } callback={ exitEditMode } />
  }

  return (
    <div className='blog'>
      <div className='entry'>
        {
          !_blog
            ? <h2>{ blog.title }</h2>
            : <h2><Link to={ `/u/${blog.userId}/blog/${blog.id ?? 0}` }>{ blog.title }</Link></h2>
        }
        <div className='body'>
          { bodyWithEmoji }
        </div>
        { /* <p>{ blog.publishedAt }</p> */ }
        { /* <p>Likes: { blog.likesCount }</p> */ }
        <p>
          { editButton }
        </p>
      </div>
      <div className='comments'>
      </div>
    </div>
  )
}

export default BlogView
