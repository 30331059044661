import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AxiosError } from 'axios'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

import { StripeAccount } from '../types'
import { countries } from '../lib/countries'
import { stripeAccountRepository } from '../repositories/stripe_accounts_repository'
import { ErrorsContext } from '../contexts/errors_context'
import { Link } from 'react-router-dom'
import { ModalWrapper } from './modal_wrapper'
import { AboutPersonalInformation } from './static'

type Props = Readonly<{
  onClose: (stripeAccount?: StripeAccount) => void
  showCancelButton: boolean
  stripeAccount?: StripeAccount
  buttonText: string
}>

// type AddressForm= Readonly<{
//   address?: Address
// }>

const EditStripeAccountModal = ({ buttonText, onClose, showCancelButton, stripeAccount }: Props) => {
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)
  const [loading, setLoading] = useState<boolean>(false)

  const countriesLocalised = useMemo(() => countries.map(c => { return { ...c, label: t(`account.countries.${c.value}`) } }), [t])

  const {
    register,
    handleSubmit,
    reset,
    // setValue,
    getValues,
    control,
  } = useForm<StripeAccount>()

  // const countryCode = useWatch({
  //   control,
  //   name: 'countryCode',
  // })

  /*
  const [countryCodePrevious, setCountryCodePrevious] = useState<string>('')

  useEffect(() => {
    if (countryCode === countryCodePrevious) return

    const selectedCountry = countries.find(c => c.value === countryCode)
    const selectedCountryPrevious = countries?.find(c => c.value === countryCodePrevious)

    let phoneReplaced = getValues('phone')

    if (selectedCountryPrevious) {
      phoneReplaced = phoneReplaced.replace(`+${selectedCountryPrevious?.callingCode ?? ''}`, '')
    }

    setValue('phone', `+${String(selectedCountry?.callingCode)}${phoneReplaced}`)

    setCountryCodePrevious(countryCode ?? '')
  }, [setValue, getValues, countryCodePrevious, countryCode])
  */

  useEffect(() => {
    if (stripeAccount) {
      reset(stripeAccount)
    } else {
      const defaultStripeAccount = {
        countryCode: 'JP',
      }

      reset(defaultStripeAccount)
    }
  }, [reset, stripeAccount])

  // useEffect(() => {
  //   if (stripeAccount) {
  //     reset(stripeAccount)
  //   } else {
  //     if (process.env.REACT_APP_RAILS_ENV === 'production') return

  //     const defaultStripeAccount = {
  //       // firstName: 'Kenta',
  //       // lastName: 'Nichome',
  //       // birthDate: '1969-04-20',
  //       // gender: 'female',
  //       // nationalityCode: 'JP',
  //       // city: 'Shinjuku',
  //       // addressLine2: '2-chome 2-2',
  //       // addressLine1: 'Mystery Building 222',
  //       // state: 'Tokyo',
  //       // zipCode: '1600001',
  //       // countryCode: 'JP',
  //       // phone: '09022222222',
  //     }

  //     reset(defaultStripeAccount)
  //   }
  // }, [stripeAccount, reset])

  const onSubmit = useCallback((params: StripeAccount) => {
    // TODO: Validation
    if (loading) return

    setLoading(true)

    stripeAccountRepository
      .update(params)
      .then(({ stripeAccount }) => {
        onClose(stripeAccount)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addError, loading, onClose])

  const [aboutModalDisplayed, setAboutModalDisplayed] = useState<boolean>(false)

  const showAboutModal = useCallback((e) => {
    e.preventDefault()
    setAboutModalDisplayed(true)
    return false
  }, [])

  return (
    <>
      {
        aboutModalDisplayed
          ? <ModalWrapper>
            <AboutPersonalInformation />
            <button onClick={ () => setAboutModalDisplayed(false) } className='full'>{ t('account.sell.edit_stripe_account.close_button') }</button>
          </ModalWrapper>
          : null
      }
      <form onSubmit={ handleSubmit(onSubmit) } className='full-height'>
        {
          stripeAccount?.chargesEnabled
            ? (
              <p>
                <label>{ t('account.sell.edit_stripe_account.country') }</label>
                <span>{ countriesLocalised.find(c => c.value === stripeAccount?.countryCode)?.label }</span>
                <input type="hidden" { ...register('countryCode') } value={ stripeAccount?.countryCode } />
              </p>
              )
            : (
              <div className="select">
                <label>{ t('account.sell.edit_stripe_account.country') }</label>
                {
                  <Controller
                    control={ control }
                    name='countryCode'
                    render={ ({ field }) => (
                      <Select
                        options={ countriesLocalised }
                        placeholder={ t('defaults.select') }
                        onChange={ val => field.onChange(val?.value) }
                        value={ countriesLocalised.find(c => c.value === getValues('countryCode')) }
                      />
                    ) }
                  />
                }
              </div>
              )
        }
        {
        // <p>
        //   <label>{ t('account.sell.edit_stripe_account.state') }</label>
        //   <input type="text" className='full' { ...register('state') } />
        // </p>
        // <p>
        //   <label>{ t('account.sell.edit_stripe_account.line3') }</label>
        //   <input type="text" className='full' { ...register('city') } />
        // </p>
        // <p>
        //   <label>{ t('account.sell.edit_stripe_account.postcode') }</label>
        //   <input type="text" className='full' { ...register('zipCode') } placeholder={ t('account.sell.edit_stripe_account.postcode_placeholder') } />
        // </p>
        // <p>
        //   <label>{ t('account.sell.edit_stripe_account.phone') }</label>
        //   <input type="text" className='full' { ...register('phone') } required />
        // </p>
        // <p>{ t('account.sell.edit_stripe_account.more_info') }</p>
        }
        <div className='single-line'>
          <p>
            <label>{ t('account.sell.edit_stripe_account.last_name') }</label>
            <input type="text" className='full' { ...register('lastName') } required />
          </p>
          <p>
            <label>{ t('account.sell.edit_stripe_account.first_name') }</label>
            <input type="text" className='full' { ...register('firstName') } required />
          </p>
        </div>
        <div>
          <label>{ t('account.sell.edit_stripe_account.birth_date') }</label>
          <input type="date" className='full' { ...register('birthDate') } required />
        </div>
        <div className='bottom-element'>
          <p>
            <button className="full" disabled={ loading }>{ buttonText }</button>
          </p>
          <p className='center'><Link className='black small' target='_blank' to='/about/personal-information' onClick={ showAboutModal }>本人情報の登録について →</Link></p>
        </div>
      </form>
      {
        showCancelButton
          ? <p>
            <button className="large tool" onClick={ () => { onClose(stripeAccount) } } disabled={ loading }>
              { t('general.cancel') }
            </button>
          </p>
          : null
      }
    </>
  )
}

export { EditStripeAccountModal }
